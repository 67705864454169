import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Radio, Divider } from 'antd';
import {
  typeOfComparaison,
  listItems
} from '../../../utils/constants/constantes';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';

const EvolutionProjectSelected = ({
  t,
  keyTab,
  version,
  current_status,
  extensionFile,
  setExtensionFile
}) => {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile
        keyTab={keyTab}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
      />
      <Divider />
      <Form.Item
        name={['type_evolution']}
        label={t('reporting.select_type_evolution')}
        rules={[{ required: true }]}
        initialValue={version}
      >
        <Radio.Group
          size="large"
          buttonStyle="solid"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%'
          }}
        >
          {Object.entries(typeOfComparaison).map(([key, value]) => (
            <Radio
              disabled={current_status === 'V0' || current_status === 'V-Init'}
              style={{ margin: '0px 10px 0px 10px' }}
              key={key}
              value={key}
              checked={key === version}
            >
              {value}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['items']} initialValue={[...listItems]}>
        <Select
          className="select-item"
          mode="multiple"
          allowClear
          style={{ width: '100%', color: 'black' }}
          placeholder="Please select"
        >
          {listItems.map((item) => (
            <Select.Option
              key={item}
              value={item}
              label={t(`projects.form.${item}`)}
            >
              {t(`projects.form.${item}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

EvolutionProjectSelected.propTypes = {
  t: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  current_status: PropTypes.string,
  keyTab: PropTypes.string,
  extensionFile: PropTypes.string.isRequired,
  setExtensionFile: PropTypes.func.isRequired
};
EvolutionProjectSelected.defaultProps = {
  current_status: null,
  keyTab: null
};
export default EvolutionProjectSelected;
