import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DashboardContextProvider } from '../../contexts/DashboardContext';
import Projects from './Projects';
import ProjectDashBoard from './ProjectDashboard';

const ActorRouter = () => {
  const { path } = useRouteMatch();
  return (
    <DashboardContextProvider>
      <Switch>
        <Route path={`${path}/:id`} component={ProjectDashBoard} />
        <Route path={path} component={Projects} />
      </Switch>
    </DashboardContextProvider>
  );
};
export default ActorRouter;
