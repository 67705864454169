import { useCallback } from 'react';
import { enums } from './utils';
import { getBeneficiary } from './fields/handleValuesChange';

/**
 * A custom hook to manage form values and interactions related to planning and utility fields.
 *
 * @param {Object} form - The form instance from Ant Design Form.
 * @param {Object} configPlanningUtility - Initial values for planning utility.
 * @param {Object} config -  Fonfiguration for managing form values.
 * @param {Object} dataItems - Data items to populate the form.
 *
 * @returns {Object} - An object containing functions to manage form values.
 */
const useFormValuesManager = (
  form,
  configPlanningUtility,
  config,
  dataItems
) => {
  const setValues = useCallback(
    (setConfigPlanningUtility, setColorGantt) => {
      if (config !== null) {
        const dataToEdit = config.onGetResource.setFields(dataItems);
        form.setFieldsValue(
          config.onGetResource && config.onGetResource.setFields
            ? config.onGetResource.setFields(dataItems)
            : dataItems
        );
        setConfigPlanningUtility({
          ...dataToEdit.deliverables
        });
        setColorGantt(dataToEdit.deliverables.planning?.color || '#000');
      }
    },
    [dataItems]
  );

  const resetDates = (setDateChanged) => {
    setDateChanged(0);
    form.setFieldsValue({
      deliverables: {
        planning: {
          start_date: configPlanningUtility?.planning?.start_date,
          due_date: configPlanningUtility?.planning?.due_date
        }
      }
    });
  };

  const resetBeneficiary = (value, project) =>
    form.setFieldsValue({
      deliverables: {
        utility: { beneficiaries: getBeneficiary(value, project) }
      }
    });

  const setDefaultValueIfPlanning = () => {
    if (configPlanningUtility.planning && config === null) {
      form.setFieldsValue({
        deliverables: {
          planning: {
            action_type: enums.action_types.MACRO_TASK,
            percentage: 0,
            color: '#000',
            piloting_mark: true,
            gantt_mark: true
          }
        }
      });
    }
    if (configPlanningUtility.utility && config === null) {
      form.setFieldsValue({
        deliverables: {
          utility: {
            coefficient: 50
          }
        }
      });
    }
  };

  return { setValues, resetDates, resetBeneficiary, setDefaultValueIfPlanning };
};
export default useFormValuesManager;
