import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BankOutlined,
  ExpandOutlined,
  LineChartOutlined
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useTranslation } from 'react-i18next';
import ButtonManageStatus from './Projects/utilsProject/ButtonManageStatus';
import useProjectContext from '../../contexts/ProjectContext';
import useAuthContext from '../../contexts/AuthContext';
import setIconStatus from '../../utils/constants/setIconStatus';
import DeleteButton from '../../components/DeleteButton/DeleteButton';
import EditButton from '../../components/EditButton/EditButton';
import CreateProjectFormModal from './Projects/CreateProjectFormModal/CreateProjectFormModal';

/**
 * Component for displaying the header of a project.
 *
 * @component
 * @param {Function} checkRole - Function to check the user's role.
 * @param {Object} project - The project data.
 * @returns {JSX.Element} - The project page header.
 */
const ProjectHeader = ({ checkRole, project }) => {
  const { getProject, updateProject, deleteProject } = useProjectContext();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const isAssignee = user?._id === project?.assignee?._id;

  return project ? (
    <>
      <CreateProjectFormModal
        project={project}
        visible={visible}
        setVisible={setVisible}
        handleSubmit={async (values) => {
          await updateProject(project._id, values);
          window.location.reload();
        }}
        purpose="edit"
        loading={false}
      />
      <div style={{ fontSize: '1.5em' }}>{project.code_project}</div>
      <Content className="custom-header">
        <Row
          align="middle"
          justify="space-between"
          style={{ flexWrap: 'nowrap' }}
        >
          <Col xl={22} xxl={20}>
            <Row align="middle" justify="space-between">
              <Col style={{ marginLeft: 16, minWidth: 38 }}>
                {setIconStatus(null, project.status, false, 19)}
              </Col>
              <Col>{project.name}</Col>
              <Col>
                <Row align="middle">
                  <BankOutlined
                    style={{ fontSize: '20px', marginRight: '7px' }}
                  />
                  {project.customer}
                </Row>
              </Col>
              <Col>
                <Row align="middle">
                  <ExpandOutlined
                    style={{ fontSize: '20px', marginRight: '7px' }}
                  />
                  {project.perimeter.name}
                </Row>
              </Col>
              <Col>
                {isAssignee && (
                  <ButtonManageStatus
                    project={project}
                    getProject={() => getProject(project._id)}
                    checkRole={checkRole}
                  />
                )}
              </Col>

              {isAssignee && (
                <Col style={{ marginLeft: 50, minWidth: 38, textAlign: 'end' }}>
                  <Link
                    to={{
                      pathname: `/dashboard/${project._id}`
                    }}
                  >
                    <Button type="text" icon={<LineChartOutlined />}>
                      {t('basiclayout.menu.dashboard')}
                    </Button>
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
          {isAssignee && (
            <Row justify="end" align="middle">
              <EditButton onClick={() => setVisible(true)} />
              <DeleteButton
                onConfirm={async () => {
                  await deleteProject(project._id);
                  history.push('/projects');
                }}
              />
            </Row>
          )}
        </Row>
      </Content>
    </>
  ) : null;
};

ProjectHeader.propTypes = {
  checkRole: PropTypes.func,
  project: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    customer: PropTypes.string,
    status: PropTypes.string,
    assignee: PropTypes.shape({
      _id: PropTypes.string
    }),
    code_project: PropTypes.string,
    perimeter: PropTypes.shape({ name: PropTypes.string })
  })
};

ProjectHeader.defaultProps = {
  checkRole: null,
  project: null
};

export default ProjectHeader;
