import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

/**
 * Create a range filter hook using Ant Design DatePicker component.
 *
 * @hook
 * @returns {RangeFilterHook} An object with functions to get the range filter
 *                           component and to filter values based on the date range.
 */
const useRangeFilter = () => {
  const [timeRange, setTimeRange] = useState();

  const getRangeFilter = () => {
    return { content: <RangePicker onChange={setTimeRange} />, type: 'range' };
  };

  const getRangeFilteredValues = (values, getValueToCompare) => {
    if (!values) return [];
    return values.filter((value) => {
      return (
        moment(getValueToCompare(value))
          .startOf('day')
          .isBetween(
            timeRange?.[0].startOf('day'),
            timeRange?.[1].startOf('day'),
            null,
            '[]'
          ) || !timeRange
      );
    });
  };

  const getRangeFilteredRange = (values, getRangeToCompare) => {
    if (!values) return [];
    return values.filter((value) => {
      const range = getRangeToCompare(value);
      return (
        (moment(range[0]).startOf('day') <= timeRange?.[1].startOf('day') &&
          moment(range[1]).startOf('day') >= timeRange?.[0].startOf('day')) ||
        !timeRange
      );
    });
  };

  return { getRangeFilter, getRangeFilteredValues, getRangeFilteredRange };
};

export default useRangeFilter;
