/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { getIndexByVersionSelected } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';
import { actorsToString, groupsToString } from './utils';
import { checkActionActor } from '../../../Projects/Projects/utilsProject/ManagePlanning/utils';

const planning = (t, project, filter) => {
  const checkDataAndReturnSorting = (data) => {
    if (data.planning) {
      return [
        filter.version === 'VX' ? 'V+' : filter.version,
        data.description,
        t(
          `projects.form.deliverables.planning.${data.planning.action_type.toLowerCase()}`
        ),
        data.global_type,
        data.comment,
        data.planning.start_date &&
          moment(data.planning.start_date).format('DD-MM-YYYY'),
        moment(data.planning.due_date).format('DD-MM-YYYY'),
        actorsToString(data.planning.assigned_actors),
        groupsToString(data.planning.assigned_groups),
        t(`projects.form.deliverables.statuses.${data.planning.status}`),
        `${data.planning.percentage} %`,
        data?.utility?.use_for
          ? t(
              `projects.form.items.utility.deliverables.beneficiaries.${data.utility.use_for}`
            )
          : '',
        data?.utility?.beneficiaries,
        data?.utility?.why,
        data.utility?.coefficient
      ];
    }
    return false;
  };
  const workbook = new Workbook();
  try {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.planing`)}`);
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(true);
    const header = newHeader.manage_planning.map(({ label }) => {
      return t(`projects.form.${label}`);
    });

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    let row;
    let filterbeforeSetData =
      project.manage_planning[
        getIndexByVersionSelected(filter.version, project.manage_planning)
      ].content;
    if (filter.dates) {
      filterbeforeSetData = filterbeforeSetData.filter(
        (el) =>
          el.planning &&
          !el.planning.default &&
          ((el.planning.start_date &&
            moment(el.planning.start_date) >= moment(filter.dates[0]) &&
            moment(el.planning.start_date) <= moment(filter.dates[1])) ||
            (el.planning.due_date &&
              moment(el.planning.due_date) >= moment(filter.dates[0]) &&
              moment(el.planning.due_date) <= moment(filter.dates[1])))
      );
    }
    if (filter.actor) {
      filterbeforeSetData = filterbeforeSetData.filter((el) =>
        checkActionActor(el, filter.actor)
      );
    }
    (filterbeforeSetData || []).forEach((deliverable) => {
      row = checkDataAndReturnSorting(deliverable);
      if (row) worksheet.addRow(row);
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default planning;
