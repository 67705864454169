import React from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/constants/routes';

/**
 * Custom hook to get columns for perimeters
 *
 * @hook
 * @returns {Array} columns
 */
const usePerimeterColumns = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('perimeters.details.name'),
      key: 'name',
      sorter: true,
      fixed: 'left',
      render: (record) =>
        record.default ? t('perimeters.show.default') : record.name
    },
    {
      title: t('perimeters.details.project_number'),
      dataIndex: 'projects',
      key: 'projects',
      sorter: true,
      render: (projects) => (projects ? `${projects.length}` : 'N/A')
    },
    {
      key: 'action',
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `${routes.PERIMETERS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          <Divider type="vertical" />
          <Link to={`${routes.PERIMETERS}/edit/${record.key}`}>
            <EditOutlined style={{ fontSize: 18 }} />
          </Link>
        </>
      )
    }
  ];

  return columns;
};

export default usePerimeterColumns;
