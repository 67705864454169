import React from 'react';
import { Layout, PageHeader } from 'antd';
import { Link } from 'react-router-dom';
// import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import KanbanSettings from './kanbanSettings';
// import CategorySettings from './categorySettings';
// import TicketsStatusSettings from './ticketsStatusSettings';
// import TicketsTypeSettings from './ticketsTypeSettings';
// import AccountingSettings from './AccountingSettings';
// import PermissionsSettings from './PermissionSettings';
// import ImportTemplate from './importTemplate';

const { Content } = Layout;
// const LineSetting = styled.div`
//   background-color: #f0f2f5;
//   padding: 10px;
//   margin-bottom: 30px;
//   p {
//     font-size: 16px;
//     font-weight: bold;
//     margin-top: 5px;
//   }
// `;

const Settings = () => {
  const { t } = useTranslation();
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/parameters',
      breadcrumbName: 'Paramètres'
    }
  ];
  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        title={t('settings.title')}
        onBack={() => window.history.back()}
        ghost={false}
      />
      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        {/* <LineSetting>
          <p>{t('settings.kanbantable.title')}</p>
          <KanbanSettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.permissions.title')}</p>
          <PermissionsSettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.categoriestable.title')}</p>
          <CategorySettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.accounting.title')}</p>
          <AccountingSettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.tickets.status')}</p>
          <TicketsStatusSettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.tickets.type')}</p>
          <TicketsTypeSettings />
        </LineSetting>
        <LineSetting>
          <p>{t('settings.import.title')}</p>
          <ImportTemplate />
        </LineSetting> */}
      </Content>
    </>
  );
};

export default Settings;
