import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CreateIcon } from '../../utils/constants/customIcon';

const AddItemButton = ({ disabled, onClick, title }) => {
  return (
    <Button
      disabled={disabled}
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '24px auto',
        padding: '22px 16px'
      }}
      type="primary"
      onClick={onClick}
    >
      <CreateIcon />
      {title}
    </Button>
  );
};

AddItemButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

AddItemButton.defaultProps = {
  disabled: true,
  onClick: null,
  title: ''
};

export default AddItemButton;
