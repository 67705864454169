import React from 'react';
import { Col, Progress, Row, Statistic, Tag, Tooltip } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { getItemVersion } from '../../Projects/Projects/utilsProject/ManageVersion/utils';

/**
 * Generates a React component for displaying utility information and its variation.
 *
 * @function
 * @param {Array} managePlanningVersions - An array of planning versions.
 * @param {Function} t - A translation function.
 * @param {Function} showModal - A function to show a modal.
 * @param {number} width - The width of the component.
 * @param {string} vue - The view type (e.g., 'actor').
 * @returns {JSX.Element|null} A JSX element representing the utility component.
 */
export default (managePlanningVersions, t, showModal, width, vue) => {
  if (managePlanningVersions !== null && managePlanningVersions.length) {
    const allUtility = [];
    const variations = [];
    const lastUtilityVersion = getItemVersion(managePlanningVersions, -1);

    if (lastUtilityVersion) {
      lastUtilityVersion.content
        .filter((deli) => deli.utility)
        .forEach((e) => {
          allUtility.push(e.utility?.coefficient);
        });
      const penultimateVersion = getItemVersion(managePlanningVersions, -2);
      if (penultimateVersion) {
        penultimateVersion.content
          .filter((deli) => deli.utility)
          .forEach((e) => {
            variations.push(e.utility?.coefficient);
          });
      }

      const utility =
        allUtility.length !== 0
          ? allUtility.reduce((curr, prev) => curr + prev, 0) /
            allUtility.length
          : 50;

      const variation =
        variations.length !== 0
          ? variations.reduce((curr, prev) => curr + prev, 0) /
            variations.length
          : utility;

      return (
        <Col
          xl={4}
          md={8}
          sm={9}
          xs={18}
          style={{
            height: '100%',
            margin: width > 1200 ? '0 24px' : '16px 24px 8px'
          }}
          onClick={() => {
            if (vue !== 'actor') showModal('utility');
          }}
        >
          <Row style={{ marginBottom: 8 }} justify="start">
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.utility`)}
            </span>
          </Row>
          <Row justify="space-between">
            <Col span={12}>
              <Progress
                strokeColor={{
                  '50%': '#ffff00',
                  '51%': '#29ea12'
                }}
                id="white-progress"
                percent={
                  allUtility.length > 0
                    ? Math.round(
                        allUtility.reduce((a, b) => a + b, 0) /
                          allUtility.length
                      )
                    : 0
                }
                width={80}
                type="circle"
              />
            </Col>
            <Col span={12}>
              <Tooltip title={t('projects.details.fluctuation_utility')}>
                <Statistic
                  style={{ marginTop: 30, right: 20 }}
                  id="white-statistic"
                  precision={0}
                  value={utility - variation}
                  valueStyle={
                    utility - variation >= 0
                      ? { color: 'var(--addColor)', fontSize: '17px' }
                      : { color: '#ff0000', fontSize: '17px' }
                  }
                  suffix="%"
                  prefix={
                    variation - utility > 0 ? (
                      <ArrowDownOutlined />
                    ) : (
                      <ArrowUpOutlined />
                    )
                  }
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <>
        <Col
          xl={4}
          md={8}
          sm={9}
          xs={20}
          style={{
            height: '100%',
            margin: width > 1200 ? '0 24px' : '16px 24px 8px'
          }}
          onClick={() => showModal('utility')}
        >
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.15em',
              fontSize: 13
            }}
          >
            {t(`projects.details.utility`)}
          </span>
          <Tag color="orange" style={{ marginRight: 16 }}>
            {t(`projects.details.no_utility`)}
          </Tag>
        </Col>
      </>
    );
  }
  return (
    <Col
      xl={4}
      md={8}
      sm={9}
      xs={18}
      style={{
        height: '100%',
        margin: width > 1200 ? '0 24px' : '16px 24px 8px'
      }}
      onClick={() => showModal('utility')}
    >
      <Row style={{ marginBottom: 8 }} justify="start">
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.15em',
            fontSize: 13
          }}
        >
          {t(`projects.details.utility`)}
        </span>
      </Row>
      <Row justify="center">
        <Progress
          strokeColor={{
            '0%': '#ffffff',
            '51%': '#ffffff'
          }}
          id="white-progress"
          percent={0}
          width={80}
          type="circle"
        />
      </Row>
    </Col>
  );
};
