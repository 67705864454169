import React from 'react';
import PropTypes from 'prop-types';
import { Card, Collapse, Row, Table } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import useColumns from './columns';
import ActorsDescription from '../ActorsDescription';
import useProjectContext from '../../../../../../contexts/ProjectContext';

const { Panel } = Collapse;

/**
 * A React component representing a group card.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {Object} props.groupData - Data about the group.
 * @param {Boolean} props.role - Controls components visibility according to user's role.
 * @param {Object} props.project - Data about the project.
 * @param {Object} props.version - Data about the version.
 * @param {string} props.title - The type of the item.
 * @param {Function} props.openForm - Function to open a form.
 * @param {Function} props.closeForm - Function to close a form.
 * @returns {JSX.Element} The rendered GroupCard component.
 */
const GroupCard = ({
  groupData,
  role,
  project,
  version,
  title,
  openForm,
  closeForm
}) => {
  const { updateResource, getProject } = useProjectContext();
  const { name, actors } = groupData;

  const removeUser = (actor) => {
    updateResource(
      {
        remove_user: {
          group: { _id: groupData._id, $pull: { actors: actor._id } }
        }
      },
      version,
      title,
      'remove_user',
      () => getProject(project._id)
    );
  };

  const columns = useColumns(
    removeUser,
    role,
    groupData.default,
    project?.assignee?._id
  );

  return (
    <Card>
      <Collapse ghost>
        <Panel
          header={
            <ActorsDescription
              data={{ group: groupData }}
              openForm={openForm}
              closeForm={closeForm}
              role={role}
              project={project}
              version={version}
              title={
                <Row align="middle">
                  <TeamOutlined style={{ marginRight: '16px' }} />
                  {name}
                </Row>
              }
            />
          }
        >
          <Table
            columns={columns}
            dataSource={actors}
            pagination={false}
            rowKey={(record) => record._id}
          />
        </Panel>
      </Collapse>
    </Card>
  );
};

GroupCard.propTypes = {
  groupData: PropTypes.shape({
    _id: PropTypes.string,
    default: PropTypes.bool,
    name: PropTypes.string,
    actors: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  role: PropTypes.bool,
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    assignee: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  version: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired
};

GroupCard.defaultProps = {
  role: false,
  title: ''
};

export default GroupCard;
