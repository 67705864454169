import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tabs, Row, Col, Card, Collapse, Button, Modal, Tooltip } from 'antd';
import {
  CaretRightOutlined,
  CaretLeftOutlined,
  PlusOutlined,
  CloseOutlined
} from '@ant-design/icons';
import BalanceSheetModalForm from './BalanceSheetModalForm';
import useBalanceSheetContext from './BalanceSheetContext';
import { V0Icon } from '../../utils/constants/customIcon';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const BalanceSheetTab = ({ data }) => {
  const { t } = useTranslation();

  const {
    visible,
    setVisible,
    setVisibleNone,
    visibleNone,
    config,
    deleteElement
  } = useBalanceSheetContext();
  const type = ['project_organisation', 'realization_project'];
  const axis = ['elements_analyzed', 'causes', 'action_plan'];
  const goodBad = {
    good: (
      <span>
        <V0Icon />
        <span
          style={{
            fontSize: 30,
            fontWeight: 'bold',
            color: 'var(--addColor)',
            position: 'relative',
            right: 21
          }}
        >
          +
        </span>
      </span>
    ),
    bad: (
      <span>
        <V0Icon />
        <span
          style={{
            fontSize: 40,
            fontWeight: 'bold',
            color: 'var(--primaryColor)',
            position: 'relative',
            right: 19
          }}
        >
          -
        </span>
      </span>
    )
  };
  const [activeType, setActiveType] = useState(type[0]);

  return data !== null && data !== undefined ? (
    <>
      <Tabs
        onChange={(activeKey) => setActiveType(activeKey)}
        activeKey={activeType}
      >
        {type.map((e, i) => (
          <TabPane tab={t(`balance_sheet.${e}`)} key={e}>
            <Row justify="space-between">
              {Object.entries(goodBad).map(([key, value]) => (
                <Collapse
                  key={key}
                  style={{
                    marginBottom: 50,
                    width: '100%',
                    backgroundColor: '#FFF',
                    borderRadius: '5px'
                  }}
                  ghost
                >
                  <Panel
                    showArrow={false}
                    header={
                      <Row>
                        <Col
                          style={{
                            textAlign: 'center'
                          }}
                          span={24}
                        >
                          <Tooltip title={t(`balance_sheet.${key}`)}>
                            <span
                              style={{
                                color: key === 'good' ? 'green' : 'red'
                              }}
                            >
                              {value}
                            </span>
                          </Tooltip>
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={24}>
                          <Row>
                            {axis.map((j) => (
                              <Col key={j} span={8}>
                                {t(`balance_sheet.${j}`)}
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    }
                  >
                    <Modal
                      footer={false}
                      onCancel={() => setVisibleNone(false)}
                      closable
                      width={1000}
                      visible={visibleNone}
                    >
                      <BalanceSheetModalForm
                        type={e}
                        goodBad={key}
                        data={data?.balance_sheet}
                      />
                    </Modal>
                    <Row justify="center">
                      <Button
                        style={{ margin: 20, width: 100 }}
                        type="dashed"
                        onClick={() => setVisibleNone(true)}
                        block
                        icon={<PlusOutlined />}
                      />
                    </Row>

                    <Row key={`${e}${key}`} justify="space-between">
                      {data.balance_sheet[e][key].map((ele, index) => (
                        <>
                          {Object.entries(ele).map(([keys, values]) =>
                            keys !== '_id' ? (
                              <React.Fragment key={keys}>
                                <Col span={6}>
                                  <Card key={key}>{values}</Card>
                                </Col>
                                {keys !== 'action_plan' ? (
                                  <span>{`>`}</span>
                                ) : (
                                  <Col style={{ textAlign: 'center' }}>
                                    <CloseOutlined
                                      onClick={() =>
                                        deleteElement(
                                          e,
                                          key,
                                          data.balance_sheet._id,
                                          ele._id
                                        )
                                      }
                                    />
                                  </Col>
                                )}
                              </React.Fragment>
                            ) : null
                          )}
                          <Modal
                            footer={false}
                            onCancel={() => setVisible(false)}
                            closable
                            width={1000}
                            visible={visible}
                          >
                            <BalanceSheetModalForm
                              id={ele._id}
                              type={e}
                              data={data?.balance_sheet}
                              goodBad={key}
                              config={config}
                              index={index}
                            />
                          </Modal>
                        </>
                      ))}
                    </Row>
                  </Panel>
                </Collapse>
              ))}
            </Row>
            <Row justify="end">
              <Button
                onClick={() =>
                  setActiveType(
                    type[e === 'project_organisation' ? i + 1 : i - 1]
                  )
                }
                type="text"
              >
                {e === 'project_organisation' ? (
                  <>
                    {' '}
                    {`${t(`balance_sheet.${type[i + 1]}`)} `}
                    <CaretRightOutlined />
                  </>
                ) : (
                  <>
                    <CaretLeftOutlined />
                    {` ${t(`balance_sheet.${type[i - 1]}`)}`}
                  </>
                )}
              </Button>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </>
  ) : null;
};
BalanceSheetTab.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
BalanceSheetTab.defaultProps = {
  data: null
};

export default BalanceSheetTab;
