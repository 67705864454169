/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { getIndexByVersionSelected } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';
import { checkActionActor } from '../../../Projects/Projects/utilsProject/ManagePlanning/utils';

const gap = (t, project, filter) => {
  const actorsToString = (a) => {
    let result = '';
    a.forEach((actor) => {
      result = `${result}${actor.first_name} ${actor.last_name}, `;
    });

    return result.slice(0, -2);
  };
  const checkDataAndReturnSorting = (data, event) => {
    if (data.planning) {
      return [
        filter.version === 'VX' ? 'V+' : filter.version,
        data.description,
        data.global_type,
        data.comment,
        data.planning.start_date &&
          moment(data.planning.start_date).format('DD-MM-YYYY'),
        moment(data.planning.due_date).format('DD-MM-YYYY'),
        actorsToString(data.planning.assigned_actors),
        t(`projects.form.deliverables.statuses.${data.planning.status}`),
        `${data.planning.percentage} %`,
        moment(event.created_at).format('DD-MM-YYYY'),
        event.comment,
        event.old_start_date &&
          moment(event.old_start_date).format('DD-MM-YYYY'),
        event.old_due_date && moment(event.old_due_date).format('DD-MM-YYYY')
      ];
    }
    return false;
  };
  const workbook = new Workbook();
  try {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.gap`)}`);
    const newHeader = headerByitems(true);
    const header = newHeader.gap.map(({ label }) => {
      return t(`projects.form.${label}`);
    });

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    let row;
    let filterbeforeSetData =
      project.manage_planning[
        getIndexByVersionSelected(filter.version, project.manage_planning)
      ].content;
    if (filter.dates) {
      filterbeforeSetData = filterbeforeSetData.filter(
        (el) =>
          (el.planning.start_date &&
            moment(el.planning.start_date) >= moment(filter.dates[0]) &&
            moment(el.planning.start_date) <= moment(filter.dates[1])) ||
          (el.planning.due_date &&
            moment(el.planning.due_date) >= moment(filter.dates[0]) &&
            moment(el.planning.due_date) <= moment(filter.dates[1]))
      );
    }
    if (filter.actor) {
      filterbeforeSetData = filterbeforeSetData.filter((el) =>
        checkActionActor(el, filter.actor)
      );
    }
    filterbeforeSetData.forEach((deliverable) => {
      deliverable.events.forEach((event) => {
        row = checkDataAndReturnSorting(deliverable, event);
        if (row) worksheet.addRow(row);
      });
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default gap;
