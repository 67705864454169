import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Geom, Axis, Label, Tooltip, Coord } from 'bizcharts';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getDefaultMacroTaskFromVersion } from '../../Projects/Projects/utilsProject/ManagePlanning/utils';

/**
 * Component for displaying a time chart that visualizes the project's duration variation across versions.
 *
 * @component
 * @param {Object} project - The project data containing planning and event information.
 * @param {string} purpose - The purpose of the chart.
 * @param {number} width - The width of the chart.
 * @returns {JSX.Element} Time component
 */
const Time = ({ project, purpose, width }) => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState();

  const defaultPlannings = project?.manage_planning?.map(
    (managePlanningVersion) =>
      getDefaultMacroTaskFromVersion(managePlanningVersion)?.planning
  );

  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };
  const getData = () => {
    let chartData = [];
    if (
      ['V1', 'VX'].includes(project?.status) &&
      defaultPlannings[defaultPlannings.length - 1]
    ) {
      const origin = moment(defaultPlannings[2]?.due_date).diff(
        moment(defaultPlannings[2]?.start_date),
        'days'
      );
      project.manage_planning.slice(1).forEach((managePlanningVersion) => {
        const { event, index } = managePlanningVersion;
        const { start_date, due_date } = getDefaultMacroTaskFromVersion(
          managePlanningVersion
        )?.planning;
        chartData.push({
          id: managePlanningVersion._id,
          version: `V${index} (${moment(event?.date).format('DD-MM-YYYY')})`,
          description: t(`projects.show.indicators.time.event`, {
            name: event?.name,
            date: moment(event?.date).format('DD-MM-YYYY')
          }),
          comment: event?.comment,
          variation: moment(due_date).diff(moment(start_date), 'days') - origin
        });
      });
    } else {
      chartData = undefined;
    }

    setDataChart(chartData);
  };
  useEffect(() => {
    if (project) getData();
  }, [purpose, project]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart renderer="canvas" width={width} height={400} data={dataChart}>
            <span className="main-title" style={styles.mainTitle}>
              {t(`projects.show.indicators.time.description`)}
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col style={{ marginBottom: 10 }} span={24}>
                  <span>
                    {t(`projects.show.indicators.time.V1`, {
                      date: moment(
                        project?.manage_planning[2]?.event?.date
                      ).format('DD-MM-YYYY')
                    })}
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    {`${t(
                      `projects.show.indicators.time.start_date`
                    )}: ${moment(defaultPlannings[2]?.start_date).format(
                      'DD-MM-YYYY'
                    )}`}
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    {`${t(`projects.show.indicators.time.end_date`)}: ${moment(
                      defaultPlannings[2]?.due_date
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>
                <Col span={24}>
                  <span>
                    {`${t(`projects.show.indicators.time.duration`)} ${moment(
                      defaultPlannings[2]?.due_date
                    ).diff(
                      moment(defaultPlannings[2]?.start_date),
                      'days'
                    )} ${t(`projects.show.indicators.time.day`)}`}
                  </span>
                </Col>
              </Row>
            </span>
            <Axis name="variation" />
            <Axis name="version" />
            <Coord transpose={false} />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />

            <Geom
              tooltip={[
                'version*variation*description*comment',
                (version, variation, description, comment) => {
                  return {
                    name: `${version} : ${variation +
                      moment(defaultPlannings[2]?.due_date).diff(
                        moment(defaultPlannings[2]?.start_date),
                        'days'
                      )} ${t(`projects.show.indicators.time.day`)}`,
                    title: `${description}`,
                    value: `${t(
                      `projects.show.indicators.time.comment`
                    )}: ${comment || ''}`
                  };
                }
              ]}
              type="interval"
              position="version*variation"
              color={[
                'variation',
                (variation) => {
                  if (variation > 0) return '#E66F33';
                  return '#24BF4D';
                }
              ]}
            >
              <Label
                content={[
                  'variation',
                  (variation) => {
                    return `${variation} ${t(
                      `projects.show.indicators.time.day`
                    )}`;
                  }
                ]}
              />
            </Geom>
          </Chart>
        </div>
      ) : (
        <span>{t(`projects.show.indicators.error`)}</span>
      )}
    </>
  );
};

Time.propTypes = {
  project: PropTypes.shape({
    status: PropTypes.string,
    manage_planning: PropTypes.arrayOf(
      PropTypes.shape({ event: PropTypes.shape({ date: PropTypes.string }) })
    )
  }),
  purpose: PropTypes.string,
  width: PropTypes.number
};

Time.defaultProps = {
  project: {},
  purpose: null,
  width: null
};

export default Time;
