import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import UpdateOnlyFederation from '../../../../../utilsProject/UpdateOnlyFederation';
import PlanComCollapse from './PlanComCards/PlanComCollapse';
import usePlanComFilters from './PlanComFilters/usePlanComFilters';

/**
 * Component for displaying the events tab in the PlanCom section.
 *
 * @component
 * @param {Object} project - The project data.
 * @param {Object} version - The version object containing planCom items.
 * @param {Function} generateForm - Function to generate form.
 * @param {string} title - The item type.
 * @param {Object} dataDeliverables - Data for deliverables.
 * @param {Function} setDataDeliverables - Function to set deliverables data.
 * @param {Function} getProject - Function to get project data.
 * @param {boolean} role - Control component visibility according to user's role.
 * @returns {JSX.Element} EventsTabPane component
 */
const EventsTabPane = ({
  project,
  version,
  generateForm,
  title,
  config,
  dataDeliverables,
  setDataDeliverables,
  getProject,
  role
}) => {
  const [visibleFederation, setVisibleFederation] = useState(false);
  const [dataFederation, setDataFederation] = useState();
  const [visible, setVisible] = useState();
  const { getPlanComFilters, getFilteredPlanComs } = usePlanComFilters(
    project?.federation_list
  );

  const setDataToFederation = (dataFede) => {
    setDataFederation(dataFede);
    setVisibleFederation(true);
  };

  const openForm = (idRender, com) => {
    setVisible(idRender);
    setDataDeliverables({
      ...com,
      repeat_event: !!(com.repeat?.value && com.repeat?.time_unit),
      repeat_text: com.repeat?.value,
      start_date: moment(com.start_date),
      due_date: moment(com.due_date)
    });
  };

  const closeForm = () => {
    setDataDeliverables();
    setVisible(false);
    getProject();
  };

  const configFederation = {
    onGetResource: {
      setFields: (dataFede) => ({
        ...dataFede
      })
    }
  };

  return (
    <>
      {getPlanComFilters()}
      {getFilteredPlanComs(version?.content)?.map((e) => {
        const { _id } = e;
        return visible === _id && dataDeliverables ? (
          generateForm(
            'edit',
            version,
            title,
            closeForm,
            dataDeliverables,
            'deliverables_plan_com',
            config
          )
        ) : (
          <React.Fragment key={_id}>
            <PlanComCollapse
              planCom={e}
              project={project}
              versionId={version._id}
              role={role}
              setDataToFederation={setDataToFederation}
              openForm={openForm}
              closeForm={closeForm}
              title={title}
            />

            {dataFederation && (
              <UpdateOnlyFederation
                dataItems={dataFederation}
                idVersion={version._id}
                idMatrice={_id}
                projectId={project._id}
                config={configFederation}
                visible={visibleFederation}
                setVisible={setVisibleFederation}
                getProject={getProject}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

EventsTabPane.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    federation_list: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  version: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  generateForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  dataDeliverables: PropTypes.shape({}),
  setDataDeliverables: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  role: PropTypes.bool
};

EventsTabPane.defaultProps = {
  config: null,
  dataDeliverables: null,
  role: false
};

export default EventsTabPane;
