import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Form } from 'antd';

const RadioForChooseTypeOfFile = ({
  keyTab,
  extensionFile,
  setExtensionFile,
  typeOfReport
}) => {
  const typesList = ['docx', 'xlsx', 'pdf'];
  return (
    <Form.Item name={['extension_file']} initialValue={extensionFile}>
      <Radio.Group
        key={keyTab}
        buttonStyle="solid"
        style={{
          margin: '0px 0px 10px 0px',
          width: '100%'
        }}
        onChange={(e) => setExtensionFile(e.target.value)}
      >
        {typesList.map((e) => (
          <Radio
            disabled={typeOfReport === 'meeting_report' && e === 'xlsx'}
            key={e}
            value={e}
          >
            {e}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
RadioForChooseTypeOfFile.propTypes = {
  keyTab: PropTypes.string,
  extensionFile: PropTypes.string.isRequired,
  setExtensionFile: PropTypes.func.isRequired,
  typeOfReport: PropTypes.string
};
RadioForChooseTypeOfFile.defaultProps = {
  keyTab: null,
  typeOfReport: null
};
export default RadioForChooseTypeOfFile;
