import React from 'react';
import { useTranslation } from 'react-i18next';
import useSelectFilter from '../../../utilsProject/Filters/useSelectFilter';
import ItemFilters from '../../../utilsProject/Filters/ItemFilters';
import useSearchFilter from '../../../utilsProject/Filters/useSearchFilter';

/**
 * Custom hook for managing utility filters and filtered utilities.
 *
 * @hook
 * @param {string[]} types - An array of available utility types.
 * @param {string[]} beneficiaries - An array of available utility beneficiaries.
 * @returns {Object} - An object containing utility filter and filtering function.
 */
const useUtilityFilters = (types, beneficiaries) => {
  const { t } = useTranslation();
  const { getSearchFilter, getSearchedValues } = useSearchFilter();
  const {
    getSelectFilter: getTypeSelectFilter,
    getFilteredValues: getTypeFilteredValues
  } = useSelectFilter(types, 'types');
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    beneficiaries,
    'beneficiaries',
    (use_for) =>
      t(`projects.form.items.utility.deliverables.beneficiaries.${use_for}`)
  );

  const getUtilityFilters = () => {
    return (
      <ItemFilters
        filters={[getSearchFilter(), getTypeSelectFilter(), getSelectFilter()]}
      />
    );
  };

  const getFilteredUtilities = (managePlannings) => {
    if (!managePlannings) return [];
    return getFilteredValues(
      getTypeFilteredValues(
        getSearchedValues(
          managePlannings.filter((managePlanning) => managePlanning?.utility),
          (managePlanning) => managePlanning.description
        ),
        (managePlanning) => managePlanning.global_type
      ),
      (managePlanning) => managePlanning.utility.use_for
    );
  };

  return { getUtilityFilters, getFilteredUtilities };
};

export default useUtilityFilters;
