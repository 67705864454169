import React, { useState, useEffect } from 'react';
import { Button, Col, message, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import useColumns from './Columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import CreateProjectFormModal from './CreateProjectFormModal/CreateProjectFormModal';
import useProjectContext from '../../../contexts/ProjectContext';
import useActionColumns from './actionColumns';
import CreateEditModal from '../../../components/CreateUpdateContainer/Modals/CreateEditModal';
import ReassignProjectForm from '../../../components/ProjectCard/ReassignProjectForm/ReassignProjectForm';

/**
 * The Projects component displays a list of projects and provides actions for managing them.
 *
 * @component
 * @returns {JSX.Element} - The Projects component.
 */
const Projects = () => {
  const {
    setProject,
    purpose,
    setPurpose,
    updateProject,
    t
  } = useProjectContext();
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const [getRole, setGetRole] = useState();
  const [dataToUpdate, setDataToUpdate] = useState();
  const [refresh, setRefresh] = useState(false);
  const [visibleCreateUpdateForm, setVisibleCreateUpdateForm] = useState(false);
  const [form] = useForm();
  const [perimeters, setPerimeters] = useState([]);

  const actionColumns = useActionColumns(
    user.role,
    setVisibleCreateUpdateForm,
    setDataToUpdate,
    refresh,
    setRefresh
  );
  const columns = useColumns(actionColumns);

  const getUserRole = () => {
    if (user.role.includes('admins:')) {
      setGetRole('');
    }
    if (user.role === 'managers:PROJECT_MANAGER') {
      setGetRole(`status=V&status=V0&status=V1&status=VX&assignee=${user._id}`);
    }
  };

  const getPerimeters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/perimeters`
      });
      setPerimeters(data);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  useEffect(() => {
    getUserRole();
    setProject();
  }, [getRole]);

  useEffect(() => {
    if (user?.role === 'admins:PERIMETER_ADMIN') {
      (async () => {
        await getPerimeters();
      })();
    }
  }, []);

  const headers = [
    {
      label: 'libellé',
      key: 'name'
    },
    {
      label: 'assignee_to_first_name',
      key: 'assignee.first_name'
    },
    {
      label: 'assignee_to_last_name',
      key: 'assignee.last_name'
    }
  ];

  const showModal = () => {
    setPurpose('create');
    setVisibleCreateUpdateForm(true);
  };

  const createProject = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: 'projects',
        body
      });
      message.success(t('projects.form.title.created'));
      history.push(`/projects/projects/show/${response.data._id}`);
    } catch (e) {
      if (e.response?.data?.description) {
        message.warn(e.response.data.description);
      } else if (e.response) {
        message.warn(e.response.status);
      }
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'create') await createProject(values);
    if (purpose === 'edit') await updateProject(dataToUpdate.key, values);
    setRefresh(!refresh);
    setVisibleCreateUpdateForm(false);
  };

  const addOrUpdateButton = user.role === 'managers:PROJECT_MANAGER' && (
    <Col>
      <Row align="middle">
        <Button type="primary" onClick={showModal}>
          {t('buttons.create_project')}
        </Button>
      </Row>
      <CreateProjectFormModal
        handleSubmit={handleSubmit}
        project={dataToUpdate}
        setDataToUpdate={setDataToUpdate}
        visible={visibleCreateUpdateForm}
        setVisible={setVisibleCreateUpdateForm}
        purpose={purpose}
        setPurpose={setPurpose}
        loading={false}
      />
    </Col>
  );

  const reassignModal = user?.role === 'admins:PERIMETER_ADMIN' && (
    <CreateEditModal
      title={t('projects.form.reassign', {
        project_name: dataToUpdate?.name
      })}
      handleSubmit={() => form.submit()}
      handleCancel={() => {
        setVisibleCreateUpdateForm(false);
        form.resetFields();
        setDataToUpdate();
      }}
      visible={visibleCreateUpdateForm}
    >
      <ReassignProjectForm
        form={form}
        perimeters={perimeters}
        setVisible={setVisibleCreateUpdateForm}
        getProjects={() => setRefresh(!refresh)}
        project={dataToUpdate}
      />
    </CreateEditModal>
  );

  return getRole || user.role.includes('admins:') ? (
    <>
      <SimpleResourceLandingLayout
        columns={columns}
        extraButtons={addOrUpdateButton}
        resourceName="projects"
        withSubRoutes
        populate="assignee,supervisors,perimeter"
        headers={headers}
        resourceModelName="Project"
        customActionColumn
        withUploadButton={false}
        forceRefresh={refresh}
        extraQuery={getRole}
      />
      {reassignModal}
    </>
  ) : null;
};

export default Projects;
