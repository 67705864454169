import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Spin, message as antdMessage } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { outOfNavRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import useFields from '../users/fields';
import useGenerateFormItem from '../../utils/GenerateFormItem';
import useErrorMessage from '../../utils/ErrorMessage';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';

const UpdateProfile = () => {
  const history = useHistory();
  const { id } = useParams();
  const { message } = useErrorMessage();
  const { dispatchAPI, setUser } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [photo, setPhoto] = useState(null);

  const { fields, base64 } = useFields(photo, true);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/users/${id}` });
      form.setFieldsValue({
        ...data,
        date_of_birth: data.date_of_birth && moment(data.date_of_birth)
      });
      setUser(data);
      setPhoto(!!data.photo);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const updateProfile = async (body) => {
    try {
      let newPhoto = body.photo;
      if (base64 !== undefined) {
        if (base64 !== '') newPhoto = base64;
      } else newPhoto = null;
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: { ...body, photo: newPhoto }
      });
      await fetchUser();
      history.push(outOfNavRoutes.PROFILE);
      antdMessage.success(t('profile.message.success'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    await updateProfile(values);
  };

  return (
    <>
      <PageHeaderCustom title={t('profile.title')} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form form={form} onFinish={handleSubmit} {...formItemLayout}>
            {fields.map((field) => generateFields('users', field))}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => history.goBack()}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

export default UpdateProfile;
