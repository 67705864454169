import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children, style }) => {
  return (
    <Content
      style={{
        padding: 24,
        background: 'var(--componentBackground)',
        borderRadius: '0px 0px 5px 5px',
        ...style
      }}
    >
      {children}
    </Content>
  );
};

export default ContentCustom;
