import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * A component that displays the header information for risk item.
 *
 * @component
 * @param {Object} risk - The risk object containing risk details to be displayed in the header.
 * @returns {JSX.Element} A JSX Element representing the RiskCollapseHeader component.
 */
const RiskCollapseHeader = ({ risk }) => {
  const { t } = useTranslation();
  return (
    <Descriptions
      column={6}
      title={`${t(`projects.form.items.risks.number`)} : ${risk?.number}`}
      size="small"
      extra={`${t(`projects.form.items.risks.date`)} : ${moment(
        risk?.decision_date
      ).format('DD-MM-YYYY')} `}
    >
      <Descriptions.Item
        span={2}
        label={t(`projects.form.items.risks.potential_risk`)}
      >
        {risk?.potential_risk}
      </Descriptions.Item>
      <Descriptions.Item label={t(`projects.form.items.risks.type_of_risk`)}>
        {risk?.type_of_risk}
      </Descriptions.Item>

      <Descriptions.Item
        span={2}
        label={t(`projects.form.items.risks.impact_effect`)}
      >
        {risk?.impact_effect}
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        label={t(`projects.form.items.risks.criticality`)}
      >
        {risk?.criticality}
      </Descriptions.Item>
    </Descriptions>
  );
};

RiskCollapseHeader.propTypes = {
  risk: PropTypes.shape({
    _id: PropTypes.string,
    type_of_risk: PropTypes.string,
    potential_risk: PropTypes.string,
    impact_effect: PropTypes.string,
    decision_date: PropTypes.string,
    criticality: PropTypes.number,
    number: PropTypes.number
  })
};

RiskCollapseHeader.defaultProps = {
  risk: {}
};

export default RiskCollapseHeader;
