import { Col, Divider, Row, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import { AdminIcon } from '../../../utils/constants/customIcon';
import { routes } from '../../../utils/constants/routes';
import UsersList from './UsersList';
import ProjectsFilters from './ProjectsFilters';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';
import userPerimeterContext from '../../../contexts/PerimeterContext';
import ListCard from '../../Configuration/ListCard';
import DeleteButton from '../../../components/DeleteButton/DeleteButton';
import EditButton from '../../../components/EditButton/EditButton';

const ShowPerimeter = () => {
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const [users, setUsers] = useState();
  const [perimeterProjects, setPerimeterProjects] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [selectedVersion, setSelectedVersion] = useState();
  const [customers, setCustomers] = useState();
  const [managers, setManagers] = useState();
  const [usersToAddOrRemove, setUsersToAddOrRemove] = useState([]);
  const [purpose, setPurpose] = useState('');
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const iconSize = 18;
  const history = useHistory();
  const {
    getPerimeter,
    deletePerimeter,
    perimeter,
    getPerimeters,
    perimeters,
    loading,
    setLoading
  } = userPerimeterContext();

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?perimeters!=${id}&role!=admins:ADMIN`
      });
      setUsers(data ?? null);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  const getPerimeterProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?perimeter=${id}&populate=assignee,perimeter${
          selectedCustomer ? `&customer=${selectedCustomer}` : ''
        }${selectedManager ? `&assignee=${selectedManager}` : ''}${
          selectedVersion ? `&status=${selectedVersion}` : ''
        }`
      });

      setCustomers([...new Set(data?.map((project) => project.customer))]);
      setManagers(
        Object.values(
          data?.reduce((acc, project) => {
            const { _id } = project.assignee || {};
            if (!acc[_id]) {
              acc[_id] = project.assignee;
            }
            return acc;
          }, {})
        )
      );
      setPerimeterProjects(data ?? []);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  const updatePerimeter = async () => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `perimeters/${id}`,
        body: { usersArray: usersToAddOrRemove, purpose }
      });
      await getPerimeter(id);
      await getUsers();
      setLoading(false);
      setRefresh(!refresh);
      message.success(t(`perimeters.form.edit.succeed`));
    } catch (e) {
      setLoading(false);
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        await getPerimeter(id);
        await getUsers();
        await getPerimeterProjects();
        await getPerimeters();
        setLoading(false);
      })();
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? t(`errors.message.${error.response.status}`)
          : error.message
      );
    }
  }, []);

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        await getPerimeterProjects();
        setLoading(false);
      })();
    } catch (error) {
      setLoading(false);
      message.error(
        error.response
          ? t(`errors.message.${error.response.status}`)
          : error.message
      );
    }
  }, [selectedCustomer, selectedManager, selectedVersion]);

  return perimeter && perimeterProjects && users && perimeters ? (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {perimeter.default
          ? t('perimeters.show.default')
          : `${t('perimeters.show.title')} ${perimeter.name}`}
      </div>
      <Spin spinning={loading} tip={t('global.loading')}>
        {!perimeter.default && (
          <>
            <Row className="custom-header" justify="space-between">
              <Col>
                <Row align="middle">
                  <AdminIcon />
                  <Row style={{ marginLeft: 10 }} align="middle">
                    {`${t('perimeters.header.admin')} : ${
                      perimeter?.assignee
                        ? `${perimeter.assignee.first_name} ${perimeter.assignee.last_name}`
                        : ' - '
                    }`}
                  </Row>
                </Row>
              </Col>
              <Col>
                <PaperClipOutlined
                  style={{ marginRight: 10, fontSize: iconSize }}
                />
                {`${t('perimeters.header.attached_files')} : ${t(
                  `perimeters.header.boolean.${perimeter.attached_files.toString()}`
                )}`}
              </Col>
              <Col>
                <EditButton
                  onClick={() =>
                    history.push(`${routes.PERIMETERS}/edit/${id}`)
                  }
                />
                {user.role === 'admins:PERIMETER_ADMIN' && (
                  <DeleteButton
                    onConfirm={() => deletePerimeter(id)}
                    iconSize={iconSize}
                  />
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={16}>
                <UsersList
                  users={users}
                  perimeter={perimeter}
                  updatePerimeter={updatePerimeter}
                  refresh={refresh}
                  setUsersToAddOrRemove={setUsersToAddOrRemove}
                  purpose={purpose}
                  setPurpose={setPurpose}
                />
              </Col>
              <Col span={8}>
                <ListCard category="PERIMETER" perimeter={perimeter} />
              </Col>
            </Row>
          </>
        )}
        <Divider orientation="left">{t(`perimeters.projects.title`)}</Divider>
        <ProjectsFilters
          setSelectedCustomer={setSelectedCustomer}
          setSelectedManager={setSelectedManager}
          setSelectedVersion={setSelectedVersion}
          customers={customers}
          managers={managers}
        />
        <Row gutter={[48, 32]}>
          {perimeterProjects?.length > 0 ? (
            perimeterProjects.map((e) => (
              <ProjectCard
                key={e._id}
                project={e}
                pathname={`/projects/projects/show/${e._id}`}
                perimeters={perimeters}
                getProjects={getPerimeterProjects}
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                xxl={8}
              />
            ))
          ) : (
            <Row justify="center" style={{ display: 'flex', width: '100%' }}>
              {t('errors.message.no_projects')}
            </Row>
          )}
        </Row>
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

export default ShowPerimeter;
