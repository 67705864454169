import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateUser = ({ purpose }) => {
  const { user, setUser } = useAuthContext();
  const [photo, setPhoto] = useState(null);
  const { fields, isFieldsLoading, base64 } = useFields(
    photo,
    user?.role === 'admins:ADMIN' || purpose === 'create'
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setPhoto(!!data.photo);
        return {
          ...data,
          date_of_birth: data.date_of_birth && moment(data.date_of_birth)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        let newPhoto = data.photo;
        if (base64 !== undefined) {
          if (base64 !== '') newPhoto = base64;
        } else newPhoto = null;
        if (data._id === user._id)
          setUser({ ...user, ...data, photo: newPhoto });
        return {
          ...data,
          photo: newPhoto
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
