import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, message, Button } from 'antd';
import { ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import { getNotificationIcon } from './utils';

/**
 * NotificationCard is a React component that displays a notification card.
 *
 * @component
 * @param {object} notification - The notification data to be displayed.
 * @param {function} getNotifications - A function to retrieve notifications.
 * @returns {ReactNode} - A ReactNode representing the NotificationCard component.
 */
const NotificationCard = ({ notification, getNotifications }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isHovered, setIsHovered] = useState(false);
  const [isCardHovered, setIsCardHovered] = useState(false);

  const [isRead, setIsRead] = useState(notification.isRead);

  const { type, content, translation_key, created_at } = notification;

  const patchNotification = async (response) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/notifications/${notification._id}`,
        body: {
          isRead: !isRead,
          translation_key: response
            ? `notifications.perimeter.actor_activation_${response}`
            : notification.translation_key
        }
      });
      setIsRead(!isRead);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const deleteNotification = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/notifications/${notification._id}`
      });
      await getNotifications();
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const respondToActivationRequest = async (response) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/actor/${notification._id}`,
        body: { response, ...content }
      });
      await patchNotification(response);
      await getNotifications();
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnterCard = () => {
    setIsCardHovered(true);
  };

  const handleMouseLeaveCard = () => {
    setIsCardHovered(false);
  };

  const handleCardClick = (e) => {
    if (translation_key !== 'notifications.perimeter.actor_activation') {
      patchNotification();
    }
    e.stopPropagation();
  };

  const getTimeDisplay = () => {
    const currentDate = new Date();
    const createdAtDate = new Date(created_at);

    const timeDifference = currentDate - createdAtDate;

    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    if (minutesDifference < 60) {
      return `Il y a ${minutesDifference} minutes`;
    }
    if (hoursDifference < 24) {
      return `Il y a ${hoursDifference} heures`;
    }
    return moment(created_at).format('DD-MM-YYYY');
  };

  return (
    <Row
      gutter={[16, 16]}
      align="middle"
      justify="center"
      className="notification-card"
      onClick={handleCardClick}
      onMouseEnter={handleMouseEnterCard}
      onMouseLeave={handleMouseLeaveCard}
      style={{ flexWrap: 'nowrap', height: '70px' }}
    >
      {translation_key !== 'notifications.perimeter.actor_activation' ||
      !isCardHovered ? (
        <>
          <Col>{!isRead && <div className="green-circle" />}</Col>
          <Col span={21}>
            <div>{t(translation_key, content)}</div>
            <Row align="middle" className="notification-time">
              <ClockCircleOutlined style={{ marginRight: 8 }} />
              <span>{getTimeDisplay()}</span>
            </Row>
          </Col>
          <Col onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {isHovered ? (
              <CloseCircleOutlined
                style={{ fontSize: 20, color: 'var(--errorColor)' }}
                onClick={deleteNotification}
              />
            ) : (
              getNotificationIcon(type)
            )}
          </Col>
        </>
      ) : (
        <>
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => respondToActivationRequest('accepted')}
          >
            {t('buttons.accept')}
          </Button>
          <Button
            type="danger"
            onClick={() => respondToActivationRequest('rejected')}
          >
            {t('buttons.reject')}
          </Button>
        </>
      )}
    </Row>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.number,
    content: PropTypes.object,
    translation_key: PropTypes.string,
    created_at: PropTypes.string,
    isRead: PropTypes.bool
  }).isRequired,
  getNotifications: PropTypes.func
};

NotificationCard.defaultProps = {
  getNotifications: () => {}
};

export default NotificationCard;
