import { useTranslation } from 'react-i18next';

/**
 * A custom hook for generating a list of utility details for display.
 *
 * @hook
 * @param {object} utility - The utility data to generate the list for.
 * @returns {Array} An array of utility details with labels and content.
 */
const useUtilityList = (utility) => {
  const { t } = useTranslation();
  if (!utility) return [];

  const utilityList = [
    {
      label: t('projects.form.deliverables.utility.use_for'),
      content: t(
        `projects.form.items.utility.deliverables.beneficiaries.${utility?.use_for}`
      )
    },
    {
      label: t('projects.form.deliverables.utility.beneficiaries'),
      content: utility?.beneficiaries
    },
    {
      label: t('projects.form.deliverables.utility.why'),
      content: utility?.why,
      span: 2
    },
    {
      label: t('projects.form.deliverables.utility.coefficient'),
      content: utility?.coefficient
    }
  ];

  return utilityList;
};

export default useUtilityList;
