import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { enums } from '../../../../../utilsProject/ManagePlanning/utils';

/**
 * A component to display header information for a deliverable.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.deliverable - The deliverable object containing description and planning details.
 * @returns {JSX.Element} - A JSX Element representing the Header component.
 */
const Header = ({ deliverable }) => {
  const { t } = useTranslation();
  const { description } = deliverable;
  const {
    start_date,
    due_date,
    action_type,
    status,
    percentage,
    deleted_to_upper_version
  } = deliverable?.planning;

  const getColorForPercentage = (value) => {
    if (value === 0) return { color: 'red' };
    if (value < 50) return { color: 'var(--clientColor)' };
    if (value > 50) return { color: 'var(--addColor)' };
    return { color: 'var(--clientColor)' };
  };

  return (
    <Descriptions column={6} size="small">
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.description`)}
        span={2}
      >
        {description}
      </Descriptions.Item>
      {action_type !== enums.action_types.DELIVERABLE && (
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.start_date`)}
        >
          <div>
            {start_date ? (
              <Tag>{moment(start_date).format('DD-MM-YYYY')}</Tag>
            ) : (
              <Tag color="red">
                {t(`projects.form.items.utility.deliverables.nodata`)}
              </Tag>
            )}
            {deleted_to_upper_version ? (
              <p style={{ marginTop: 5, color: 'orange' }}>
                {t(
                  `projects.form.items.utility.deliverables.deleted_to_upper_version`
                )}
              </p>
            ) : (
              []
            )}
          </div>
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.due_date`)}
      >
        {due_date ? (
          <Tag>{moment(due_date).format('DD-MM-YYYY')}</Tag>
        ) : (
          <Tag>{t('projects.details.no_data')}</Tag>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.status`)}
      >
        <Tag>{t(`projects.form.deliverables.statuses.${status}`)}</Tag>
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.percentage`)}
      >
        <span style={getColorForPercentage(percentage)}>{percentage}</span>
      </Descriptions.Item>
    </Descriptions>
  );
};

Header.propTypes = {
  deliverable: PropTypes.shape({
    description: PropTypes.string,
    planning: PropTypes.shape({})
  }).isRequired
};

export default Header;
