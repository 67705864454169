import moment from 'moment';

/**
 * Converts ManagePlanning data to chart data for rendering the Gantt chart.
 *
 * @param {Array} managePlannings - The ManagePlanning data.
 * @returns {Array} Chart data for Gantt chart
 */
export default (managePlannings) => {
  const chartData = [];
  (managePlannings || []).forEach((e) => {
    if (e.planning && e.planning.gantt_mark === true) {
      const { start_date, due_date, status, color, action_type } = e.planning;
      if ((start_date, due_date !== null)) {
        chartData.push({
          id: e._id,
          task: `${e._id + e.description}`,
          start_date: start_date
            ? moment(start_date).format('YYYY-MM-DD')
            : moment(due_date).format('YYYY-MM-DD'),
          due_date: moment(due_date).format('YYYY-MM-DD'),
          status,
          description: e.description,
          comment: e.comment,
          color: color || '#000',
          action_type
        });
      }
    }
  });
  return chartData;
};
