import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const ForgotPwdBtn = styled(Button)`
  float: right;
  padding: 0;
  height: auto;
  color: var(--textColor);
  transition: color 0.3s;
  border: none;

  &:hover {
    color: var(--primaryColor);
  }
`;

const LoginForm = ({ switchForm }) => {
  const location = useLocation();
  const { isValid, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  const loginUser = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe
      });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await loginUser(
      values.username.toLowerCase(),
      values.password,
      values.remember
    );
  };

  return (
    <Form
      onFinish={handleSubmit}
      initialValues={{ remember: true }}
      style={{ width: '80%', maxWidth: '400px', maxHeight: '80vh' }}
    >
      {isValid && <Redirect to={from} />}
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
        style={{ marginBottom: 20 }}
      >
        <Input
          prefix={
            <UserOutlined
              style={{ color: 'var(--disabledColor)', marginRight: 5 }}
            />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password
          prefix={
            <LockOutlined
              style={{ color: 'var(--disabledColor)', marginRight: 5 }}
            />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left', marginTop: 15, marginBottom: 30 }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <ForgotPwdBtn onClick={() => switchForm('forgotPwd')}>
          {t('login.forgotPwd')}
        </ForgotPwdBtn>
      </Form.Item>
      <Form.Item style={{ margin: '0 0 16px 0' }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            width: '80%',
            maxWidth: '200px',
            position: 'relative',
            left: '50%',
            transform: 'translate(-50%)'
          }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
      {/* <Form.Item style={{ margin: 0 }}>
        <Button
          type="default"
          style={{
            width: '80%',
            maxWidth: '200px',
            position: 'relative',
            left: '50%',
            transform: 'translate(-50%)'
          }}
          onClick={() => switchForm('register')}
        >
          {t('login.register')}
        </Button>
      </Form.Item> */}
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default LoginForm;
