import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * Custom hook for managing glossary select inputs. It also allows the user to add new options.
 *
 * @hook
 * @param {Array} options - An array of options.
 * @param {boolean} loading - Indicates whether the component is in a loading state.
 * @param {string} type - The type of the glossary (e.g. type or role).
 * @returns {JSX.Element} The rendered glossary select input.
 */
const useGlossarySelect = (options, loading, type) => {
  const { t } = useTranslation();

  const [newOption, setNewOption] = useState();
  const [dataList, setDataList] = useState([]);

  const onNameChange = (event) => {
    setNewOption(event.target.value);
  };

  const addItem = () => {
    if (newOption && newOption !== '') setDataList([...dataList, newOption]);
    setNewOption('');
  };

  return (
    <Select
      loading={loading}
      placeholder={t(`projects.form.placeholder.${type}`)}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <Space style={{ padding: 8 }}>
            <Input
              style={{ flex: 'auto' }}
              value={newOption}
              onChange={onNameChange}
            />
            <Button onClick={() => addItem()}>
              <PlusOutlined />
              {t(`configurations.items.types.${type}`)}
            </Button>
          </Space>
        </div>
      )}
    >
      {[...(options || []), ...dataList].map((option) => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
  );
};

export default useGlossarySelect;
