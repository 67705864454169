import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getItemVersion } from '../../Projects/Projects/utilsProject/ManageVersion/utils';

/**
 * Component for displaying a modal with a membership chart using BizCharts.
 *
 * @component
 * @param {Object} data - The project data containing membership information.
 * @param {string} purpose - The purpose of the chart.
 * @param {number} mean - The mean value for the chart.
 * @returns {JSX.Element} ShowModalBizchart component
 */
const ShowModalBizchart = ({ data, purpose, mean }) => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState({});

  const cols = {
    time: {
      range: [0, 1]
    },
    pourcentage: {
      type: 'linear',
      tickCount: 5,
      ticks: ['0', '25', '50', '75', '100']
    }
  };

  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };

  const getData = () => {
    let chartFede = [];
    const lastVersion = getItemVersion(data.plan_com, -1);
    if (lastVersion?.content) {
      const createChartFedeObject = (item) => ({
        time: moment(item.start_date),
        city: t('projects.details.federation'),
        comment: item.goal_description || '',
        description: `${item.name}`,
        pourcentage: item.federation
      });

      lastVersion.content.forEach((event) => {
        const mainEventObject = event?.federation
          ? [createChartFedeObject(event)]
          : [];

        const duplicateObjects = (event.duplicates || []).flatMap((dup) =>
          dup.federation ? createChartFedeObject({ ...event, ...dup }) : []
        );

        chartFede = chartFede.concat(mainEventObject, duplicateObjects);
      });

      chartFede.sort((a, b) => a.time - b.time);

      const formatedChartFede = chartFede.map((e) => {
        return {
          ...e,
          time: e.time.format('DD-MM-YYYY')
        };
      });

      setDataChart(formatedChartFede);
    }
  };

  useEffect(() => {
    if (data) getData();
  }, [purpose, data]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart
            theme="light"
            padding="auto"
            height={400}
            data={dataChart}
            autoFit
            scale={cols}
          >
            <span className="main-title" style={styles.mainTitle}>
              {t(`projects.show.indicators.membership.description`)}
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col span={24}>
                  <span>
                    {`${t(
                      `projects.show.indicators.membership.mean`
                    )} ${mean} %`}
                  </span>
                </Col>
              </Row>
            </span>
            <Axis />
            <Tooltip shared />
            <Legend />
            <Axis name="time" />
            <Axis
              name="pourcentage"
              label={{
                formatter: (val) => `${val}%`
              }}
            />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />
            <Geom
              type="line"
              position="time*pourcentage"
              size={2}
              color="city"
              shape="smooth"
            />
            <Geom
              tooltip={[
                'description*pourcentage*comment',
                (description, pourcentage, comment) => {
                  return {
                    name: `${comment}`,
                    title: `${t(
                      'projects.show.indicators.membership.event'
                    )}: ${description}`,
                    value: `${pourcentage} %`
                  };
                }
              ]}
              type="point"
              position="time*pourcentage"
              size={4}
              shape="circle"
              color="city"
              style={{
                stroke: '#fff',
                lineWidth: 1
              }}
            />
          </Chart>
        </div>
      ) : (
        <span>{t(`projects.show.indicators.error`)}</span>
      )}
    </>
  );
};

ShowModalBizchart.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string,
  mean: PropTypes.number
};

ShowModalBizchart.defaultProps = {
  data: null,
  purpose: null,
  mean: null
};

export default ShowModalBizchart;
