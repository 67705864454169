import React from 'react';
import PropTypes from 'prop-types';
import GroupCard from './GroupCard';

/**
 * A component that displays group data using either an editable form or a group card.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.groupData - The group data object.
 * @param {boolean} [props.role=false] - Controls components visibility according to user role.
 * @param {object} props.project - The project object.
 * @param {object} props.version - The version object.
 * @param {string} props.title - The title for the component.
 * @param {string} props.visible - The ID of the visible group.
 * @param {function} props.openForm - Function to open the form.
 * @param {function} props.closeForm - Function to close the form.
 * @param {function} props.generateForm - Function to generate the form.
 * @returns {JSX.Element} - A JSX element displaying group data or an editable form.
 */
const GroupDisplay = ({
  groupData,
  role,
  project,
  version,
  title,
  visible,
  openForm,
  closeForm,
  generateForm
}) => {
  const config = {
    onGetResource: {
      setFields: (group) => {
        return {
          actors_list: {
            group: {
              ...group,
              actors: group.actors?.map((actor) => actor._id)
            }
          }
        };
      }
    }
  };

  return (
    <>
      {visible === groupData?._id ? (
        generateForm(
          'edit',
          version,
          title,
          closeForm,
          groupData,
          'actors_list',
          config
        )
      ) : (
        <GroupCard
          groupData={groupData}
          role={role}
          project={project}
          version={version}
          title={title}
          openForm={openForm}
          closeForm={closeForm}
        />
      )}
    </>
  );
};

GroupDisplay.propTypes = {
  groupData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    actors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  role: PropTypes.bool,
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  visible: PropTypes.string,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  generateForm: PropTypes.func
};

GroupDisplay.defaultProps = {
  role: false,
  title: null,
  visible: null,
  generateForm: null
};

export default GroupDisplay;
