import {
  NotificationOutlined,
  ProjectOutlined,
  RiseOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import React from 'react';

/**
 * Get the appropriate notification icon based on the provided type.
 *
 * @param {number} type - The type of notification.
 * @param {string} purpose - The purpose of the notification.
 * @returns {JSX.Element} - A JSX element representing the notification icon.
 */
export const getNotificationIcon = (type, purpose) => {
  switch (type) {
    case 1:
      return <ProjectOutlined style={{ fontSize: 20 }} />;
    case 2:
      return <NotificationOutlined style={{ fontSize: 20 }} />;
    case 3:
      return <RiseOutlined style={{ fontSize: 20 }} />;
    case 4:
      return purpose === 'remove' ? (
        <UserDeleteOutlined style={{ fontSize: 20 }} />
      ) : (
        <UserAddOutlined style={{ fontSize: 20 }} />
      );
    case 6:
      return <UserSwitchOutlined style={{ fontSize: 20 }} />;
    case 7:
      return purpose === 'remove' ? (
        <UserDeleteOutlined style={{ fontSize: 20 }} />
      ) : (
        <UserAddOutlined style={{ fontSize: 20 }} />
      );
    case 8:
      return purpose === 'remove' ? (
        <UserDeleteOutlined style={{ fontSize: 20 }} />
      ) : (
        <UserAddOutlined style={{ fontSize: 20 }} />
      );
    case 9:
      return <TeamOutlined style={{ fontSize: 20 }} />;
    case 10:
      return purpose === 'remove' ? (
        <UserDeleteOutlined style={{ fontSize: 20 }} />
      ) : (
        <UserAddOutlined style={{ fontSize: 20 }} />
      );
    case 11:
      return purpose === 'remove' ? (
        <UserDeleteOutlined style={{ fontSize: 20 }} />
      ) : (
        <UserAddOutlined style={{ fontSize: 20 }} />
      );
    case 12:
      return <UserAddOutlined style={{ fontSize: 20 }} />;
    default:
      return <UserOutlined style={{ fontSize: 20 }} />;
  }
};
