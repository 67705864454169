import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import { routes } from '../../../../utils/constants/routes';
import CollapseFormItem from '../../../Configuration/formItems/CollapseFormItem';
import usePerimeterFields from './perimeterFields';
import useReassignPerimeterField from './reassignPerimeterFields';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import CustomForm from '../../../../components/CustomForm/CustomForm';
import {
  getGlossary,
  useUserGlossary
} from '../../../../utils/constants/configurableItemList';

const CreateUpdatePerimeterForm = ({ form, purpose, perimeter }) => {
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const history = useHistory();
  const [importConfig, setImportConfig] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const generateFields = useGenerateFormItem();
  const { getUserGlossary } = useUserGlossary();

  const { mainFields, importFields, importSwitch } = usePerimeterFields(
    importConfig,
    setImportConfig,
    purpose
  );

  const { reassignPerimeterField } = useReassignPerimeterField();

  const handleSubmit = async (values) => {
    try {
      let method;
      let url = 'perimeters';
      url += values.import_perimeter?.import ? '/import' : '';
      if (purpose === 'create') {
        method = 'POST';
      } else if (purpose === 'edit') {
        method = 'PATCH';
        url += `/${perimeter._id}`;
      }
      const { data } = await dispatchAPI(method, {
        url,
        body: { ...values, ...form.getFieldsValue(true) }
      });
      message.success(t(`perimeters.form.${purpose}.succeed`));
      history.push(
        `${routes.PERIMETERS}/show/${data ? data._id : perimeter._id}`
      );
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  useEffect(() => {
    try {
      if (perimeter) {
        setInitialValues(getGlossary(perimeter));
      } else {
        (async () => {
          const initialGlossary = await getUserGlossary();
          form.setFieldsValue(initialGlossary);
          setInitialValues(initialGlossary);
        })();
      }
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  }, [perimeter]);

  return (
    <CustomForm
      form={form}
      onFinish={handleSubmit}
      handleCancel={() => history.goBack()}
    >
      {user.role === 'admins:PERIMETER_ADMIN' ? (
        <>
          {mainFields.map((field) => generateFields('perimeters', field))}
          {purpose === 'create' && generateFields('perimeters', importSwitch)}
          {importConfig ? (
            importFields.map((field) => generateFields('perimeters', field))
          ) : (
            <>
              <Divider orientation="left">
                {t(`perimeters.form.settings`)}
              </Divider>
              <CollapseFormItem
                glossarySources={perimeter ? [perimeter] : undefined}
                initialValues={initialValues}
              />
            </>
          )}
        </>
      ) : (
        generateFields('perimeters', reassignPerimeterField)
      )}
    </CustomForm>
  );
};

CreateUpdatePerimeterForm.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired,
  purpose: PropTypes.oneOf(['edit', 'create']).isRequired,
  perimeter: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    assignee: PropTypes.shape({ _id: PropTypes.string.isRequired })
  })
};

CreateUpdatePerimeterForm.defaultProps = {
  perimeter: null
};
export default CreateUpdatePerimeterForm;
