import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Button, Spin, Row, Col, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import EventFields from './EventFields/EventFields';
import CommunicationFields from './CommunicationFields/CommunicationFields';

/**
 * Component for managing planning data in a form.
 *
 * @component
 * @param {string|Object} dataItems - Data items.
 * @param {string} projectId - Project ID.
 * @param {Object} config - Configuration options.
 * @param {Function} handleCancel - Function to handle form cancellation.
 * @param {Function} handleSubmit - Function to handle form submission.
 * @param {object} actorsItem - AcotrsItem containing actors and groups.
 * @param {string} purposeArg - Purpose of the form ('create' or 'edit').
 * @param {Object} version - Version data.
 * @returns {JSX.Element} PlanCom form.
 */
const ManagePlanning = ({
  dataItems,
  projectId,
  config,
  handleCancel,
  handleSubmit,
  actorsItem,
  purposeArg,
  version
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [ifDuplicates, setIfDuplicates] = useState();
  const [colorGantt, setColorGantt] = useState('#000');

  const disable = !!ifDuplicates?.length;

  const onChangeColor = (value) => {
    form.setFieldsValue({
      deliverables_plan_com: { color: value.hex || value }
    });
    setColorGantt(value.hex || value);
  };

  const initiaRepeat = form.getFieldValue([
    'deliverables_plan_com',
    'repeat_event'
  ]);

  const setValues = () => {
    form.setFieldsValue(
      config && config.onGetResource && config.onGetResource.setFields
        ? config.onGetResource.setFields(dataItems)
        : dataItems
    );
  };

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });

  const handleFinish = (values) => {
    setIsLoading(true);
    handleSubmit(
      values,
      dataItems,
      'plan_com',
      'deliverables_plan_com',
      handleCancel,
      version
    );
    setIfDuplicates();
  };

  const handleChange = (_, values) => {
    const { repeat } = values.deliverables_plan_com;
    if (repeat?.value) {
      form.setFieldsValue({
        deliverables_plan_com: {
          repeat_text: repeat?.value
        }
      });
    }
  };

  useEffect(() => {
    if (config !== null) {
      const duplicate = config.onGetResource.setFields(dataItems);
      setIfDuplicates(duplicate?.deliverables_plan_com?.duplicates);
    }
    setValues();
  }, []);

  useEffect(() => {
    setColorGantt(form.getFieldValue(['deliverables_plan_com', 'color']));
  }, [form.getFieldValue(['deliverables_plan_com', 'color'])]);

  return (
    <>
      <Spin spinning={isLoading} />
      <Col span={24}>
        <Form
          name="plan_com"
          layout="vertical"
          onValuesChange={handleChange}
          onFinish={handleFinish}
          form={form}
        >
          <EventFields
            initiaRepeat={initiaRepeat}
            disable={disable}
            config={config}
            projectId={projectId}
            dataItems={dataItems}
          />
          <Divider />
          <CommunicationFields
            purposeArg={purposeArg}
            actorsItem={actorsItem}
            colorGantt={colorGantt}
            onChangeColor={onChangeColor}
            repeated={disable}
          />

          <Form.Item style={{ marginBottom: 0 }}>
            <Row
              justify="end"
              wrap={false}
              style={{
                position: 'relative'
              }}
            >
              <Col>
                <Button
                  style={{ margin: '0 6px' }}
                  type="link"
                  danger
                  onClick={() => {
                    form.resetFields();
                    setIfDuplicates();
                    handleCancel();
                  }}
                >
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                  </span>
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="add" htmlType="submit">
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.save')} `}
                  </span>
                  <CheckOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

ManagePlanning.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  actorsItem: PropTypes.shape({}),
  purposeArg: PropTypes.string,
  version: PropTypes.shape({}).isRequired
};
ManagePlanning.defaultProps = {
  dataItems: null,
  config: null,
  handleCancel: null,
  handleSubmit: null,
  actorsItem: {
    actors: [],
    groups: []
  },
  purposeArg: 'create'
};

export default ManagePlanning;
