import React from 'react';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import useGlossarySelect from '../../../../fieldsItems/Inputs/useGlossarySelect';
/**
 *
 * Custom hook that generates common fields for managing deliverables.
 * @param {string} purposeArg - Purpose argument indicating the use case.
 * @param {string[]} planningUtitlityList - Array of planning utility list.
 * @returns {Array} An array of common fields.
 */
const useCommonFields = (purposeArg, planningUtitlityList) => {
  const disabled = purposeArg === 'actor';

  const typeSelect = useGlossarySelect(planningUtitlityList, false, 'type');

  const commonFields = [
    {
      name: ['deliverables', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'description'],
      rules: [{ required: true }],
      input: <Input disabled={disabled} row={6} />
    },
    {
      name: ['deliverables', 'global_type'],
      rules: [{ required: true }],
      input: typeSelect
    },
    {
      name: ['deliverables', 'comment'],
      input: <TextArea disabled={disabled} row={6} />
    }
  ];

  return commonFields;
};

export default useCommonFields;
