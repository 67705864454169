import { useTranslation } from 'react-i18next';

/**
 * Custom React hook for generating columns configuration for the perimeter admins table.
 *
 * @hook
 * @param {Array} action - An array representing an action column configuration.
 * @return {Array} Columns configuration for the table.
 * @example
 */
const useColumns = (action) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.users'),
      key: 'users',
      render: (record) => (record.users ? record.users.length : 0),
      sorter: true
    },
    action[0]
  ];

  return columns;
};

export default useColumns;
