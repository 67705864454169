import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'antd';
import PlanComCardHeader from './Header';
import PlanComDescription from './PlanComDescription';
import RepeatedEventTable from './RepeatedEventTable/RepeatedEventTable';

const { Panel } = Collapse;

/**
 * Component for collapsing and expanding a PlanCom card.
 *
 * @component
 * @param {Object} planCom - The PlanCom data.
 * @param {Object} project - The project data.
 * @param {string} versionId - The version ID.
 * @param {boolean} role - Control component visibility according to user's role.
 * @param {Function} setDataToFederation - Function to set data to federation.
 * @param {Function} openForm - Function to open form.
 * @param {Function} closeForm - Function to close form.
 * @param {string} title - The item type.
 * @returns {JSX.Element} PlanComCollapse component
 */
const PlanComCollapse = ({
  planCom,
  project,
  versionId,
  role,
  setDataToFederation,
  openForm,
  closeForm,
  title
}) => {
  return (
    <Collapse key={planCom._id}>
      <Panel
        style={{ marginBottom: 16 }}
        header={
          <PlanComCardHeader
            planCom={planCom}
            project={project}
            versionId={versionId}
            role={role}
            setDataToFederation={setDataToFederation}
            openForm={openForm}
            closeForm={closeForm}
            title={title}
          />
        }
      >
        <PlanComDescription planCom={planCom} />
        <Row gutter={16} style={{ padding: '0 16px' }}>
          <Col span={12}>
            {planCom.repeat && (
              <RepeatedEventTable events={planCom.duplicates} display="past" />
            )}
          </Col>
          <Col span={12}>
            {planCom.repeat && (
              <RepeatedEventTable
                events={planCom.duplicates}
                display="scheduled"
              />
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

PlanComCollapse.propTypes = {
  planCom: PropTypes.shape({
    _id: PropTypes.string,
    repeat: PropTypes.shape({}),
    duplicates: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  project: PropTypes.shape({}).isRequired,
  versionId: PropTypes.string.isRequired,
  role: PropTypes.bool,
  setDataToFederation: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

PlanComCollapse.defaultProps = {
  role: false
};

export default PlanComCollapse;
