import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Tooltip, message, Popconfirm } from 'antd';
import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import { DeleteIcon } from '../../../../utils/constants/customIcon';
import ModalToConfirmDeleteWithVersionning from './ModalToConfirmDeleteWithVersionning';
import useProjectContext from '../../../../contexts/ProjectContext';
import CreateUpdateForm from './CreateUpdateForm';
import { isLastItemVersion } from './ManageVersion/utils';
import { getVersionModelByType } from '../../../../utils/manageItems/utils';

const ButtonDeleteAll = ({
  purpose,
  title,
  titleId,
  projectId,
  matriceName,
  matriceId,
  handleCancel,
  version
}) => {
  const { confirm } = Modal;
  const { dispatchAPI } = useAuthContext();
  const {
    project,
    loading,
    createResource,
    deleteResourceFromItemOrMatrice
  } = useProjectContext();
  const [visibleEvent, setVisibleEvent] = useState(false);
  const { t } = useTranslation();
  const modalRef = useRef();
  const [objectData, setObjectdata] = useState();

  const deleteResource = async () => {
    try {
      const versionRoute =
        getVersionModelByType(title) === 'Version'
          ? 'versions'
          : 'objectVersions';
      await dispatchAPI('DELETE', {
        url: `projects/${versionRoute}/delete/${projectId}/${version?._id}`
      });

      message.success(t('projects.version.delete_success'));
      handleCancel();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const deleteProject = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/projects/${projectId}` });
      await handleCancel();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const showConfirm = (titleConfirm, cancelTex) => {
    confirm({
      title: titleConfirm,
      closable: true,
      icon: <ExclamationCircleOutlined />,
      okText: 'Oui',
      cancelText: cancelTex,
      onOk() {
        if (purpose === 'project') deleteProject();
        else deleteResource();
      }
    });
  };
  const openModal = (
    dataT,
    titleModal,
    indexItem,
    action,
    fields,
    configArg
  ) => {
    setObjectdata({
      data_to_send: dataT,
      title: titleModal,
      index: indexItem,
      purpose: action,
      config: configArg,
      field_name: fields,
      visible: true
    });

    setVisibleEvent(true);
  };

  const deleteSubmit = () => {
    if (purpose === 'version') {
      const titleConfirm = t('projects.version.delete');
      const cancelText = t('projects.version.keep');
      showConfirm(titleConfirm, cancelText);
    }
    if (purpose === 'matrice') {
      if (
        version?.index > 1 &&
        project &&
        isLastItemVersion(project[title], version)
      )
        modalRef.current.toggleModal(
          projectId,
          title,
          titleId,
          matriceName,
          matriceId
        );
      else
        deleteResourceFromItemOrMatrice(
          projectId,
          title,
          version?._id,
          matriceId
        );
    }
    if (purpose === 'project') {
      const titleConfirm = t(`buttons.delete_project`);
      const cancelText = t('buttons.cancel');
      showConfirm(titleConfirm, cancelText);
    }
  };
  const handleSubmitEvent = async (values) => {
    await createResource(
      values,
      title,
      version?._id,
      matriceId,
      setVisibleEvent(!visibleEvent)
    );
  };

  useEffect(() => {}, [visibleEvent]);

  return (
    <>
      <Popconfirm
        title={t('datatable.column.action.delete.title')}
        okText={t('datatable.column.action.delete.ok')}
        okButtonProps={{ type: 'danger' }}
        cancelText={t('datatable.column.action.delete.cancel')}
        onConfirm={(e) => {
          e.stopPropagation();
          deleteSubmit();
        }}
        icon={<WarningOutlined />}
      >
        <Tooltip title={t(`buttons.delete`)}>
          <Button
            disabled={project?.status === 'close'}
            style={{
              color: purpose === 'project' ? '#fff' : 'var(--errorColor)',
              background: 'transparent',
              border: 'none',
              padding: 4,
              boxShadow: 'none'
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </Popconfirm>
      <ModalToConfirmDeleteWithVersionning
        destroyOnClose
        openModal={openModal}
        ref={modalRef}
      />
      <Modal
        title={t(`projects.form.items.${title}.title`)}
        visible={visibleEvent}
        footer={false}
        destroyOnClose
        onCancel={() => {
          setVisibleEvent(false);
          handleCancel();
        }}
        width={1200}
      >
        <CreateUpdateForm
          purpose={purpose}
          resource="projects"
          title={objectData?.title}
          handleCancel={handleCancel}
          projectId={project?._id}
          data={objectData?.data_to_send}
          config={objectData?.config || null}
          index={null}
          fieldsName={objectData?.field_name}
          handleSubmit={handleSubmitEvent}
          version={version}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

ButtonDeleteAll.propTypes = {
  purpose: PropTypes.string.isRequired,
  title: PropTypes.string,
  projectId: PropTypes.string,
  matriceName: PropTypes.string,
  handleCancel: PropTypes.func,
  titleId: PropTypes.string,
  matriceId: PropTypes.string,
  version: PropTypes.shape({
    _id: PropTypes.string,
    index: PropTypes.number
  })
};

ButtonDeleteAll.defaultProps = {
  title: null,
  projectId: null,
  matriceName: null,
  handleCancel: null,
  titleId: null,
  matriceId: null,
  version: null
};

export default ButtonDeleteAll;
