import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import useAuthContext from './AuthContext';

const PerimeterContext = createContext({});

export const PerimeterContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [perimeter, setPerimeter] = useState();
  const [perimeters, setPerimeters] = useState();
  const history = useHistory();

  const getPerimeter = useCallback(async (id) => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/perimeters/${id}?populate=assignee,users,projects`
      });
      setPerimeter(data);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
    setLoading(false);
  }, []);

  const getPerimeters = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/perimeters?default=false&populate=assignee`
      });
      setPerimeters(data);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
    setLoading(false);
  }, []);

  const deletePerimeter = useCallback(async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/perimeters/${id}`
      });
      history.push('/perimeters');
      message.success(t(`perimeters.delete.succeed`));
      setRefresh(!refresh);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  }, []);

  return (
    <PerimeterContext.Provider
      value={{
        getPerimeter,
        deletePerimeter,
        perimeter,
        setPerimeter,
        getPerimeters,
        perimeters,
        loading,
        setLoading,
        refresh,
        setRefresh
      }}
    >
      {children}
    </PerimeterContext.Provider>
  );
};

PerimeterContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(PerimeterContext);
