import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const useWhatWhyFields = () => {
  const whatWhyFields = [
    {
      name: ['_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['description'],
      rules: [{ required: true }],
      input: <TextArea rows={4} />
    }
  ];

  return { whatWhyFields };
};

export default useWhatWhyFields;
