import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, DatePicker, message } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../../contexts/AuthContext';
import { getLastItemVersion } from '../utilsProject/ManageVersion/utils';

const useMainFields = (setPerimeter, initialValues, form) => {
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const [supervisorsLoading, setsupervisorsLoading] = useState();
  const [perimeterLoading, setPerimeterLoading] = useState(true);

  const [perimeters, setPerimeters] = useState([]);
  const [managers, setManagers] = useState();

  const dateFormat = 'DD-MM-YYYY';

  const getManagers = async (perimeterId) => {
    try {
      form.setFieldValue('supervisors', []);
      setsupervisorsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=managers:PROJECT_MANAGER&perimeters=${perimeterId}`
      });
      setManagers(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setsupervisorsLoading(false);
  };

  const handlePerimeterChange = async (newPerimeterId) => {
    try {
      setPerimeter(
        perimeters.find((perimeter) => perimeter._id === newPerimeterId)
      );
      form.setFieldValue(['event', 'type'], null);
      await getManagers(newPerimeterId);
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  const getPerimeters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/perimeters?users=${user._id}`
      });
      setPerimeters(data);
    } catch (e) {
      if (e.response) message.error(t(`errors.message.${e.response.status}`));
    }
  };

  useEffect(() => {
    try {
      setPerimeterLoading(true);
      (async () => {
        await getPerimeters();
        if (initialValues?.perimeter) {
          await getManagers(
            initialValues?.perimeter._id || initialValues?.perimeter
          );

          const defaultProject = {
            ...initialValues,
            perimeter: initialValues.perimeter.default
              ? null
              : initialValues.perimeter._id
          };

          if (initialValues.manage_planning) {
            const defaultDeliverable = getLastItemVersion(
              initialValues.manage_planning
            ).content.find((deliverable) => deliverable.planning.default);

            defaultProject.start_date = moment(
              defaultDeliverable.planning.start_date
            );
            defaultProject.end_date = moment(
              defaultDeliverable.planning.due_date
            );
          }

          form.setFieldsValue(defaultProject);
        }
        setPerimeterLoading(false);
      })();
    } catch (error) {
      if (error.message) message.error(error.message);
      setPerimeterLoading(false);
    }
  }, [initialValues]);

  const mainFields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['code_project'],
      rules: [{ required: true }]
    },
    {
      name: ['customer'],
      rules: [{ required: true }]
    },
    {
      name: ['perimeter'],
      rules: [{ required: true }],
      input: (
        <Select onChange={handlePerimeterChange} loading={perimeterLoading}>
          {perimeters?.map((perimeter) => (
            <Select.Option key={perimeter._id} value={perimeter._id}>
              {perimeter.name}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['supervisors'],
      input: (
        <Select
          mode="multiple"
          disabled={!managers}
          loading={supervisorsLoading}
        >
          {managers?.map(
            (manager) =>
              manager._id !== user._id && (
                <Select.Option key={manager._id} value={manager._id}>
                  {`${manager.first_name || ''} ${manager.last_name || ''}`}
                </Select.Option>
              )
          )}
        </Select>
      )
    },
    {
      name: ['start_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} defaultPickerValue={moment()} />
    },
    {
      name: ['end_date'],
      rules: [{ required: true }],
      input: <DatePicker format={dateFormat} defaultPickerValue={moment()} />
    }
  ];

  return { mainFields };
};

export default useMainFields;
