import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Collapse, Row, Divider, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  HighlightOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { routes } from '../../../utils/constants/routes';
import Datatable from '../../../components/DataTable/Datatable';
import Columns from '../Templates/Columns';
import useErrorMessage from '../../../utils/ErrorMessage';
import { listTypeOfReport } from '../../../utils/constants/constantes';

const { Panel } = Collapse;

const iconSize = 18;

const DocumentsManager = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { pathname } = useLocation();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});
  const [isSigning, setIsSigning] = useState({});
  const routesFiltered = {
    REPORTING: routes.REPORTING,
    BALANCE_SHEET: routes.BALANCE_SHEET
  };

  const deleteFile = async (id) => {
    try {
      if (
        usedTemplates.length > 0 &&
        usedTemplates.find((te) => te.value === id)
      ) {
        return message('used_file');
      }

      await dispatchAPI('DELETE', { url: `/files/${id}` });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return true;
  };

  const downloadFile = async (id, name, contentType) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: contentType
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getSignURL = async (fileId) => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/sign/${fileId}` });

      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsSigning({ ...isSigning, [fileId]: false });
  };

  const handleSignFile = async (fileId) => {
    const { redirectUrl } = await getSignURL(fileId);

    if (redirectUrl) {
      window.location.replace(redirectUrl);
    } else {
      message(500);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?unitType=TEMPLATE'
      });
      setUsedTemplates(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    await getTemplateSetting();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const actionColumn = [
    {
      key: 'action',
      render: ({ key, contentType, metadata }) => {
        return (
          <span>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={async () => {
                if (!isDownloading[key]) {
                  setIsDownloading({ ...isDownloading, [key]: true });
                  await downloadFile(key, metadata.originalName, contentType);
                }
              }}
            >
              {isDownloading[key] ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>
            {contentType === 'application/pdf' && user.docusign_token && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={async () => {
                    if (!isSigning[key]) {
                      setIsSigning({ ...isSigning, [key]: true });
                      await handleSignFile(key);
                    }
                  }}
                >
                  {isSigning[key] ? (
                    <LoadingOutlined />
                  ) : (
                    <HighlightOutlined style={{ fontSize: iconSize }} />
                  )}
                </Button>
              </>
            )}
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              icon={<WarningOutlined />}
              onConfirm={async () => {
                setForceRefresh(false);
                await deleteFile(key);
              }}
            >
              <DeleteOutlined
                style={{ color: 'red', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </span>
        );
      }
    }
  ];

  const generateCollapse = () => (
    <Collapse style={{ marginTop: 24 }}>
      {Object.keys(routesFiltered).map((route) => (
        <Panel
          header={t(`basiclayout.menu.${route.toLowerCase()}`)}
          key={route}
        >
          {route === 'REPORTING' ? (
            listTypeOfReport.map((type) => (
              <Collapse key={type}>
                <Panel
                  header={t(`reporting.type.${type}`)}
                  key={type}
                  style={{ padding: '0px !important' }}
                >
                  <Datatable
                    resourceName="files"
                    columns={[...Columns(t), ...actionColumn]}
                    extraQuery={`type=reporting_${type}`}
                    customActionColumn
                    forceRefresh={forceRefresh}
                  />
                </Panel>
              </Collapse>
            ))
          ) : (
            <Datatable
              resourceName="files"
              columns={[...Columns(t), ...actionColumn]}
              extraQuery={`type=parent_${route}`}
              customActionColumn
              forceRefresh={forceRefresh}
            />
          )}
        </Panel>
      ))}
    </Collapse>
  );

  return (
    <>
      <PageHeaderCustom title={t('ged.title')} />
      <ContentCustom>
        <Row justify="end" gutter={[8, 16]}>
          <Col>
            <Row align="middle">
              <Link to={`${pathname}/create`}>
                <Button type="add">
                  {`${t('buttons.create')}`}
                  &nbsp;
                  <PlusOutlined />
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
        {generateCollapse()}
      </ContentCustom>
    </>
  );
};

export default DocumentsManager;
