import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ShowPerimeterAdmin from './ShowPerimeterAdmin';
import PerimeterAdmins from './PerimeterAdmins';
import { routes } from '../../utils/constants/routes';

/**
 * React component representing the router for managing perimeter group of perimeter administrators.
 *
 * @component
 * @return {JSX.Element} React component for the perimeter administrators router.
 * @example
 */
const PerimeterAdminsRouter = () => {
  return (
    <Switch>
      <Route
        path={`${routes.PERIMETER_ADMINS}/show/:id`}
        component={ShowPerimeterAdmin}
      />
      <Route path={`${routes.PERIMETER_ADMINS}`} component={PerimeterAdmins} />
    </Switch>
  );
};
export default PerimeterAdminsRouter;
