import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';

/**
 * A custom input component for Federation.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {function} props.onChange - A function to handle value changes.
 * @param {number} props.defaultValue - The default value for the input.
 * @returns {JSX.Element} - A JSX Element representing the FederationInput.
 */
const FederationInput = ({ onChange, defaultValue }) => (
  <InputNumber
    defaultValue={defaultValue}
    onChange={onChange}
    style={{ width: '50px' }}
    min={0}
    max={100}
  />
);

FederationInput.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.number
};

FederationInput.defaultProps = {
  onChange: () => {},
  defaultValue: 0
};

export default FederationInput;
