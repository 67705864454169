import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker, Divider } from 'antd';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';

const { RangePicker } = DatePicker;
const MeetingReport = ({
  keyTab,
  extensionFile,
  setExtensionFile,
  typeOfReport
}) => {
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile
        keyTab={keyTab}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
        typeOfReport={typeOfReport}
      />
      <Divider />
      <Form.Item name={['dates']}>
        <RangePicker />
      </Form.Item>
    </div>
  );
};
MeetingReport.propTypes = {
  keyTab: PropTypes.string,
  extensionFile: PropTypes.string,
  setExtensionFile: PropTypes.func,
  typeOfReport: PropTypes.string
};

MeetingReport.defaultProps = {
  keyTab: undefined,
  typeOfReport: undefined,
  extensionFile: undefined,
  setExtensionFile: undefined
};

export default MeetingReport;
