import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from './AuthContext';
import {
  WhatIcon,
  WhyIcon,
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  UtilityIcon,
  PlanComIcon,
  RiskIcon
} from '../utils/constants/customIcon';
import { getItemByType } from '../utils/manageItems/utils';

const ProjectContext = createContext({});

/**
 * Context provider for managing project-related data.
 *
 * @component
 * @param {object} props - Props for the ProjectContextProvider component.
 * @param {ReactNode} props.children - The child components to be wrapped by the context provider.
 * @returns {ReactNode} The wrapped child components with the project context.
 */
export const ProjectContextProvider = ({ children }) => {
  const { t } = useTranslation();
  const { message, success } = useErrorMessage();
  const { dispatchAPI, token, user } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [dataFiles, setDataFiles] = useState([]);
  const [visibleToShowFiles, setVisibleToShowFiles] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const [visibleRole, setVisibleRole] = useState(false);
  const [title, setTitle] = useState();
  const [idItem, setIdItem] = useState();
  const [matriceFiles, setMatriceFiles] = useState();
  const [purpose, setPurpose] = useState();
  const [visible, setVisible] = useState(false);
  const [tempValue, setTempValue] = useState();

  const itemKeysInit = {
    what: <WhatIcon />,
    why: <WhyIcon />,
    prior: <PriorIcon />,
    actors: <ActorsIcon />,
    context: <ContextIcon />,
    cost: <CostIcon />,
    risks: <RiskIcon />,
    planning: <PlanningIcon />,
    utility: <UtilityIcon />,
    plan_com: <PlanComIcon />
  };

  const checkRole = () => {
    switch (user?._id) {
      case project?.assignee?._id:
        setVisibleRole(true);
        break;
      default:
        setVisibleRole(false);
    }
  };
  const getProject = useCallback(async (id) => {
    setIsLoading(true);
    setLoadingMedia(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?populate=assignee,created_by,perimeter.users`
      });
      setProject(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
    setLoadingMedia(false);
  }, []);

  const updateProject = async (id, body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${id}`,
        body
      });
      success(t('projects.form.title.updated'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const deleteProject = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/projects/${id}`
      });
      success(t('projects.form.title.deleted'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setPurpose(null);
    getProject(project._id);
  };

  const patchDuplicateEvent = async (body, duplicateId) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/items/duplicate/${duplicateId}`,
        body
      });

      await getProject(project?._id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const deleteDuplicateEvent = async (duplicateId, planComId, versionId) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/items/plan_com/${planComId}/${versionId}`,
        body: { duplicateId }
      });

      await getProject(project?._id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
      setIsLoading(false);
    }
  };
  const handleMascotteClick = async (id) => {
    setIsLoading(true);
    const mascot_state =
      project?.mascot_state === 2 ? 0 : parseInt(project?.mascot_state, 10) + 1;
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project?._id}`,
        body: { mascot_state }
      });
      await getProject(id);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onChangeImg = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const deleteFiles = async (
    idFile,
    id,
    titleItem,
    idIt,
    matrice,
    idMatrice
  ) => {
    setLoadingMedia(true);
    try {
      await dispatchAPI('GET', {
        url: `/files/project/${id}/${titleItem}/${idIt}${
          matrice && idMatrice ? `/${matrice}/${idMatrice}` : ''
        }/${idFile}`
      });
      setVisibleToShowFiles(false);
      getProject(id);
      setLoadingMedia(false);
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) message(e.response.status);
    }
  };

  const getFilesByItem = async (body) => {
    if (body.length > 0) {
      setLoadingMedia(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/files?_id=${[...body]}`
        });
        setDataFiles(data);
        setLoadingMedia(false);
      } catch (e) {
        setLoadingMedia(false);
        if (e.response) message(e.response.status);
      }
    } else setDataFiles([]);
  };
  const setDataToModalFiles = (data, titlteFile, idIt, matrice, idMatrice) => {
    getFilesByItem(data);
    setIdItem(idIt);
    setTitle(titlteFile);
    if (matrice && idMatrice) {
      setMatriceFiles({ id: idMatrice, matrice });
    } else setMatriceFiles(undefined);
    setVisibleToShowFiles(true);
  };

  const displayFileMedia = async (url, type) => {
    setLoadingMedia(true);
    try {
      const response = await dispatchAPI('GET', {
        url,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type
      });
      setLoadingMedia(false);
      const urlModal = window.URL.createObjectURL(blob);
      window.open(urlModal);
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) message(e.response.status);
    }
  };

  const updateResource = async (body, version, titleItem, field, func) => {
    setIsLoading(true);
    try {
      const newBody = field ? body[field] : body;
      if (!version && newBody._id)
        await dispatchAPI('PATCH', {
          url: `/projects/items/${field === 'event' ? 'event' : titleItem}/${
            newBody._id
          }`,
          body: newBody
        });
      else {
        if (titleItem === 'actors') newBody._id = version.content._id;
        if (newBody._id) {
          await dispatchAPI('PATCH', {
            url: `/projects/items/${titleItem}/${newBody._id}/${version._id}`,
            body: newBody
          });
        } else {
          await dispatchAPI('POST', {
            url: `/projects/items/${titleItem}/${version._id}`,
            body: newBody
          });
        }
      }
      setIsLoading(false);
      if (func) func();
      handleCancel();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (
    body,
    titleItem,
    versionId,
    idIt,
    funcCancel,
    field
  ) => {
    setIsLoading(true);
    try {
      const { event, newItem } = body;
      const newBody =
        field && field !== '_id' ? newItem[field] : { ...newItem };
      const versionType =
        getItemByType(titleItem).version_model === 'Version'
          ? 'versions'
          : 'objectVersions';
      if (newItem) {
        await dispatchAPI('PATCH', {
          url: `/projects/${versionType}/${project?._id}/${versionId}`,
          body: { event, newItem: newBody }
        });
      } else {
        await dispatchAPI('PATCH', {
          url: `/projects/${versionType}/${project?._id}/${versionId}/${idIt}`,
          body: { event }
        });
      }
      setIsLoading(false);
      setPurpose(null);
      setTempValue(null);
      if (funcCancel) funcCancel();
      handleCancel();
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const adddEventToDuplicateFederation = async (
    body,
    idFederationVersion,
    extraUrl
  ) => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/items/plan_com/${extraUrl}/${idFederationVersion}`,
        body: { duplicate: body }
      });

      await getProject(project?._id);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const deleteResourceFromItemOrMatrice = async (
    projectId,
    item,
    versionId,
    itemId
  ) => {
    const versionType = item === 'actors' ? 'objectVersions' : 'versions';
    const url = `projects/${versionType}/delete/${item}/${itemId}/${versionId}`;
    try {
      await dispatchAPI('DELETE', { url });
      getProject(projectId);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        getProject,
        updateProject,
        deleteProject,
        projects,
        setProjects,
        loading,
        setLoading,
        project,
        setProject,
        isLoading,
        setIsLoading,
        itemKeysInit,
        fileList,
        setFileList,
        onPreview,
        onChangeImg,
        deleteFiles,
        token,
        visibleToShowFiles,
        setVisibleToShowFiles,
        getFilesByItem,
        dataFiles,
        setDataFiles,
        displayFileMedia,
        loadingMedia,
        setLoadingMedia,
        checkRole,
        visibleRole,
        setVisibleRole,
        handleMascotteClick,
        title,
        setTitle,
        setDataToModalFiles,
        idItem,
        setIdItem,
        matriceFiles,
        setMatriceFiles,
        patchDuplicateEvent,
        t,
        purpose,
        setPurpose,
        createResource,
        updateResource,
        handleCancel,
        visible,
        setVisible,
        tempValue,
        setTempValue,
        adddEventToDuplicateFederation,
        deleteDuplicateEvent,
        deleteResourceFromItemOrMatrice
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
export default () => useContext(ProjectContext);
