import React from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Divider } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../../../../utils/constants/customIcon';
import ButtonDeleteAll from '../../../../../../utilsProject/ButtonDeleteAll';
import FederationIdicator from '../../../FederationIdicator';

/**
 * Component for rendering the header section of a PlanCom card.
 *
 * @component
 * @param {Object} planCom - The PlanCom data.
 * @param {Object} project - The project data.
 * @param {string} versionId - The version ID.
 * @param {boolean} role - Control component visibility according to user's role.
 * @param {Function} setDataToFederation - Function to set data to federation.
 * @param {Function} openForm - Function to open form.
 * @param {Function} closeForm - Function to close form.
 * @param {string} title - The item type.
 * @returns {JSX.Element} PlanComCardHeader component
 */
const PlanComCardHeader = ({
  planCom,
  project,
  versionId,
  role,
  setDataToFederation,
  openForm,
  closeForm,
  title
}) => {
  const { t } = useTranslation();
  return (
    <Descriptions
      extra={
        role && (
          <>
            {!planCom.repeat && (
              <>
                <span style={{ marginRight: 10 }}>
                  {`${t('projects.form.federation')} : `}
                </span>
                <FederationIdicator federationValue={planCom.federation} />
                <Button
                  disabled={project?.status === 'close'}
                  style={{ margin: 10 }}
                  type="primary"
                  icon={<WechatOutlined />}
                  onClick={() => setDataToFederation(planCom)}
                />
              </>
            )}
            <Divider type="vertical" />
            <ButtonDeleteAll
              purpose="matrice"
              projectId={project._id}
              title={title}
              titleId={versionId}
              matriceName="deliverables_plan_com"
              matriceId={planCom._id}
              handleCancel={closeForm}
              version={{ _id: versionId }}
            />
            <Button
              disabled={project?.status === 'close'}
              onClick={() => openForm(planCom._id, planCom)}
              size="small"
              type="link"
              style={{
                color: 'var(--secondaryColor)',
                top: -2
              }}
            >
              <EditIcon />
            </Button>
          </>
        )
      }
      column={2}
      title={planCom.name}
      size="small"
    >
      <Descriptions.Item
        label={t(`projects.form.items.plan_com.deliverables_plan_com.target`)}
      >
        {planCom.target}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.transmitter`
        )}
      >
        {planCom.transmitter}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t(`projects.form.items.plan_com.deliverables_plan_com.channel`)}
      >
        {planCom.channel}
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.goal_action`
        )}
      >
        {planCom.goal_action}
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.goal_description`
        )}
      >
        {planCom.goal_description}
      </Descriptions.Item>
    </Descriptions>
  );
};

PlanComCardHeader.propTypes = {
  planCom: PropTypes.shape({
    _id: PropTypes.string,
    federation: PropTypes.number,
    name: PropTypes.string,
    target: PropTypes.string,
    transmitter: PropTypes.string,
    channel: PropTypes.string,
    goal_action: PropTypes.string,
    goal_description: PropTypes.string,
    repeat: PropTypes.shape({})
  }).isRequired,
  project: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  versionId: PropTypes.string.isRequired,
  role: PropTypes.bool,
  setDataToFederation: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

PlanComCardHeader.defaultProps = {
  role: false
};

export default PlanComCardHeader;
