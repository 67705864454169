import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import RiskMatrixChart from '../../../../../../../../components/Widgets/RiskMatrixChart';

/**
 * Gantt chart related to risks.
 *
 * @component
 * @param {Array} risks - An array of risk objects to be displayed in the Gantt chart.
 * @returns {JSX.Element} A JSX Element representing the GanttTabPane component.
 */
const MatrixTabPane = ({ risks }) => {
  const { t } = useTranslation();
  const itemRisks = [];

  const dataChart = () => {
    (risks || []).forEach((risk) => {
      const { criticality } = risk;
      itemRisks.push({
        item: risk.type_of_risk ? risk.type_of_risk.toString() : 'N/A',
        id: risk._id,
        type: t(`projects.form.items.risks.criticality`),
        value: Math.round(criticality)
      });
    });
    return itemRisks;
  };

  return (
    <Row
      justify="center"
      style={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%' }}
    >
      <Col>
        <RiskMatrixChart dataChart={dataChart()} width={700} height={450} />
      </Col>
    </Row>
  );
};

MatrixTabPane.propTypes = {
  risks: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default MatrixTabPane;
