import React from 'react';
import useSelectFilter from '../../../utilsProject/Filters/useSelectFilter';
import useSearchFilter from '../../../utilsProject/Filters/useSearchFilter';
import ItemFilters from '../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing cost filters and filtering costs.
 *
 * @hook
 * @param {Array} types - List of cost types.
 * @returns {Object} - An object containing functions for getting cost filters and filtering costs.
 */
const useCostFilters = (types) => {
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    types,
    'types'
  );
  const { getSearchFilter, getSearchedValues } = useSearchFilter();

  const getCostFilters = () => {
    return <ItemFilters filters={[getSearchFilter(), getSelectFilter()]} />;
  };

  const getFilteredCosts = (costs) => {
    if (!costs) return [];
    return getFilteredValues(
      getSearchedValues(costs, (cost) => cost?.denomination),
      (cost) => cost?.type
    );
  };

  return { getCostFilters, getFilteredCosts };
};

export default useCostFilters;
