import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const DeleteButton = ({ onConfirm, color, iconSize }) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={t('datatable.column.action.delete.title')}
      okText={t('datatable.column.action.delete.ok')}
      okButtonProps={{ type: 'danger' }}
      cancelText={t('datatable.column.action.delete.cancel')}
      onConfirm={onConfirm}
      icon={<WarningOutlined />}
    >
      <DeleteOutlined style={{ color, fontSize: iconSize }} type="delete" />
    </Popconfirm>
  );
};

DeleteButton.propTypes = {
  onConfirm: PropTypes.func,
  color: PropTypes.string,
  iconSize: PropTypes.string
};

DeleteButton.defaultProps = {
  onConfirm: null,
  color: '000',
  iconSize: '18px'
};

export default DeleteButton;
