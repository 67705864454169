import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import Moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = (action) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [assignees, setAssignees] = useState();
  const [statuses, setStatuses] = useState([]);

  const getAssignees = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setAssignees(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getStatuses = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setStatuses(data.statuses);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getAssignees();
      await getStatuses();
    })();
  }, []);

  const cardStyle = (status) => {
    if (status === 'V') {
      return '#FFC300';
    }
    if (status === 'V0') {
      return '#008dbb';
    }
    return '#28a745';
  };

  return [
    {
      title: t('projects.details.wording'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      fixed: 'left',
      width: 650
    },
    {
      title: t('projects.details.perimeter'),
      dataIndex: 'perimeter',
      render: (perimeter) => (perimeter?.default ? ' - ' : perimeter?.name)
    },
    user.role !== 'managers:PROJECT_MANAGER' && {
      title: t('projects.details.leader'),
      dataIndex: 'assignee',
      key: 'assignee',
      sorter: true,
      render: (assignee) =>
        assignee ? `${assignee.first_name} ${assignee.last_name}` : 'N/A',
      filters:
        assignees &&
        assignees.map((a) => ({
          text: `${a.first_name} ${a.last_name}`,
          value: a._id
        }))
    },
    {
      title: t('projects.details.supervisors'),
      dataIndex: 'supervisors',
      key: 'supervisors',
      width: '20%',
      sorter: true,
      render: (supervisors) => {
        return supervisors?.length
          ? supervisors.map((supervisor, index) => {
              const separator = index !== supervisors.length - 1 ? ' - ' : '';
              return `${supervisor.first_name} ${supervisor.last_name}${separator}`;
            })
          : 'N/A';
      },
      filters:
        assignees &&
        assignees.map((a) => ({
          text: `${a.first_name} ${a.last_name}`,
          value: a._id
        }))
    },
    {
      title: t('projects.details.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (status) =>
        status ? (
          <Tag color={cardStyle(status)}>{t(`projects.status.${status}`)}</Tag>
        ) : (
          'N/A'
        ),
      filters:
        statuses &&
        statuses.map((a) => ({
          text: `${a}`,
          value: `${a}`
        }))
    },
    {
      title: t('projects.details.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (created_at) =>
        created_at ? (
          <Tag color="blue">{Moment(created_at).format('DD-MM-YYYY')}</Tag>
        ) : (
          'N/A'
        )
    },
    action[0]
  ].filter(Boolean);
};

export default useColumns;
