import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spin, message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import PerimeterGroup from './PerimeterGroup/PerimeterGroup';

/**
 * Component that will show the list of users in the perimeter group of a perimeter admin
 *
 * @component
 * @returns {JSX} React component
 */
const ShowPerimeterAdmin = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [perimeterAdmin, setPerimeterAdmin] = useState();
  const [refresh, setRefresh] = useState(false);
  const getPerimeterAdmin = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=users`
      });
      setPerimeterAdmin(data ?? null);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  useEffect(() => {
    getPerimeterAdmin();
  }, [refresh]);

  return perimeterAdmin ? (
    <PerimeterGroup
      perimeterAdmin={perimeterAdmin}
      setRefresh={setRefresh}
      refresh={refresh}
    />
  ) : (
    <Spin />
  );
};

export default ShowPerimeterAdmin;
