import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import CollapseFormItem from '../../../Configuration/formItems/CollapseFormItem';
import useImportFields from './useImportFields';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

/**
 * React component for configuring a project.
 *
 * @component
 * @param {object} props - The component's properties.
 * @param {object} props.projectPerimeter - The project's perimeter.
 * @param {object} props.form - Ant Design form instance for form control.
 * @param {function} props.onFinish - Callback function triggered on form submission.
 * @param {object} [props.project] - The project being configured (null for new projects).
 * @param {object} [props.initialValues] - Initial values for form fields.
 * @param {string} [props.purpose] - The purpose of the configuration (e.g., 'create' or 'edit').
 * @returns {JSX.Element} React JSX element representing the Project Configuration form.
 */
const ProjectConfigurationForm = ({
  projectPerimeter,
  form,
  onFinish,
  project,
  initialValues,
  purpose
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const [importProject, setImportProject] = useState(false);
  const [importGlossary, setImportGlossary] = useState(false);
  const [projects, setProjects] = useState([]);

  const { importSwitch, importFields } = useImportFields(
    projects,
    importProject,
    setImportProject,
    setImportGlossary
  );

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?assignee=${user._id}`
      });
      setProjects(data);
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Form form={form} onFinish={onFinish}>
      {purpose === 'create' && generateFields('projects', importSwitch)}
      {importProject &&
        importFields.map((field) => generateFields('projects', field))}
      {!importGlossary && (
        <CollapseFormItem
          glossarySources={
            project ? [project, projectPerimeter] : [projectPerimeter]
          }
          initialValues={initialValues}
        />
      )}
    </Form>
  );
};

ProjectConfigurationForm.propTypes = {
  projectPerimeter: PropTypes.shape({ _id: PropTypes.string.isRequired })
    .isRequired,
  form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired })
    .isRequired,
  onFinish: PropTypes.func.isRequired,
  project: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  purpose: PropTypes.string
};

ProjectConfigurationForm.defaultProps = {
  project: null,
  initialValues: null,
  purpose: null
};
export default ProjectConfigurationForm;
