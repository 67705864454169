import React, { useEffect } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';
import ShowIndicators from '../home/ShowIndicators';
import MascotteRouge from '../../assets/images/PersoRouge.png';
import MascotteOrange from '../../assets/images/PersoOrange.png';
import MascotteVert from '../../assets/images/PersoVert.png';
import useProjectContext from '../../contexts/ProjectContext';
import Actions from '../../components/Actions/Actions';
import IndicatorsDashboard from '../Dashboard/IndicatorsDashboard';
import ProjectHeader from '../Projects/ProjectHeader';

/**
 * ShowSupervisorDashBoard component displays the dashboard for a supervisor.
 * It includes indicators, actions, and project details.
 *
 * @component
 * @returns {JSX.Element} ShowSupervisorDashBoard component JSX.
 */
const ShowSupervisorDashBoard = () => {
  const { id } = useParams();
  const { getProject, project } = useProjectContext();
  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];

  useEffect(() => {
    (async () => {
      await getProject(id);
    })();
  }, []);

  const IndicatorWrapper = styled(Content)`
    padding: 15px;
    background: var(--clientColor);
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    color: var(--textColorSecondary);
  `;

  return project ? (
    <Col>
      <ProjectHeader project={project} />
      <Content>
        <Row>
          <IndicatorWrapper>
            <ShowIndicators project={project} vue="supervisor" />
          </IndicatorWrapper>
          <Col
            xl={4}
            sm={0}
            xs={0}
            style={{
              display: 'flex',
              justifySelf: 'end'
            }}
          >
            <Button
              style={{
                background: '#D3D3D3',
                borderRadius: 5,
                height: '100%',
                width: '100%',
                marginLeft: 16,
                boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              disabled
            >
              <img
                alt="Mascotte de CitizenCan"
                src={mascotteArray[project?.mascot_state]}
                height="150px"
              />
            </Button>
          </Col>
        </Row>
      </Content>

      <Row justify="space-between" gutter={24}>
        <Col xl={12} sm={24} xs={24}>
          <Actions
            id={project._id}
            status={project.status}
            title="action_done"
            purpose="finished"
            data={
              project.manage_planning[project.manage_planning.length - 1] ||
              null
            }
            actors={
              project.actors[project.actors.length - 1]?.actors_list || []
            }
            version={project.manage_planning.length}
            assignee={project.assignee}
          />
        </Col>
        <Col xl={12} sm={24} xs={24}>
          <Actions
            id={project._id}
            status={project.status}
            title="action_to_do"
            purpose="planned"
            data={
              project.manage_planning[project.manage_planning.length - 1] ||
              null
            }
            actors={
              project.actors[project.actors.length - 1]?.actors_list || []
            }
            version={project.manage_planning.length}
            assignee={project.assignee}
          />
        </Col>
      </Row>
      <Col>
        <IndicatorsDashboard data={project} />
      </Col>
    </Col>
  ) : (
    <Spin />
  );
};

export default ShowSupervisorDashBoard;
