import { useCallback, useEffect, useState } from 'react';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useEventFields from './eventFields';
import useWhatWhyFields from './whatWhyFields';
import usePriorFields from './priorFields';
import useContextFields from './contextFields';
import useUtilityFields from './utilityFields';
import useCostFields from './costFields';
import useActorFields from './actorsFields';
import usePlaningFields from './planingFields';
import useFederationFields from './federationFields';
import useRisksFields from './risksFields';
import useNoteFields from './noteFields';

/**
 * Custom hook for fetching and managing fields data for different sections of the project form.
 *
 * @hook
 * @param {string} title - The title of the section for which fields are being fetched.
 * @param {string} project - The project details.
 * @param {string} perimeter - The project perimeter.
 * @param {Object} initialValues - The initial values of the form.
 * @returns {Object} Fields data for the specified section.
 */
const useFields = (title, project, perimeter, initialValues) => {
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { eventFields } = useEventFields(perimeter, isFieldsLoading);
  const { whatWhyFields } = useWhatWhyFields();
  const { priorFields } = usePriorFields(isFieldsLoading);
  const { contextFields } = useContextFields(isFieldsLoading);
  const { utilityFields } = useUtilityFields(
    null,
    enums || null,
    isFieldsLoading
  );
  const { costFields } = useCostFields(isFieldsLoading, initialValues);
  const { actorFields } = useActorFields(isFieldsLoading);
  const { planingFields } = usePlaningFields();
  const { federationFields } = useFederationFields();
  const { risksFields } = useRisksFields(null, enums || null, isFieldsLoading);
  const { noteFields } = useNoteFields();
  const { dispatchAPI } = useAuthContext();

  const fields = {
    event: eventFields,
    what: whatWhyFields,
    why: whatWhyFields,
    studies: priorFields,
    factors: contextFields,
    deliverables: utilityFields,
    costs: costFields,
    actors_list: actorFields,
    lifeProject: planingFields,
    membership: federationFields,
    risk_list: risksFields,
    note: noteFields
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return fields[title];
};

export default useFields;
