import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

const CustomForm = ({ children, form, onFinish, handleCancel }) => {
  const { t } = useTranslation();

  return (
    <Form {...formItemLayout} form={form} onFinish={onFinish}>
      {children}
      <Divider style={{ borderTop: '1px solid rgba(0, 0, 0, 0.06)' }} />
      <Form.Item {...tailFormItemLayout}>
        <Row justify="center" wrap={false}>
          <Col>
            <Button
              style={{ margin: '0 6px' }}
              type="link"
              danger
              onClick={handleCancel}
            >
              <span>{`${t('buttons.cancel')} `}</span>
              <CloseOutlined />
            </Button>
          </Col>
          <Col>
            <Button type="link" htmlType="submit">
              <span>{`${t('buttons.save')} `}</span>
              <CheckOutlined />
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

CustomForm.propTypes = {
  children: PropTypes.node,
  form: PropTypes.shape({}).isRequired,
  visible: PropTypes.bool,
  onFinish: PropTypes.func,
  handleCancel: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
};

CustomForm.defaultProps = {
  children: null,
  visible: true,
  onFinish: null,
  handleCancel: null,
  title: ''
};

export default CustomForm;
