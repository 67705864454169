import React from 'react';
import { message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import {
  PriorIcon,
  ActorsIcon,
  PlanningIcon,
  ContextIcon,
  CostIcon,
  PlanComIcon,
  RiskIcon
} from './customIcon';

export const items = [
  {
    key: 'prior',
    type: 'type',
    icon: <PriorIcon />
  },
  {
    key: 'groupRole',
    type: 'role',
    icon: <ActorsIcon />
  },
  {
    key: 'context',
    type: 'type',
    icon: <ContextIcon />
  },
  {
    key: 'cost',
    type: 'type',
    icon: <CostIcon />
  },
  {
    key: 'risks',
    type: 'type',
    icon: <RiskIcon />
  },
  {
    key: 'planning_utility',
    type: 'type',
    icon: <PlanningIcon />
  },
  {
    key: 'event',
    type: 'type',
    icon: <PlanComIcon />
  }
];

export const getGlossary = (object) => {
  const glossary = {};
  if (!object) return glossary;
  items.forEach((item) => {
    glossary[item.key] = object[`${item.key}_list`];
  });

  return glossary;
};

const createGlossary = (words) => {
  const glossary = {};
  words.forEach((word) => {
    if (glossary[word.sub_category])
      glossary[word.sub_category].push(word.value);
    else glossary[word.sub_category] = [word.value];
  });
  return glossary;
};

export const useUserGlossary = () => {
  const { user, dispatchAPI } = useAuthContext();

  const getUserGlossary = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/lists?created_by=${user._id}`
      });
      return createGlossary(data);
    } catch (error) {
      if (error.message) message.error(error.message);
      return null;
    }
  };

  return { getUserGlossary };
};
