import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Descriptions, Divider, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../utils/constants/customIcon';
import ButtonDeleteAll from '../../utilsProject/ButtonDeleteAll';
import useAuthContext from '../../../../../contexts/AuthContext';
import ActorActivationButton from './actorActivation/ActorActivationButton';
import ActorActivationForm from './actorActivation/ActorActivationForm';
import useProjectContext from '../../../../../contexts/ProjectContext';
import { getDisplayedActorRole } from './utils';

/**
 * A component that displays descriptions of actors or groups in a project.
 * It also provides options for editing and deleting.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.data - The data object containing actor or group information.
 * @param {boolean} [props.role=false] - Controls components visibility according to user role.
 * @param {object} props.project - The project object containing project details.
 * @param {function} props.openForm - Function to open the edit form.
 * @param {function} props.closeForm - Function to close the form.
 * @param {object} props.version - The version object containing version details.
 * @param {node} props.title - The type of the item.
 * @returns {JSX.Element} - A JSX element displaying the actor or group descriptions.
 */
const ActorsDescription = ({
  data,
  openForm,
  role,
  project,
  closeForm,
  version,
  title
}) => {
  const { t } = useTranslation();
  const { user: authUser } = useAuthContext();
  const { updateResource, getProject } = useProjectContext();
  const [visible, setVisible] = useState(false);

  const sendActivationRequest = () => {
    setVisible(true);
  };

  const cancelActivationRequest = async () => {
    try {
      await updateResource(
        {
          _id: data.actor._id,
          actor: {
            _id: data.actor._id,
            activation_request: { status: 'cancelled' }
          }
        },
        version,
        'actors',
        null,
        () => getProject(project?._id)
      );
      message.success(
        t('projects.form.actors_list.actor.activation_request.canceled')
      );
      setVisible(false);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const handleSubmit = async (email) => {
    try {
      await updateResource(
        {
          _id: data.actor._id,
          actor: {
            _id: data.actor._id,
            activation_request: {
              email,
              first_name: data.actor.first_name,
              last_name: data.actor.last_name,
              status: 'pending'
            }
          }
        },
        version,
        'actors',
        null,
        () => getProject(project?._id)
      );
      message.success(
        t('projects.form.actors_list.actor.activation_request.sent')
      );
      setVisible(false);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const setSpan = (arg) => {
    if (arg === 'gain') {
      return 2;
    }
    return 1;
  };

  const { actor, group } = data;
  const { _id, actors, user, name, default: defaultGroup, ...rest } =
    actor || group;
  rest.role = rest.role || '';
  if (actor)
    actor.role = getDisplayedActorRole(actor, project?.assignee?._id, t);

  return (
    project && (
      <Descriptions
        extra={
          role && (
            <Row justify="space-around" align="middle">
              {!user && !name && (
                <>
                  <ActorActivationForm
                    visible={visible}
                    setVisible={setVisible}
                    handleSubmit={handleSubmit}
                  />
                  <ActorActivationButton
                    status={actor.activation_request?.status}
                    sendActivationRequest={sendActivationRequest}
                    cancelActivationRequest={cancelActivationRequest}
                  />
                  <Divider
                    type="vertical"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      height: 30
                    }}
                  />
                </>
              )}
              <Button
                disabled={project?.status === 'close'}
                onClick={() => (role ? openForm(_id) : null)}
                size="small"
                type="link"
                style={{
                  color: 'var(--secondaryColor)',
                  top: 2
                }}
              >
                <EditIcon />
              </Button>
              {!defaultGroup && user?._id !== authUser._id && (
                <>
                  <Divider
                    type="vertical"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      height: 30
                    }}
                  />
                  <ButtonDeleteAll
                    purpose="matrice"
                    projectId={project._id}
                    title="actors"
                    titleId={version._id}
                    matriceName="actors_list"
                    matriceId={_id}
                    handleCancel={closeForm}
                    version={version}
                  />
                </>
              )}
            </Row>
          )
        }
        key={_id}
        column={4}
        title={title}
        size="small"
      >
        {data.group && (
          <Descriptions.Item
            key="workforce"
            label={t(`projects.form.items.actors.workforce`)}
            span={setSpan('workforce')}
          >
            {actors.length}
          </Descriptions.Item>
        )}
        {['code', 'role', 'coefficient'].map((key) => {
          return (
            <Descriptions.Item
              key={key}
              label={t(`projects.form.items.actors.${key}`)}
              span={setSpan(key)}
            >
              {(actor || group)[key] || '-'}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    )
  );
};

ActorsDescription.propTypes = {
  data: PropTypes.shape({
    actor: PropTypes.shape(),
    group: PropTypes.shape({})
  }).isRequired,
  role: PropTypes.bool,
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
  }).isRequired,
  version: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired,
  title: PropTypes.node.isRequired,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired
};

ActorsDescription.defaultProps = {
  role: false
};

export default ActorsDescription;
