import React from 'react';
import { Row, Col, Tag } from 'antd';

const useCost = (data, t, status) => {
  const cost = data.cost[status !== 'all' ? status : data.cost.length - 1];
  let costs = 0;

  if (cost !== null && cost !== undefined) {
    (cost.costs || []).forEach((e) => {
      costs += e.cost_value;
    });
    return (
      <Row style={{ marginBottom: 8 }} justify="start">
        <Col span={24}>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.10em',
              fontSize: 10,
              color: '#8c8c8c'
            }}
          >
            {t(`projects.details.cost`)}
          </span>
        </Col>
        <Col span={24}>{` ${costs} €`}</Col>
      </Row>
    );
  }

  return (
    <>
      <Col>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {t(`projects.details.cost`)}
        </span>
      </Col>
      <Col>
        <Tag color="orange" style={{ marginRight: 16 }}>
          Non saisi
        </Tag>
      </Col>
    </>
  );
};

export default useCost;
