import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import Settings from './Settings/settings';
import UserRouter from './users/UserRouter';
import ProjectsRouter from './Projects/ProjectsRouter';
import ShowReporting from './Reporting/ShowReporting';
import DocumentRouter from './Documents/DocumentsRouter';
import BalanceSheetRouter from './BalanceSheet/BalanceSheetRouter';
import HomeRouter from './home/HomeRouter';
import HistoryRouter from './History/HistoryRouter';
import DashboardRouter from './Dashboard/DashboardRouter';
import SupervisorRouter from './Supervisor/SupervisorRouter';
import PerimetersRouter from './Perimeters/PerimetersRouter';
import ConfigurationRouter from './Configuration/ConfigurationRouter';
import ActorRouter from './Actor/ActorRouter';
import PerimeterAdminsRouter from './PerimeterAdmins/PerimeterAdminsRouter';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const { isValid, checkIsGranted } = useAuthContext();
  const splitPath = location.pathname.split('/');
  const splitPathGrant = location.pathname.split('/').filter((e) => e !== '');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;
  const grantFromPath =
    Symbol.iterator in Object(splitPathGrant)
      ? splitPathGrant.join('.').toLowerCase()
      : splitPathGrant.toLowerCase();
  const checkToRender = (props, resourceToCheck) => {
    if (!isValid) {
      return (
        <Redirect
          to={{
            pathname: outOfNavRoutes.LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }
    if (!checkIsGranted(resourceToCheck)) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location }
          }}
        />
      );
    }
    return (
      <BasicLayout path={path}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </BasicLayout>
    );
  };
  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) => checkToRender(props, grantFromPath)}
    />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};
PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};
const Router = () => {
  const { checkShouldDisplayMenuItem } = useAuthContext();
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />
        <PrivateRoute
          exact
          path={outOfNavRoutes.SETTINGS}
          component={Settings}
        />
        <PrivateRoute exact path={routes.HOME} component={HomeRouter} />
        {checkShouldDisplayMenuItem('supervisor') && (
          <PrivateRoute path={routes.SUPERVISOR} component={SupervisorRouter} />
        )}
        <PrivateRoute path={routes.ACTOR} component={ActorRouter} />
        {checkShouldDisplayMenuItem('users') && (
          <PrivateRoute path={routes.USERS} component={UserRouter} />
        )}
        {checkShouldDisplayMenuItem('perimeters') && (
          <PrivateRoute path={routes.PERIMETERS} component={PerimetersRouter} />
        )}
        <PrivateRoute path={routes.PROJECTS} component={ProjectsRouter} />
        {checkShouldDisplayMenuItem('dashboard') && (
          <PrivateRoute path={routes.DASHBOARD} component={DashboardRouter} />
        )}
        {checkShouldDisplayMenuItem('documents') && (
          <PrivateRoute path={routes.DOCUMENTS} component={DocumentRouter} />
        )}
        {checkShouldDisplayMenuItem('reporting') && (
          <PrivateRoute path={routes.REPORTING} component={ShowReporting} />
        )}
        {checkShouldDisplayMenuItem('balance_sheet') && (
          <PrivateRoute
            path={routes.BALANCE_SHEET}
            component={BalanceSheetRouter}
          />
        )}
        {checkShouldDisplayMenuItem('hisotry') && (
          <PrivateRoute path={routes.HISTORY} component={HistoryRouter} />
        )}
        {checkShouldDisplayMenuItem('configuration') && (
          <PrivateRoute
            path={routes.CONFIGURATION}
            component={ConfigurationRouter}
          />
        )}
        {checkShouldDisplayMenuItem('perimeter_admins') && (
          <PrivateRoute
            path={routes.ADMINISTRATORS}
            component={PerimeterAdminsRouter}
          />
        )}
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};
export default Router;
