import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Statistic, Tooltip } from 'antd';
import moment from 'moment';
import { getDefaultMacroTaskFromVersion } from '../../Projects/Projects/utilsProject/ManagePlanning/utils';

/**
 * Component for rendering time-related statistics.
 *
 * @function
 * @param {Array} managePlanningVersions - Array of manage planning versions.
 * @param {string} status - Project status.
 * @param {number} width - Screen width.
 * @param {function} showModal - Function to show a modal.
 * @param {string} vue - Controls components visibility according to user role.
 * @param {function} t - Translation function.
 * @returns {JSX.Element} JSX element containing the time-related statistics.
 */
export default (managePlanningVersions, status, width, showModal, vue, t) => {
  const data = managePlanningVersions.map(
    (managePlanning) => getDefaultMacroTaskFromVersion(managePlanning)?.planning
  );

  if ((status === 'V1' || status === 'VX') && data[2]) {
    const diff = moment(
      data[data.length - 1].due_date !== null
        ? data[data.length - 1].due_date
        : 0
    ).diff(
      moment(
        data[data.length - 1].start_date !== null
          ? data[data.length - 1].start_date
          : 0
      ),
      'days'
    );

    const diffInit = moment(
      data[2].due_date !== null ? data[2].due_date : 0
    ).diff(
      moment(data[2].start_date !== null ? data[2].start_date : 0),
      'days'
    );

    const realize =
      (moment(Date.now()).diff(
        moment(
          data[data.length - 1].start_date !== null
            ? data[data.length - 1].start_date
            : 0
        ),
        'days'
      ) /
        diff) *
      100;

    const value = diff - diffInit;

    return (
      <Col
        xl={6}
        sm={12}
        xs={20}
        style={{
          height: '100%',
          margin: width > 1200 ? '0 24px' : '0 8px 16px'
        }}
        onClick={() => {
          if (vue !== 'actor') showModal('time');
        }}
      >
        <Tooltip title={t(`projects.details.evolution_project`)}>
          <Row justify="space-between" align="top">
            <Col span={24}>
              <span
                style={{
                  textTransform: 'uppercase',
                  letterSpacing: '.15em',
                  fontSize: 13
                }}
              >
                {t(`projects.details.time`)}
              </span>
            </Col>
            <Col span={12}>{` ${diff} ${t('dashboard.days')}`}</Col>
            <Col span={12}>
              <Statistic
                value={value < 0 ? -value : value}
                precision={0}
                valueStyle={
                  value <= 0
                    ? { color: 'var(--addColor)', fontSize: 14 }
                    : { color: '#ff0000', fontSize: 15 }
                }
                prefix={value < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                suffix={t('dashboard.days')}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }} justify="start">
            <Col span={24}>{t(`projects.details.realize`)}</Col>
          </Row>
          <Row>
            <Col span={24}>
              <Progress
                id="white-progress"
                status="normal"
                precision={0}
                style={{ width: '200px' }}
                percent={Math.round(realize) > 0 ? Math.round(realize) : 0}
                strokeWidth={10}
                type="line"
              />
            </Col>
            <Col span={24}>
              <span>
                {`(${moment(Date.now()).diff(
                  moment(
                    data[data.length - 1].start_date !== null
                      ? data[data.length - 1].start_date
                      : 0
                  ),
                  'days'
                )} ${t('dashboard.daysince')})`}
              </span>
            </Col>
          </Row>
        </Tooltip>
      </Col>
    );
  }
  return (
    <Col
      xl={6}
      sm={12}
      xs={20}
      style={{
        height: '100%',
        margin: width > 1200 ? '0 24px' : '0 8px 16px'
      }}
      onClick={() => vue !== 'actor' && showModal('time')}
    >
      <Tooltip title={t('projects.show.indicators.tooltip.duration')}>
        <Row justify="space-between" align="top">
          <Col>
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.time`)}
            </span>
          </Col>
          <Col>
            {0}
            {t('dashboard.days')}
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} justify="start">
          <Col span={12}>{t(`projects.details.realize`)}</Col>
        </Row>
        <Row>
          <Col>
            <Progress
              id="white-progress"
              status="normal"
              precision={0}
              style={{ width: '200px' }}
              percent={0}
              strokeWidth={10}
              type="line"
            />
          </Col>
        </Row>
      </Tooltip>
    </Col>
  );
};
