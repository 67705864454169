import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import HomeManager from './HomeManager';
import useAuthContext from '../../contexts/AuthContext';
import HomeCommercial from './HomeCommercial/HomeCommercial';
import { HomeContextProvider } from '../../contexts/HomeContext';

const HomeRouter = () => {
  const { path } = useRouteMatch();
  const { user } = useAuthContext();
  return (
    <HomeContextProvider>
      <Switch>
        <Route
          path={`${path}`}
          component={
            user?.role === 'managers:PROJECT_MANAGER'
              ? HomeManager
              : HomeCommercial
          }
        />
      </Switch>
    </HomeContextProvider>
  );
};
export default HomeRouter;
