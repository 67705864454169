import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';
import ShowBalanceSheet from './ShowBalanceSheet';
import { BalanceSheetProvider } from './BalanceSheetContext';

const BalanceSheetRouter = () => {
  return (
    <BalanceSheetProvider>
      <Switch>
        <Route path={`${routes.BALANCE_SHEET}`} component={ShowBalanceSheet} />
      </Switch>
    </BalanceSheetProvider>
  );
};
export default BalanceSheetRouter;
