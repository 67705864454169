import React from 'react';
import { Tag } from 'antd';
import { VInitIcon, V0Icon, VPlusIcon } from './customIcon';

const setIconStatus = (name, current, check, config) => {
  if (current === 'DRAFT') {
    return (
      <>
        {check ? <span>{name}</span> : null}
        <Tag color="orange">DRAFT</Tag>
      </>
    );
  }
  if (current === 'V-Init')
    return (
      <>
        {check ? <span>{name}</span> : null}
        <span>
          <VInitIcon />
          <span
            style={{
              fontSize: 10.5,
              fontWeight: 'bold',
              color: 'white',
              position: 'relative',
              bottom: 7,
              right: config
            }}
          >
            V-I
          </span>
        </span>
      </>
    );
  if (current === 'V0')
    return (
      <>
        {check ? <span>{name}</span> : null}
        <span>
          <V0Icon />
          <span
            style={{
              fontSize: 10.5,
              fontWeight: 'bold',
              color: 'white',
              position: 'relative',
              bottom: 7,
              right: config
            }}
          >
            {current}
          </span>
        </span>
      </>
    );
  if (current === 'V1')
    return (
      <>
        {check ? <span>{name}</span> : null}
        <span>
          <VPlusIcon />
          <span
            style={{
              fontSize: 10.5,
              fontWeight: 'bold',
              color: 'white',
              position: 'relative',
              bottom: 7,
              right: config
            }}
          >
            {current}
          </span>
        </span>
      </>
    );
  if (current === 'VX')
    return (
      <>
        {check ? <span>{name}</span> : null}
        <span>
          <VPlusIcon />
          <span
            style={{
              fontSize: 10.5,
              fontWeight: 'bold',
              color: 'white',
              position: 'relative',
              bottom: 7,
              right: config
            }}
          >
            V+
          </span>
        </span>
      </>
    );
};

export default setIconStatus;
