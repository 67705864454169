import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import PlanComTabs from './PlanComTabs/PlanComTabs';

/**
 * Component for displaying and managing communication plans (PlanCom).
 * This component handles displaying tabs for different communication plan types, opening modals for creating new PlanCom items,
 * and rendering the form for creating new PlanCom items.
 *
 * @component
 * @param {Object} version - The version object containing planCom items.
 * @param {string} title - The item type.
 * @param {Function} getProject - A function to fetch project data.
 * @param {Function} generateForm - A function to generate the form for creating or updating PlanCom items.
 * @param {boolean} role - Control component visibility according to user's role.
 * @param {Object} project - The project.
 * @returns {JSX.Element} PlanComDisplay component
 */
const PlanComDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataDeliverables, setDataDeliverables] = useState();
  const config = {
    onGetResource: {
      setFields: (data) => ({
        deliverables_plan_com: data
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
    setDataDeliverables({
      repeat_event: false,
      repeat: { value: 1, time_unit: 'day', no_weekends: true }
    });
  };
  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  return (
    <>
      <PlanComTabs
        project={project}
        version={version}
        generateForm={generateForm}
        config={config}
        role={role}
        dataDeliverables={dataDeliverables}
        setDataDeliverables={setDataDeliverables}
        setVisibleModal={setVisibleModal}
        getProject={getProject}
        title={title}
      />

      {visibleModal && (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={1000}
          visible={visibleModal}
          footer={false}
          forceRender
          onCancel={closeFormModal}
        >
          {visibleModal &&
            generateForm(
              'create',
              version,
              title,
              closeFormModal,
              dataDeliverables,
              'deliverables_plan_com',
              config
            )}
        </Modal>
      )}
      {role && (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={openFormModal}
          title={t('projects.form.items.plan_com.addPlanCom')}
        />
      )}
    </>
  );
};

PlanComDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  project: PropTypes.shape({
    status: PropTypes.string
  })
};

PlanComDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: () => {},
  getProject: () => {},
  role: false,
  project: null
};

export default PlanComDisplay;
