import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { InputNumber, Row, message } from 'antd';
import { deliverablesStatuses, enums as planningEnums } from '../utils';

/**
 * A custom hook for generating a list of planning details for display.
 *
 * @param {object} planning - The planning data to generate the list for.
 * @param {function} updateResource - The function to update a planning.
 * @returns {Array} An array of planning details with labels and content.
 */
const usePlanningList = (planning, updateResource) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [percentage, setPercentage] = useState();
  const dateFormat = 'DD/MM/YYYY';

  const handleSubmit = async () => {
    try {
      await updateResource(percentage);
      setIsEditing(false);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  useEffect(() => {
    if (planning) setPercentage(planning.percentage);
  }, [planning]);

  if (!planning) return [];

  const actionType = planning.action_type;
  const macroTaskDisplay = actionType === planningEnums.action_types.MACRO_TASK;

  const planningList = [
    {
      label: t('projects.form.deliverables.planning.action_type'),
      content: t(
        `projects.form.deliverables.planning.${planning.action_type.toLowerCase()}`
      )
    },
    actionType !== planningEnums.action_types.DELIVERABLE && {
      label: t('projects.form.deliverables.planning.start_date'),
      content: moment(planning.start_date).format(dateFormat)
    },
    {
      label: t('projects.form.deliverables.planning.due_date'),
      content: moment(planning.due_date).format(dateFormat)
    },
    !macroTaskDisplay && {
      label: t('projects.form.deliverables.planning.macro_task'),
      content: planning.macro_task?.description
    },
    !macroTaskDisplay && {
      label: t('projects.form.deliverables.planning.groups'),
      content: planning.assigned_groups.map((group) => group.name).join(', ')
    },
    !macroTaskDisplay && {
      label: t('projects.form.deliverables.planning.actors'),
      content: planning.assigned_actors
        .map((actor) => `${actor?.first_name} ${actor?.last_name}`)
        .join(', ')
    },
    !macroTaskDisplay && {
      label: t('projects.form.deliverables.planning.workload'),
      content: planning.workload
        ? `${planning.workload} ${t('projects.form.deliverables.planning.day')}`
        : '-'
    },
    {
      label: t('projects.form.deliverables.planning.status'),
      content: t(`projects.form.deliverables.statuses.${planning.status}`)
    },
    {
      label: t('projects.form.deliverables.planning.percentage'),
      content: (
        <Row align="middle" justify="center">
          {isEditing ? (
            <>
              <InputNumber
                defaultValue={percentage}
                onChange={(value) => setPercentage(value)}
                max={100}
                min={0}
              />
              <CheckOutlined
                style={{ marginLeft: '16px' }}
                onClick={handleSubmit}
              />
            </>
          ) : (
            <>
              {`${percentage} %`}
              {planning.status === deliverablesStatuses[0] && (
                <EditOutlined
                  style={{ marginLeft: '16px' }}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </>
          )}
        </Row>
      )
    },
    {
      label: t('projects.form.deliverables.planning.color'),
      content: planning.color
    },
    {
      label: t('projects.form.deliverables.planning.gantt_mark'),
      content: planning.gantt_mark ? <CheckOutlined /> : <CloseOutlined />
    },
    {
      label: t('projects.form.deliverables.planning.piloting_mark'),
      content: planning.piloting_mark ? <CheckOutlined /> : <CloseOutlined />
    }
  ].filter(Boolean);

  return planningList;
};

export default usePlanningList;
