/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { getIndexByVersionSelected } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';
import { getDisplayedActorRole } from '../../../Projects/Projects/ItemsDisplay/ActorsDisplay/utils';
import { actorsToString, frequencyToString, groupsToString } from './utils';

const projectState = (t, project, filter) => {
  const checkDataAndReturnSorting = (data, type) => {
    switch (type) {
      case 'prior':
        return [
          data.type,
          data.denomination,
          moment(data.creation_date).format('DD-MM-YYYY'),
          data.description
        ];
      case 'actors':
        return [
          data.first_name,
          data.last_name,
          data.user ? t('global.yes') : t('global.no'),
          data.code,
          getDisplayedActorRole(data, project?.assignee._id, t),
          data.coefficient
        ];
      case 'groups':
        return [
          data.name,
          data.actors.length,
          data.code,
          data.role,
          actorsToString(data.actors),
          data.coefficient
        ];
      case 'context':
        return [data.denomination, data.type, data.impact, data.description];
      case 'cost':
        return [
          data.denomination,
          data.type,
          `${data.charge} j/h`,
          `${data.cost_value} €`,
          data.description
        ];
      case 'risks':
        return [
          data.number,
          data.potential_risk,
          data.type_of_risk,
          data.impact_effect,
          data.criticality,
          data.decision,
          data.measure_description
        ];
      case 'planning':
        if (data.planning) {
          return [
            data.description,
            t(
              `projects.form.deliverables.planning.${data.planning.action_type.toLowerCase()}`
            ),
            data.global_type,
            data.comment,
            moment(data.planning.start_date).format('DD-MM-YYYY'),
            moment(data.planning.due_date).format('DD-MM-YYYY'),
            actorsToString(data.planning.assigned_actors),
            groupsToString(data.planning.assigned_groups),
            data.planning.workload,
            t(`projects.form.deliverables.statuses.${data.planning.status}`),
            `${data.planning.percentage} %`
          ];
        }
        return false;
      case 'utility':
        if (data.utility) {
          return [
            data.description,
            data.global_type,
            t(
              `projects.form.items.utility.deliverables.beneficiaries.${data.utility.use_for}`
            ),
            data.utility.beneficiaries,
            data.utility.why,
            data.utility.coefficient
          ];
        }
        return false;
      case 'plan_com':
        return [
          data.name,
          moment(data.start_date).format('DD-MM-YYYY'),
          moment(data.due_date).format('DD-MM-YYYY'),
          frequencyToString(data, t),
          data.type,
          data.channel,
          data.impact,
          data.in_charge_of,
          data.transmitter,
          data.target,
          data.federation,
          data.goal_description,
          data.goal_action
        ];
      default:
        return false;
    }
  };
  const workbook = new Workbook();

  const createWorksheet = (key) => {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.${key}`)}`);
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(false);
    const header = newHeader[key].map(({ label }) => {
      return t(`projects.form.${label}`);
    });
    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell) => {
      cell.width = cell._value.model.value?.length + 10;
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    if (key === 'what' || key === 'why') {
      worksheet.addRow([
        project[key][getIndexByVersionSelected(filter.version, project[key])]
          .content.description
      ]);
    } else if (['actors', 'groups'].includes(key)) {
      let row;
      project.actors[
        getIndexByVersionSelected(filter.version, project.actors)
      ].content[key].forEach((c) => {
        if (c.default) return;
        row = checkDataAndReturnSorting(c, key);
        if (row) worksheet.addRow(row);
      });
    } else {
      let row;
      project[
        key === 'planning' || key === 'utility' ? 'manage_planning' : key
      ][
        getIndexByVersionSelected(
          filter.version,
          project[
            key === 'planning' || key === 'utility' ? 'manage_planning' : key
          ]
        )
      ].content.forEach((c) => {
        row = checkDataAndReturnSorting(c, key);
        if (row) worksheet.addRow(row);
      });
    }
  };

  try {
    filter.items.forEach((key) => {
      createWorksheet(key);
      if (key === 'actors') {
        createWorksheet('groups');
      }
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default projectState;
