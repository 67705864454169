import { createGlobalStyle } from 'styled-components';

const LightTheme = createGlobalStyle`
  body {
    --clientColor: #113c4f;
    --primaryColor: #E66F33;
    --addColor: #24BF4D;
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--secondaryColor);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #e6f7ff;
    --secondaryColor: #008cb9;
    --disabledColor: #bfbfbf;
    --bodyBackground: #e6e3e3;
    --textColor: #000;
    --textColorSecondary: var(--componentBackground);
    --itemActiveBackground: #e6f7ff;
    --itemHoverBackground: rgba(255, 255, 255, 0.2);
    --borderColor: #d9d9d9;
    --contrastBackground: #f5f5f5;
    --opacitySVG: 1;
    --errorColor: #EB5757;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;

export default LightTheme;
