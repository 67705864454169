import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import Perimeters from './Perimeters/Perimeters';
import CreateOrUpdatePerimeter from './Perimeters/CreateOrUpdatePerimeter';
import ShowPerimeter from './Perimeters/ShowPerimeter';
import { PerimeterContextProvider } from '../../contexts/PerimeterContext';

const PerimetersRouter = () => {
  const { path } = useRouteMatch();
  return (
    <PerimeterContextProvider>
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateOrUpdatePerimeter purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateOrUpdatePerimeter purpose="edit" />}
        />
        <Route path={`${path}/show/:id`} component={ShowPerimeter} />
        <Route path={path} component={Perimeters} />
        <Route path={path} render={() => <Redirect to={path} />} />
      </Switch>
    </PerimeterContextProvider>
  );
};
export default PerimetersRouter;
