import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { Col, Select, Spin, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying and interacting with event information.
 *
 * @component
 * @param {Array} events - An array of events to be displayed in the Select dropdown.
 * @param {Object} project - The project containing the event information.
 * @param {string} event_type - The type of event (e.g., 'previous_event' or 'nex_event').
 * @param {function} updateResource - Function to update the resource with new event data.
 * @param {function} getProjects - Function to retrieve the updated list of projects.
 * @returns {JSX.Element} React component representing event information.
 */
const EventView = ({
  events,
  project,
  event_type,
  updateResource,
  getProjects
}) => {
  const { t } = useTranslation();
  const [visibleSelect, setVisibleSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Col
      xxl={4}
      xl={12}
      sm={24}
      xs={24}
      style={{
        margin: '16px 0 24px 0'
      }}
    >
      <Spin spinning={isLoading}>
        <Col
          style={{
            textAlign: 'center',
            marginBottom: 5,
            borderRadius: 5
          }}
          span={24}
        >
          <Tag className="dashboard-event-tag">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <span>{t(`dashboard.${event_type}`)}</span>
              <EditOutlined onClick={() => setVisibleSelect(!visibleSelect)} />
            </div>
          </Tag>
        </Col>
        {visibleSelect ? (
          <Select
            style={{
              width: '100%'
            }}
            showSearch
            onChange={async (_, option) => {
              setIsLoading(true);
              await updateResource(
                {
                  [event_type]: option.option
                },
                null,
                null,
                null,
                null,
                project._id
              );
              setVisibleSelect(!visibleSelect);
              await getProjects();
              setIsLoading(false);
            }}
            options={events?.map((ele) => ({
              option: {
                start_date: ele.start_date,
                due_date: ele.due_date,
                task: ele.task,
                description: ele.description
              },
              value: ele._id,
              key: ele._id,
              label: `${ele.task} : ${moment(ele.start_date).format(
                'DD-MM-YYYY'
              )}`
            }))}
          />
        ) : (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <p>
              {project[event_type] ? `Nom : ${project[event_type]?.task}` : '-'}
            </p>
            <p>
              {`${
                project[event_type]
                  ? moment(project[event_type]?.start_date).format(
                      'ddd DD-MM-YYYY'
                    )
                  : '-'
              }`}
            </p>
          </div>
        )}
      </Spin>
    </Col>
  );
};

EventView.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape({
    _id: PropTypes.string
  }),
  event_type: PropTypes.string.isRequired,
  updateResource: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired
};

EventView.defaultProps = {
  events: null,
  project: null
};

export default EventView;
