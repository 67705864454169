/**
 * Handles changes in the start date or due date of planning, adjusting related values.
 *
 * @param {object} newDate - An object containing the new start date or due date.
 * @param {Date} initialPlannig - The initial planning object with start and due dates.
 * @param {Form} form - The form object to update the form fields.
 * @returns {number} The variation in days between the initial date and the new date.
 */
const handleDateChange = (newDate, initialPlannig, form) => {
  const { start_date, due_date } = newDate;

  if (start_date) {
    const variation = start_date.diff(initialPlannig?.start_date, 'days');

    const newDueDate = initialPlannig?.due_date.clone().add(variation, 'days');
    form.setFieldsValue({
      deliverables: { planning: { due_date: newDueDate } }
    });

    return variation;
  }
  if (due_date) {
    const variation = due_date.diff(initialPlannig?.due_date, 'days');

    const newStartDate = initialPlannig?.start_date
      ?.clone()
      .add(variation, 'days');
    form.setFieldsValue({
      deliverables: { planning: { start_date: newStartDate } }
    });

    return variation;
  }

  return 0;
};

/**
 * Handles changes in the status of planning.
 *
 * @function
 * @param {string} newStatus - The new status value.
 * @param {number} percentage - The percentage value associated with the planning.
 * @returns {number} The adjusted percentage value based on the new status.
 */
const handleStatusChange = (newStatus, percentage) => {
  if (newStatus === 'Finalisé') return 100;
  if (newStatus === 'En cours' && percentage === 100) return 99;
  return percentage;
};

/**
 * Handles changes in values related to planning and deliverables.
 *
 * @function
 * @param {Object} changedValues - The changed values from the form.
 * @param {Object} allValues - All values from the form.
 * @param {Object} initialPlanning - The initial planning data.
 * @param {function} setDateChanged - A function to set the date change.
 * @param {object} form - The form instance.
 * @param {boolean} linkedDates - A boolean to indicate if the dates are linked.
 * @returns {void}
 */
const handleValuesChange = (
  changedValues,
  allValues,
  initialPlanning,
  setDateChanged,
  form,
  linkedDates
) => {
  const { planning } = changedValues?.deliverables || {};
  if (planning) {
    if (linkedDates)
      setDateChanged(handleDateChange(planning, initialPlanning, form));

    if (planning.status)
      form.setFieldsValue({
        deliverables: {
          planning: {
            percentage: handleStatusChange(
              planning.status,
              allValues?.deliverables?.planning?.percentage
            )
          }
        }
      });
  }
};
export default handleValuesChange;

/**
 * Handles beneficiary value according to selected beneficiary type.
 *
 * @function
 * @param {string} useFor - The new value of beneficiary type.
 * @param {Object} project - The project details.
 * @returns {void}
 */
export const getBeneficiary = (useFor, project) => {
  switch (useFor) {
    case 'self':
      return `${project?.assignee?.first_name} ${project?.assignee?.last_name}`;
    case 'customer':
      return project?.customer;
    default:
      return null;
  }
};
