import moment from 'moment';

/**
 * Action type enumeration.
 *
 * @readonly
 * @enum {string}
 */
export const enums = {
  action_types: {
    TASK: 'TASK',
    DELIVERABLE: 'DELIVERABLE',
    MACRO_TASK: 'MACRO_TASK'
  }
};

export const deliverablesStatuses = [
  'ON_GOING',
  'FINISHED',
  'PLANNED',
  'TO_PLAN',
  'PAUSED',
  'CANCELLED',
  'AWAITING_VALIDATION'
];

/**
 * Gets the default macro task from a project.
 *
 * @function
 * @param {object} project - Project containing manage_planning.
 * @returns {object|null} Default macro task from the project.
 */
export const getDefaultMacroTaskFromProject = (project) => {
  if (!project.manage_planning) return [];
  return project.manage_planning[
    project.manage_planning.length - 1
  ].content.find((action) => action.planning.default);
};

/**
 * Gets the default macro task from a version.
 *
 * @function
 * @param {object} managePlanning - Manage planning with content.
 * @returns {object|null} Default macro task from the version.
 */
export const getDefaultMacroTaskFromVersion = (managePlanning) => {
  if (!managePlanning?.content) return null;
  return managePlanning.content.find((action) => action?.planning?.default);
};

/**
 * Removes default macro task from an array of actions.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {object[]} Actions without default macro task.
 */
const removeDefault = (actions) =>
  actions.filter((action) => action.planning && !action.planning.default);

/**
 * Splits actions into macro tasks and other actions (tasks and deliverables).
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {[object[], object[]]} Arrays of macro tasks and other actions (tasks and deliverables).
 */
export const splitActions = (actions) => {
  const [resultMacroTasks, resultRest] = actions.reduce(
    (acc, action) => {
      const [macroTasks, rest] = acc;
      if (action.planning.action_type === enums.action_types.MACRO_TASK) {
        return [[...macroTasks, action], rest];
      }
      return [macroTasks, [...rest, action]];
    },
    [[], []]
  );

  return [resultMacroTasks, resultRest];
};

/**
 * Gets an array of macro tasks from an array of actions.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {object[]} Array of macro tasks.
 */
export const getMacroTasks = (actions) =>
  actions.filter(
    (action) => action.planning?.action_type === enums.action_types.MACRO_TASK
  );

/**
 * Gets an array of macro tasks sorted in ascending order by start date.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {object[]} Array of macro tasks sorted in ascending order by start date.
 */
export const getAscSortedMacrotasks = (actions) =>
  getMacroTasks(actions).sort((a, b) =>
    moment(a.planning.start_date).diff(moment(b.planning.start_date))
  );

/**
 * Formats an array of actions with additional properties.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @param {object[]} sortedMacroTasks - Array of sorted macro tasks.
 * @returns {object[]} Formatted array of actions.
 */
const formatActions = (actions, sortedMacroTasks) => {
  return actions.map((action) => {
    return {
      ...action,
      label: `${action?.description.substr(0, 25)}${
        action?.description.length > 25 ? '[...]' : ''
      }`,
      index: sortedMacroTasks.findIndex(
        (macroTask) => macroTask._id === action.planning.macro_task._id
      )
    };
  });
};

/**
 * Formats an array of macro tasks with additional properties.
 *
 * @function
 * @param {object[]} macroTasks - Array of macro tasks.
 * @returns {object[]} Formatted array of macro tasks.
 */
const formatMacroTasks = (macroTasks) => {
  const sorttedMacroTasks = macroTasks.sort((a, b) =>
    moment(a.planning.start_date).diff(moment(b.planning.start_date))
  );
  return sorttedMacroTasks.map((macroTask, index) => {
    return { ...macroTask, index };
  });
};

/**
 * Filters and returns an array of actions with 100% planning percentage.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {object[]} Array of finished actions.
 */
const filterFinishedActions = (actions) => {
  return actions.filter(
    (action) => action.planning.status === deliverablesStatuses[1]
  );
};

/**
 * Filters and returns an array of not finished actions.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @returns {object[]} Array of planned actions.
 */
const filterPlanifiedActions = (actions) => {
  return actions.filter(
    (action) => action.planning.status !== deliverablesStatuses[1]
  );
};

/**
 * Checks if an action involves a specific actor.
 *
 * @function
 * @param {object} action - Action object.
 * @param {string} actorId - ID of the actor.
 * @returns {boolean} True if the action involves the actor, otherwise false.
 */
export const checkActionActor = (action, actorId) => {
  return (
    action?.planning?.assigned_actors.find(
      (actor) =>
        String(actor?.user?._id) === String(actorId) ||
        String(actor?._id) === String(actorId)
    ) ||
    action?.planning?.assigned_groups.find((group) =>
      group.actors.find((actor) => String(actor._id) === String(actorId))
    )
  );
};

/**
 * Gets formatted actions based on purpose and actor.
 *
 * @function
 * @param {object[]} formattedActions - Array of formatted actions.
 * @param {string} purpose - Purpose of the actions ('finished' or 'planned').
 * @param {string|null} actorId - Actor's ID.
 * @returns {object[]} Filtered and formatted actions.
 */
const filterDisplayedActions = (formattedActions, purpose, actorId) => {
  const displayedActions = formattedActions.filter(
    (action) => action.planning.piloting_mark
  );
  const purposeFilteredActions = [];
  switch (purpose) {
    case 'finished':
      purposeFilteredActions.push(...filterFinishedActions(displayedActions));
      break;
    case 'planned':
      purposeFilteredActions.push(...filterPlanifiedActions(displayedActions));
      break;
    default:
      break;
  }
  if (actorId)
    return purposeFilteredActions.filter((action) =>
      checkActionActor(action, actorId)
    );
  return purposeFilteredActions;
};

/**
 * Gets filtered and formatted actions based on purpose and actor.
 *
 * @function
 * @param {object[]} actions - Array of actions.
 * @param {string} purpose - Purpose of the actions ('finished' or 'planned').
 * @param {string|null} actorId - Actor's ID.
 * @returns {object[]} Filtered and formatted actions (macro tasks are displayed first).
 */
export const getData = (actions, purpose, actorId) => {
  try {
    if (!actions) return [];
    const actionsWithoutDefault = removeDefault(actions);
    const sortedMacroTasks = getAscSortedMacrotasks(actionsWithoutDefault);
    const formattedActions = formatActions(
      actionsWithoutDefault,
      sortedMacroTasks
    );
    const [macroTasks, rest] = splitActions(formattedActions);
    const formattedAndSortedMacroTask = formatMacroTasks(macroTasks);
    const displayedActions = filterDisplayedActions(
      [...formattedAndSortedMacroTask, ...rest],
      purpose,
      actorId
    );
    return displayedActions;
  } catch (error) {
    console.log(error);
  }
  return true;
};

/**
 * Filters and returns the child actions that belong to a specific parent macro task.
 *
 * @function
 * @param {Array} actions - The list of all actions.
 * @param {string} parentId - The ID of the parent macro task.
 * @returns {Array} - The filtered list of child actions.
 */
export const getChildren = (actions, parentId) => {
  if (!actions || !parentId) return [];
  return actions.filter(
    (action) => action?.planning?.macro_task?._id === parentId
  );
};
