import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import Projects from './Projects';
import Perimeters from './Perimeters';

/**
 * Home for all users but project managers
 *
 * @returns {JSX.Element} HomeCommercial
 */
const HomeCommercial = () => {
  const { user, dispatchAPI } = useAuthContext();
  const [allPerimeters, setAllPerimeters] = useState([]);

  const getPerimeters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/perimeters`
      });
      setAllPerimeters(data);
    } catch (error) {
      if (error.response) message.error(error.response.status);
    }
  };

  useEffect(() => {
    if (user?.role !== 'actors:ACTOR') {
      (async () => {
        await getPerimeters();
      })();
    }
  }, []);

  return (
    <>
      {user?.role === 'admins:ADMIN' && <Perimeters />}
      <Projects allPerimeters={allPerimeters} />
    </>
  );
};

export default HomeCommercial;
