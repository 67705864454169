import React from 'react';
import { Input } from 'antd';
import useProjectContext from '../../../../contexts/ProjectContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

const { TextArea } = Input;

const useContextFields = (loading) => {
  const { project } = useProjectContext();

  const typeSelect = useGlossarySelect(project?.context_list, loading, 'type');

  const contextFields = [
    {
      name: ['factors', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['factors', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['factors', 'type'],
      input: typeSelect
    },
    {
      name: ['factors', 'impact'],
      input: <Input row={6} />
    },
    {
      name: ['factors', 'description'],
      input: <TextArea row={6} />
    }
  ];
  return { contextFields };
};

export default useContextFields;
