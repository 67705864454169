import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import useHandleResize from '../../utils/HandleResize';
import NavMenu from './NavMenu';
import Logo from '../../assets/images/l_citizencan_logo_noir.png';
import LogoSmall from '../../assets/images/icons/blue-drop.svg';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  height: 64px;
  margin-top: 5px;
  padding-left: 0px;
  overflow: hidden;
  line-height: 64px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 50px;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  margin: 60px 4px 8px 280px;
  padding-right: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (max-width: 992px) {
    margin-left: 100px;
  }

  @media (max-width: 576px) {
    margin-left: 24px;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: #c4c4c4;
  color: var(--textColor);
  box-shadow: 0 -1px 4px var(--borderColor);

  a {
    color: var(--textColor);
    font-weight: bold;
  }

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <StyledLayout as={Layout}>
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={width > 992 ? Logo : LogoSmall} />
          </Link>
        </LogoDiv>
        <NavMenu path={path} setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content}>{children}</StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,

  path: PropTypes.string.isRequired
};

export default BasicLayout;
