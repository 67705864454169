import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, Radio, Tooltip, Divider } from 'antd';
import setIconStatus from '../../../utils/constants/setIconStatus';
import { listVersion, listItems } from '../../../utils/constants/constantes';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';

const ProjectStateSelected = ({
  t,
  keyTab,
  version,
  current_status,
  extensionFile,
  setExtensionFile
}) => {
  const checkIfStatusIsAvailable = (v) => {
    if (v === 'V-init') {
      return false;
    }
    if (v === 'V0') {
      if (
        current_status === 'V0' ||
        current_status === 'V1' ||
        current_status === 'VX'
      )
        return false;
      return true;
    }
    if (v === 'V1') {
      if (current_status === 'V1' || current_status === 'VX') return false;
      return true;
    }
    if (v === 'VX') {
      if (current_status === 'VX') return false;
      return true;
    }
    return true;
  };
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile
        keyTab={keyTab}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
      />
      <Divider />
      <Form.Item name={['version']} initialValue={version}>
        <Radio.Group
          buttonStyle="solid"
          style={{
            margin: '10px 0px 10px 0px',
            width: '100%'
          }}
        >
          {listVersion.map((e) => (
            <Tooltip
              key={e}
              placement="topLeft"
              title={`${t('reporting.items_tooltip')} ${e === 'VX' ? 'V+' : e}`}
            >
              <Radio disabled={checkIfStatusIsAvailable(e)} value={e}>
                {setIconStatus(null, e, false, 19)}
              </Radio>
            </Tooltip>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['items']} initialValue={[...listItems]}>
        <Select
          className="select-item"
          mode="multiple"
          allowClear
          style={{ width: '100%', color: 'black' }}
          placeholder="Please select"
        >
          {listItems.map((item) => (
            <Select.Option
              key={item}
              value={item}
              label={t(`projects.form.${item}`)}
            >
              {t(`projects.form.${item}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

ProjectStateSelected.propTypes = {
  t: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  current_status: PropTypes.string,
  keyTab: PropTypes.string,
  extensionFile: PropTypes.string.isRequired,
  setExtensionFile: PropTypes.func.isRequired
};
ProjectStateSelected.defaultProps = {
  current_status: null,
  keyTab: null
};
export default ProjectStateSelected;
