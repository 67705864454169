import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row, Modal, Input, Tag, Button } from 'antd';
import { ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import useDashboardContext from '../../contexts/DashboardContext';
import { setVersionItem } from '../../utils/constants/setVersionItem';

const { TextArea } = Input;

/**
 * Component to display a modal for event activity.
 *
 * @component
 * @param {Object} data - Data for the event activity.
 * @param {Object} dataUpdate - Updated data for the event activity.
 * @param {boolean} visible - Visibility state of the modal.
 * @param {function} setVisible - Function to set modal visibility state.
 * @param {number} versionIndex - Index of the version.
 * @param {function} setDataToModal - Function to set data for the modal.
 * @param {function} setVisibleForm - Function to set form visibility state.
 * @param {string} idProject - Project ID.
 * @param {Object} version - Version object containing mangePlanning items.
 * @returns {JSX.Element} - The generated modal component for event activity.
 */
const ModalForEventActivity = ({
  data,
  dataUpdate,
  visible,
  setVisible,
  setDataToModal,
  setVisibleForm,
  idProject,
  version
}) => {
  const { updateResource } = useDashboardContext();
  const { t } = useTranslation();
  const [comment, setComment] = useState();
  const onChangeTextArea = (event) => {
    setComment(event);
  };

  const returnTrue = () => true;
  const handleSubmit = (purpose) => {
    let result;
    const listEvents = data.events || [];
    if (purpose === 'with_comment') {
      if (data.events) {
        listEvents.push({
          start_date: dataUpdate?.deliverables?.planning?.start_date,
          old_start_date: data?.planning.start_date,
          comment,
          due_date: dataUpdate?.deliverables?.planning?.due_date,
          old_due_date: data?.planning.due_date
        });
        result = {
          deliverables: { ...dataUpdate.deliverables, events: listEvents }
        };
      } else {
        result = {
          deliverables: {
            ...dataUpdate.deliverables,
            events: [
              {
                start_date: dataUpdate?.deliverables?.planning?.start_date,
                old_start_date: data?.planning.start_date,
                comment,
                due_date: dataUpdate?.deliverables?.planning?.due_date,
                old_due_date: data?.planning.due_date
              }
            ]
          }
        };
      }
      updateResource(
        result,
        version,
        'manage_planning',
        'deliverables',
        returnTrue,
        idProject
      );
    }
    if (purpose === 'without') {
      result = {
        deliverables: { ...dataUpdate.deliverables, events: listEvents }
      };
      updateResource(
        result,
        version,
        'manage_planning',
        'deliverables',
        returnTrue,
        idProject
      );
    }
    setVisible(!visible);
    setDataToModal();
    setVisibleForm(false);
  };

  return (
    <Modal
      centered
      destroyOnClose
      visible={visible}
      onCancel={() => setVisible(!visible)}
      onOk={() => setVisible(!visible)}
      title={t(`dashboard.event_activity.title_modal`, {
        title: data?.description
      })}
      footer={
        <Row justify="space-around">
          <Button onClick={() => setVisible(false)} type="danger">
            {t('dashboard.event_activity.cancel')}
          </Button>
          <Button onClick={() => handleSubmit('without')} type="text">
            {t('dashboard.event_activity.no_comment')}
          </Button>
          <Button onClick={() => handleSubmit('with_comment')} type="primary">
            {t('dashboard.event_activity.save')}
          </Button>
        </Row>
      }
    >
      <div style={{ margin: 20 }}>
        <ExclamationCircleOutlined
          style={{
            margin: '0 12px',
            fontSize: '1.5em',
            position: 'relative',
            top: 2
          }}
        />
        {t('dashboard.event_activity.description_modal')}
        {data && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 15,
                  marginRight: 20
                }}
              >
                {t('dashboard.event_activity.version')}
              </p>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: -5
                }}
              >
                {setVersionItem(version?.index)}
              </p>
            </div>
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col span={10}>{t('dashboard.event_activity.before_change')}</Col>
              <Col style={{ textAlign: 'center', fontWeight: 'bold' }} span={8}>
                {t('dashboard.event_activity.after_change')}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={6}>
                <p style={{ fontWeight: 'bold' }}>
                  {' '}
                  {t('dashboard.event_activity.start_date')}
                </p>
              </Col>
              <Col span={8} style={{ textAlign: 'start' }}>
                <Tag>
                  {data?.planning?.start_date &&
                    moment(data?.planning?.start_date).format('DD-MM-YYYY')}
                </Tag>
              </Col>
              <Col span={4}>
                <RightOutlined />
              </Col>
              <Col span={6}>
                <Tag
                  color={
                    moment(
                      dataUpdate?.deliverables?.planning?.start_date
                    ).format('DD-MM-YYYY') !==
                      moment(data?.planning?.start_date).format('DD-MM-YYYY') &&
                    'red'
                  }
                >
                  {dataUpdate?.deliverables?.planning?.start_date &&
                    moment(
                      dataUpdate?.deliverables?.planning?.start_date
                    ).format('DD-MM-YYYY')}
                </Tag>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col span={6} style={{ fontWeight: 'bold' }}>
                {t('dashboard.event_activity.due_date')}
              </Col>
              <Col span={8} style={{ textAlign: 'start' }}>
                <Tag>
                  {moment(data?.planning?.due_date).format('DD-MM-YYYY')}
                </Tag>
              </Col>
              <Col span={4}>
                <RightOutlined />
              </Col>

              <Col span={6}>
                <Tag
                  color={
                    moment(dataUpdate?.deliverables?.planning?.due_date).format(
                      'DD-MM-YYYY'
                    ) !==
                      moment(data?.planning?.due_date).format('DD-MM-YYYY') &&
                    'red'
                  }
                >
                  {moment(dataUpdate?.deliverables?.planning?.due_date).format(
                    'DD-MM-YYYY'
                  )}
                </Tag>
              </Col>
            </Row>
          </div>
        )}
        <div
          style={{
            textAlign: 'center',
            marginTop: 30,
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          <p>{t('dashboard.event_activity.purpose_desc')}</p>
          <TextArea
            onChange={(value) => onChangeTextArea(value.target.value)}
            style={{ height: 50, width: 300 }}
          />
        </div>
      </div>
    </Modal>
  );
};

ModalForEventActivity.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  dataUpdate: PropTypes.oneOfType([PropTypes.object]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setDataToModal: PropTypes.func.isRequired,
  setVisibleForm: PropTypes.func.isRequired,
  idProject: PropTypes.string.isRequired,
  version: PropTypes.shape({
    index: PropTypes.number
  }).isRequired
};
ModalForEventActivity.defaultProps = {
  data: null,
  dataUpdate: null
};

export default ModalForEventActivity;
