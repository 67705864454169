import React, { useEffect, useState } from 'react';
import { Select, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import getBeneficiariesInput from './beneficiariesInput';

const { Option } = Select;

/**
 * Custom hook that generates utility fields for deliverables.
 *
 * @hook
 * @param {string} purposeArg - Purpose argument indicating the use case.
 * @param {string[]} deliverablesFor - Array of deliverables use cases.
 * @param {object[]} actorsItem - AcotrsItem containing actors and groups.
 * @param {string} initialUseFor - Initial value of useFor.
 * @param {Function} resetBeneficiaries - Function to reset beneficiaries field
 * @returns {object[]} Array of utility fields for deliverables.
 */
const useUtilityFields = (
  purposeArg,
  deliverablesFor,
  actorsItem,
  initialUseFor,
  resetBeneficiaries
) => {
  const { t } = useTranslation();
  const [useFor, setUseFor] = useState(initialUseFor);

  useEffect(() => {
    setUseFor(initialUseFor);
  }, [initialUseFor]);

  const disabled = purposeArg === 'actor';

  const utilityFields = [
    {
      name: ['deliverables', 'utility', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'utility', 'use_for'],
      rules: [{ required: true }],
      input: (
        <Select
          disabled={disabled}
          showSearch
          onChange={(value) => {
            setUseFor(value);
            resetBeneficiaries(value);
          }}
        >
          {deliverablesFor?.map((value) => (
            <Option key={value} value={value}>
              {t(
                `projects.form.items.utility.deliverables.beneficiaries.${value}`
              )}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'utility', 'beneficiaries'],
      hidden: ['customer', 'self'].includes(useFor),
      rules: [{ required: true }],
      input: getBeneficiariesInput(disabled, useFor, actorsItem)
    },
    {
      name: ['deliverables', 'utility', 'why'],
      input: (
        <TextArea disabled={disabled} style={{ width: 250, maxWidth: 250 }} />
      )
    },
    {
      name: ['deliverables', 'utility', 'coefficient'],
      input: <InputNumber disabled={disabled} min={1} max={100} />
    }
  ];

  return utilityFields;
};

export default useUtilityFields;
