import useUtilityFields from './useUtilityFields';
import useGenerateFormItem from '../../../../../../../utils/GenerateFormItem';
/**
 * Custom hook that generates utility fields for deliverables.
 *
 * @param {string} purpose - Purpose argument indicating the use case.
 * @param {string[]} deliverablesFor - Array of deliverables use cases.
 * @param {object[]} actorsItem - AcotrsItem containing actors and groups.
 * @param {string} initialUseFor - Initial value of useFor.
 * @param {Function} resetBeneficiaries - Function to reset beneficiaries field
 * @returns {object[]} Array of utility fields for deliverables.
 */
const UtilityFields = ({
  purpose,
  deliverablesFor,
  actorsItem,
  initialUseFor,
  resetBeneficiary
}) => {
  const generateFields = useGenerateFormItem();
  const utilityFields = useUtilityFields(
    purpose,
    deliverablesFor,
    actorsItem,
    initialUseFor,
    resetBeneficiary
  );

  return utilityFields?.map((field) => generateFields('projects', field));
};

export default UtilityFields;
