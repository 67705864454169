import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowSupervisedProjetcs from './ShowSupervisedProjects';
import ShowSupervisorDashBoard from './ShowSupervisorDashBoard';
import { ProjectContextProvider } from '../../contexts/ProjectContext';
import { routes } from '../../utils/constants/routes';

const SupervisorRouter = () => {
  const { path } = useRouteMatch();
  return (
    <ProjectContextProvider>
      <Switch>
        <Route
          path={`${path}${routes.SUPERVISOR}/show/:id`}
          component={ShowSupervisorDashBoard}
        />
        <Route
          path={`${path}${routes.SUPERVISOR}`}
          component={ShowSupervisedProjetcs}
        />
        <Route
          path={path}
          render={() => <Redirect to={`${path}${routes.SUPERVISOR}`} />}
        />
      </Switch>
    </ProjectContextProvider>
  );
};
export default SupervisorRouter;
