import React, { useEffect, useState } from 'react';

import { InputNumber, Input } from 'antd';
import useProjectContext from '../../../../contexts/ProjectContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

const { TextArea } = Input;

const useCostFields = (loading, initialValues) => {
  const { project } = useProjectContext();
  const [charge, setCharge] = useState();
  const [costValue, setCostValue] = useState();

  const typeSelect = useGlossarySelect(project?.cost_list, loading, 'type');

  const handleCostValueChange = (value) => {
    setCostValue(value);
  };

  const handleChargeChange = (value) => {
    setCharge(value);
  };

  useEffect(() => {
    if (initialValues) {
      setCharge(initialValues.charge);
      setCostValue(initialValues.cost_value);
    }
  }, [initialValues?.cost_value, initialValues?.charge]);

  const required =
    (charge === undefined || charge === null) &&
    (costValue === undefined || costValue === null);

  const costFields = [
    {
      name: ['costs', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['costs', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['costs', 'type'],
      rules: [{ required: true }],
      input: typeSelect
    },
    {
      name: ['costs', 'charge'],
      rules: [{ required }],
      input: (
        <InputNumber
          onChange={handleChargeChange}
          style={{ width: 150 }}
          min={0}
        />
      )
    },
    {
      name: ['costs', 'cost_value'],
      rules: [{ required }],
      input: (
        <InputNumber
          style={{ width: 200 }}
          min={0}
          addonAfter="€"
          onChange={handleCostValueChange}
        />
      )
    },
    {
      name: ['costs', 'description'],
      input: <TextArea row={4} />
    }
  ];
  return { costFields };
};

export default useCostFields;
