import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Checkbox, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { formItemLayout } from '../../../../../utils/constants/formLayout';

/**
 * A form component to manage planning settings.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.form - The Form instance for the component.
 * @param {Object} props.listItemsManage - The list of items to manage.
 * @param {Object} props.configPlanningUtility - The initial values for planning utility.
 * @param {Function} props.setConfigPlanningUtility - Function to set planning utility configuration.
 * @param {Function} props.handleChange - Function to handle field changes.
 * @param {Function} props.manageSubmit - Function to handle form submission.
 * @param {Function} props.handleCancel - Function to handle form cancellation.
 * @returns {JSX.Element} - A JSX Element representing the ManagePlanningForm component.
 */
const ManagePlanningForm = ({
  form,
  listItemsManage,
  configPlanningUtility,
  setConfigPlanningUtility,
  handleChange,
  manageSubmit,
  handleCancel
}) => {
  const { t } = useTranslation();
  return (
    <Form
      {...formItemLayout}
      onValuesChange={handleChange}
      onFinish={(values) => manageSubmit(values)}
      name={t('projects.form.items.manage_planning.title')}
      form={form}
    >
      <>
        <Card
          headStyle={{
            boxShadow: 0,
            borderRadius: 10,
            backgroundColor: 'var(--clientColor'
          }}
          title={
            <span style={{ color: '#fff' }}>
              {t('projects.form.common_fields')}
            </span>
          }
        >
          {listItemsManage.common}
        </Card>

        <Row>
          {Object.entries(listItemsManage).map(
            ([key, value]) =>
              key !== 'common' && (
                <Col key={key} xxl={12} sm={24} style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      marginRight: 20,
                      boxShadow: 0,
                      borderRadius: 10,
                      backgroundColor: 'var(--clientColor',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      height: 50,
                      marginBottom: 20
                    }}
                  >
                    <span style={{ color: '#fff' }}>
                      {t(`projects.form.${key}`)}
                    </span>
                    <Checkbox
                      checked={configPlanningUtility[key]}
                      onChange={() =>
                        setConfigPlanningUtility({
                          ...configPlanningUtility,
                          [key]: !configPlanningUtility[key]
                        })
                      }
                    />
                  </div>
                  {configPlanningUtility[key] && value}
                </Col>
              )
          )}
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row
            justify="end"
            wrap={false}
            style={{
              position: 'relative',
              right: '-90%',
              marginBottom: 50
            }}
          >
            <Col>
              <Button
                style={{ margin: '0 6px' }}
                type="link"
                danger
                onClick={handleCancel}
              >
                <span>{`${t('buttons.cancel')} `}</span>
                <CloseOutlined />
              </Button>
            </Col>
            <Col>
              <Button type="add" htmlType="submit">
                <span>{`${t('buttons.save')} `}</span>
                <CheckOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </>
    </Form>
  );
};

ManagePlanningForm.propTypes = {
  form: PropTypes.shape({}),
  listItemsManage: PropTypes.shape({ common: PropTypes.shape({}) }),
  configPlanningUtility: PropTypes.shape({}),
  setConfigPlanningUtility: PropTypes.func,
  handleChange: PropTypes.func,
  manageSubmit: PropTypes.func,
  handleCancel: PropTypes.func
};

ManagePlanningForm.defaultProps = {
  form: null,
  listItemsManage: null,
  configPlanningUtility: null,
  setConfigPlanningUtility: null,
  handleChange: null,
  manageSubmit: null,
  handleCancel: null
};

export default ManagePlanningForm;
