import React from 'react';
import ProjectsGantt from '../Projects/Projects/projectGantt/ProjectsGantt';

/**
 * Displays a Gantt chart of projects for actors.
 *
 * @component
 * @returns {JSX.Element} The rendered Gantt chart component for actors.
 */
const ShowProjects = () => {
  return <ProjectsGantt purpose="actor" projectPath="/actor/" />;
};

export default ShowProjects;
