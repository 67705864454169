import React from 'react';
import { Badge, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { enums } from '../../routes/Projects/Projects/utilsProject/ManagePlanning/utils';

/**
 * Custom hook for generating action columns for a task table.
 *
 * @returns {Object} - Contains the actionColumns configuration for the table.
 */
const useActionColumns = () => {
  const { t } = useTranslation();

  const badgeStyle = (color) => {
    return {
      backgroundColor: color,
      borderRadius: '50%',
      width: '25px',
      height: '25px',
      lineHeight: '25px',
      textAlign: 'center',
      fontSize: '14px'
    };
  };

  const setColor = (action_type) => {
    return (
      <span
        style={{
          color:
            action_type === enums.action_types.MACRO_TASK
              ? 'var(--primary)'
              : 'var(--secondaryColor)',
          fontWeight: 'bold'
        }}
      >
        {
          t(
            `projects.form.deliverables.planning.${action_type.toLowerCase()}`
          )[0]
        }
      </span>
    );
  };

  const actionColumns = [
    {
      render: (task) => {
        return task.index > -1 ? (
          <Badge
            count={task.index + 1}
            overflowCount={999}
            style={badgeStyle(task.planning.color)}
          />
        ) : (
          <div style={badgeStyle(task.planning.color)} />
        );
      },
      width: 50
    },
    {
      title: `${t(`datatable.column.type`)}`,
      dataIndex: ['planning', 'action_type'],
      key: 'type',
      render: (actionType) => setColor(actionType),
      sorter: (a, b) => a.action_type?.localeCompare(b.action_type),
      filters: Object.keys(enums.action_types).map((actionType) => ({
        text: t(
          `projects.form.deliverables.planning.${actionType.toLowerCase()}`
        ),
        value: actionType
      })),
      onFilter: (value, record) => record.planning.action_type === value,
      width: 20
    },
    {
      title: `${t(`datatable.column.entitled`)}`,
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <Space>
          <span>{text}</span>
          <Badge
            title={`Changements de dates: ${record.events &&
              record.events.length}`}
            count={record.events && record.events.length}
            size="small"
          />
        </Space>
      ),
      sorter: (a, b) => a.label.localeCompare(b.label)
    },
    {
      title: `${t(`datatable.column.actor`)}`,
      key: 'actor',
      render: (record) => {
        const { planning } = record;
        const items = [
          ...planning.assigned_actors,
          ...planning.assigned_groups
        ];
        return items?.length
          ? items
              ?.map((item) => {
                if (item.first_name && item.last_name)
                  return `${item.first_name} ${item.last_name}`;
                if (item.name) return item.name;
                return null;
              })
              .join(' - ')
          : '-';
      },
      sorter: (a, b) => a.actor?.localeCompare(b.actor)
    },
    {
      title: `${t(`datatable.column.macro_task`)}`,
      key: 'macro_task',
      dataIndex: ['planning', 'macro_task'],
      render: (parent) => parent.description,
      sorter: (a, b) => a.description.localeCompare(b.description)
    },
    {
      title: `${t(`datatable.column.date`)}`,
      dataIndex: ['planning', 'due_date'],
      sorter: (a, b) => {
        return new Date(a?.planning.due_date) - new Date(b?.planning.due_date);
      },
      key: 'date',
      render: (date) => date && moment(date).format('DD-MM-YYYY'),
      width: 30
    },
    {
      title: '%',
      dataIndex: ['planning', 'percentage'],
      key: 'percentage',
      width: 40
    }
  ];

  return {
    actionColumns
  };
};

export default useActionColumns;
