import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Card } from 'antd';
import EventView from './EventView';

const EventHeadband = ({
  eventClose,
  getProjects,
  updateResource,
  configNote,
  data,
  openModal
}) => {
  return (
    <Row justify="space-between" gutter={24}>
      <EventView
        events={eventClose?.beforeDate}
        project={data}
        event_type="previous_event"
        updateResource={updateResource}
        getProjects={getProjects}
      />
      <Col xxl={16} xl={16} sm={24} xs={24}>
        <Card
          onClick={() =>
            openModal(data, 'note', null, 'note', null, configNote)
          }
          style={{
            background: 'var(--itemHoverBackground)',
            boxShadow: 'none',
            color: 'var(--textColor)',
            fontSize: '1.1em',
            padding: 0,
            margin: '16px 0 24px 0',
            borderRadius: 50
          }}
        >
          <InfoCircleOutlined
            style={{
              margin: '0 12px',
              fontSize: '1.5em',
              position: 'relative',
              top: 2
            }}
          />
          {data.note !== null ? `${data?.note.substr(0, 80)} [...]` : null}
        </Card>
      </Col>
      <EventView
        events={eventClose?.afterDate}
        project={data}
        event_type="next_event"
        updateResource={updateResource}
        getProjects={getProjects}
      />
    </Row>
  );
};

EventHeadband.propTypes = {
  eventClose: PropTypes.oneOfType([PropTypes.array]),
  getProjects: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
  configNote: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]),
  openModal: PropTypes.func.isRequired
};

EventHeadband.defaultProps = {
  data: null,
  eventClose: null
};

export default EventHeadband;
