import React from 'react';
import { Checkbox, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Custom React hook for managing import-related fields in a form.
 *
 * @param {Array} projects - List of projects to select from.
 * @param {boolean} importProject - Boolean state to manage project import.
 * @param {function} setImportProject - Function to update the project import state.
 * @param {function} setImportGlossary - Function to update the glossary import state.
 * @returns {object} An object containing importFields and importSwitch for form management.
 */
const useImportFields = (
  projects,
  importProject,
  setImportProject,
  setImportGlossary
) => {
  const { t } = useTranslation();

  const importSwitch = {
    name: ['import_project', 'import'],
    required: false,
    input: (
      <Switch
        checked={importProject}
        onChange={() => setImportProject(!importProject)}
      />
    )
  };

  const importableFields = ['actors', 'glossary', 'planning', 'risks'];

  const importFields = [
    {
      name: ['import_project', 'project'],
      required: true,
      input: (
        <Select>
          {projects?.map((project) => {
            return (
              <Select.Option key={project._id} value={project._id}>
                {project.name}
              </Select.Option>
            );
          })}
        </Select>
      )
    },
    {
      name: ['import_project', 'configurations'],
      input: (
        <Checkbox.Group
          onChange={(checked) =>
            setImportGlossary(checked.includes('glossary'))
          }
        >
          {importableFields.map((field) => (
            <Checkbox key={field} value={field}>
              {t(`projects.form.import_project.${field}`)}
            </Checkbox>
          ))}
        </Checkbox.Group>
      )
    }
  ];

  return { importFields, importSwitch };
};

export default useImportFields;
