import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import GroupDisplay from './GroupDisplay.jsx/GroupDisplay';
import ActorDisplay from './ActorDisplay.jsx/ActorDisplay';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import useActorsFilters from './ActorsFilters/useActorsFilters';

/**
 * Component for displaying actors and groups associated with a project.
 *
 * @component
 * @param {Object} version - The version object containing actors items.
 * @param {string} title - The type of the item.
 * @param {Function} getProject - Function to fetch project data.
 * @param {Function} generateForm - Function to generate form for actors.
 * @param {boolean} role - Controls componenents visibility according to user role.
 * @param {Object} project - The project.
 * @returns {JSX.Element} - The rendered component for displaying actors and groups.
 */
const ActorsDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [purpose, setPurpose] = useState('addGroup');
  const { getActorsItemFilters, getFilteredActorsItems } = useActorsFilters(
    project?.groupRole_list
  );

  const openFormModal = (newPurpose) => {
    setVisibleModal(true);
    setPurpose(newPurpose);
  };
  const openForm = (idRender) => {
    setVisible(idRender);
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  const filteredActorsItems = getFilteredActorsItems(version?.content);
  return (
    project && (
      <>
        {getActorsItemFilters()}
        {filteredActorsItems?.groups?.map((group) => (
          <GroupDisplay
            key={group._id}
            groupData={group}
            role={role}
            project={project}
            title={title}
            version={version}
            visible={visible}
            openForm={openForm}
            closeForm={closeForm}
            generateForm={generateForm}
          />
        ))}
        {filteredActorsItems?.actors?.map((actor) => {
          return (
            <ActorDisplay
              key={actor._id}
              actorData={actor}
              role={role}
              project={project}
              title={title}
              version={version}
              visible={visible}
              openForm={openForm}
              closeForm={closeForm}
              generateForm={generateForm}
            />
          );
        })}
        {visibleModal && (
          <Modal
            title={t(`projects.form.items.${title}.title`)}
            width={700}
            closable
            visible={visibleModal}
            footer={false}
            onCancel={closeFormModal}
          >
            {generateForm(
              purpose,
              version,
              title,
              closeFormModal,
              null,
              'actors_list'
            )}
          </Modal>
        )}
        {role && (
          <Row>
            <AddItemButton
              disabled={project?.status === 'close'}
              onClick={() => openFormModal('addActor')}
              title={t('projects.form.items.actors.add_actor')}
            />
            <AddItemButton
              disabled={project?.status === 'close'}
              onClick={() => openFormModal('addGroup')}
              title={t('projects.form.items.actors.add_group')}
            />
          </Row>
        )}
      </>
    )
  );
};

ActorsDisplay.propTypes = {
  version: PropTypes.shape({
    content: PropTypes.shape({})
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  project: PropTypes.shape({
    status: PropTypes.string,
    groupRole_list: PropTypes.arrayOf(PropTypes.string)
  })
};

ActorsDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  project: null
};

export default ActorsDisplay;
