import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Spin, message } from 'antd';
import Title from 'antd/lib/skeleton/Title';
import useAuthContext from '../../../contexts/AuthContext';
import formatProjectData from '../../Supervisor/utils';
import ProjectCard from '../../../components/ProjectCard/ProjectCard';
import ProjectFilters from './ProjectFilters';

/**
 * List of projects without perimeter
 *
 * @param {Object} allPerimeters List of all perimeters
 * @returns {JSX.Element} Projects
 */
const Projects = ({ allPerimeters }) => {
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const [selectedPerimeter, setSelectedPerimeter] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [searchValue, setSearchValue] = useState();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [perimeters, setPerimeters] = useState();
  const [customers, setCustomers] = useState();
  const [managers, setManagers] = useState();

  const getDisplayedProjects = (allProjects) => {
    switch (user?.role) {
      case 'actors:ACTOR':
        return allProjects;
      case 'admins:ADMIN':
        return allProjects.filter((project) => project.perimeter.default);
      case 'admins:PERIMETER_ADMIN':
        return allProjects.filter((project) => !project.assignee);
      default:
        return [];
    }
  };

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      const { data } = await dispatchAPI('GET', {
        url: `/projects${
          user?.role === 'actors:ACTOR' ? '/actorView' : ''
        }${searchURL ?? ''}?populate=assignee,perimeter${
          selectedPerimeter ? `&perimeter=${selectedPerimeter}` : ''
        }${selectedCustomer ? `&customer=${selectedCustomer}` : ''}${
          selectedManager ? `&assignee=${selectedManager}` : ''
        }${selectedStatus ? `&status=${selectedStatus}` : ''}`
      });

      setProjects(getDisplayedProjects(data));

      const { newPerimeters, newCustomers, newManagers } = formatProjectData(
        data
      );
      setPerimeters(newPerimeters);
      setCustomers(newCustomers);
      setManagers(newManagers);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setLoading(false);
  }, [
    selectedCustomer,
    selectedPerimeter,
    selectedManager,
    selectedStatus,
    searchValue
  ]);

  useEffect(() => {
    (async () => {
      await getProjects();
    })();
  }, [getProjects]);

  return (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`projects.home.${user?.role.split(':')[1]}`)}
      </div>
      <Spin spinning={loading}>
        <ProjectFilters
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          perimeters={perimeters}
          setSelectedPerimeter={setSelectedPerimeter}
          customers={customers}
          setSelectedCustomer={setSelectedCustomer}
          managers={managers}
          setSelectedManager={setSelectedManager}
          setSelectedStatus={setSelectedStatus}
        />
        <Row gutter={[16, 16]}>
          {projects.length > 0 ? (
            projects.map((f) => (
              <ProjectCard
                key={f._id}
                project={f}
                pathname={`actor/${f._id}`}
                getProjects={getProjects}
                perimeters={allPerimeters}
                purpose="home"
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={8}
                xxl={6}
              />
            ))
          ) : (
            <Row justify="center" style={{ display: 'flex', width: '100%' }}>
              <Title style={{ textAlign: 'center' }} level={2}>
                {t('errors.message.no_projects')}
              </Title>
            </Row>
          )}
        </Row>
      </Spin>
    </>
  );
};

export default Projects;

Projects.propTypes = {
  allPerimeters: PropTypes.arrayOf(PropTypes.shape({}))
};

Projects.defaultProps = {
  allPerimeters: []
};
