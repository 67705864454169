import React from 'react';
import { Input } from 'antd';
import getActorsAndGroupsSelect from '../../../../fieldsItems/Inputs/getActorsAndGroupsSelect';

/**
 * BeneficiariesInput component displays the input field or select options based on the useFor value.
 *
 * @component
 * @param {boolean} [disabled=true] - Indicates if the input/select should be disabled.
 * @param {string} useFor - The purpose for which the component is used. Possible values:"customer", "self", "others", "company_at_large".
 * @param {object} actorsItem - AcotrsItem containing actors and groups.
 * @returns {JSX.Element|null} - The JSX representation of the component.
 */
export default (disabled, useFor, actorsItem) => {
  switch (useFor) {
    case 'others':
      return getActorsAndGroupsSelect(actorsItem, false, disabled);
    case 'company_at_large':
      return <Input disabled={disabled} />;
    default:
      return <Input />;
  }
};
