import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

const Header = ({ deliverable }) => {
  const { t } = useTranslation();
  const { description } = deliverable;
  const { use_for, coefficient, why } = deliverable?.utility;

  return (
    <Descriptions column={5} size="small">
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.description`)}
        span={2}
      >
        {description}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.use_for`)}
      >
        {t(`projects.form.items.utility.deliverables.beneficiaries.${use_for}`)}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.coefficient`)}
      >
        {coefficient}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.why`)}
      >
        {why}
      </Descriptions.Item>
    </Descriptions>
  );
};

Header.propTypes = {
  deliverable: PropTypes.shape({
    description: PropTypes.string,
    utility: PropTypes.shape({})
  }).isRequired
};

export default Header;
