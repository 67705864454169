import { useTranslation } from 'react-i18next';
import projectState from './projectState';
import evolutionProject from './evolutionProject';
import federation from './federation';
import planning from './planning';
import gap from './gap';

const useFormatReportByType = (typeOfReport) => {
  const { t } = useTranslation();
  const formatByTypeOfReport = {
    project_state: (data, filter) => projectState(t, data, filter),
    evolution_project: (data, filter) => evolutionProject(t, data, filter),
    federation: (data, filter) => federation(t, data, filter),
    planning: (data, filter) => planning(t, data, filter),
    gap: (data, filter) => gap(t, data, filter),
    meeting_report: (data) => projectState(data)
  };

  return formatByTypeOfReport[typeOfReport];
};
export default useFormatReportByType;
