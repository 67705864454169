import React from 'react';
import { Button, Collapse, Descriptions, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../../../utils/constants/customIcon';
import ButtonDeleteAll from '../../../../../utilsProject/ButtonDeleteAll';
import Header from './Header';

const { Panel } = Collapse;

/**
 * A component that renders the planning tab content for deliverables.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project object.
 * @param {Object} props.version - The version object.
 * @param {Function} props.generateForm - The function to generate a form.
 * @param {Function} props.openForm - The function to open a form.
 * @param {Function} props.closeForm - The function to close a form.
 * @param {Object} props.config - The configuration object.
 * @param {string} props.visible - The ID of the visible element.
 * @param {boolean} props.role - Controls components visibility according to user role.
 * @param {Object} props.dataDeliverables - The data for deliverables.
 * @returns {React.ReactNode|null} - The rendered component JSX or null.
 */
const PlanningTabPane = ({
  project,
  version,
  generateForm,
  openForm,
  closeForm,
  config,
  visible,
  role,
  dataDeliverables
}) => {
  const { t } = useTranslation();
  return (version?.content || [])
    .filter((deli) => deli.planning)
    .map((e) => {
      const { _id, planning } = e;

      return visible === _id && dataDeliverables ? (
        <React.Fragment key={_id}>
          {generateForm(
            'edit',
            version,
            'manage_planning',
            closeForm,
            dataDeliverables,
            'deliverables',
            config
          )}
        </React.Fragment>
      ) : (
        <React.Fragment key={_id}>
          <Collapse>
            <Panel
              style={{ marginBottom: 12 }}
              extra={
                role && (
                  <Row justify="center" style={{ flexWrap: 'nowrap' }}>
                    <Button
                      disabled={project?.status === 'close'}
                      onClick={() => openForm(_id, e)}
                      size="small"
                      type="link"
                      style={{
                        color: 'var(--secondaryColor)',
                        top: 2
                      }}
                    >
                      <EditIcon />
                    </Button>
                    {!e?.planning.default && (
                      <ButtonDeleteAll
                        purpose="matrice"
                        projectId={project._id}
                        title="manage_planning"
                        titleId={version._id}
                        data={version}
                        matriceName="deliverables"
                        matriceId={_id}
                        handleCancel={closeForm}
                        version={version}
                      />
                    )}
                  </Row>
                )
              }
              header={<Header deliverable={e} />}
            >
              <Descriptions column={2} size="small" style={{ padding: 12 }}>
                <Descriptions.Item
                  label={t(`projects.form.deliverables.actors`)}
                >
                  {planning.assigned_actors
                    ?.map(
                      (actor) =>
                        `${actor.first_name || ''} ${actor.last_name || ''}`
                    )
                    .join(' - ')}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t(`projects.form.deliverables.groups`)}
                >
                  {planning.assigned_groups
                    ?.map((group) => `${group.name || ''}`)
                    .join(' - ')}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
          </Collapse>
        </React.Fragment>
      );
    });
};

export default PlanningTabPane;
