import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Switch } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../../../../../../utils/GenerateFormItem';
import { enums as planningEnums, getChildren } from '../../utils';
import useActionTypeFields from './usePlanningFields/useActionTypeFields';
import useDateFields from './usePlanningFields/useDateFields';
import useActionActorsFields from './usePlanningFields/useActionActorsFields';
import useDetailsFields from './usePlanningFields/useDetailsFields';

/**
 * A component to render planning fields based on various inputs.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.purposeArg - The purpose indicating the use case.
 * @param {string} props.colorGantt - The color associated with Gantt chart.
 * @param {function} props.onChangeColor - The function to handle color change.
 * @param {Array} props.managePlanningItems - List of planning items to manage.
 * @param {Object} props.actorsItem - Object containing project actors and groups.
 * @param {Object} props.initialValues - Initial values for planning.
 * @param {number} props.dateVariation - Variation in dates.
 * @param {boolean} props.linkedDates - Whether linked dates are enabled.
 * @param {function} props.setLinkedDates - Function to set linked dates.
 * @param {function} props.resetDates - Function to reset dates.
 * @param {Array} props.deliverableStatuses - List of deliverable statuses.
 * @returns {JSX.Element} - A JSX Element representing the PlanningFields component.
 */
const PlanningFields = ({
  purposeArg,
  colorGantt,
  onChangeColor,
  managePlanningItems,
  actorsItem,
  initialValues,
  dateVariation,
  linkedDates,
  setLinkedDates,
  resetDates,
  deliverableStatuses
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const [actionType, setActionType] = useState(
    planningEnums.action_types.MACRO_TASK
  );

  const [childrenBlockingTooltip, setChildrenBlockingTooltip] = useState(false);

  const isMacroTask = actionType === planningEnums.action_types.MACRO_TASK;
  const isNotDeliverable =
    actionType !== planningEnums.action_types.DELIVERABLE;

  const actionTypeFields = useActionTypeFields(
    setActionType,
    purposeArg,
    isMacroTask
  );

  const dateFields = useDateFields(isNotDeliverable, childrenBlockingTooltip);

  const actionActorsFields = useActionActorsFields(
    actorsItem,
    onChangeColor,
    managePlanningItems
  );

  const detailsFields = useDetailsFields(
    deliverableStatuses,
    colorGantt,
    onChangeColor,
    isMacroTask
  );

  const handleLinkedDatesChange = (value) => {
    setLinkedDates(value);
    if (value) resetDates();
  };

  const dateChangedWarning = () => (
    <span style={{ fontSize: 16, color: 'red' }}>
      <WarningOutlined />
      {` ${t('projects.form.deliverables.planning.children_warning', {
        difference: dateVariation
      })}`}
    </span>
  );

  const customSwitch = () => {
    return (
      <Row align="middle" justify="center">
        <div style={{ color: linkedDates ? '#999' : '' }}>
          Modifier les dates
        </div>
        <Switch
          onChange={(value) => handleLinkedDatesChange(value)}
          style={{ margin: '16px' }}
        />
        <div style={{ color: linkedDates ? '' : '#999' }}>
          Décaler la macro-tâche
        </div>
      </Row>
    );
  };

  useEffect(() => {
    if (initialValues?.planning) {
      setActionType(
        initialValues.planning.action_type ||
          planningEnums.action_types.MACRO_TASK
      );
      const blockingChild = getChildren(
        managePlanningItems,
        initialValues._id
      ).find((child) =>
        deliverableStatuses?.slice(0, 2).includes(child.planning.status)
      );

      setChildrenBlockingTooltip(
        blockingChild &&
          t('projects.form.deliverables.planning.disabled.children', {
            children: blockingChild.description
          })
      );
    }
  }, [initialValues.planning, deliverableStatuses, managePlanningItems]);

  return (
    <>
      {actionTypeFields.map((field) => generateFields('projects', field))}

      {isMacroTask &&
        !childrenBlockingTooltip &&
        purposeArg === 'edit' &&
        customSwitch()}

      {dateFields.map((field) => generateFields('projects', field))}

      {!!dateVariation && linkedDates && dateChangedWarning()}

      {!isMacroTask &&
        actionActorsFields.map((field) => generateFields('projects', field))}

      {detailsFields.map((field) => generateFields('projects', field))}
    </>
  );
};

PlanningFields.propTypes = {
  purposeArg: PropTypes.string.isRequired,
  colorGantt: PropTypes.string.isRequired,
  onChangeColor: PropTypes.func.isRequired,
  managePlanningItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  actorsItem: PropTypes.objectOf(PropTypes.any).isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  dateVariation: PropTypes.number.isRequired,
  linkedDates: PropTypes.bool.isRequired,
  setLinkedDates: PropTypes.func.isRequired,
  resetDates: PropTypes.func.isRequired,
  deliverableStatuses: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PlanningFields;
