import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import useCommunicationFields from './useCommunicationFields';
import useGenerateFormItem from '../../../../../../utils/GenerateFormItem';

/**
 * Component for rendering communication fields in a form.
 *
 * @component
 * @param {string} purposeArg - Purpose of the form ('create' or 'edit').
 * @param {object} actorsItem - AcotrsItem containing actors and groups.
 * @param {string} colorGantt - Gantt color.
 * @param {Function} onChangeColor - Function to handle color change.
 * @param {boolean} repeated - Boolean indicating if the event is repeated.
 * @returns {JSX.Element} Component with communication fields.
 */
const CommunicationFields = ({
  purposeArg,
  actorsItem,
  colorGantt,
  onChangeColor,
  repeated
}) => {
  const generateFields = useGenerateFormItem();
  const {
    planComFields1,
    planComFields2,
    planComFields3,
    planComFields4
  } = useCommunicationFields(
    purposeArg,
    actorsItem,
    colorGantt,
    onChangeColor,
    repeated
  );
  return (
    <>
      <Row gutter={16}>
        {planComFields1.map((field) => (
          <Col key={field.name[field.name.length - 1]} span={12}>
            {generateFields('projects', field)}
          </Col>
        ))}
      </Row>

      {planComFields2.map((e) => generateFields('projects', e))}

      <Row gutter={16}>
        {planComFields3.map((field) => (
          <Col key={field.name[field.name.length - 1]} span={12}>
            {generateFields('projects', field)}
          </Col>
        ))}
      </Row>
      <Row gutter={16}>
        {planComFields4.map((field) => (
          <Col
            key={field.name[field.name.length - 1]}
            span={24 / planComFields4.length ?? 1}
          >
            {generateFields('projects', field)}
          </Col>
        ))}
      </Row>
    </>
  );
};

CommunicationFields.propTypes = {
  purposeArg: PropTypes.string,
  actorsItem: PropTypes.arrayOf(PropTypes.shape({})),
  colorGantt: PropTypes.string,
  onChangeColor: PropTypes.func,
  repeated: PropTypes.bool
};

CommunicationFields.defaultProps = {
  purposeArg: 'create',
  actorsItem: {
    actors: [],
    groups: []
  },
  colorGantt: '#000',
  onChangeColor: null,
  repeated: false
};

export default CommunicationFields;
