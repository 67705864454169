import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Descriptions, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../utils/constants/customIcon';
import ImportDocumentButton from '../../../../../components/ImportDocumentButton/ImportDocumentButton';
import ButtonDeleteAll from '../../utilsProject/ButtonDeleteAll';

/**
 * A component to display context information in a card format.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.context - The context object containing context details.
 * @param {Object} props.project - The project object containing project details.
 * @param {Object} props.version - The version object containing version details.
 * @param {boolean} props.role - Controls components visibility according to user role.
 * @param {string} props.title - The type of the item.
 * @param {Function} props.setDataToModalFiles - A function to set data to modal files.
 * @param {Function} props.openForm - A function to open a form.
 * @param {Function} props.closeForm - A function to close a form.
 * @returns {JSX.Element} A JSX Element representing the ContextCard component.
 */
const ContextCard = ({
  context,
  project,
  version,
  role,
  title,
  setDataToModalFiles,
  openForm,
  closeForm
}) => {
  const { t } = useTranslation();
  const { _id, files, type, ...rest } = context;

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }

    return 1;
  };

  return (
    <Card>
      <Descriptions
        extra={
          role && (
            <Row justify="space-around">
              <Button
                disabled={project?.status === 'close'}
                onClick={() => (role ? openForm(_id, context) : null)}
                size="small"
                type="link"
                style={{
                  color: 'var(--secondaryColor)',
                  top: 2
                }}
              >
                <EditIcon />
              </Button>
              <Divider
                type="vertical"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  height: 30
                }}
              />
              {project?.perimeter?.attached_files && (
                <>
                  <ImportDocumentButton
                    count={files?.length}
                    disabled={project?.status === 'close'}
                    onClick={() => setDataToModalFiles(files || [], title, _id)}
                  />
                  <Divider
                    type="vertical"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      height: 30
                    }}
                  />
                </>
              )}
              <ButtonDeleteAll
                purpose="matrice"
                projectId={project._id}
                title={title}
                titleId={version._id}
                matriceName="factors"
                matriceId={_id}
                handleCancel={closeForm}
                version={version}
              />
            </Row>
          )
        }
        key={_id}
        column={4}
        title={type}
        size="small"
      >
        {Object.entries(rest).map(([key, value]) => {
          return (
            <Descriptions.Item
              key={key}
              label={t(`projects.form.items.context.factors.${key}`)}
              span={setSpan(key)}
            >
              {value}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </Card>
  );
};

ContextCard.propTypes = {
  context: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string
  }),
  project: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    perimeter: PropTypes.shape({
      attached_files: PropTypes.bool
    })
  }),
  version: PropTypes.shape({
    _id: PropTypes.string
  }),
  role: PropTypes.bool,
  title: PropTypes.string,
  setDataToModalFiles: PropTypes.func,
  openForm: PropTypes.func,
  closeForm: PropTypes.func
};

ContextCard.defaultProps = {
  context: {},
  project: {},
  version: {},
  role: false,
  title: '',
  setDataToModalFiles: () => {},
  openForm: () => {},
  closeForm: () => {}
};

export default ContextCard;
