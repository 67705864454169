import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PlanningTabPane from './TabPanes/PlanningTabPane/PlanningTabPane';
import UtilityTabPane from './TabPanes/UtillityTabPane/UtilityTabPane';
import GanttTabPane from './TabPanes/GanttTabPane/GanttTabPane';

/**
 * Represents the component for managing planning tabs .
 *
 * @component
 * @param {Object} project - Information about the project.
 * @param {Object} version - Information about a given manage_planning version.
 * @param {Function} generateForm - Function to generate a form.
 * @param {Object} config - Configuration data.
 * @param {boolean} role - Role information.
 * @param {Object} dataDeliverables - Data for deliverables.
 * @param {Function} setDataDeliverables - Function to set deliverable data.
 * @param {Function} setVisibleModal - Function to set modal visibility.
 * @param {Function} setGanttConfig - Function to set Gantt chart configuration.
 * @param {Function} getProject - Function to fetch project data by its id.
 * @returns {JSX.Element} - Rendered component.
 */
const ManagePlanningTabs = ({
  project,
  version,
  generateForm,
  config,
  role,
  dataDeliverables,
  setDataDeliverables,
  setVisibleModal,
  setGanttConfig,
  getProject
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState();

  const openForm = (idRender, deliverables) => {
    const { planning, ...rest } = deliverables;
    setVisible(idRender);
    setDataDeliverables({
      ...rest,
      planning: planning
        ? {
            ...planning,
            start_date: planning.start_date
              ? moment(planning.start_date)
              : undefined,
            due_date: moment(planning.due_date)
              ? moment(planning.due_date)
              : '',
            macro_task: planning.macro_task?._id
          }
        : undefined
    });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const tabPanes = {
    planning: (
      <PlanningTabPane
        project={project}
        version={version}
        generateForm={generateForm}
        openForm={openForm}
        closeForm={closeForm}
        config={config}
        role={role}
        visible={visible}
        dataDeliverables={dataDeliverables}
      />
    ),
    utility: (
      <UtilityTabPane
        project={project}
        version={version}
        generateForm={generateForm}
        openForm={openForm}
        closeForm={closeForm}
        config={config}
        role={role}
        visible={visible}
        dataDeliverables={dataDeliverables}
      />
    ),
    gantt: (
      <GanttTabPane
        project={project}
        version={version}
        role={role}
        setVisibleModal={setVisibleModal}
        setDataDeliverables={setDataDeliverables}
        setGanttConfig={setGanttConfig}
      />
    )
  };

  return (
    <Tabs
      animated={{ inkBar: true }}
      id="blue-tab-planning"
      defaultActiveKey="Gantt"
    >
      {Object.entries(tabPanes).map(([key, value]) => (
        <TabPane tab={t(`projects.form.deliverables.tab.${key}`)} key={key}>
          {value}
        </TabPane>
      ))}
    </Tabs>
  );
};

ManagePlanningTabs.propTypes = {
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  generateForm: PropTypes.func,
  config: PropTypes.shape({}).isRequired,
  role: PropTypes.bool,
  dataDeliverables: PropTypes.shape({}),
  setDataDeliverables: PropTypes.func,
  setVisibleModal: PropTypes.func,
  setGanttConfig: PropTypes.func,
  getProject: PropTypes.func
};

ManagePlanningTabs.defaultProps = {
  role: false,
  dataDeliverables: {},
  setDataDeliverables: () => {},
  setVisibleModal: () => {},
  setGanttConfig: () => {},
  generateForm: () => {},
  getProject: () => {}
};

export default ManagePlanningTabs;
