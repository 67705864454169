/* eslint-disable react/jsx-indent */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, Row, Divider, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import FederationComponent from './FederationComponent';
import Time from './ChartIndicators/Time';
import Cost from './ChartIndicators/Cost';
import Utility from './ChartIndicators/Utility';
import Federation from './ChartIndicators/Federation';
import useHandleResize from '../../utils/HandleResize';
import getUtility from './utilityIndicator/utils';
import getCost from './costIndicator/utils';
import getTime from './timeIndicator/utils';

const ShowIndicators = ({ project, openModal, vue }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [purpose, setPurpose] = useState();
  const { width } = useHandleResize();

  const showModal = (key) => {
    setVisible(true);
    setPurpose(key);
  };

  const handleCancelBizchart = () => {
    setVisible(false);
  };

  const listIndicators = {
    time: getTime(
      project.manage_planning,
      project.status,
      width,
      showModal,
      vue,
      t
    ),
    cost:
      vue !== 'actor' &&
      getCost(project.cost, project.status, width, showModal, t),
    utility: getUtility(project.manage_planning, t, showModal, width, vue)
  };

  const getChartIndicators = {
    time: <Time project={project} purpose={purpose} width={700} />,
    cost: vue !== 'actor' && <Cost data={project} purpose={purpose} />,
    utility: <Utility data={project} purpose={purpose} />,
    membership: <Federation data={project} purpose={purpose} />
  };

  return project.status === 'V1' ||
    project.status === 'VX' ||
    project.status === 'V0' ? (
    <Row
      align="top"
      justify="space-between"
      style={{
        flexFlow: width > 1200 ? 'row nowrap' : 'row wrap',
        padding: '8px 0',
        minHeight: 100
      }}
    >
      {Object.entries(listIndicators).map(([key, value]) => {
        return project ? (
          <Fragment key={key}>
            {value}

            {key !== 'membership' && value && (
              <Divider
                type="vertical"
                style={{
                  height: 80,

                  borderLeft: '8px solid var(--itemHoverBackground)',
                  margin: 0,
                  alignSelf: 'center'
                }}
              />
            )}
          </Fragment>
        ) : (
          <>
            {t(`projects.details.${key}`)}
            <Tag>{t('projects.details.no_item')}</Tag>
          </>
        );
      })}
      <FederationComponent openModal={openModal} project={project} vue={vue} />
      <Modal
        title={t(`projects.show.indicators.${purpose}.title`)}
        visible={visible}
        footer={false}
        width="1000px"
        onCancel={handleCancelBizchart}
      >
        <Row justify="center">{getChartIndicators[purpose]}</Row>
      </Modal>
    </Row>
  ) : (
    <Row gutter={16}>
      <Col span={19}>
        <Federation data={project} purpose="membership" />
      </Col>
      <Col span={5}>
        <FederationComponent
          openModal={openModal}
          project={project}
          vue={vue}
        />
      </Col>
    </Row>
  );
};

ShowIndicators.propTypes = {
  project: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  openModal: PropTypes.func,
  vue: PropTypes.oneOf(['actor', 'supervisor', 'assignee', 'admin'])
};

ShowIndicators.defaultProps = {
  vue: 'actor',
  project: null,
  openModal: null
};

export default ShowIndicators;
