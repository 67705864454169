import React from 'react';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import useProjectContext from '../../../contexts/ProjectContext';
import DeleteButton from '../../../components/DeleteButton/DeleteButton';

const iconSize = 18;

const useActionColumns = (
  role,
  setVisibleCreateUpdateForm,
  setDataToUpdate,
  refresh,
  setRefresh
) => {
  const { deleteProject, setPurpose } = useProjectContext();

  const actionColumns = [
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `/projects/projects/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <EditOutlined
            style={{ fontSize: iconSize }}
            onClick={() => {
              setVisibleCreateUpdateForm(true);
              const adjustedRecord = {
                ...record,
                _id: record.key,
                supervisors: record.supervisors?.map(
                  (supervisor) => supervisor._id
                )
              };
              setDataToUpdate(adjustedRecord);
              setPurpose(
                role === 'admins:PERIMETER_ADMIN' ? 'reassign' : 'edit'
              );
            }}
          />
          {role === 'managers:PROJECT_MANAGER' ? (
            <>
              <Divider type="vertical" />
              <DeleteButton
                onConfirm={async () => {
                  await deleteProject(record.key);
                  setRefresh(!refresh);
                }}
                iconSize={iconSize}
                color="var(--errorColor)"
              />
            </>
          ) : null}
        </div>
      ),
      fixed: 'rigth'
    }
  ];

  return actionColumns;
};

export default useActionColumns;
