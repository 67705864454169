export const routes = {
  HOME: '/',
  SUPERVISOR: '/supervisor',
  ACTOR: '/actor',
  USERS: '/users',
  PERIMETERS: '/perimeters',
  PROJECTS: '/projects',
  DASHBOARD: '/dashboard',
  REPORTING: '/reporting',
  BALANCE_SHEET: '/balance_sheet',
  HISTORY: '/history',
  DOCUMENTS: '/documents',
  PERIMETER_ADMINS: '/perimeter_admins',
  CONFIGURATION: '/configuration'
};
export const subRoutes = {
  // DOCUMENTS: {
  //   // SCAN: '/scan',
  //   TEMPLATES: '/templates',
  //   GED: '/ged'
  // }
};
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

export const pathSearches = {
  PERIMETERS: '?s=created_at'
};
