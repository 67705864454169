import { getDefaultMacroTaskFromVersion } from '../utilsProject/ManagePlanning/utils';
import { getItemVersion } from '../utilsProject/ManageVersion/utils';

/**
 * Formats an array of project data into a structured format, extracting specific information.
 *
 * @param {Array} projects - An array of project objects to be formatted.
 * @returns {Object} An object containing formatted data:
 *   - newPerimeters: Array of unique perimeter objects.
 *   - newCustomers: Array of unique customer names.
 *   - newManagers: Array of unique manager (assignee) objects.
 *   - newFormattedProjects: Array of formatted project objects ( retreive default macro task from project).
 */
export const formatProjectData = (projects) => {
  const newPerimeters = [];
  const newCustomers = [];
  const newManagers = [];
  const newFormattedProjects = [];
  (projects || []).forEach((project) => {
    if (!newPerimeters.some((p) => p._id === project.perimeter._id)) {
      newPerimeters.push(project.perimeter);
    }

    if (project.customer && !newCustomers.includes(project.customer))
      newCustomers.push(project.customer);

    if (project.assignee) {
      if (!newManagers.some((m) => m._id === project.assignee._id)) {
        newManagers.push(project.assignee);
      }
    }

    const defaultMacroTask = getDefaultMacroTaskFromVersion(
      getItemVersion(project.manage_planning, -1)
    );

    if (defaultMacroTask) {
      const { planning, ...rest } = defaultMacroTask;
      const formattedProject = {
        ...planning,
        ...rest,
        id: project._id,
        task: `${defaultMacroTask._id + defaultMacroTask.description}`
      };

      newFormattedProjects.push(formattedProject);
    }
  });
  return { newPerimeters, newCustomers, newManagers, newFormattedProjects };
};

export default formatProjectData;
