import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ListCard from './ListCard';
import useAuthContext from '../../contexts/AuthContext';

const Configurations = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const lists = [
    user.role === 'managers:PROJECT_MANAGER' && <ListCard category="USER" />,
    user.role !== 'admins:ADMIN' && <ListCard category="PERIMETER" />,
    <ListCard category="MC" />
  ].filter(Boolean);

  return (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`configurations.title`)}
      </div>
      <Row gutter={[24, 24]}>
        {lists.map((listCard, index) => {
          return (
            <Col
              span={
                Math.floor((lists.length - index - 1) / 2) !== 0 ||
                lists.length === 1
                  ? 24
                  : 12
              }
            >
              {listCard}
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default Configurations;
