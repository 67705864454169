import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, Steps, message } from 'antd';
import { useTranslation } from 'react-i18next';
import CreateEditModal from '../../../../components/CreateUpdateContainer/Modals/CreateEditModal';
import ProjectMainForm from './ProjectMainForm';
import ProjectConfigurationForm from './ProjectConfigurationForm';
import {
  getGlossary,
  useUserGlossary
} from '../../../../utils/constants/configurableItemList';

/**
 * The CreateProjectFormModal component provides a modal for creating or editing a project.
 *
 * @component
 * @param {Function} handleSubmit - Function to handle the form submission.
 * @param {Object} project - The project data.
 * @param {Function} setDataToUpdate - Function to set the data to update.
 * @param {Boolean} visible - Boolean to display the modal.
 * @param {Function} setVisible - Function to set the modal visibility.
 * @param {Boolean} loading - Boolean to display the loading state.
 * @param {String} purpose - The purpose of the modal.
 * @param {Function} setPurpose - Function to set the purpose of the modal.
 * @returns {JSX.Element} - The CreateProjectFormModal component.
 */
const CreateProjectFormModal = ({
  handleSubmit,
  project,
  setDataToUpdate,
  visible,
  setVisible,
  purpose,
  setPurpose,
  loading
}) => {
  const { t } = useTranslation();
  const [mainForm] = Form.useForm();
  const [configurationForm] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [perimeter, setPerimeter] = useState();
  const [projectMainData, setProjectMainData] = useState();
  const [configurationData, setConfigurationData] = useState();

  const { getUserGlossary } = useUserGlossary();

  const onChange = async (value) => {
    try {
      if (current === 0) {
        await mainForm.validateFields();
        mainForm.submit();
      } else {
        mainForm.resetFields();
        configurationForm.submit();
      }
      setCurrent(value);
    } catch (error) {
      message.error(t('errors.form.REQUIRED'));
    }
  };

  const reset = () => {
    setDataToUpdate();
    mainForm.resetFields();
    configurationForm.resetFields();
    setCurrent(0);
    setPerimeter();
    setPurpose();
    setProjectMainData();
    setConfigurationData();
  };

  const checkSteps = async () => {
    try {
      if (current === 0 && purpose === 'create') {
        await onChange(1);
      } else {
        handleSubmit({
          ...(current === 0 ? {} : projectMainData),
          ...mainForm.getFieldsValue(),
          lists: {
            ...configurationData,
            ...configurationForm.getFieldsValue(true)
          }
        });
        reset();
      }
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  const handleCancel = () => {
    reset();
    setVisible(false);
  };

  const steps = [
    {
      title: t('projects.form.main_data'),
      content: (
        <ProjectMainForm
          form={mainForm}
          purpose={purpose}
          perimeter={perimeter}
          setPerimeter={setPerimeter}
          onFinish={(values) => {
            const { perimeter: perimeterId, ...rest } = values;
            setProjectMainData({
              perimeter: { ...projectMainData?.perimeter, _id: perimeterId },
              ...rest
            });
          }}
          initialValues={projectMainData}
        />
      )
    },
    {
      title: t('projects.form.configuration'),
      content: (project?.perimeter || perimeter) && (
        <ProjectConfigurationForm
          form={configurationForm}
          projectPerimeter={project?.perimeter || perimeter}
          project={project}
          onFinish={(values) => {
            setConfigurationData({ ...configurationData, ...values });
          }}
          initialValues={configurationData}
          purpose={purpose}
        />
      )
    }
  ];

  useEffect(() => {
    if (project) {
      setProjectMainData(project);
      setConfigurationData(getGlossary(project));
    } else {
      (async () => {
        const glossary = await getUserGlossary();
        setConfigurationData(glossary);
      })();
    }
  }, [visible]);

  return (
    <CreateEditModal
      visible={visible}
      title={
        <Steps
          type="navigation"
          size="small"
          current={current}
          onChange={onChange}
          items={steps}
        />
      }
      handleCancel={handleCancel}
      handleSubmit={checkSteps}
    >
      <Spin spinning={loading}>{steps[current].content}</Spin>
    </CreateEditModal>
  );
};

CreateProjectFormModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setDataToUpdate: PropTypes.func,
  project: PropTypes.shape({
    manage_planning: PropTypes.arrayOf(
      PropTypes.shape({
        deliverables: PropTypes.arrayOf(
          PropTypes.shape({ planning: PropTypes.shape({}) })
        )
      })
    ),
    perimeter: PropTypes.shape({}).isRequired
  }),
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  loading: PropTypes.bool,
  purpose: PropTypes.string,
  setPurpose: PropTypes.func
};

CreateProjectFormModal.defaultProps = {
  setDataToUpdate: () => {},
  project: null,
  visible: false,
  setVisible: null,
  loading: true,
  purpose: 'create',
  setPurpose: () => {}
};

export default CreateProjectFormModal;
