import React from 'react';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../../../../../components/DeleteButton/DeleteButton';
import useAuthContext from '../../../../../../contexts/AuthContext';
import { getDisplayedActorRole } from '../utils';

/**
 * Custom hook for generating table columns for actor data.
 *
 * @hook
 * @param {Function} removeUser - Function to remove a user.
 * @param {String} role - Controls components visibility according to user's role.
 * @param {Boolean} isDefaultGroup - Indicates if the table is pisplaying actors of the default group.
 * @param {String} projectAssigneeId - The ID of the project assignee.
 * @returns {Array} An array of column configuration objects for a table.
 */
const useColumns = (removeUser, role, isDefaultGroup, projectAssigneeId) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  return [
    {
      title: t(`projects.form.items.actors.last_name`),
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name)
    },
    {
      title: t(`projects.form.items.actors.first_name`),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: t(`projects.form.items.actors.code`),
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code?.localeCompare(b.code),
      render: (code) => code || '-'
    },
    {
      title: t(`projects.form.items.actors.role`),
      key: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      render: (actor) => getDisplayedActorRole(actor, projectAssigneeId, t)
    },
    role && {
      render: (actor) =>
        ((isDefaultGroup && actor.user?._id !== user._id) ||
          !isDefaultGroup) && (
          <DeleteButton onConfirm={() => removeUser(actor)} />
        )
    }
  ].filter(Boolean);
};

export default useColumns;
