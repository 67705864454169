import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Button, Card, Modal, Row, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonDeleteAll from '../../utilsProject/ButtonDeleteAll';
import { EditIcon } from '../../../../../utils/constants/customIcon';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import ImportDocumentButton from '../../../../../components/ImportDocumentButton/ImportDocumentButton';
import useCostFilters from './CostFilters/useCostFilters';

/**
 * Component to display cost information for a version.
 *
 * @component
 * @param {Object} version - The version object containing cost items.
 * @param {string} id - The ID of the project.
 * @param {string} title - The type of the item.
 * @param {Function} getProject - The function to get the project information.
 * @param {Function} generateForm - The function to generate a form.
 * @param {boolean} role - A boolean indicating if the user has a specific role.
 * @param {Function} setDataToModalFiles - Function to set data to modal files.
 * @param {Function} getFilesByItem - Function to get files by item.
 * @param {Object} project - The project.
 * @returns {JSX.Element} - The rendered CostDisplay component.
 */
const CostDisplay = ({
  version,
  title,
  id,
  getProject,
  generateForm,
  role,
  setDataToModalFiles,
  getFilesByItem,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataCost, setDataCost] = useState();
  const { getCostFilters, getFilteredCosts } = useCostFilters(
    project?.cost_list
  );

  const config = {
    onGetResource: {
      setFields: (data) => ({
        costs: { ...data }
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, cost) => {
    setVisible(idRender);
    setDataCost({ ...cost });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  useEffect(() => {
    getFilesByItem(version.content?.files || []);
  }, [project]);

  return (
    <>
      {getCostFilters()}
      {getFilteredCosts(version?.content).map((e) => {
        const { _id, files, type, ...rest } = e;
        return (
          <React.Fragment key={_id}>
            {visible === _id && dataCost ? (
              generateForm(
                'edit',
                version,
                title,
                closeForm,
                dataCost,
                'costs',
                config
              )
            ) : (
              <Card>
                <Descriptions
                  extra={
                    role && (
                      <Row justify="space-around">
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() => (role ? openForm(_id, e) : null)}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: 2
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Divider
                          type="vertical"
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            height: 30
                          }}
                        />
                        {project?.perimeter?.attached_files && (
                          <>
                            <ImportDocumentButton
                              count={files?.length}
                              disabled={project?.status === 'close'}
                              onClick={() =>
                                setDataToModalFiles(files || [], title, _id)
                              }
                            />
                            <Divider
                              type="vertical"
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                height: 30
                              }}
                            />
                          </>
                        )}
                        <ButtonDeleteAll
                          purpose="matrice"
                          projectId={id}
                          title={title}
                          titleId={version._id}
                          matriceName="costs"
                          matriceId={_id}
                          handleCancel={closeForm}
                          version={version}
                        />
                      </Row>
                    )
                  }
                  key={_id}
                  column={4}
                  title={type}
                  size="small"
                >
                  {Object.entries(rest).map(([key, value]) => {
                    return (
                      <Descriptions.Item
                        key={key}
                        label={t(`projects.form.items.cost.${key}`)}
                      >
                        {value}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            )}
          </React.Fragment>
        );
      })}
      {visibleModal && (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          width={700}
          closable
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            version,
            title,
            closeFormModal,
            null,
            'costs'
          )}
        </Modal>
      )}
      {role && (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={openFormModal}
          title={t('projects.form.items.cost.addCost')}
        />
      )}
    </>
  );
};

CostDisplay.propTypes = {
  id: PropTypes.string,
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  setDataToModalFiles: PropTypes.func,
  getFilesByItem: PropTypes.func,
  project: PropTypes.shape({
    status: PropTypes.string,
    perimeter: PropTypes.shape({
      attached_files: PropTypes.bool
    }),
    cost_list: PropTypes.arrayOf(PropTypes.string)
  })
};

CostDisplay.defaultProps = {
  id: '',
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  setDataToModalFiles: () => {},
  getFilesByItem: () => {},
  project: null
};

export default CostDisplay;
