import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';

/**
 * Component for rendering cost and charge statistics.
 *
 * @function
 * @param {Object} data - Cost version data containing cost information.
 * @param {string} status - Status to determine which statistics to display.
 * @param {number} width - Screen width.
 * @param {function} showModal - Function to show a modal.
 * @param {function} t - Translation function.
 * @returns {JSX.Element} JSX element containing the statistics.
 */
export default (data, status, width, showModal, t) => {
  if (status === 'VX' || status === 'V1') {
    let charges = 0;
    let costs = 0;
    let chargesInit = 0;
    let costsInit = 0;
    if (data[2]?.content) {
      data[2].content.forEach((p) => {
        if (p) {
          chargesInit += p.charge || 0;
          costsInit += p.cost_value || 0;
        }
      });
    }
    if (data.length && data[data.length - 1] !== null) {
      data[data.length - 1].content.forEach((e) => {
        charges += e.charge || 0;
        costs += e.cost_value || 0;
      });
    }

    return (
      <Col
        xl={4}
        md={6}
        sm={8}
        xs={20}
        style={{
          height: '100%',
          margin: width > 1200 ? '0 24px' : '0 12px'
        }}
        onClick={() => showModal('cost')}
      >
        <Row justify="start" style={{ marginBottom: 8 }}>
          <Col>
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.15em',
                fontSize: 13
              }}
            >
              {t(`projects.details.cost`)}
            </span>
          </Col>
        </Row>

        <Row justify="space-between">
          <Statistic
            id="white-statistic"
            title={`${costs} €`}
            value={costs - costsInit}
            precision={0}
            valueStyle={
              costs - costsInit <= 0
                ? { color: 'var(--addColor)', fontSize: 14 }
                : { color: '#ff0000', fontSize: 15 }
            }
            prefix={
              costs - costsInit > 0 ? (
                <ArrowUpOutlined />
              ) : (
                <ArrowDownOutlined />
              )
            }
            suffix="€"
          />

          <Statistic
            id="white-statistic"
            title={`${charges}  ${t('dashboard.m_d')}`}
            value={charges - chargesInit}
            precision={0}
            valueStyle={
              charges - chargesInit <= 0
                ? { color: 'var(--addColor)', fontSize: 14 }
                : { color: '#ff0000', fontSize: 15 }
            }
            prefix={
              charges - chargesInit > 0 ? (
                <ArrowUpOutlined />
              ) : (
                <ArrowDownOutlined />
              )
            }
            suffix={t('dashboard.m_d')}
          />
        </Row>
      </Col>
    );
  }
  return (
    <Col
      xl={4}
      md={6}
      sm={8}
      xs={20}
      style={{
        height: '100%',
        margin: width > 1200 ? '0 24px' : '0 12px'
      }}
      onClick={() => showModal('cost')}
    >
      <Row justify="start" style={{ marginBottom: 8 }}>
        <Col>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.15em',
              fontSize: 13
            }}
          >
            {t(`projects.details.cost`)}
          </span>
        </Col>
      </Row>

      <Row justify="space-between">
        <Statistic
          id="white-statistic"
          title="0 €"
          precision={0}
          valueStyle={{ color: 'var(--addColor)', fontSize: '15px' }}
          suffix="€"
        />

        <Statistic
          id="white-statistic"
          title={t('dashboard.m_d')}
          precision={0}
          valueStyle={{ color: 'var(--addColor)', fontSize: '15px' }}
          prefix={<ArrowUpOutlined />}
          suffix={t('dashboard.m_d')}
        />
      </Row>
    </Col>
  );
};
