import React from 'react';
import { DatePicker } from 'antd';

/**
 * Custom hook that generates date fields for deliverables.
 *
 * @hook
 * @param {boolean} isNotDeliverable - Boolean indicating if deliverable is not deliverable.
 * @param {boolean} childrenBlockingTooltip - Boolean indicating if child actions ongoing or finished.
 * @returns {object[]} Array of date fields for deliverables.
 */
const useDateFields = (isNotDeliverable, childrenBlockingTooltip) => {
  const dateFormat = 'DD-MM-YYYY';

  const dateFields = [
    isNotDeliverable && {
      name: ['deliverables', 'planning', 'start_date'],
      tooltip: childrenBlockingTooltip,
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={childrenBlockingTooltip}
          format={dateFormat}
          allowClear={false}
        />
      )
    },
    {
      name: ['deliverables', 'planning', 'due_date'],
      tooltip: childrenBlockingTooltip,
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={childrenBlockingTooltip}
          format={dateFormat}
          allowClear={false}
        />
      )
    }
  ].filter(Boolean);

  return dateFields;
};

export default useDateFields;
