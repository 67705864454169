/**
 * Converts an array of actors to a string
 *
 * @function
 * @param {Array} a - An array of actorItems
 * @returns {string} The actors as a string
 */
export const actorsToString = (a) => {
  let result = '';
  a.forEach((actor) => {
    result = `${actor.user ? '' : '('}${result}${actor.first_name} ${
      actor.last_name
    }${actor.user ? '' : ')'}, `;
  });

  return result.slice(0, -2);
};

/**
 * Converts an array of groups to a string
 *
 * @function
 * @param {Array} groups - An array of groups
 * @returns {string} The groups as a string
 */
export const groupsToString = (groups) => {
  let result = '';
  groups.forEach((group) => {
    result = `${result}${group.name}, `;
  });

  return result.slice(0, -2);
};

/**
 * Stringify the frequency of an event
 *
 * @function
 * @param {Object} event - The event object
 * @param {Function} t - The translation function
 * @returns {string} The frequency as a string
 */
export const frequencyToString = (event, t) =>
  event.repeat && event.repeat.value && event.repeat.time_unit
    ? `Tous les ${event.repeat.value} ${t(
        `projects.form.deliverables_plan_com.${event.repeat.time_unit}`
      )}`
    : '';
