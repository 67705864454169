import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Form } from 'antd';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import useMainFields from '../fieldsItems/mainFields';
import useFields from '../fieldsItems/itemsFields';

const ProjectMainDataFields = ({
  form,
  purpose,
  perimeter,
  setPerimeter,
  onFinish,
  initialValues
}) => {
  const { mainFields } = useMainFields(setPerimeter, initialValues, form);
  const fields = useFields('event', null, perimeter);
  const generateFields = useGenerateFormItem();
  return (
    <Form {...formItemLayout} onFinish={onFinish} form={form}>
      {mainFields.map((field) => generateFields('projects', field))}
      {purpose === 'create' && (
        <>
          <Divider />
          {fields.map((field) => generateFields('projects', field))}
        </>
      )}
    </Form>
  );
};

ProjectMainDataFields.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
  purpose: PropTypes.string,
  perimeter: PropTypes.shape({}),
  setPerimeter: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({})
};

ProjectMainDataFields.defaultProps = {
  purpose: null,
  perimeter: null,
  initialValues: null
};
export default ProjectMainDataFields;
