/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import { getIndexByVersionSelected } from '../../../../utils/constants/constantes';
import headerByitems from './headerByItems';
import { frequencyToString } from './utils';

const federation = (t, project, filter) => {
  const checkDataAndReturnSorting = (data) => {
    return [
      filter.version === 'VX' ? 'V+' : filter.version,
      data.name,
      moment(data.start_date).format('DD-MM-YYYY'),
      moment(data.due_date).format('DD-MM-YYYY'),
      frequencyToString(data, t),
      data.type,
      data.channel,
      data.impact,
      data.in_charge_of,
      data.transmitter,
      data.target,
      data.federation,
      data.goal_description,
      data.goal_action
    ];
  };
  const workbook = new Workbook();
  try {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.plan_com`)}`);
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(true);
    const header = newHeader.plan_com.map(({ label }) => {
      return t(`projects.form.${label}`);
    });

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });
    let row;
    let filterbeforeSetData =
      project.plan_com[
        getIndexByVersionSelected(filter.version, project.plan_com)
      ].content;
    if (filter.dates) {
      filterbeforeSetData = filterbeforeSetData.filter((el) => {
        if (el.repeat)
          return el.duplicates.find(
            (event) =>
              moment(event.start_date) >=
                moment(filter.dates[0]).startOf('day') &&
              moment(event.start_date) <= moment(filter.dates[1]).endOf('day')
          );
        return (
          moment(el.start_date) >= moment(filter.dates[0]).startOf('day') &&
          moment(el.start_date) <= moment(filter.dates[1]).endOf('day')
        );
      });
    }
    if (filter.actor) {
      filterbeforeSetData = filterbeforeSetData.filter((el) =>
        [el.transmitter, el.target, el.in_charge_of].includes(filter.actor)
      );
    }
    filterbeforeSetData.forEach((planC) => {
      row = checkDataAndReturnSorting(planC);
      if (row) worksheet.addRow(row);
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default federation;
