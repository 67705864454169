import useGenerateFormItem from '../../../../../../../utils/GenerateFormItem';
import useCommonFields from './useCommonFields';

/**
 * A component to render common fields based on the purpose and planning utility list.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {string} props.purpose - The purpose of the common fields.
 * @param {Array} props.planningUtitlityList - The list of planning utility items.
 * @returns {JSX.Element} - A JSX Element representing the CommonFields component.
 */
const CommonFields = ({ purpose, planningUtitlityList }) => {
  const generateFields = useGenerateFormItem();
  const commonFields = useCommonFields(purpose, planningUtitlityList);

  return commonFields?.map((field) => generateFields('projects', field));
};

export default CommonFields;
