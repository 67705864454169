/**
 * Get default group of a project from a selected version of an actorItem
 *
 * @param {Object} actorsItem - A version of the actorsItem of a project
 * @returns {Array} - The default group of the project
 */
export const getDefaultGroup = (actorsItem) => {
  return (
    actorsItem?.content?.groups?.find((group) => group.default).actors || []
  );
};
