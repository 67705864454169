import React from 'react';
import moment from 'moment';
import { DatePicker, Input, Select, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook to generate an array of form fields for federation information.
 * @param {Array} federationList - List of federation values.
 * @returns {Object} An object containing the array of federation form fields.
 */
const useFederationFields = () => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const today = moment();

  const federationFields = [
    {
      name: ['start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['goal_description'],
      rules: [{ required: true }],
      input: <TextArea />
    },
    {
      name: ['impact'],
      rules: [{ required: true }],
      input: (
        <Select>
          <Option value={1}>{t('projects.form.minor')}</Option>
          <Option value={2}>{t('projects.form.medium')}</Option>
          <Option value={3}>{t('projects.form.major')}</Option>
        </Select>
      )
    },
    {
      name: ['federation'],
      input: <InputNumber min={0} max={100} />
    }
  ];

  return { federationFields };
};

export default useFederationFields;
