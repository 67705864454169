import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row } from 'antd';
import { generateFilterSelect } from '../../../../utils/GenerateFilterItem';

const { Search } = Input;

const Filters = ({
  setSearchValue,
  setSelectedPerimeter,
  setSelectedCustomer,
  setSelectedManager,
  searchValue,
  perimeters,
  customers,
  managers
}) => {
  const { t } = useTranslation();

  return (
    <Row className="custom-header">
      <Col>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => setSearchValue(value)}
        />
      </Col>
      <Col>
        {generateFilterSelect(
          perimeters,
          t('supervisor.filter.perimeter'),
          setSelectedPerimeter,
          (perimeter) => perimeter.name
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          customers,
          t('supervisor.filter.customer'),
          setSelectedCustomer
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          managers,
          t('supervisor.filter.manager'),
          setSelectedManager,
          (manager) => `${manager.first_name} ${manager.last_name}`
        )}
      </Col>
    </Row>
  );
};

export default Filters;

Filters.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  setSelectedPerimeter: PropTypes.func.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
  setSelectedManager: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  perimeters: PropTypes.arrayOf(PropTypes.shape({})),
  customers: PropTypes.arrayOf(PropTypes.string),
  managers: PropTypes.arrayOf(PropTypes.shape({}))
};

Filters.defaultProps = {
  searchValue: '',
  perimeters: [],
  customers: [],
  managers: []
};
