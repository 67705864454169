import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import RiskTabs from './RiskTabs/RiskTabs';

/**
 * A component to display and manage risk information.
 *
 * @component
 * @param {Object} version - The version object containing version details.
 * @param {string} title - The type of the item.
 * @param {Function} getProject - A function to get project details.
 * @param {Function} generateForm - A function to generate a form.
 * @param {boolean} role - Controls components visibility according to user role.
 * @param {Object} project - The project.
 * @returns {JSX.Element} A JSX Element representing the RiskDisplay component.
 */
const RiskDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const configNew = {
    onGetResource: {
      setFields: () => ({
        risk_list: { number: version?.content.length + 1 }
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };
  return (
    <>
      <RiskTabs
        project={project}
        version={version}
        generateForm={generateForm}
        role={role}
        getProject={getProject}
        title={title}
      />
      {visibleModal && (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={800}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            version,
            title,
            closeFormModal,
            {},
            'risk_list',
            configNew
          )}
        </Modal>
      )}
      {role && (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={openFormModal}
          title={t('projects.form.items.risks.addRisk')}
        />
      )}
    </>
  );
};

RiskDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  project: PropTypes.shape({
    status: PropTypes.string
  })
};

RiskDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  project: null
};

export default RiskDisplay;
