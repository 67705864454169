import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Collapse, Descriptions, Modal, Row, message } from 'antd';
import { useTranslation } from 'react-i18next';
import ButtonDeleteAll from '../../utilsProject/ButtonDeleteAll';
import { EditIcon } from '../../../../../utils/constants/customIcon';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import Header from './Header';
import useAuthContext from '../../../../../contexts/AuthContext';
import useUtilityFilters from './UtilityFilters/useUtilityFilters';

const { Panel } = Collapse;

/**
 * Component to display utility information for a version.
 *
 * @component
 * @param {Object} version - The version object containing content.
 * @param {string} id - The identifier of the project.
 * @param {string} title - The title of the utility display.
 * @param {Function} getProject - The function to get the project information.
 * @param {Function} generateForm - The function to generate a form.
 * @param {boolean} role - A boolean indicating if the user has a specific role.
 * @param {Object} project - The project.
 * @returns {JSX.Element} - The rendered UtilityDisplay component.
 */
const UtilityDisplay = ({
  version,
  title,
  id,
  getProject,
  generateForm,
  role,
  project
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataDeliverables, setDataDeliverables] = useState();
  const [enums, setEnums] = useState();
  const { getUtilityFilters, getFilteredUtilities } = useUtilityFilters(
    project?.planning_utility_list,
    enums?.deliverables_for
  );

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const config = {
    onGetResource: {
      setFields: (dataT) => ({
        ...dataT,
        deliverables: { ...dataDeliverables }
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, deliverables) => {
    const { planning, ...rest } = deliverables;
    setVisible(idRender);
    setDataDeliverables({
      ...rest,
      planning: planning
        ? {
            ...planning,
            start_date: planning.start_date
              ? moment(planning.start_date)
              : undefined,
            due_date: moment(planning.due_date)
              ? moment(planning.due_date)
              : '',
            piloting_mark: planning.piloting_mark,
            gantt_mark: planning.gantt_mark,
            macro_task: planning.macro_task._id
          }
        : undefined
    });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      {getUtilityFilters()}
      {getFilteredUtilities(version?.content).map((e) => {
        const { _id, utility } = e;
        return visible === e._id && dataDeliverables ? (
          generateForm(
            'edit',
            version,
            'manage_planning',
            closeForm,
            dataDeliverables,
            'deliverables',
            config
          )
        ) : (
          <Collapse collapsible={false} key={_id}>
            <Panel
              style={{ marginBottom: 16 }}
              extra={
                role && (
                  <Row justify="center" style={{ flexWrap: 'nowrap' }}>
                    <Button
                      disabled={project?.status === 'close'}
                      onClick={() => openForm(_id, e)}
                      size="small"
                      type="link"
                      style={{
                        color: 'var(--secondaryColor)',
                        top: 2
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <ButtonDeleteAll
                      purpose="matrice"
                      projectId={id}
                      title="utility"
                      titleId={version._id}
                      matriceName="deliverables"
                      matriceId={_id}
                      handleCancel={closeForm}
                      version={version}
                    />
                  </Row>
                )
              }
              header={<Header deliverable={e} />}
            >
              <Descriptions size="small" style={{ padding: 12 }}>
                <Descriptions.Item
                  label={t(`projects.form.deliverables.beneficiaries`)}
                >
                  {utility?.beneficiaries}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
          </Collapse>
        );
      })}
      {visibleModal && (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={800}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            version,
            'manage_planning',
            closeFormModal,
            null,
            'deliverables'
          )}
        </Modal>
      )}
      {role && (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={openFormModal}
          title={t('projects.form.items.utility.deliverables.addUtility')}
        />
      )}
    </>
  );
};

UtilityDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  id: PropTypes.string,
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  project: PropTypes.shape({
    status: PropTypes.string,
    planning_utility_list: PropTypes.arrayOf(PropTypes.string)
  })
};

UtilityDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  id: null,
  project: null
};

export default UtilityDisplay;
