/**
 * Get the displayed actor role. Handles default roles for project manager and actors
 *
 * @function
 * @param {Object} actor - A project actor object.
 * @param {string} assigneeId - The id of the assignee.
 * @param {Object} t - i18n translation object
 * @returns {string} The displayed actor role
 */
export const getDisplayedActorRole = (actor, assigneeId, t) => {
  if (!actor) return '';
  if (!actor.role) {
    if (String(actor.user?._id) === String(assigneeId))
      return t('projects.project_manager');
    return t('projects.actor');
  }
  return actor.role;
};
