import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Form, Row, Col, Select, Spin, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { ManageStatusIcon } from '../../../../utils/constants/customIcon';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from '../fieldsItems/itemsFields';

const { Option } = Select;

const ButtonManageStatus = ({ project, getProject, checkRole }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const fields = useFields('event', project?._id || null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const { message, success } = useErrorMessage();
  const [form] = Form.useForm();
  const listStatus = ['V0', 'V1', 'VX', 'closed'];

  const updateStatus = async (bodyArg, assignee) => {
    setLoading(false);
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project._id}`,
        body: { ...bodyArg, ...assignee }
      });
      getProject();
      checkRole();
      success(`La version de ${project.name} a changé`);
      setLoading(true);
    } catch (e) {
      if (e.response) message(e.response.status);
      setLoading(false);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = async (values) => {
    await updateStatus(values);
    setVisible(false);
    form.resetFields();
  };

  const getRoleForSelect = (item) => {
    const indexTodelete = listStatus.indexOf(project.status);
    return item
      ? listStatus.slice(indexTodelete + 1)[0]
      : listStatus.slice(indexTodelete + 1);
  };

  const checkRoleForDisplayButton = () => {
    if (
      user?.role === 'managers:PROJECT_MANAGER' ||
      user?.role === 'admins:ADMIN'
    ) {
      return (
        <Button
          type="add"
          onClick={showModal}
          style={{ height: 34, display: 'flex', alignItems: 'center' }}
        >
          <ManageStatusIcon />
          <span>{`${t('projects.change_version')}`}</span>
        </Button>
      );
    }
    return null;
  };

  return project ? (
    <Row justify="end" style={{ right: 42 }}>
      <Col>
        <Row justify="end" align="middle">
          {checkRoleForDisplayButton()}
        </Row>
        <Modal
          visible={visible}
          title={`Changer le statut de ${project.name} ?`}
          centered
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            {...formItemLayout}
            onFinish={handleSubmit}
            initialValues={{
              status: getRoleForSelect(project.status),
              assignee: project?.assignee
            }}
            form={form}
          >
            <Row justify="space-between">
              <Col span={24}>
                <Form.Item
                  label={t('projects.details.status')}
                  name={['status']}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ textAlign: 'center', width: 200 }}
                    onChange={(event) => setSelectedStatus(event)}
                  >
                    {getRoleForSelect().map((type) => (
                      <Option
                        value={type}
                        key={type}
                        disabled={type === 'VX' && project.status === 'V0'}
                      >
                        {t(`projects.status.${type}`)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {(project.status === 'V0' || project.status === 'V1') &&
                  selectedStatus !== 'closed' && (
                    <>
                      <Divider />
                      {fields.map((field) => generateFields('event', field))}
                    </>
                  )}
              </Col>
            </Row>

            <Form.Item {...tailFormItemLayout}>
              <Row justify="center">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={handleCancel}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  ) : (
    <Spin spinning={loading} />
  );
};

ButtonManageStatus.propTypes = {
  project: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  getProject: PropTypes.func.isRequired,
  checkRole: PropTypes.func.isRequired
};

export default ButtonManageStatus;
