import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Input, message } from 'antd';
import useProjectContext from '../../../../contexts/ProjectContext';
import useAuthContext from '../../../../contexts/AuthContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

const { TextArea } = Input;

const useEventFields = (perimeter, loading) => {
  const [eventList, setEventList] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const { project } = useProjectContext();
  const dateFormat = 'DD-MM-YYYY';
  const today = moment();

  const typeSelect = useGlossarySelect(eventList, loading, 'type');

  const getEventList = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/lists?sub_category=event`
      });
      const filteredEventWords = data
        .filter((word) => word.sub_category === 'event')
        .map((word) => word.value);
      if (project) {
        setEventList(project.event_list);
      } else if (perimeter) {
        setEventList([
          ...new Set([...filteredEventWords, ...perimeter.event_list])
        ]);
      }
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  useEffect(() => {
    try {
      (async () => {
        await getEventList();
      })();
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  }, [perimeter]);

  const eventFields = [
    {
      name: ['event', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['event', 'name'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['event', 'type'],
      rules: [{ required: true }],
      input: typeSelect
    },
    {
      name: ['event', 'date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['event', 'comment'],
      rules: [{ required: false }],
      input: <TextArea row={4} />
    }
  ];

  return { eventFields };
};

export default useEventFields;
