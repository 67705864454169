import React from 'react';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A hook that generates project date related form fields.
 *
 * @returns {Object} - An object containing the date fields configuration.
 */
const usePlaningFields = () => {
  const { t } = useTranslation();
  const dateFormat = 'DD-MM-YYYY';
  const planingFields = [
    {
      name: ['lifeProject', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['lifeProject', 'planning', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['lifeProject', 'planning', 'start_date'],
      label: t('projects.details.start_date'),
      priorityLabel: true,
      rules: [{ required: true }],
      input: <DatePicker allowClear={false} format={dateFormat} />
    },
    {
      name: ['lifeProject', 'planning', 'due_date'],
      label: t('projects.details.end_date'),
      priorityLabel: true,
      rules: [{ required: true }],
      input: <DatePicker allowClear={false} format={dateFormat} />
    }
  ];

  return { planingFields };
};

export default usePlaningFields;
