import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

/**
 * Component for displaying the description of a PlanCom card.
 *
 * @component
 * @param {Object} planCom - The PlanCom data.
 * @returns {JSX.Element} PlanComDescription component
 */
const PlanComDescription = ({ planCom }) => {
  const { t } = useTranslation();
  return (
    <Descriptions>
      <Descriptions.Item
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.in_charge_of`
        )}
      >
        {planCom.in_charge_of}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t(`projects.form.items.plan_com.deliverables_plan_com.type`)}
      >
        {planCom.type}
      </Descriptions.Item>
      <Descriptions.Item
        span={1}
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.frequency`
        )}
      >
        {planCom.repeat?.value &&
          planCom.repeat.time_unit &&
          `${t(`projects.form.deliverables_plan_com.every`)} ${
            planCom.repeat.value
          } ${t(
            `projects.form.deliverables_plan_com.${planCom.repeat.time_unit}`
          )}`}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(
          `projects.form.items.plan_com.deliverables_plan_com.start_date`
        )}
      >
        {planCom.start_date ? (
          <Tag>{moment(planCom.start_date).format('DD-MM-YYYY')}</Tag>
        ) : (
          <Tag>{t('projects.details.no_data')}</Tag>
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.plan_com.deliverables_plan_com.due_date`)}
      >
        {planCom.due_date ? (
          <Tag>{moment(planCom.due_date).format('DD-MM-YYYY')}</Tag>
        ) : (
          <Tag>{t('projects.details.no_data')}</Tag>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

PlanComDescription.propTypes = {
  planCom: PropTypes.shape({
    in_charge_of: PropTypes.string,
    type: PropTypes.string,
    repeat: PropTypes.shape({
      value: PropTypes.number,
      time_unit: PropTypes.string
    }),
    start_date: PropTypes.string,
    due_date: PropTypes.string
  }).isRequired
};

export default PlanComDescription;
