import { useTranslation } from 'react-i18next';

const useColumns = (action, role) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('perimeters.details.name'),
      key: 'name',
      sorter: true,
      fixed: 'left',
      render: (record) =>
        record.default ? t('perimeters.show.default') : record.name
    },
    {
      title: t('perimeters.details.project_number'),
      dataIndex: 'projects',
      key: 'projects',
      sorter: true,
      render: (projects) => (projects ? `${projects.length}` : 'N/A')
    },
    action[0]
  ];

  if (role === 'admins:ADMIN') {
    columns.splice(1, 0, {
      title: t('perimeters.details.administrator'),
      dataIndex: 'assignee',
      key: 'assignee',
      sorter: true,
      render: (assignee, index) =>
        assignee && index !== 0
          ? `${assignee.first_name} ${assignee.last_name}`
          : '-'
    });
  }

  return columns;
};

export default useColumns;
