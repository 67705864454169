import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Tag } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import Realize from '../../routes/home/indicatorsCommercial/Realize';
import useTime from '../../routes/home/indicatorsCommercial/time';
import useCost from '../../routes/home/indicatorsCommercial/cost';
import useUtility from '../../routes/home/indicatorsCommercial/utility';
import useFederation from '../../routes/home/indicatorsCommercial/federation';
import setIconStatus from '../../utils/constants/setIconStatus';
import CreateEditModal from '../CreateUpdateContainer/Modals/CreateEditModal';
import useAuthContext from '../../contexts/AuthContext';
import ReassignProjectForm from './ReassignProjectForm/ReassignProjectForm';

/**
 * ProjectCard component displays project information in a card format.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Object} props.project - The project object with details like name, customer, status, etc.
 * @param {string} [props.pathname] - The pathname for the project link.
 * @param {Array} [props.projectManagers] - An array of project managers for the project.
 * @param {Array} [props.perimeters] - An array of perimeters associated with the project.
 * @param {string} [props.purpose] - The purpose for using the component (e.g., 'home').
 * @param {number} [props.xs] - The number of columns to occupy on extra-small screens.
 * @param {number} [props.sm] - The number of columns to occupy on small screens.
 * @param {number} [props.md] - The number of columns to occupy on medium screens.
 * @param {number} [props.lg] - The number of columns to occupy on large screens.
 * @param {number} [props.xl] - The number of columns to occupy on extra-large screens.
 * @param {number} [props.xxl] - The number of columns to occupy on extra-extra-large screens.
 * @param {Object} [props.styles] - Additional CSS styles to apply to the component.
 * @returns {JSX.Element} - The rendered ProjectCard component.
 */
const ProjectCard = ({
  project,
  pathname,
  perimeters,
  getProjects,
  purpose,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  styles
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [form] = useForm();

  const indicatorsList = {
    time: useTime,
    cost: user?.role !== 'actors:ACTOR' && useCost,
    utility: useUtility,
    federation: useFederation
  };

  const getCreatedDays = (created) => {
    const getDays = moment(Date.now()).diff(moment(created), 'days');
    if (getDays > 0) return ` Il y a ${getDays} jours`;
    return t('projects.today');
  };

  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      xxl={xxl}
      style={{ ...styles }}
    >
      <Card style={{ padding: '8px 0' }} className="supervisor-project-card">
        <Row justify="center" className="project-card-header">
          <Col style={{ textAlign: 'center' }} span={24}>
            <Col style={{ fontSize: 17, fontWeight: 'bold' }} span={24}>
              {project.name}
            </Col>
            <Col span={24} style={{ color: '#8c8c8c', textAlign: 'center' }}>
              {project.customer}
            </Col>
          </Col>
          <Col className="gantt-project-version">
            {setIconStatus(project.name, project.status, false, 20)}
          </Col>
        </Row>

        <Row
          style={{ marginTop: 10, marginBottom: 10 }}
          justify="space-between"
        >
          {purpose === 'home' ? (
            <>
              <Col style={{ color: '#8c8c8c' }} span={6}>
                {t('projects.perimeter')}
              </Col>
              <Col span={6}>
                {project.perimeter && !project.perimeter.default ? (
                  `${project.perimeter.name}`
                ) : (
                  <Tag color="orange">
                    {t('projects.details.no_attachment')}
                  </Tag>
                )}
              </Col>
            </>
          ) : null}
          <Col style={{ color: '#8c8c8c' }} span={purpose !== 'home' ? 12 : 6}>
            {t('projects.project_manager')}
          </Col>
          <Col span={purpose !== 'home' ? 12 : 6}>
            {project.assignee ? (
              `${project.assignee.first_name} ${project.assignee.last_name}`
            ) : (
              <Tag color="orange">{t('projects.details.no_attachment')}</Tag>
            )}
          </Col>
        </Row>
        <Row
          style={{ marginTop: 10, marginBottom: 10 }}
          justify="space-between"
        >
          {Object.entries(indicatorsList).map(([key, value]) => (
            <Col span={24 / indicatorsList?.length} key={key}>
              {value && value(project, t)}
            </Col>
          ))}
        </Row>
        <Realize data={project} translate={t} />
        <Row justify="space-between" align="middle">
          <Col>
            <HistoryOutlined style={{ marginRight: 5 }} />
            <span>{getCreatedDays(project.created_at)}</span>
          </Col>
          <Row>
            {pathname &&
              [
                'actors:ACTOR',
                'managers:PROJECT_MANAGER',
                'admins:PERIMETER_ADMIN'
              ].includes(user?.role) && (
                <Link
                  to={{
                    pathname
                  }}
                >
                  <Col>
                    <Button style={{ color: 'var(--secondaryColor)' }}>
                      {t('projects.see')}
                    </Button>
                  </Col>
                </Link>
              )}
            {perimeters && user?.role.includes('admins') && (
              <>
                <Button
                  style={{ marginLeft: 8 }}
                  type="primary"
                  onClick={() => setVisible(true)}
                >
                  {t('projects.reassign')}
                </Button>
                <CreateEditModal
                  title={t('projects.form.reassign', {
                    project_name: project.name
                  })}
                  handleSubmit={() => form.submit()}
                  handleCancel={() => {
                    setVisible(false);
                    form.resetFields();
                  }}
                  visible={visible}
                >
                  <ReassignProjectForm
                    form={form}
                    perimeters={perimeters}
                    setVisible={setVisible}
                    getProjects={getProjects}
                    project={project}
                  />
                </CreateEditModal>
              </>
            )}
          </Row>
        </Row>
      </Card>
    </Col>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.shape({
    description: PropTypes.string,
    customer: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    assignee: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    created_at: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    perimeter: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      default: PropTypes.bool
    }).isRequired
  }).isRequired,
  pathname: PropTypes.string,
  perimeters: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string.isRequired })
  ),
  purpose: PropTypes.string,
  getProjects: PropTypes.func,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  styles: PropTypes.shape({})
};

ProjectCard.defaultProps = {
  pathname: '',
  perimeters: null,
  getProjects: null,
  purpose: null,
  xs: null,
  sm: null,
  md: null,
  lg: null,
  xl: null,
  xxl: null,
  styles: {}
};

export default ProjectCard;
