import React from 'react';
import useSearchFilter from '../../../utilsProject/Filters/useSearchFilter';
import useSelectFilter from '../../../utilsProject/Filters/useSelectFilter';
import useRangeFilter from '../../../utilsProject/Filters/useRangeFilter';
import ItemFilters from '../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing and applying filters to prior information.
 *
 * @hook
 * @param {string[]} types - An array of available types for filtering.
 * @returns {Object} - An object containing functions for rendering filters and applying filters.
 */
const usePriorFilters = (types) => {
  const { getSearchFilter, getSearchedValues } = useSearchFilter();
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    types,
    'types'
  );
  const { getRangeFilter, getRangeFilteredValues } = useRangeFilter();

  const getPriorFilters = () => {
    return (
      <ItemFilters
        filters={[getSearchFilter(), getSelectFilter(), getRangeFilter()]}
      />
    );
  };

  const getfilteredPriors = (priors) => {
    if (!priors) return [];
    return getRangeFilteredValues(
      getFilteredValues(
        getSearchedValues(priors, (prior) => prior?.denomination),
        (prior) => prior?.type
      ),
      (prior) => prior?.creation_date
    );
  };

  return { getPriorFilters, getfilteredPriors };
};

export default usePriorFilters;
