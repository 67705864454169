import React from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

const EditButton = ({ onClick, iconSize }) => {
  return (
    <EditOutlined
      style={{ fontSize: iconSize, marginRight: 10 }}
      onClick={onClick}
    />
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  iconSize: PropTypes.string
};

EditButton.defaultProps = {
  onClick: null,
  iconSize: '18px'
};

export default EditButton;
