import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';

import ShowDashboard from './ShowDashboard';
import { DashboardContextProvider } from '../../contexts/DashboardContext';

const DashboardRouter = () => {
  return (
    <DashboardContextProvider>
      <Switch>
        <Route path={`${routes.DASHBOARD}/:id`} component={ShowDashboard} />
        <Route path={`${routes.DASHBOARD}`} component={ShowDashboard} />
      </Switch>
    </DashboardContextProvider>
  );
};
export default DashboardRouter;
