import React from 'react';
import GreenDrop from '../../assets/images/icons/green-drop.svg';
import BlueDrop from '../../assets/images/icons/blue-drop.svg';
import { V0Icon, VIconGray, VPlusIcon } from './customIcon';

/**
 * Generates a version item with an associated drop icon based on the provided version number.
 *
 * @function
 * @param {number} version - The version number to determine the drop icon color.
 * @returns {JSX.Element} - The generated version item JSX element.
 */
export const setVersionItem = (version) => {
  return (
    <span
      style={{
        backgroundImage: `url(${version === 0 ? BlueDrop : GreenDrop})`,
        height: 32,
        width: 24,
        marginBottom: 6
      }}
    >
      <span
        style={{
          fontSize: 10.5,
          color: 'white',
          fontWeight: 700,
          position: 'relative',
          top: 10,
          left: 5
        }}
      >
        {`V${version}`}
      </span>
    </span>
  );
};

/**
 * Generates a drop icon element with a version number label.
 *
 * @function
 * @param {JSX.Element} icon - The colored drop icon.
 * @param {number} page - The page number.
 * @returns {JSX.Element} - The generated drop icon JSX element.
 */

const getDrop = (icon, page) => {
  return (
    <span>
      {icon}
      <span
        style={{
          fontSize: 10.5,
          fontWeight: 'bold',
          color: 'white',
          position: 'relative',
          bottom: 26
        }}
      >
        {`V${page - 1}`}
      </span>
    </span>
  );
};

/**
 * Generates a pagination drop icon element with a version number label.
 *
 * @function
 * @param {number} page - The page number to display as a label.
 * @param {number} currentPage - The current page number.
 * @returns {JSX.Element|null} - The generated pagination drop icon JSX element, or null if not applicable.
 */
export const getPaginationDrop = (page, currentPage) => {
  if (page !== currentPage) {
    return getDrop(<VIconGray />, page);
  }
  if (page === 1) {
    return getDrop(<V0Icon />, page);
  }
  if (page > 1) {
    return getDrop(<VPlusIcon />, page);
  }
  return null;
};
