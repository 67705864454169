import React from 'react';
import { Row, Col, Tag } from 'antd';
import { getItemVersion } from '../../Projects/Projects/utilsProject/ManageVersion/utils';

const useCost = (data, t) => {
  if (data.status !== 'V0' && data.status !== 'V' && data.cost[1]) {
    const lastCostVersion = getItemVersion(data?.cost, -1);
    let costs = 0;
    if (lastCostVersion?.content) {
      lastCostVersion.content.forEach((e) => {
        costs += e.cost_value || 0;
      });
    }

    return (
      <Row style={{ marginBottom: 8 }} justify="start">
        <Col span={24}>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.10em',
              fontSize: 10,
              color: '#8c8c8c'
            }}
          >
            {t(`projects.details.cost`)}
          </span>
        </Col>
        <Col span={24}>{` ${costs} €`}</Col>
      </Row>
    );
  }
  return (
    <>
      <Col>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {t(`projects.details.cost`)}
        </span>
      </Col>
      <Col>
        <Tag color="orange" style={{ marginRight: 16 }}>
          Non saisi
        </Tag>
      </Col>
    </>
  );
};

export default useCost;
