import React from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';

export const getColumns = (t) => {

  return [
    {
      title: 'Item',
      render: (item) =>
        `${t(`configurations.items.${item.key}`)} - ${t(
          `configurations.items.types.${item.type}`
        )}`
    }
  ];
};

export const getExpandedColumns = (
  editPermission,
  setWordToEdit,
  setPurpose,
  form,
  setVisible,
  deleteWord,
  purposes,
  t
) => {
  const iconSize = 18;

  return [
    {
      dataIndex: 'value',
      key: 'key'
    },
    {
      key: 'action',
      render: (record) =>
        editPermission ? (
          <div style={{ float: 'right' }}>
            <Divider type="vertical" />
            <EditOutlined
              style={{ fontSize: iconSize }}
              onClick={() => {
                const editWord = {
                  ...record,
                  label: record.value
                };
                setWordToEdit(editWord);
                form.setFieldsValue(editWord);
                setPurpose(purposes.EDIT);
                setVisible(true);
              }}
            />
            <Divider type="vertical" />
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => {
                deleteWord(record);
              }}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          </div>
        ) : null,
      fixed: 'right'
    }
  ];
};
