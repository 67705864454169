import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row } from 'antd';
import { UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import ActorsDescription from '../ActorsDescription';

/**
 * A React component representing an actor card.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {Object} props.actorData - Data about the actor.
 * @param {Function} props.openForm - Function to open a form.
 * @param {Boolean} props.role - Controls components visibility according to user's role.
 * @param {Object} props.project - Data about the project.
 * @param {Object} props.version - Data about the version.
 * @param {Function} props.closeForm - Function to close a form.
 * @returns {JSX.Element} The rendered ActorCard component.
 */
const ActorCard = ({
  actorData,
  openForm,
  role,
  project,
  closeForm,
  version
}) => {
  const { first_name, last_name, user } = actorData;
  return (
    <Card>
      <ActorsDescription
        data={{ actor: actorData }}
        openForm={openForm}
        closeForm={closeForm}
        role={role}
        project={project}
        version={version}
        title={
          <Row align="middle">
            {user ? (
              <UserOutlined style={{ marginRight: '16px' }} />
            ) : (
              <UserSwitchOutlined style={{ marginRight: '16px' }} />
            )}
            {`${first_name} ${last_name}`}
          </Row>
        }
      />
    </Card>
  );
};

ActorCard.propTypes = {
  actorData: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    user: PropTypes.shape({})
  }).isRequired,
  role: PropTypes.bool,
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired
};

ActorCard.defaultProps = {
  role: false
};

export default ActorCard;
