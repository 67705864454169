import React from 'react';
import useSelectFilter from '../../../utilsProject/Filters/useSelectFilter';
import useSearchFilter from '../../../utilsProject/Filters/useSearchFilter';
import ItemFilters from '../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing context filters and filtering contexts.
 *
 * @hook
 * @param {Array} types - List of context types.
 * @returns {Object} - An object containing functions for getting context filters and filtering contexts.
 */
const useContextFilters = (types) => {
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    types,
    'types'
  );
  const { getSearchFilter, getSearchedValues } = useSearchFilter();

  const getContextFilters = () => {
    return <ItemFilters filters={[getSearchFilter(), getSelectFilter()]} />;
  };

  const getFilteredContexts = (contexts) => {
    if (!contexts) return [];
    return getFilteredValues(
      getSearchedValues(contexts, (context) => context?.denomination),
      (context) => context?.type
    );
  };

  return { getContextFilters, getFilteredContexts };
};

export default useContextFilters;
