import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useRiskFilters from './RiskFilters/useRiskFilters';
import RiskCollapse from './RiskCollapse/RiskCollapse';

/**
 * A tab pane component for displaying and managing risk information.
 *
 * @component
 * @param {Object} project - The project object containing project details.
 * @param {Object} version - The version object containing version details.
 * @param {Function} generateForm - A function to generate a form.
 * @param {boolean} role - Controls components visibility according to user role.
 * @param {string} title - The type of the item.
 * @param {Function} getProject - A function to get project details.
 * @returns {JSX.Element} A JSX Element representing the RiskTabPane component.
 */
const RiskTabPane = ({
  project,
  version,
  generateForm,
  role,
  title,
  getProject
}) => {
  const [visible, setVisible] = useState();
  const [dataRisk_list, setDataRisk_list] = useState();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        risk_list: data
      })
    }
  };

  const { getRiskFilters, getFilteredRisks } = useRiskFilters(
    project?.risks_list
  );

  const openForm = (idRender, risks) => {
    const { date, ...rest } = risks;
    setVisible(idRender);
    setDataRisk_list({
      ...rest,
      date: moment(date),
      decision_date: moment(rest.decision_date)
    });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  return (
    <>
      {getRiskFilters()}
      {getFilteredRisks(version?.content).map((risk) => {
        const { _id } = risk;
        return visible === _id && dataRisk_list ? (
          generateForm(
            'edit',
            version,
            title,
            closeForm,
            dataRisk_list,
            'risk_list',
            config
          )
        ) : (
          <RiskCollapse
            key={_id}
            risk={risk}
            project={project}
            version={version}
            role={role}
            title={title}
            openForm={openForm}
            closeForm={closeForm}
          />
        );
      })}
    </>
  );
};

RiskTabPane.propTypes = {
  project: PropTypes.shape({
    _id: PropTypes.string,
    status: PropTypes.string,
    risks_list: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  version: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  getProject: PropTypes.func,
  title: PropTypes.string.isRequired
};

RiskTabPane.defaultProps = {
  role: false,
  generateForm: () => {},
  getProject: () => {}
};

export default RiskTabPane;
