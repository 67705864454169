import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useTranslation } from 'react-i18next';
import RiskTabPane from './TabPanes/RisksTabPane/RisksTabPane';
import MatrixTabPane from './TabPanes/MatrixTabPane/MatrixTabPane';

/**
 * Component that displays tabs for different views within the Risks display.
 *
 * @component
 * @param {Object} project - The project data.
 * @param {Object} version - The version object containing risk items.
 * @param {Function} generateForm - A function to generate the form for creating risk items.
 * @param {boolean} role - Control component visibility according to user's role.
 * @param {Function} getProject - A function to fetch project data.
 * @param {string} title - The item type.
 * @returns {JSX.Element} RiskTabs component
 */
const RiskTabs = ({
  project,
  version,
  generateForm,
  role,
  getProject,
  title
}) => {
  const { t } = useTranslation();

  const tabPanes = {
    risks: (
      <RiskTabPane
        project={project}
        version={version}
        generateForm={generateForm}
        role={role}
        title={title}
        getProject={getProject}
      />
    ),
    matrix: <MatrixTabPane risks={version?.content} />
  };

  return (
    <Tabs
      animated={{ inkBar: true }}
      id="blue-tab-planning"
      defaultActiveKey="Gantt"
    >
      {Object.entries(tabPanes).map(([key, value]) => (
        <TabPane tab={t(`projects.form.items.risks.tabs.${key}`)} key={key}>
          {value}
        </TabPane>
      ))}
    </Tabs>
  );
};

RiskTabs.propTypes = {
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  getProject: PropTypes.func,
  title: PropTypes.string.isRequired
};

RiskTabs.defaultProps = {
  getProject: () => {},
  generateForm: () => {},
  role: false
};

export default RiskTabs;
