import React from 'react';
import {
  DeleteOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Divider, Popconfirm, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/constants/routes';
import { userRoles } from '../../../utils/constants/tagColors';

const iconSize = 18;

/**
 * Custom hook that returns the columns for the datatable.
 *
 * @hook
 * @param {Object} user - The current user.
 * @param {Function} setPurpose - The setter for the purpose (e.g. add or remove users to the perimeter group).
 * @param {Function} setUsersToAddOrRemove - The setter for the users to add or remove (set a list of user ID).
 * @return {Array} The columns for the datatable.
 */
const useColumns = (user, setPurpose, setUsersToAddOrRemove) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: `${t(`datatable.column.last_name`)}`,
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name)
    },
    {
      title: `${t(`datatable.column.first_name`)}`,
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: `${t(`datatable.column.email`)}`,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('_').length > 1
            ? `${r.split('_')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.USERS}/show/${record._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {user._id !== record._id && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                key={record._id}
                title={t('perimeters.group.remove_user.title')}
                okText={t('datatable.column.action.remove.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => {
                  setPurpose('remove');
                  setUsersToAddOrRemove([record._id]);
                }}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ];
  return columns;
};

export default useColumns;
