import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { items } from '../../../utils/constants/configurableItemList';
import ListTransfer from './ListTransfer';

const { Panel } = Collapse;

const CollapseFormItem = ({ glossarySources, initialValues }) => {
  const { t } = useTranslation();
  const [glossary, setglossary] = useState({});

  const intializeGlossary = () => {
    const newGlossary = {};
    if (glossarySources.length)
      items.forEach((item) => {
        newGlossary[item.key] = Array.from(
          new Set(
            glossarySources.flatMap(
              (glossarySource) => glossarySource[`${item.key}_list`]
            )
          )
        );
      });
    setglossary(newGlossary);
  };

  useEffect(() => {
    intializeGlossary();
  }, [glossarySources]);

  return (
    <Collapse ghost>
      {items.map((item) => (
        <Panel
          header={
            <Row align="middle">
              {item.icon}
              <div style={{ marginLeft: '10px' }}>
                {`${t(`configurations.items.${item.key}`)} - ${t(
                  `configurations.items.types.${item.type}`
                )}`}
              </div>
            </Row>
          }
          key={item.key}
        >
          <ListTransfer
            item={item.key}
            savedItemContent={initialValues ? initialValues[item.key] : []}
            sourceItemContent={glossary[item.key]}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

CollapseFormItem.propTypes = {
  glossarySources: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({ getFieldValue: PropTypes.func.isRequired }),
  initialValues: PropTypes.shape({})
};

CollapseFormItem.defaultProps = {
  glossarySources: [],
  form: null,
  initialValues: {}
};
export default CollapseFormItem;
