import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';
import Projects from './Projects/Projects';
import ShowProject from './Projects/ShowProject';
import { ProjectContextProvider } from '../../contexts/ProjectContext';

const ProductionRouter = () => {
  const { path } = useRouteMatch();
  return (
    <ProjectContextProvider>
      <Switch>
        <Route
          path={`${path}${routes.PROJECTS}/show/:id`}
          component={ShowProject}
        />
        <Route path={`${path}${routes.PROJECTS}`} component={Projects} />
        <Route
          path={path}
          render={() => <Redirect to={`${path}${routes.PROJECTS}`} />}
        />
      </Switch>
    </ProjectContextProvider>
  );
};
export default ProductionRouter;
