import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Switch, Checkbox } from 'antd';
import usePerimeterContext from '../../../../contexts/PerimeterContext';

const usePerimeterFields = (importConfig, setImportConfig, purpose) => {
  const { t } = useTranslation();

  const { perimeters } = usePerimeterContext();

  const handleImportConfigChange = (checked) => {
    setImportConfig(checked);
  };

  const mainFields = [
    {
      name: ['name'],
      label: 'perimeter_name',
      rules: [{ required: true }]
    },
    {
      name: ['attached_files'],
      label: 'attached_files',
      rules: [{ required: true }],
      valuePropName: 'checked',
      input: <Switch defaultChecked={purpose === 'create'} />
    }
  ];

  const importSwitch = {
    name: ['import_perimeter', 'import'],
    label: 'import.settings',
    rules: [{ required: false }],
    valuePropName: 'checked',
    input: <Switch onChange={handleImportConfigChange} />
  };

  const importFields = [
    {
      name: ['import_perimeter', 'perimeter'],
      label: 'import.perimeter',
      rules: [{ required: importConfig }],
      input: (
        <Select>
          {perimeters?.map((p) => {
            return (
              <Select.Option key={p._id} value={p._id}>
                {p.name}
              </Select.Option>
            );
          })}
        </Select>
      )
    },
    {
      name: ['import_perimeter', 'configurations'],
      label: t('import.configuration'),
      input: (
        <Checkbox.Group>
          <Checkbox value="users">{t('perimeters.form.import.users')}</Checkbox>
          <Checkbox value="glossary">
            {t('perimeters.form.import.glossary')}
          </Checkbox>
        </Checkbox.Group>
      )
    }
  ];

  return { mainFields, importSwitch, importFields };
};

export default usePerimeterFields;
