import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Row, Button, Col, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import useAuthContext from '../../../../contexts/AuthContext';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from '../fieldsItems/itemsFields';

/**
 * Form for managing federation information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string|Object} props.dataItems - The data items related to the federation.
 * @param {string} props.projectId - The ID of the project.
 * @param {Object} props.config - Configuration options for the component.
 * @param {function} props.handleCancel - Callback function to handle cancellation.
 * @param {function} props.getProject - Callback function to get project information.
 * @returns {JSX.Element} JSX element representing the component for managing federation information.
 */
const ManageFederation = ({ dataItems, projectId, config, handleCancel }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const fieldsFederation = useFields('membership', dataItems);

  const updateFederation = async (values) => {
    setIsLoading(true);
    try {
      await dispatchAPI('POST', {
        url: `/projects/lastVersions/${projectId}/plan_com`,
        body: values
      });
      form.resetFields();
      await handleCancel();
      setIsLoading(false);
      message.success(t('projects.form.event.created'));
    } catch (e) {
      setIsLoading(false);
      if (e.response) message.error(t(`errors.message.${e.response.status}`));
    }
  };
  const setValues = () => {
    if (config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(dataItems.federation)
          : dataItems.federation
      );
    }
  };

  useEffect(() => {
    if (config !== null && dataItems?.federation !== null) {
      setValues();
    }
  }, []);

  const isReductScreen = useMediaQuery({ query: '(max-width: 992px)' });
  const handleSubmit = (values) => {
    updateFederation(values);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        {...formItemLayout}
        onFinish={(values) => handleSubmit(values)}
        name={t('projects.form.items.membership.title')}
        form={form}
      >
        <>
          {fieldsFederation.map((field) => generateFields('projects', field))}
          <Form.Item style={{ marginBottom: 0 }}>
            <Row
              justify="end"
              wrap={false}
              style={{
                position: 'relative',
                right: isReductScreen ? '-140%' : '-200%'
              }}
            >
              <Col offset={12}>
                <Button
                  style={{ margin: '0 6px' }}
                  type="link"
                  danger
                  onClick={() => handleCancel()}
                >
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.cancel')} `}
                  </span>
                  <CloseOutlined />
                </Button>
              </Col>
              <Col>
                <Button type="add" htmlType="submit">
                  <span
                    style={{
                      display: isReductScreen ? 'none' : 'inline-block'
                    }}
                  >
                    {`${t('buttons.save')} `}
                  </span>
                  <CheckOutlined />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </>
      </Form>
    </Spin>
  );
};

ManageFederation.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  projectId: PropTypes.string.isRequired,
  title: PropTypes.string,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  fieldsName: PropTypes.string,
  idVersion: PropTypes.string,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  idMatrice: PropTypes.string
};

ManageFederation.defaultProps = {
  dataItems: null,
  title: null,
  config: null,
  fieldsName: null,
  idVersion: null,
  visible: false,
  idMatrice: null
};

export default ManageFederation;
