import React from 'react';
import {
  ContactsOutlined,
  EuroOutlined,
  FieldTimeOutlined,
  SettingOutlined,
  StockOutlined,
  TagsOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {
  HomeIcon,
  SupervisorIcon,
  ActorIcon,
  PerimetersIcon,
  ProjectsIcon,
  UsersIcon,
  DashboardIcon,
  IndicatorIcon,
  ReportingIcon,
  BalanceIcon,
  HistoryIcon,
  SettingsIcon
} from './customIcon';

const navMenuLogos = {
  HOME: <HomeIcon />,
  SUPERVISOR: <SupervisorIcon />,
  ACTOR: <ActorIcon />,
  DASHBOARD: <DashboardIcon />,
  REPORTING: <ReportingIcon />,
  INDICATORS: <IndicatorIcon />,
  HISTORY: <HistoryIcon />,
  BALANCE_SHEET: <BalanceIcon />,
  MEMBERS: <UsergroupAddOutlined />,
  ACCOUNTING: <EuroOutlined />,
  COMMERCIAL: <ContactsOutlined />,
  PERIMETERS: <PerimetersIcon />,
  PROJECTS: <ProjectsIcon />,
  THEME: <SettingOutlined />,
  DOCUMENTS: <ContainerOutlined style={{ fontSize: 35 }} />,
  'TIMES-AND-ATTENDANCES': <FieldTimeOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  USERS: <UsersIcon />,
  PERIMETER_ADMINS: <TeamOutlined style={{ fontSize: 35 }} />,
  CONFIGURATION: <SettingsIcon />
};

export default navMenuLogos;
