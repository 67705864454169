import React from 'react';
import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { enums } from '../../../utils';

/**
 *
 * Custom hook that generates fields related to actors that are assigned to actions.
 * @param {string} actorsItem - Object containing project actors and groups.
 * @param {string[]} onChangeColor - Function to handle color change.
 * @param {string[]} managePlannings - Array of managePlanning objects.
 * @returns {Array} An array of fields.
 */
const useActionActorsFields = (actorsItem, onChangeColor, managePlannings) => {
  const { t } = useTranslation();

  const macro_tasks = managePlannings?.filter(
    (deliverable) =>
      deliverable.planning?.action_type === enums.action_types.MACRO_TASK
  );

  const handleMacroTaskChange = (newMacroTask) => {
    const selectedMacroTask = macro_tasks.find(
      (macroTask) => macroTask._id === newMacroTask
    );
    onChangeColor(selectedMacroTask.planning.color);
  };

  const actionActorsFields = [
    {
      name: ['deliverables', 'planning', 'macro_task'],
      rules: [{ required: true }],
      input: (
        <Select onChange={handleMacroTaskChange}>
          {macro_tasks?.map((macro_task) => (
            <Select.Option key={macro_task._id} value={macro_task._id}>
              {macro_task.description}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'assigned_groups'],
      input: (
        <Select mode="multiple">
          {actorsItem?.groups
            ?.filter((group) => !group.default)
            .map((group) => (
              <Select.Option key={group._id} value={group._id}>
                {group.name}
              </Select.Option>
            ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'assigned_actors'],
      input: (
        <Select mode="multiple">
          {actorsItem?.actors?.map((actor) => (
            <Select.Option key={actor._id} value={actor._id}>
              {`${actor.first_name} ${actor.last_name}`}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'workload'],
      input: <Input addonAfter={t('projects.form.deliverables.planning.day')} />
    }
  ].filter(Boolean);

  return actionActorsFields;
};

export default useActionActorsFields;
