import React from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useProjectContext from '../../../../../contexts/ProjectContext';
import useGlossarySelect from '../../fieldsItems/Inputs/useGlossarySelect';

/**
 * Custom hook for generating form fields for passive actors.
 *
 * @hook
 * @param {boolean} loading - Indicates whether the component is in a loading state.
 * @returns {Object} An object containing an array of passive actor form fields.
 */
const usePassiveActorFields = (loading) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const roleSelect = useGlossarySelect(
    project?.groupRole_list,
    loading,
    'role'
  );

  const passiveActorFields = [
    {
      name: ['actors_list', 'actor', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['actors_list', 'actor', 'last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['actors_list', 'actor', 'first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['actors_list', 'actor', 'code'],
      rules: [{ required: true }]
    },
    {
      name: ['actors_list', 'actor', 'role'],
      rules: [{ required: true }],
      input: roleSelect
    },
    {
      name: ['actors_list', 'actor', 'coefficient'],
      input: (
        <Input
          type="number"
          min={0}
          max={100}
          suffix={
            <Tooltip title={t('projects.form.tooltip.actor_utility')}>
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      )
    }
  ];

  return { passiveActorFields };
};

export default usePassiveActorFields;
