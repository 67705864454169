import React, { createContext, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useErrorMessage from '../../utils/ErrorMessage';
import useAuthContext from '../../contexts/AuthContext';
import { getSortedProjects } from '../Projects/Projects/utilsProject/projectSorter';

const BalanceSheet = createContext({});

export const BalanceSheetProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI, user, getUser } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [valuesState, setValuesState] = useState();
  const [visibleNone, setVisibleNone] = useState(false);

  const config = {
    onGetResource: {
      setFields: (data) => {
        return {
          ...data
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    }
  };
  const getProjects = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?status=V&status=V0&status=V1&status=VX&assignee=${user._id}&populate=assignee,created_by,balance_sheet`
      });
      setProjects(getSortedProjects(data, (await getUser()).selected_project));
    } catch (e) {
      setLoading(false);
      if (e.response) errorMessage(e.response.status);
    }
  }, []);

  const patchBalanceSheet = async (values, balanceId) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/balance-sheet/${balanceId}`,
        body: { ...values }
      });
      getProjects();
      setVisible(false);
      setVisibleNone(false);
    } catch (e) {
      setLoading(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  const deleteElement = async (type, gb, balanceId, idElement) => {
    setLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/balance-sheet/delete/${balanceId}/${type}/${gb}/${idElement}`,
        body: { delete: true }
      });

      getProjects();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <BalanceSheet.Provider
      value={{
        config,
        patchBalanceSheet,
        getProjects,
        projects,
        setProjects,
        loading,
        setLoading,
        visible,
        setVisible,
        deleteElement,
        valuesState,
        setValuesState,
        visibleNone,
        setVisibleNone
      }}
    >
      {children}
    </BalanceSheet.Provider>
  );
};

BalanceSheetProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

BalanceSheetProvider.defaultProps = {
  children: null
};

export default () => useContext(BalanceSheet);
