import moment from 'moment';

const rgbToHex = (color) => {
  const hex = color.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const getRandomHexColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  const hex = `#${rgbToHex(red)}${rgbToHex(green)}${rgbToHex(blue)}`;

  return hex;
};

export const formatProjectData = (projects) => {
  const newPerimeters = [];
  const newCustomers = [];
  const newManagers = [];
  const newFormattedProjects = [];

  (projects || []).forEach((project) => {
    if (!newPerimeters.some((p) => p._id === project.perimeter._id)) {
      newPerimeters.push(project.perimeter);
    }

    if (project.customer && !newCustomers.includes(project.customer))
      newCustomers.push(project.customer);

    if (project.assignee) {
      if (!newManagers.some((m) => m._id === project.assignee._id)) {
        newManagers.push(project.assignee);
      }
    }

    const formattedProject = {
      id: project._id,
      name: project.name,
      color: getRandomHexColor(),
      start_date: moment(project.created_at).startOf('day'),
      due_date:
        project.status === 'close' ||
        moment(project.created_at) < moment().startOf('day')
          ? moment(project.updated_at).startOf('day')
          : moment().startOf('day'),
      status: project.status,
      task: `${project._id}${project.name}`,
      description: project.name
    };

    newFormattedProjects.push(formattedProject);
  });
  return { newPerimeters, newCustomers, newManagers, newFormattedProjects };
};

export default formatProjectData;
