import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useProjectContext from '../../../../contexts/ProjectContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

/**
 * Custom hook for managing group fields in a form.
 *
 * hook
 * @param {boolean} loading - Indicates whether data is loading.
 * @param {Array} perimeterActors - An array of actors within the perimeter.
 * @param {Array} actors - An array of existing actors.
 * @param {Array} defaultGroupActors - An array of actors in the default group.
 * @returns {Object} An object containing group field configuration for a form.
 */
const useGroupFields = (
  loading,
  perimeterActors,
  actors,
  defaultGroupActors
) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const roleSelect = useGlossarySelect(
    project?.groupRole_list,
    loading,
    'role'
  );

  if (!defaultGroupActors) return {};

  const passiveActors =
    actors?.filter(
      (actor) =>
        !actor.user ||
        !perimeterActors.find(
          (perimeterActor) => perimeterActor._id === actor.user._id
        )
    ) || [];
  const formattedActors = [
    ...perimeterActors.map((perimeterActor) => {
      return (
        defaultGroupActors?.find(
          (actor) => actor.user?._id === perimeterActor._id
        ) || perimeterActor
      );
    }),
    ...passiveActors
  ];

  const groupFields = [
    {
      name: ['actors_list', 'group', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['actors_list', 'group', 'default'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['actors_list', 'group', 'name'],
      rules: [{ required: true }]
    },
    {
      name: ['actors_list', 'group', 'code'],
      input: <Input row={2} />
    },
    {
      name: ['actors_list', 'group', 'role'],
      input: roleSelect
    },
    {
      name: ['actors_list', 'group', 'coefficient'],
      input: (
        <Input
          type="number"
          min={0}
          max={100}
          suffix={
            <Tooltip title={t('projects.form.tooltip.group_utility')}>
              <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
        />
      )
    },
    {
      name: ['actors_list', 'group', 'actors'],
      input: (
        <Select mode="multiple">
          {formattedActors?.map((actor) => (
            <Select.Option key={actor._id} value={actor._id}>
              {`${actor.first_name} ${actor.last_name}`}
            </Select.Option>
          ))}
        </Select>
      )
    }
  ];

  return { groupFields };
};

export default useGroupFields;
