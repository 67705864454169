import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Steps, Form, Row } from 'antd';

import useBalanceSheetContext from './BalanceSheetContext';

const { TextArea } = Input;
const { Step } = Steps;

const BalanceSheetModalForm = ({ data, goodBad, type, config, index, id }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    patchBalanceSheet,
    setVisible,
    setValuesState,
    valuesState,
    setVisibleNone
  } = useBalanceSheetContext();
  const [current, setCurrent] = useState(0);

  const steps = [
    {
      title: t('balance_sheet.elements_analyzed'),
      content: (
        <Form.Item name={['elements_analyzed']}>
          <TextArea />
        </Form.Item>
      )
    },
    {
      title: t('balance_sheet.causes'),
      content: (
        <Form.Item name={['causes']}>
          <TextArea />
        </Form.Item>
      )
    },
    {
      title: t('balance_sheet.action_plan'),
      content: (
        <Form.Item name={['action_plan']}>
          <TextArea />
        </Form.Item>
      )
    }
  ];
  const setValues = () => {
    if (config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(data[type][goodBad][index])
          : data[type][goodBad][index]
      );
    }
  };

  useEffect(() => {
    if (config !== null) {
      (async () => {
        await setValues();
      })();
    }
  }, [goodBad]);

  const onChange = (_, values) => {
    setValuesState({ ...valuesState, ...values });
  };

  const next = (values) => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const handleSubmit = (values) => {
    const result = {
      ...data,
      [type]: {
        ...data[type],
        [goodBad]: [...data[type][goodBad], { ...valuesState }]
      }
    };

    patchBalanceSheet(result, data._id);
    setValuesState(null);
  };

  return (
    <>
      <Col span={24}>
        <Form
          name="balance_sheet"
          onFinish={(values) => {
            handleSubmit(values);
            form.resetFields();
          }}
          onValuesChange={(_, values) => onChange(_, values)}
          form={form}
        >
          <Steps type="navigation" size="small" current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          {steps[current].content}
          <Row justify="end">
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Précédent
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next(form.getFieldsValue())}
              >
                Suivant
              </Button>
            )}

            {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                Terminé
              </Button>
            )}
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                setVisible(false);
                setVisibleNone(false);
              }}
            >
              Annuler
            </Button>
          </Row>
        </Form>
      </Col>
    </>
  );
};

BalanceSheetModalForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  goodBad: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  config: PropTypes.oneOfType([PropTypes.object]),
  index: PropTypes.number,
  id: PropTypes.string
};
BalanceSheetModalForm.defaultProps = {
  data: null,
  config: null,
  index: null,
  id: null
};

export default BalanceSheetModalForm;
