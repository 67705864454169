/**
 * Sorts the projects based on the selected project
 *
 * @function
 * @param {Array} projectsToSort - The projects to sort
 * @param {String} selected_project - The selected project ID
 * @returns {Array} - The sorted projects
 */
export const getSortedProjects = (projectsToSort, selected_project) => {
  if (!projectsToSort) return [];
  if (!selected_project) return projectsToSort;
  const resultFilter = projectsToSort?.filter(
    (ele) => ele._id !== selected_project
  );
  const selectedProject = projectsToSort.find(
    (project) => project._id === selected_project
  );
  if (selectedProject) resultFilter.unshift(selectedProject);
  return resultFilter;
};
