import React, { useEffect, useState } from 'react';
import {
  Select,
  Input,
  DatePicker,
  InputNumber,
  Radio,
  Space,
  Form,
  Switch
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

/**
 * Custom hook for generating event fields configuration.
 *
 * @hook
 * @param {boolean} initialRepeat - Flag indicating initial repeat.
 * @param {boolean} disable - Flag indicating whether to disable date fields.
 * @returns {Array} Array of event fields configuration.
 */
const useEventFields = (initialRepeat, disable) => {
  const today = moment();
  const { t } = useTranslation();
  const [repeat, setRepeat] = useState();

  const handleRepeatChange = (e) => {
    setRepeat(e.target.value);
  };

  useEffect(() => {
    setRepeat(initialRepeat);
  }, [initialRepeat]);

  const timeUnits = ['day', 'week', 'month'];

  const eventFields = [
    {
      name: ['deliverables_plan_com', 'name'],
      rules: [{ required: true }],
      input: <TextArea style={{ minHeight: '125px' }} />
    },
    {
      noStyle: true,
      hidden: true,
      name: ['deliverables_plan_com', '_id']
    },
    {
      name: ['deliverables_plan_com', 'start_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={disable}
          format="DD-MM-YYYY"
          initialValues={today}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['deliverables_plan_com', 'due_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabled={disable}
          format="DD-MM-YYYY"
          initialValues={today}
          defaultPickerValue={moment()}
        />
      )
    },
    !disable && {
      name: ['deliverables_plan_com', 'repeat_event'],
      rules: [{ required: true }],
      input: (
        <Radio.Group onChange={handleRepeatChange}>
          <Radio value>{t('global.yes')}</Radio>
          <Radio value={false}>{t('global.no')}</Radio>
        </Radio.Group>
      )
    },
    !disable &&
      repeat && {
        name: ['deliverables_plan_com', 'repeat_text'],
        rules: [{ required: true }],
        input: (
          <Space.Compact>
            <Form.Item name={['deliverables_plan_com', 'repeat', 'value']}>
              <InputNumber />
            </Form.Item>
            <Form.Item name={['deliverables_plan_com', 'repeat', 'time_unit']}>
              <Select
                options={timeUnits.map((timeUnit) => {
                  return {
                    value: timeUnit,
                    label: t(`projects.form.deliverables_plan_com.${timeUnit}`)
                  };
                })}
              />
            </Form.Item>
          </Space.Compact>
        )
      },
    !disable &&
      repeat && {
        name: ['deliverables_plan_com', 'repeat', 'no_weekends'],
        label: 'deliverables_plan_com.repeat_no_weekends',
        input: <Switch defaultChecked />
      }
  ].filter(Boolean);

  return eventFields;
};

export default useEventFields;
