import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Row,
  Avatar,
  message as antdMessage,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  MailOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import { routes } from '../../utils/constants/routes';
import Datatable from '../../components/DataTable/Datatable';
import useProjectsColumns from './useProjectsColumns';

const ShowUser = () => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [userFetched, setUserFetched] = useState(null);
  const adminsRole = ['admins:ADMIN', 'admins:PERIMETER_ADMIN'];
  const isAdminOrAdminPerimeter = adminsRole.includes(user?.role);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}?populate=permission`
      });
      setUserFetched(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/users/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const resetPassword = async () => {
    try {
      await dispatchAPI('GET', { url: `/reset-pwd/${userFetched?.email}` });
      history.goBack();
      antdMessage.success(t('users.message.success.reset_pwd'));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const tables = [
    {
      columns: useProjectsColumns('assigned'),
      extra_query: `assignee=${id}`
    },
    {
      columns: useProjectsColumns('supervised'),
      extra_query: `supervisors=${id}`
    }
  ].map((item) => (
    <Col span={12} key={item.extra_query}>
      <Datatable
        path={`${routes.PROJECTS}${routes.PROJECTS}`}
        resourceName="projects"
        columns={item.columns}
        extraQuery={item.extra_query}
        customActionColumn
      />
    </Col>
  ));

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          userFetched?.status === 'ACTIVE' && (
            <>
              {isAdminOrAdminPerimeter ? (
                <Popconfirm
                  title={t('datatable.column.action.reset_password.title')}
                  okText={t('datatable.column.action.reset_password.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t(
                    'datatable.column.action.reset_password.cancel'
                  )}
                  onConfirm={resetPassword}
                  icon={<WarningOutlined />}
                >
                  <Button type="primary">
                    {`${t('buttons.reset_password')} `}
                    <MailOutlined />
                  </Button>
                </Popconfirm>
              ) : null}
              {user?.role === 'admins:ADMIN' && (
                <>
                  <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
                    <Button type="primary">
                      {`${t('buttons.edit')} `}
                      <EditOutlined />
                    </Button>
                  </Link>
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={deleteUser}
                    icon={<WarningOutlined />}
                  >
                    <Button type="link" danger>
                      {`${t('buttons.delete')} `}
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>
          )
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          >
            <Avatar
              size={64}
              src={userFetched && userFetched.photo ? userFetched.photo : ``}
              style={{ marginBottom: 30, marginLeft: 16 }}
            />
          </Skeleton>
        </Row>
        <Row>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList
              data={listContent(userFetched || {}, t)}
              translate
            />
          </Skeleton>
        </Row>
        <Row gutter={16}>
          {userFetched?.role === 'managers:PROJECT_MANAGER' && tables}
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowUser;
