import React from 'react';
import { Form, Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';

/**
 * Generates a Form.Item component with the specified configuration.
 *
 * @hook
 * @param {string} resource - The resource identifier for translations.
 * @param {Object} config - Configuration options for the Form.Item.
 * @param {string} config.label - The label for the Form.Item.
 * @param {string[]} config.name - The field name for the Form.Item.
 * @param {any} config.initialValue - The initial value for the Form.Item.
 * @param {string[]} config.dependencies - The dependencies for conditional rendering.
 * @param {boolean} config.hasFeedback - Indicates if the Form.Item has feedback.
 * @param {Array} config.rules - Validation rules for the Form.Item.
 * @param {React.Component} config.input - Custom input component.
 * @param {Object} config.layout - Layout configuration for the Form.Item.
 * @param {boolean} config.noLabel - Whether to hide the label.
 * @param {string} config.valuePropName - Prop name for passing the value.
 * @param {React.ReactNode} config.extra - Extra content for the Form.Item.
 * @param {boolean} config.noStyle - Whether to apply no styles to the Form.Item.
 * @param {boolean} config.hidden - Whether the Form.Item is hidden.
 * @param {boolean} config.priorityLabel - Priority label display.
 * @param {boolean} config.colon - Whether to display a colon after the label.
 * @param {string} config.tooltip - Tooltip content for the label.
 * @param {string[]} fieldKey - Field key for nested Form.Item components.
 * @returns {React.Component} The generated Form.Item component.
 */
const useGenerateFormItem = () => {
  const { t } = useTranslation();

  return (
    resource,
    {
      label,
      name,
      initialValue,
      dependencies,
      hasFeedback,
      rules,
      input,
      layout,
      noLabel,
      valuePropName,
      extra,
      noStyle,
      hidden,
      priorityLabel,
      colon,
      tooltip
    },
    fieldKey
  ) => (
    <Form.Item
      {...layout}
      key={fieldKey ? [...fieldKey, ...name] : name}
      name={fieldKey ? [...fieldKey, ...name] : name}
      noStyle={noStyle}
      hidden={hidden}
      initialValue={initialValue}
      dependencies={dependencies}
      colon={colon}
      label={
        !noLabel && tooltip ? (
          <Tooltip title={<div style={{ textAlign: 'center' }}>{tooltip}</div>}>
            <WarningOutlined />
            {!noLabel && !priorityLabel
              ? ` ${t(`${resource}.form.${label || name.join('.')}`)}`
              : label}
          </Tooltip>
        ) : (
          <>
            {!noLabel && !priorityLabel
              ? t(`${resource}.form.${label || name.join('.')}`)
              : label}
          </>
        )
      }
      hasFeedback={hasFeedback}
      rules={rules}
      valuePropName={valuePropName}
      extra={extra}
    >
      {input || <Input />}
    </Form.Item>
  );
};

export default useGenerateFormItem;
