import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tag, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { setVersionItem } from '../../../../utils/constants/setVersionItem';

/**
 * Component to display version event details in a Descriptions layout.
 *
 * @component
 * @param {string} title - The item title.
 * @param {Object} data - Version data.
 * @param {number} version - The version of the project item details.
 * @param {React.Element} extra - Additional content to display in the Descriptions layout header.
 * @returns {JSX.Element} - The event header card.
 */
const IndexItem = ({ title, data, version, extra }) => {
  const { t } = useTranslation();
  const event = data?.event;
  return event ? (
    <>
      <Descriptions
        column={4}
        extra={
          <>
            {setVersionItem(version - 1)}
            <Divider type="vertical" style={{ marginLeft: 16, height: 24 }} />
            {extra}
          </>
        }
        title={
          <span>
            {`${t(`projects.form.${title}`).toUpperCase()}: ${t(
              `wallet.subTitleList.${title}`
            )}`}
          </span>
        }
      >
        <Descriptions.Item label={t('projects.details.last_version')}>
          <Tag color="var(--secondaryColor)">
            {moment(event.date).format('DD-MM-YYYY')}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.event_type')}>
          <Tag color="blue">{event.type}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.wording')}>
          <Tag color="blue">{event.name}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.comment')}>
          {event.comment ? (
            event.comment
          ) : (
            <Tag color="red">{t('projects.details.no_comment')}</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  ) : (
    <Descriptions
      column={4}
      extra={setVersionItem(version - 1)}
      title={`${t(`projects.form.${title}`).toUpperCase()}: ${t(
        `wallet.subTitleList.${title}`
      )}`}
    />
  );
};

IndexItem.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.object],
    PropTypes.array
  ),
  version: PropTypes.number,
  extra: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})])
};

IndexItem.defaultProps = {
  title: null,
  data: null,
  version: null,
  extra: null
};

export default IndexItem;
