import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, Row, Button, Col, Card, message } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import EventsTableForPlanning from './EventsTableForPlanning';
import handleValuesChange from './fields/handleValuesChange';
import PlanningFields from './fields/planningFields/PlanningFields';
import { getItemVersion } from '../ManageVersion/utils';
import UtilityFields from './fields/UtilityFields/UtilityFields';
import useAuthContext from '../../../../../contexts/AuthContext';
import CommonFields from './fields/CommonFields/CommonFields';
import ManagePlanningForm from './Form';
import useFormValuesManager from './useFormValuesManger';

/**
 * Component for managing project planning details.
 *
 * @component
 * @param {Object} dataItems - The project manage_planing data.
 * @param {string} project - The project details.
 * @param {Object} config - Configuration for the data.
 * @param {Function} handleCancel - Callback function for cancel action.
 * @param {Function} handleSubmit - Callback function for submit action.
 * @param {string} purposeArg - The purpose argument.
 * @param {Object} version - The version object.
 * @returns {JSX.Element} - The manage_planning form.
 */
const ManagePlanning = ({
  dataItems,
  project,
  config,
  handleCancel,
  handleSubmit,
  purposeArg,
  version
}) => {
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [colorGantt, setColorGantt] = useState('#000');
  const [visibleEvents, setVisibleEvents] = useState(false);
  const [dateVariation, setDateVariation] = useState(0);
  const [linkedDates, setLinkedDates] = useState(false);
  const [enums, setEnums] = useState();

  const [configPlanningUtility, setConfigPlanningUtility] = useState({
    planning: false,
    utility: false
  });

  const {
    setValues,
    resetDates,
    resetBeneficiary,
    setDefaultValueIfPlanning
  } = useFormValuesManager(form, configPlanningUtility, config, dataItems);

  const onChangeColor = (value) => {
    form.setFieldsValue({
      deliverables: { planning: { color: value.hex || value } }
    });
    setColorGantt(value.hex || value);
  };

  const listItemsManage = {
    common: (
      <CommonFields
        purpose={purposeArg}
        planningUtitlityList={project?.planning_utility_list}
      />
    ),
    planning: enums && (
      <PlanningFields
        purposeArg={purposeArg}
        colorGantt={colorGantt}
        onChangeColor={onChangeColor}
        managePlanningItems={
          getItemVersion(project?.manage_planning, -1)?.content
        }
        actorsItem={getItemVersion(project?.actors, -1)?.content}
        initialValues={configPlanningUtility}
        dateVariation={dateVariation}
        linkedDates={linkedDates}
        setLinkedDates={setLinkedDates}
        resetDates={() => resetDates(setDateVariation)}
        deliverableStatuses={enums?.deliverables_status}
      />
    ),
    utility: enums && (
      <UtilityFields
        purpose={purposeArg}
        deliverablesFor={enums?.deliverables_for}
        actorsItem={getItemVersion(project?.actors, -1)?.content}
        initialUseFor={configPlanningUtility?.utility?.use_for}
        resetBeneficiary={(value) => resetBeneficiary(value, project)}
      />
    )
  };

  const getEnums = useCallback(async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', { url: '/projects/enums' });
      setEnums(data);
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
      setIsFieldsLoading(false);
    }
  }, [dispatchAPI]);

  useEffect(() => {
    (async () => {
      if (config !== null) {
        await setValues(setConfigPlanningUtility, setColorGantt);
      }
      await getEnums();
    })();
    setDefaultValueIfPlanning();
  }, []);

  useEffect(() => {
    if (configPlanningUtility.planning) setDefaultValueIfPlanning();
  }, [configPlanningUtility.planning]);

  const manageSubmit = (values) => {
    handleSubmit(
      values,
      dataItems,
      'manage_planning',
      'deliverables',
      handleCancel,
      version
    );
  };

  const handleChange = (changedValues, allValues) => {
    handleValuesChange(
      changedValues,
      allValues,
      configPlanningUtility.planning,
      setDateVariation,
      form,
      linkedDates
    );
  };

  return (
    <>
      <Spin spinning={isFieldsLoading}>
        <Row justify="center">
          <Col span={22}>
            <ManagePlanningForm
              form={form}
              listItemsManage={listItemsManage}
              configPlanningUtility={configPlanningUtility}
              setConfigPlanningUtility={setConfigPlanningUtility}
              handleChange={handleChange}
              manageSubmit={manageSubmit}
              handleCancel={handleCancel}
            />
          </Col>
          {dataItems && dataItems.events && (
            <Col span={2}>
              <Button
                style={{ color: visibleEvents ? 'var(--primaryColor)' : null }}
                onClick={() => setVisibleEvents(!visibleEvents)}
                icon={<ClockCircleOutlined />}
              />
              {visibleEvents && (
                <Card
                  style={{
                    padding: 0,
                    position: 'absolute',
                    maxHeight: 1000,
                    border: '0.1px solid',
                    marginTop: 5
                  }}
                >
                  <EventsTableForPlanning
                    dataSource={(dataItems && dataItems.events) || []}
                  />
                </Card>
              )}
            </Col>
          )}
        </Row>
      </Spin>
    </>
  );
};

ManagePlanning.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  project: PropTypes.shape({
    planning_utility_list: PropTypes.arrayOf(PropTypes.string),
    manage_planning: PropTypes.arrayOf(PropTypes.shape({})),
    actors: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  purposeArg: PropTypes.string,
  version: PropTypes.shape({}).isRequired
};

ManagePlanning.defaultProps = {
  dataItems: null,
  config: null,
  handleCancel: null,
  handleSubmit: null,
  purposeArg: null
};

export default ManagePlanning;
