import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

/**
 * A form component for actor activation requests.
 *
 * @component
 * @param {Object} props - React component props.
 * @param {boolean} props.visible - Whether the modal is visible.
 * @param {Function} props.setVisible - A function to set the modal's visibility.
 * @param {Function} props.handleSubmit - A function to handle the form submission.
 * @returns {JSX.Element} The ActorActivationForm component.
 */
const ActorActivationForm = ({ visible, setVisible, handleSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = ({ email }) => {
    handleSubmit(email);
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      title={t('projects.form.actors_list.actor.activation_request.title')}
      footer={false}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          label={t('projects.form.actors_list.actor.activation_request.email')}
          rules={[{ required: true }]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row
            justify="end"
            wrap={false}
            style={{
              position: 'relative'
            }}
          >
            <Col>
              <Button
                style={{ margin: '0 6px' }}
                type="link"
                danger
                onClick={onCancel}
              >
                <span>{t('buttons.cancel')}</span>
                <CloseOutlined />
              </Button>
            </Col>
            <Col>
              <Button type="add" htmlType="submit">
                <span>{t('buttons.validate')}</span>
                <CheckOutlined />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ActorActivationForm.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  handleSubmit: PropTypes.func
};

ActorActivationForm.defaultProps = {
  visible: false,
  setVisible: () => {},
  handleSubmit: () => {}
};

export default ActorActivationForm;
