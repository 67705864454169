import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

/**
 * A component for rendering a collection of filters.
 *
 * @component
 * @param {Object[]} filters - An array of filter objects to be rendered.
 * @param {ReactNode} filters[].content - The content of the filter to be displayed.
 * @returns {JSX.Element} A component for rendering filters.
 */
const ItemFilters = ({ filters }) => {
  return (
    <Row gutter={12} style={{ marginBottom: '28px' }}>
      {filters.map((filter) => (
        <Col key={filter.type}>{filter.content}</Col>
      ))}
    </Row>
  );
};

ItemFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({}))
};

ItemFilters.defaultProps = {
  filters: []
};

export default ItemFilters;
