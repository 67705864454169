import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Configurations from './Configurations';

const ConfigurationRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={Configurations} />
    </Switch>
  );
};
export default ConfigurationRouter;
