import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Radio, Row, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import usePassiveActorFields from './passiveActorFields';
import useGenerateFormItem from '../../../../../utils/GenerateFormItem';
import useGroupFields from '../../fieldsItems/groupFields';
import useProjectContext from '../../../../../contexts/ProjectContext';
import useActorFields from '../../fieldsItems/actorsFields';

/**
 * A React component for managing actor and group form content.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {Object} props.actors - ActorsItem containing data about actors and groups.
 * @param {Object} props.data - Initial data about the form content.
 * @param {Boolean} props.loading - Indicates whether the component is in a loading state.
 * @returns {JSX.Element} The rendered ActorsFormContent component.
 */
const ActorsFormContent = ({ actors, data, loading }) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const [isGroup, setIsGroup] = useState(data?.actors_list?.group);
  const [isPassive, setIsPassive] = useState(
    data && !data?.actors_list?.actor?.user
  );

  const generateFields = useGenerateFormItem();
  const defaultGroup = actors.groups.find((group) => group.default);
  const { actorFields } = useActorFields(
    loading,
    defaultGroup,
    actors.actors,
    data?.actors_list?.actor?.user
  );
  const { passiveActorFields } = usePassiveActorFields(loading);
  const { groupFields } = useGroupFields(
    loading,
    data?.actors_list?.group?.default
      ? project?.perimeter?.users
      : defaultGroup.actors,
    data?.actors_list?.group?.default ? actors.actors : null,
    defaultGroup.actors
  );

  const handleActorsTypeChange = ({ target: { value } }) => {
    setIsGroup(value === 'group');
  };

  const handleActorTypeChange = (checked) => {
    setIsPassive(checked);
  };

  useEffect(() => {
    setIsGroup(data?.actors_list?.group);
  }, [data?.actors_list?.group]);

  return (
    <>
      <Radio.Group
        options={['group', 'actor'].map((type) => {
          return {
            label: t(`projects.form.items.actors.${type}`),
            value: type
          };
        })}
        onChange={handleActorsTypeChange}
        value={isGroup ? 'group' : 'actor'}
        optionType="button"
        buttonStyle="solid"
      />
      {isGroup ? (
        (groupFields || []).map((field) => generateFields('projects', field))
      ) : (
        <>
          <Col span={24}>
            <Row align="middle">
              <div style={{ color: isPassive ? '#999' : '' }}>
                {t('projects.form.actors_list.actor.existing_user')}
              </div>
              <Switch
                defaultChecked={isPassive}
                onChange={handleActorTypeChange}
                style={{ margin: '16px' }}
              />
              <div style={{ color: isPassive ? '' : '#999' }}>
                {t('projects.form.actors_list.actor.open_fields')}
              </div>
            </Row>
          </Col>
          {isPassive
            ? passiveActorFields?.map((field) =>
                generateFields('projects', field)
              )
            : actorFields?.map((field) => generateFields('projects', field))}
        </>
      )}
    </>
  );
};

ActorsFormContent.propTypes = {
  actors: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    actors: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  data: PropTypes.shape({
    actors_list: PropTypes.shape({
      group: PropTypes.shape({
        default: PropTypes.bool
      }),
      actor: PropTypes.shape({
        user: PropTypes.string
      })
    })
  }),
  loading: PropTypes.bool
};

ActorsFormContent.defaultProps = {
  actors: [],
  data: {},
  loading: true
};

export default ActorsFormContent;
