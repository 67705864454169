import React from 'react';
import { Button, Input, InputNumber, Popover, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { SwatchesPicker } from 'react-color';
import { BgColorsOutlined } from '@ant-design/icons';

const { Option } = Select;

/**
 *
 * Custom hook that generates action detail fields.
 * @param {string[]} deliverableStatuses - Array of deliverable statuses.
 * @param {string} colorGantt - Color of the gantt.
 * @param {string} onChangeColor - Function to handle color change.
 * @param {boolean} isMacroTask - Boolean indicating if deliverable is a macro task.
 * @returns {Array} An array of fields.
 */
const useDetailsFields = (
  deliverableStatuses,
  colorGantt,
  onChangeColor,
  isMacroTask
) => {
  const { t } = useTranslation();

  const detailsFields = [
    {
      name: ['deliverables', 'planning', 'status'],
      rules: [{ required: true }],
      input: (
        <Select showSearch>
          {deliverableStatuses?.map((value) => (
            <Option key={value} value={value}>
              {t(`projects.form.deliverables.statuses.${value}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['deliverables', 'planning', 'percentage'],
      input: <InputNumber min={0} max={100} />
    },
    {
      name: ['deliverables', 'planning', 'color'],
      colon: false,
      priorityLabel: true,
      label: (
        <>
          <Button type="link" style={{ color: '#000' }}>
            {`${t('projects.form.deliverables.planning.color')} :`}
          </Button>
          <Popover
            content={
              <SwatchesPicker
                onChange={(value) => {
                  onChangeColor(value);
                }}
              />
            }
            trigger={isMacroTask ? 'hover' : 'none'}
          >
            <BgColorsOutlined
              style={{
                fontSize: 25,
                color: colorGantt
              }}
            />
          </Popover>
        </>
      ),
      input: <Input disabled={!isMacroTask} />
    },
    {
      name: ['deliverables', 'planning', 'piloting_mark'],
      valuePropName: 'checked',
      input: <Switch />
    },
    {
      name: ['deliverables', 'planning', 'gantt_mark'],
      valuePropName: 'checked',
      input: <Switch />
    }
  ].filter(Boolean);

  return detailsFields;
};

export default useDetailsFields;
