import React from 'react';
import { Divider, Popconfirm, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  ReloadOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { userRoles } from '../../utils/constants/tagColors';
import { routes } from '../../utils/constants/routes';

const iconSize = 18;

/**
 * Custom hook that returns the columns for the datatable.
 *
 * @hook
 * @param {Object} user - The current user.
 * @param {Function} removeUser - The function to remove a user from the group.
 * @param {String} status - The status of the users to display (active or archived).
 * @param {Function} reintegrateUser - The function to reintegrate a user.%%%
 * @return {Array} The columns for the datatable.
 */
const useColumns = (user, removeUser, status, reintegrateUser) => {
  const { t } = useTranslation();

  const actionColumn = [
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.USERS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {status === 'ACTIVE' ? (
            <>
              {record.role === 'actors:ACTOR' && user?.role !== 'admins:ADMIN' && (
                <>
                  <Divider type="vertical" />
                  <Link
                    to={{
                      pathname: `${routes.USERS}/edit/${record.key}`
                    }}
                  >
                    <EditOutlined style={{ fontSize: iconSize }} />
                  </Link>
                </>
              )}
              {user?._id !== record.key && (
                <>
                  <Divider type="vertical" />
                  <Popconfirm
                    title={t('perimeters.group.remove_user.title')}
                    okText={t('datatable.column.action.remove.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={() => removeUser(record.key)}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined
                      style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                      type="delete"
                    />
                  </Popconfirm>
                </>
              )}
            </>
          ) : (
            <>
              <Divider type="vertical" />
              <Tooltip title={t('users.reintegrate')}>
                <ReloadOutlined
                  style={{ fontSize: iconSize }}
                  onClick={() => reintegrateUser(record.key)}
                />
              </Tooltip>
            </>
          )}
        </div>
      )
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('_').length > 1
            ? `${r.split('_')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    }
  ];

  if (user?.role === 'admins:PERIMETER_ADMIN' || status === 'ARCHIVED') {
    columns.push(...actionColumn);
  }

  return columns;
};

export default useColumns;
