import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A component to display a federation value.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.federationValue - The value representing the federation.
 * @param {boolean} props.noStyle - If true, no additional styling will be applied.
 * @returns {JSX.Element} JSX element representing the federation indicator.
 */
const FederationIdicator = ({ federationValue, noStyle }) => {
  const { t } = useTranslation();
  return federationValue !== null && federationValue !== undefined ? (
    <span
      style={
        !noStyle
          ? {
              color: 'var(--secondaryColor)',
              marginRight: 10,
              fontWeight: 'bold'
            }
          : null
      }
    >
      {federationValue}
    </span>
  ) : (
    <Tag color="var(--primaryColor)">
      {t('projects.form.items.plan_com.deliverables_plan_com.no_federation')}
    </Tag>
  );
};

FederationIdicator.propTypes = {
  federationValue: PropTypes.number,
  noStyle: PropTypes.bool
};

FederationIdicator.defaultProps = {
  federationValue: null,
  noStyle: false
};

export default FederationIdicator;
