import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';
import ShowIndicators from '../home/ShowIndicators';
import MascotteRouge from '../../assets/images/PersoRouge.png';
import MascotteOrange from '../../assets/images/PersoOrange.png';
import MascotteVert from '../../assets/images/PersoVert.png';
import useDashboardContext from '../../contexts/DashboardContext';
import Actions from '../../components/Actions/Actions';
import IndicatorsDashboard from '../Dashboard/IndicatorsDashboard';
import ProjectHeader from '../Projects/ProjectHeader';
import { getItemVersion } from '../Projects/Projects/utilsProject/ManageVersion/utils';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const IndicatorWrapper = styled(Content)`
  padding: 15px;
  background: var(--clientColor);
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  color: var(--textColorSecondary);
`;

const ProjectDashBoard = () => {
  const { id } = useParams();
  const { user, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { updateResource } = useDashboardContext();
  const mascotteArray = [MascotteRouge, MascotteOrange, MascotteVert];
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState();

  const getProject = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?populate=assignee,created_by,perimeter`
      });
      setProject(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getProject();
  }, [getProject]);

  const lastManagePlanningVersion = getItemVersion(
    project?.manage_planning,
    -1
  );

  return isLoading ? (
    <Spin
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    />
  ) : (
    <Col>
      <ProjectHeader project={project} />
      <Content>
        <Row>
          <IndicatorWrapper>
            <ShowIndicators project={project} vue="actor" />
          </IndicatorWrapper>
          <Col
            xl={4}
            sm={0}
            xs={0}
            style={{
              display: 'flex',
              justifySelf: 'end'
            }}
          >
            <Button
              style={{
                background: '#D3D3D3',
                borderRadius: 5,
                height: '100%',
                width: '100%',
                marginLeft: 16,
                boxShadow: '0 2px 3px rgb(0 0 0 / 30%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              disabled
            >
              <img
                alt="Mascotte de CitizenCan"
                src={mascotteArray[project?.mascot_state]}
                height="150px"
              />
            </Button>
          </Col>
        </Row>
      </Content>
      <Row justify="space-between" gutter={24}>
        {[
          { title: 'action_done', purpose: 'finished' },
          { title: 'action_to_do', purpose: 'planned' }
        ].map((action) => (
          <Col xl={12} sm={24} xs={24} key={action.title}>
            <Actions
              id={project._id}
              status={project.status}
              title={action.title}
              purpose={action.purpose}
              data={lastManagePlanningVersion}
              versionIndex={project.manage_planning.length}
              assignee={project.assignee}
              actorId={user._id}
              updateResource={async (body) => {
                await updateResource(
                  { ...body, projectId: id },
                  lastManagePlanningVersion,
                  'manage_planning',
                  null,
                  () => getProject(id)
                );
              }}
            />
          </Col>
        ))}
      </Row>
      <Col>
        <IndicatorsDashboard data={project} purpose="actor" />
      </Col>
    </Col>
  );
};

export default ProjectDashBoard;
