import React from 'react';
import { Button, Col, Row, Divider, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useColumns from './Columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import { routes } from '../../../utils/constants/routes';
import usePerimeterContext from '../../../contexts/PerimeterContext';

const iconSize = 18;

const Perimeters = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    deletePerimeter,
    refresh,
    setRefresh,
    setPerimeter
  } = usePerimeterContext();

  setPerimeter();

  const actionColumn = [
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.PERIMETERS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {!record.default && (
            <>
              <Divider type="vertical" />
              <Link to={`${routes.PERIMETERS}/edit/${record.key}`}>
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
            </>
          )}

          <Divider type="vertical" />
          {user.role === 'admins:PERIMETER_ADMIN' && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() => {
                deletePerimeter(record.key);
                setRefresh(!refresh);
              }}
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                type="delete"
              />
            </Popconfirm>
          )}
        </div>
      ),
      fixed: 'rigth'
    }
  ];
  const columns = useColumns(actionColumn, user.role);
  const addButton =
    user.role === 'admins:PERIMETER_ADMIN' ? (
      <Col>
        <Row align="middle">
          <Link to={`${routes.PERIMETERS}/create`}>
            <Button type="primary">
              {`${t('buttons.create')}`}
              <PlusOutlined />
            </Button>
          </Link>
        </Row>
      </Col>
    ) : null;

  return user.role.includes('admins') ? (
    <SimpleResourceLandingLayout
      columns={columns}
      extraButtons={addButton}
      resourceName="perimeters"
      populate="users,assignee"
      resourceModelName="Perimeter"
      customActionColumn
      withUploadButton={false}
      forceRefresh={refresh}
      scroll={500}
    />
  ) : null;
};

export default Perimeters;
