import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying a utility chart that visualizes the project's utility variation across versions.
 *
 * @component
 * @param {Object} data - The project data containing utility information.
 * @param {string} purpose - The purpose of the chart.
 * @returns {JSX.Element} Utility component
 */
const Utility = ({ data, purpose }) => {
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState({});
  const cols = {
    time: {
      range: [0, 1]
    },
    pourcentage: {
      type: 'linear',
      tickCount: 5,
      ticks: ['0', '25', '50', '75', '100']
    }
  };
  const styles = {
    wrapper: {
      width: 800,
      height: 600,
      overflow: 'auto',
      textAlign: 'center'
    },
    mainTitle: {
      fontSize: 15,
      color: '#333',
      display: 'block',
      padding: 10
    },
    subTitle: {
      marginBottom: 50,
      fontSize: 13,
      color: '#bbb',
      display: 'block',
      padding: 5
    }
  };

  const getData = () => {
    const chartUtility = [];

    if (data.manage_planning.length > 1 || data.manage_planning[0] !== null) {
      data.manage_planning.slice(1).forEach((version) => {
        const result = [];
        (version.content || [])
          .filter((deli) => deli.utility)
          .forEach((element) => {
            result.push(element.utility?.coefficient);
          });
        if (result.length > 0)
          chartUtility.push({
            time: `V${version.index} (${moment(version.event.date).format(
              'DD-MM-YYYY'
            )})`,
            type: 'Coeff.',
            description: version?.event?.name,
            comment: version?.event?.comment,
            pourcentage:
              result.reduce((curr, prev) => curr + prev, 0) / result.length
          });
      });
      setDataChart(chartUtility);
    }
  };
  useEffect(() => {
    if (data) getData();
  }, [purpose, data]);

  return (
    <>
      {purpose && dataChart && dataChart.length > 0 ? (
        <div style={styles.wrapper}>
          <Chart
            theme="light"
            padding="auto"
            height={400}
            data={dataChart}
            ForceFit
            scale={cols}
          >
            <span className="main-title" style={styles.mainTitle}>
              Variation de l&apos;utilité du projet selon les versions
            </span>
            <span className="sub-title" style={styles.subTitle}>
              <Row>
                <Col style={{ marginBottom: 10 }} span={24}>
                  <span>
                    {`Item Utilité V1 du ${moment(
                      data.manage_planning[2]?.event?.date
                    ).format('DD-MM-YYYY')}`}
                  </span>
                </Col>
                <Col span={24}>
                  <span>{`Moyenne ${dataChart[0]?.pourcentage} %`}</span>
                </Col>
              </Row>
            </span>
            <Axis />
            <Tooltip shared />
            <Legend />
            <Axis name="time" />
            <Axis
              name="pourcentage"
              label={{
                formatter: (val) => `${val}%`
              }}
            />
            <Tooltip
              useHtml
              g2-tooltip={{
                boxShadow: 'none',
                color: '#fff',
                backgroundColor: 'var(--clientColor)'
              }}
              crosshairs={{
                type: 'y'
              }}
              style={{
                color: 'red'
              }}
            />
            <Geom
              type="line"
              position="time*pourcentage"
              size={2}
              color="type"
              shape="smooth"
            />
            <Geom
              tooltip={[
                'time*description*pourcentage*type*comment',
                (time, description, pourcentage, type, comment) => {
                  return {
                    name: `${comment}`,
                    title: `Event: ${description}`,
                    value: `${pourcentage} %`
                  };
                }
              ]}
              type="point"
              position="time*pourcentage"
              size={4}
              shape="circle"
              color="type"
              style={{
                stroke: '#fff',
                lineWidth: 1
              }}
            />
          </Chart>
        </div>
      ) : (
        <span>{t(`projects.show.indicators.error`)}</span>
      )}
    </>
  );
};

Utility.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  purpose: PropTypes.string
};

Utility.defaultProps = {
  data: null,
  purpose: null
};

export default Utility;
