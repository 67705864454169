import React from 'react';
import moment from 'moment';
import { DatePicker, Input } from 'antd';
import useProjectContext from '../../../../contexts/ProjectContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

const { TextArea } = Input;

const usePriorFields = (loading) => {
  const { project } = useProjectContext();
  const dateFormat = 'DD-MM-YYYY';
  const today = moment();

  const typeSelect = useGlossarySelect(project?.prior_list, loading, 'type');

  const priorFields = [
    {
      name: ['studies', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['studies', 'denomination'],
      rules: [{ required: true }],
      input: <Input row={4} />
    },
    {
      name: ['studies', 'type'],
      input: typeSelect
    },
    {
      name: ['studies', 'creation_date'],
      input: (
        <DatePicker
          initialValues={today}
          format={dateFormat}
          defaultPickerValue={moment()}
        />
      )
    },
    {
      name: ['studies', 'description'],
      input: <TextArea style={{ whiteSpace: 'pre-line' }} row={4} />
    }
  ];

  return { priorFields };
};

export default usePriorFields;
