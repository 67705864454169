import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

const useNoteFields = () => {
  const noteFields = [
    {
      name: ['note'],
      rules: [{ required: true }],
      input: <TextArea rows={4} />
    }
  ];

  return { noteFields };
};

export default useNoteFields;
