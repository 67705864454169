import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Modal to add users to a perimeter group
 *
 * @component
 * @param {boolean} visible Boolean to show the modal
 * @param {function} setVisible Function to set the visibility of the modal
 * @param {function} handleCancel Function to cancel the modal
 * @param {function} setPurpose Function to set the purpose of the modal
 * @param {function} handleSelectChange Function to handle the change of the select
 * @param {array} users Array of users
 * @param {object} perimeterAdmin Perimeter admin object
 * @returns {JSX} React component
 */
const AddUserModal = ({
  visible,
  setVisible,
  handleCancel,
  setPurpose,
  handleSelectChange,
  users,
  perimeterAdmin
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      closable
      destroyOnClose
      centered
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      title={t(`perimeters.form.perimeter_group.add`, {
        perimeterAdmin
      })}
      footer={[
        <Button
          key="cancel"
          style={{ margin: '0 6px' }}
          type="link"
          danger
          onClick={handleCancel}
        >
          <span>{`${t('buttons.cancel')} `}</span>
          <CloseOutlined />
        </Button>,
        <Button
          key="submit"
          type="add"
          htmlType="submit"
          onClick={() => setPurpose('add')}
        >
          <span>{`${t('buttons.save')} `}</span>
          <CheckOutlined />
        </Button>
      ]}
    >
      <span>{`${t('perimeters.form.email_search')} : `}</span>
      <Select
        mode="multiple"
        placeholder={t('perimeters.form.email_placeholder')}
        onChange={handleSelectChange}
        style={{ width: '300px', marginLeft: '16px' }}
        optionFilterProp="children"
      >
        {users?.map((u) => (
          <Select.Option key={u._id} value={u._id}>
            {u.email}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default AddUserModal;

AddUserModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  handleCancel: PropTypes.func,
  setPurpose: PropTypes.func,
  handleSelectChange: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ),
  perimeterAdmin: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired
};

AddUserModal.defaultProps = {
  visible: false,
  setVisible: () => {},
  handleCancel: () => {},
  setPurpose: () => {},
  handleSelectChange: () => {},
  users: []
};
