import React from 'react';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useFields from './useFields';

/**
 * ReassignProjectForm component handles the form for reassigning a project.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Object} props.form - Ant Design form instance for form control.
 * @param {Array} [props.perimeters] - An array of perimeters for selection.
 * @param {Array} [props.projectManagers] - An array of project managers for selection.
 * @param {Function} props.setVisible - Function to control the visibility of the form.
 * @param {Function} props.getProjects - Function to fetch projects after reassignment.
 * @param {Object} [props.project] - The project object being reassigned.
 * @returns {JSX.Element} - The rendered ReassignProjectForm component.
 */
const ReassignProjectForm = ({
  form,
  perimeters,
  setVisible,
  getProjects,
  project
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { assignee, perimeter } = project || {};
  const initialValues = {
    assignee: assignee?._id,
    perimeter: perimeter.default ? null : perimeter?._id
  };
  const fields = useFields(perimeters, { ...initialValues, assignee }, form);

  const onFinish = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${project._id}`,
        body: values
      });
      setVisible(false);
      await getProjects();
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      {fields.map((field) => generateFields('projects', field))}
    </Form>
  );
};

ReassignProjectForm.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  }).isRequired,
  perimeters: PropTypes.arrayOf(PropTypes.shape({})),
  setVisible: PropTypes.func.isRequired,
  getProjects: PropTypes.func.isRequired,
  project: PropTypes.shape({
    _id: PropTypes.string.isRequired
  })
};

ReassignProjectForm.defaultProps = {
  perimeters: null,
  project: null
};

export default ReassignProjectForm;
