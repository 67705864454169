import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import AddEventToFederation from '../../PlanComFederationUtils/AddEventToFederation';
import useEventFields from './useEventFields';
import useGenerateFormItem from '../../../../../../utils/GenerateFormItem';

/**
 * A component representing fields for plan_com events.
 * @component
 *
 * @param {boolean} initiaRepeat - Flag indicating initial repeat.
 * @param {boolean} disable - Flag indicating whether to disable date fields.
 * @param {Function} config - Object with onfiguration function.
 * @param {string} projectId - The project ID.
 * @param {Object} dataItems - Data items object.
 *
 * @returns {JSX.Element} PlanCom fields.
 */
const EventFields = ({
  initiaRepeat,
  disable,
  config,
  projectId,
  dataItems
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const eventFields = useEventFields(initiaRepeat, disable);
  const modalAddEventToDuplicate = useRef();

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>{generateFields('projects', eventFields[0])}</Col>
      <Col span={12}>
        <Row gutter={[16, 16]}>
          {eventFields.slice(1).map((field) => (
            <Col
              key={field.name[field.name.length - 1]}
              span={field.hidden ? 0 : 12}
            >
              {generateFields('projects', field)}
            </Col>
          ))}
          {disable && (
            <Col span={24}>
              <Row align="middle" justify="center">
                <span style={{ fontSize: 13, color: 'red' }}>
                  {t(
                    'projects.form.deliverables_plan_com.duplicate.duplicate_info'
                  )}
                </span>
                <Button
                  onClick={() =>
                    modalAddEventToDuplicate.current.toggleModal(
                      config.onGetResource.setFields(dataItems),
                      projectId
                    )
                  }
                >
                  {t('projects.form.items.plan_com.addPlanCom')}
                </Button>
              </Row>

              <AddEventToFederation
                destroyOnClose
                ref={modalAddEventToDuplicate}
              />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

EventFields.propTypes = {
  initiaRepeat: PropTypes.bool,
  disable: PropTypes.bool,
  config: PropTypes.shape({}),
  projectId: PropTypes.string,
  dataItems: PropTypes.shape({})
};

EventFields.defaultProps = {
  initiaRepeat: false,
  disable: false,
  config: null,
  projectId: '',
  dataItems: null
};

export default EventFields;
