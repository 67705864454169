import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Descriptions, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * A component to display project date range and provide an option to open an edit form.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.defaultMacroTask - The default macro task object with planning details.
 * @param {Function} props.generateForm - A function to generate an edit form.
 * @param {number} props.version - The selected managePlanning version.
 * @returns {JSX.Element} - A JSX Element representing the ProjectRange component.
 */
const ProjectRange = ({ defaultMacroTask, generateForm, version }) => {
  const { t } = useTranslation();
  const [visibleLife, setVisibleLife] = useState();

  const configLifeProject = {
    onGetResource: {
      setFields: (deliverable) => ({
        lifeProject: {
          _id: deliverable._id,
          planning: {
            _id: deliverable.planning._id,
            start_date: moment(deliverable.planning.start_date),
            due_date: moment(deliverable.planning.due_date)
          }
        }
      })
    }
  };

  const openLifeProject = () => {
    setVisibleLife(true);
  };
  const closeLifeProject = () => {
    setVisibleLife(false);
  };

  const { start_date, due_date } = defaultMacroTask?.planning || {};

  return visibleLife ? (
    generateForm(
      'edit',
      version,
      'manage_planning',
      closeLifeProject,
      defaultMacroTask,
      'lifeProject',
      configLifeProject
    )
  ) : (
    <Card onClick={openLifeProject}>
      <Descriptions size="small" column={2}>
        <Descriptions.Item label={t('projects.details.start_date')}>
          {start_date ? (
            moment(start_date).format('DD-MM-YYYY')
          ) : (
            <Tag>{t('projects.details.no_date')}</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t('projects.details.end_date')}>
          {due_date ? (
            moment(due_date).format('DD-MM-YYYY')
          ) : (
            <Tag>{t('projects.details.no_date')}</Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

ProjectRange.propTypes = {
  defaultMacroTask: PropTypes.shape({
    planning: PropTypes.shape({
      start_date: PropTypes.string,
      due_date: PropTypes.string
    })
  }).isRequired,
  generateForm: PropTypes.func,
  version: PropTypes.shape({}).isRequired
};

ProjectRange.defaultProps = {
  generateForm: () => {}
};

export default ProjectRange;
