import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Row } from 'antd';
import { EditIcon } from '../../../../../../../../../utils/constants/customIcon';
import ButtonDeleteAll from '../../../../../../utilsProject/ButtonDeleteAll';
import RiskCollapseHeader from './Header';
import RiskBody from './Body';

const { Panel } = Collapse;

/**
 * A collapsible component that displays detailed information about a risk item.
 *
 * @component
 * @param {Object} risk - The risk object containing risk details to be displayed.
 * @param {Object} project - The project object containing project details.
 * @param {Object} version - The version object containing version details.
 * @param {boolean} role - Controls components visibility according to user role.
 * @param {string} title - The type of the item.
 * @param {Function} openForm - A function to open the form for editing.
 * @param {Function} closeForm - A function to close the form.
 * @returns {JSX.Element} A JSX Element representing the RiskCollapse component.
 */
const RiskCollapse = ({
  risk,
  project,
  version,
  role,
  title,
  openForm,
  closeForm
}) => {
  return (
    <Collapse>
      <Panel
        style={{ marginBottom: 16 }}
        extra={
          role && (
            <Row justify="center" style={{ flexWrap: 'nowrap' }}>
              <Button
                disabled={project?.status === 'close'}
                onClick={() => openForm(risk._id, risk)}
                size="small"
                type="link"
                style={{
                  color: 'var(--secondaryColor)',
                  top: 2
                }}
              >
                <EditIcon />
              </Button>
              <ButtonDeleteAll
                purpose="matrice"
                projectId={project._id}
                title={title}
                titleId={version._id}
                matriceName="risk_list"
                matriceId={risk._id}
                handleCancel={closeForm}
                version={version}
              />
            </Row>
          )
        }
        header={<RiskCollapseHeader risk={risk} />}
      >
        <RiskBody risk={risk} />
      </Panel>
    </Collapse>
  );
};

RiskCollapse.propTypes = {
  risk: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  project: PropTypes.shape({ _id: PropTypes.string, status: PropTypes.string })
    .isRequired,
  version: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  role: PropTypes.bool,
  title: PropTypes.string.isRequired,
  openForm: PropTypes.func,
  closeForm: PropTypes.func
};

RiskCollapse.defaultProps = {
  role: false,
  openForm: () => {},
  closeForm: () => {}
};

export default RiskCollapse;
