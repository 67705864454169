/**
 * Get the latest version of an item from the list of versions.
 *
 * @param {Array} itemVersions - The list of item versions.
 * @returns {Object} - The latest version of the item.
 */
export const getLastItemVersion = (itemVersions) => {
  if (!itemVersions) return {};

  const lastVersion = itemVersions.reduce((lastItemVersion, itemVersion) => {
    if (itemVersion.index > lastItemVersion.index) {
      return itemVersion;
    }
    return lastItemVersion;
  }, itemVersions[0]);

  return lastVersion;
};

/**
 * Get the specific version of an item based on the target index.
 *
 * @param {Array} versions - The list of item versions.
 * @param {number} targetIndex - The target index of the version to retrieve.
 * @returns {Object|null} - The corresponding version or null if not found.
 */
export const getItemVersion = (versions, targetIndex) => {
  if (!versions || !versions.length) return null;
  if (targetIndex >= 0 && targetIndex < versions.length) {
    return versions.find((version) => version?.index === targetIndex);
  }
  if (targetIndex < 0 && Math.abs(targetIndex) <= versions.length) {
    versions.sort((a, b) => a?.index - b?.index);
    return versions[versions.length + targetIndex];
  }
  return null;
};

/**
 * Check if a given version is the last version of an item.
 *
 * @param {Array} itemVersions - The list of item versions.
 * @param {Object} itemVersion - The version of the item to check.
 * @returns {boolean} - true if the version is the last one, otherwise false.
 */
export const isLastItemVersion = (itemVersions, itemVersion) => {
  const lastItemVersion = getLastItemVersion(itemVersions);
  return lastItemVersion?.index === itemVersion?.index;
};
