import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Descriptions, Divider, Row, Tag } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ButtonDeleteAll from '../../utilsProject/ButtonDeleteAll';
import { EditIcon } from '../../../../../utils/constants/customIcon';
import ImportDocumentButton from '../../../../../components/ImportDocumentButton/ImportDocumentButton';

/**
 * Component to display a card for a prior study.
 *
 * @component
 * @param {object} prior - The prior study object.
 * @param {object} project - The project object containing project details.
 * @param {object} version - The version object containing version details.
 * @param {string} title - The type of the item.
 * @param {boolean} role - Controls components visibility according to user role.
 * @param {Function} setDataToModalFiles - Function to set data to modal files.
 * @param {Function} openForm - Function to open a form.
 * @param {Function} closeForm - Function to close a form.
 * @returns {JSX.Element} Component to display a card for a prior study.
 */
const PriorCard = ({
  prior,
  project,
  version,
  title,
  role,
  setDataToModalFiles,
  openForm,
  closeForm
}) => {
  const { t } = useTranslation();
  const { _id, files, type, creation_date, ...rest } = prior;

  const setSpan = (arg) => {
    if (arg === 'description') {
      return 2;
    }
    return 1;
  };

  return (
    <Card>
      <Descriptions
        extra={
          role && (
            <Row justify="space-around">
              <Button
                disabled={project?.status === 'close'}
                onClick={() => (role ? openForm(_id, prior) : null)}
                size="small"
                type="link"
                style={{
                  color: 'var(--secondaryColor)',
                  top: 2
                }}
              >
                <EditIcon />
              </Button>
              <Divider
                type="vertical"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  height: 30
                }}
              />
              {project?.perimeter?.attached_files && (
                <>
                  <ImportDocumentButton
                    count={files?.length}
                    disabled={project?.status === 'close'}
                    onClick={() => setDataToModalFiles(files || [], title, _id)}
                  />
                  <Divider
                    type="vertical"
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      height: 30
                    }}
                  />
                </>
              )}
              <ButtonDeleteAll
                purpose="matrice"
                projectId={project?._id}
                title={title}
                titleId={version._id}
                matriceName="studies"
                matriceId={_id}
                handleCancel={closeForm}
                version={version}
              />
            </Row>
          )
        }
        column={4}
        title={type}
        size="small"
      >
        {Object.entries(rest).map(([key, value]) => {
          return (
            <Descriptions.Item
              style={{ whiteSpace: 'pre-line' }}
              key={key}
              label={t(`projects.form.items.prior.studies.${key}`)}
              span={setSpan(key)}
            >
              {value}
            </Descriptions.Item>
          );
        })}
        <Descriptions.Item
          label={t(`projects.form.items.prior.studies.creation_date`)}
        >
          <Tag>{moment(creation_date).format('DD-MM-YYYY')}</Tag>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

PriorCard.propTypes = {
  prior: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    creation_date: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  project: PropTypes.shape({
    status: PropTypes.string,
    _id: PropTypes.string,
    perimeter: PropTypes.shape({
      attached_files: PropTypes.bool
    })
  }).isRequired,
  version: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  title: PropTypes.string,
  role: PropTypes.bool,
  setDataToModalFiles: PropTypes.func,
  openForm: PropTypes.func,
  closeForm: PropTypes.func
};

PriorCard.defaultProps = {
  title: '',
  role: false,
  setDataToModalFiles: () => {},
  openForm: () => {},
  closeForm: () => {}
};

export default PriorCard;
