import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Axis, Tooltip, Geom, Coord } from 'bizcharts';

const RiskMatrixChart = ({ dataChart, width, height }) => {
  const cols = {
    value: {
      min: 0,
      max: 120
    }
  };
  return (
    <Chart
      width={width}
      height={height}
      padding="auto"
      data={dataChart || null}
      autoFit
      scale={cols}
    >
      <Coord type="polar" radius={0.8} />
      <Axis
        name="item"
        line={null}
        tickLine={null}
        grid={{
          lineStyle: {
            lineDash: null
          },
          hideFirstLine: false
        }}
      />
      <Tooltip />
      <Axis
        name="value"
        line={null}
        tickLine={null}
        grid={{
          type: 'circle',
          lineStyle: {
            lineDash: null
          },
          alternateColor: 'rgba(0, 0, 0, 0.04)'
        }}
      />
      <Geom type="area" position="item*value" color="type" />
      <Geom type="line" position="item*value" color="type" size={2} />
    </Chart>
  );
};
RiskMatrixChart.propTypes = {
  dataChart: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  width: PropTypes.number,
  height: PropTypes.number
};

RiskMatrixChart.defaultProps = {
  dataChart: [],
  width: 500,
  height: 250
};

export default RiskMatrixChart;
