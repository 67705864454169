import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { generateFilterSelect } from '../../../utils/GenerateFilterItem';

const ProjectsFilters = ({
  setSelectedCustomer,
  setSelectedManager,
  setSelectedVersion,
  customers,
  managers,
  versions
}) => {
  const { t } = useTranslation();

  return (
    <Row className="custom-header">
      <Col>
        {generateFilterSelect(
          customers,
          t('perimeters.filter.customer'),
          setSelectedCustomer
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          managers,
          t('perimeters.filter.manager'),
          setSelectedManager,
          (el) => `${el.first_name} ${el.last_name}`
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          versions,
          t('projects.status.ALL'),
          setSelectedVersion,
          (el) => t(`projects.status.${el}`)
        )}
      </Col>
    </Row>
  );
};

export default ProjectsFilters;

ProjectsFilters.propTypes = {
  setSelectedCustomer: PropTypes.func.isRequired,
  setSelectedManager: PropTypes.func.isRequired,
  setSelectedVersion: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.string),
  managers: PropTypes.arrayOf(PropTypes.shape({})),
  versions: PropTypes.arrayOf(PropTypes.string)
};

ProjectsFilters.defaultProps = {
  customers: [],
  managers: [],
  versions: ['V0', 'V1', 'VX']
};
