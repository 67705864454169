import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import { routes } from '../../utils/constants/routes';

const iconSize = 18;

/**
 * Custom hook that returns the columns for the datatable.
 *
 * @hook
 * @param {String} title - The title of the datatable.
 * @return {Array} The columns for the datatable.
 */
const useProjectsColumns = (title) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t(`projects.details.${title}`),
      dataIndex: 'name',
      render: null,
      sorter: true
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.PROJECTS}${routes.PROJECTS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
        </div>
      )
    }
  ];

  return columns;
};

export default useProjectsColumns;
