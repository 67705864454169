import React from 'react';
import PropTypes from 'prop-types';
import ActorCard from './ActorCard';

/**
 * A component that displays actor data using either an editable form or an actor card.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.actorData - The actor data object.
 * @param {boolean} [props.role=false] - Controls component visibility according to use role.
 * @param {object} props.project - The project object.
 * @param {object} props.version - The version object.
 * @param {string} props.title - The title for the component.
 * @param {string} props.visible - The ID of the visible actor.
 * @param {function} props.openForm - Function to open the form.
 * @param {function} props.closeForm - Function to close the form.
 * @param {function} props.generateForm - Function to generate the form.
 * @returns {JSX.Element} - A JSX element displaying actor data or an editable form.
 */
const ActorDisplay = ({
  actorData,
  role,
  project,
  version,
  title,
  visible,
  openForm,
  closeForm,
  generateForm
}) => {
  const config = {
    onGetResource: {
      setFields: (actor) => {
        return {
          actors_list: {
            actor: { ...actor, user: actor.user?._id }
          }
        };
      }
    }
  };

  return (
    <>
      {visible === actorData?._id ? (
        generateForm(
          'edit',
          version,
          title,
          closeForm,
          actorData,
          'actors_list',
          config
        )
      ) : (
        <ActorCard
          actorData={actorData}
          role={role}
          project={project}
          version={version}
          openForm={openForm}
          closeForm={closeForm}
        />
      )}
    </>
  );
};

ActorDisplay.propTypes = {
  actorData: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    user: PropTypes.shape({ _id: PropTypes.string.isRequired })
  }).isRequired,
  role: PropTypes.bool,
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  visible: PropTypes.string,
  openForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  generateForm: PropTypes.func
};

ActorDisplay.defaultProps = {
  role: false,
  title: null,
  visible: null,
  generateForm: null
};

export default ActorDisplay;
