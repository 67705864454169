import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider, message, Popconfirm, Table } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const iconSize = 18;
const Datatable = ({
  resourceName,
  path,
  columns,
  customActionColumn,
  populate,
  style,
  extraQuery,
  forceRefresh,
  scroll,
  disableDoubleClick,
  customRoute
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const { user, setUser, dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const params = new URLSearchParams(location.search);
  const searchValue = params.get('k');
  const currentPage = Number(params.get('p') || 1);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [visibleRole, setVisibleRole] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const getPageSize = () => {
    let pageS = params.get('pS') || 10;
    if (user?.page_sizes) {
      user.page_sizes.forEach((page) => {
        if (page?.page_name === resourceName) {
          pageS = page.size;
        }
      });
    }
    setPageSize(Number(pageS));
  };

  const [pagination, setPagination] = useState({
    hideOnSinglePage: false,
    pageSize: 10,
    total: 0,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} sur ${total} éléments`,
    showSizeChanger: true
  });

  const fetchData = useCallback(
    async (page = pagination) => {
      setIsLoading(true);
      const searchURL = searchValue ? `/search/${searchValue}` : null;

      let sortingParameter;
      if (currentSorter) sortingParameter = `sort=${currentSorter}&`;
      let filterParameter;
      if (currentFilters)
        filterParameter = `${currentFilters.replaceAll('__', '&')}`;

      try {
        const { data, headers } = await dispatchAPI('GET', {
          url: `/${resourceName}${customRoute ?? ''}${searchURL || ''}?${
            extraQuery ? `${extraQuery}&` : ''
          }${sortingParameter || ''}${filterParameter || ''}${
            populate ? `populate=${populate}&` : ''
          }limit=${pageSize}&skip=${(currentPage - 1) * pageSize}`
        });
        setPagination({
          ...page,
          total: parseInt(headers['x-total-count'], 10)
        });
        setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
      } catch (e) {
        message.error(
          e.response ? t(`errors.message.${e.response.status}`) : e.message
        );
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line
    [
      searchValue,
      pageSize,
      currentPage,
      currentSorter,
      currentFilters,
      forceRefresh,
      extraQuery
    ]
  );

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/${resourceName}/${id}` });
      await fetchData();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const updateUserPageSize = async (size) => {
    const page_sizes = user.page_sizes || [];
    page_sizes.filter((page) => page.page_name !== resourceName);
    page_sizes.push({ page_name: resourceName, size });
    try {
      const newUser = { ...user, page_sizes };
      setUser(newUser);
      await dispatchAPI('PATCH', {
        url: `/users/${user._id}`,
        body: { page_sizes }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      if (e.response) message.error(e.response.data.message);
    }
  };

  const handlePageChange = (page, filters, sorters = {}) => {
    let sortingParameter;
    if (sorters) {
      if (!sorters.order) {
        sortingParameter = null;
      } else if (sorters.order === 'descend') {
        sortingParameter = `&s=-${sorters.columnKey}`;
      } else {
        sortingParameter = `&s=${sorters.columnKey}`;
      }
    }
    let filterParameter = '';
    Object.entries(filters || {}).forEach((el) => {
      if (el[1] && el[1].length) filterParameter += `${el[0]}=${[...el[1]]}__`;
    });
    history.push({
      pathname,
      search: `?p=${page.current}&pS=${page.pageSize}${sortingParameter || ''}${
        filterParameter ? `&f=${filterParameter}` : ''
      }${searchValue ? `&k=${searchValue}` : ''}`
    });
    updateUserPageSize(page?.pageSize);
  };

  const checkRole = () => {
    if (user.role === 'managers:PROJECT_MANAGER') setVisibleRole(true);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      checkRole();
    })();
  }, [fetchData]);

  useEffect(() => {
    getPageSize();
  }, [user, params]);

  const actionColumn = [
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${path || pathname}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {record.status !== 'V0' || visibleRole ? (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `${path || pathname}/edit/${record.key}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => deleteResource(record.key)}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          ) : null}
        </div>
      )
    }
  ];

  const adjustedColumns = columns.map((col) => {
    let order;
    let orderKey;
    if (currentSorter) {
      order = 'ascend';
      orderKey = currentSorter;
    }
    if (currentSorter && currentSorter.charAt(0) === '-') {
      order = 'descend';
      orderKey = currentSorter.substring(1);
    }
    const filters = {};
    if (currentFilters) {
      const filtersList = currentFilters.split('__');
      filtersList.forEach((f) => {
        if (f.split('=').length) {
          const [key, values] = f.split('=');
          filters[key] = values;
        }
      });
    }

    return {
      ...col,
      ...(col.dataIndex === orderKey || col.key === orderKey
        ? {
            sortOrder: order
          }
        : { sortOrder: false }),
      ...(filters[col.dataIndex] || filters[col.key]
        ? { filteredValue: filters[col.dataIndex || col.key].split(',') }
        : { filteredValue: [] })
    };
  });

  return (
    <>
      <Table
        style={style}
        rowClassName="rowStyle"
        onRow={({ key }) => ({
          onDoubleClick: !disableDoubleClick
            ? () =>
                history.push({
                  pathname: `${path || pathname}/show/${key}`
                })
            : undefined
        })}
        dataSource={resources}
        loading={isLoading}
        onChange={handlePageChange}
        pagination={{ ...pagination, current: currentPage, pageSize }}
        columns={
          customActionColumn
            ? adjustedColumns
            : [...adjustedColumns, ...actionColumn]
        }
        scroll={{ x: scroll }}
      />
      <style>
        {`
          .rowStyle {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

Datatable.propTypes = {
  resourceName: PropTypes.string.isRequired,
  customRoute: PropTypes.string,
  path: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      dataIndex: PropTypes.string,
      render: PropTypes.func,
      sorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.string
        })
      )
    })
  ),
  customActionColumn: PropTypes.bool,
  populate: PropTypes.string,
  style: PropTypes.shape({}),
  extraQuery: PropTypes.string,
  forceRefresh: PropTypes.bool,
  scroll: PropTypes.number,
  disableDoubleClick: PropTypes.bool
};

Datatable.defaultProps = {
  customRoute: '',
  path: null,
  columns: [],
  customActionColumn: false,
  populate: null,
  style: null,
  extraQuery: null,
  forceRefresh: null,
  scroll: null,
  disableDoubleClick: false
};

export default Datatable;
