import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Descriptions, Card, Row, Col, Button, Tag, Divider } from 'antd';
import { EditIcon } from '../../../../utils/constants/customIcon';
import ImportDocumentButton from '../../../../components/ImportDocumentButton/ImportDocumentButton';

/**
 * Component for displaying the "What" section of a project version.
 *
 * @component
 * @param {Object} version - The version object containing the What item.
 * @param {string} title - The type of the item.
 * @param {Function} getProject - Function to fetch project data.
 * @param {Function} generateForm - Function to generate form for actors.
 * @param {boolean} role - Indicates if the user has a certain role.
 * @param {object} project - The project.
 * @returns {JSX.Element} - The rendered component for displaying actors and groups.
 */
const WhatDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  setDataToModalFiles,
  getFilesByItem,
  project
}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const openForm = () => {
    setVisible(true);
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  useEffect(() => {
    getFilesByItem(version.content?.files || []);
  }, [project]);

  return (
    <>
      {visible ? (
        <Card style={{ width: '100%' }}>
          {generateForm('edit', version, title, closeForm, version?.content)}
        </Card>
      ) : (
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <Descriptions
                extra={
                  role && (
                    <Row justify="space-around" align="middle">
                      {project?.perimeter?.attached_files && (
                        <>
                          <ImportDocumentButton
                            count={version?.content?.files?.length}
                            disabled={project?.status === 'close'}
                            onClick={() =>
                              setDataToModalFiles(
                                version?.content?.files || [],
                                title,
                                version?.content?._id
                              )
                            }
                          />
                          <Divider
                            type="vertical"
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              height: 30
                            }}
                          />
                        </>
                      )}
                      <Button
                        disabled={project?.status === 'close'}
                        onClick={() => (role ? openForm() : null)}
                        size="small"
                        type="link"
                        style={{
                          color: 'var(--secondaryColor)',
                          top: 2
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Row>
                  )
                }
              >
                <Descriptions.Item
                  contentStyle={{
                    flexDirection: 'column',
                    whiteSpace: 'pre-line'
                  }}
                  label={t('projects.details.description')}
                >
                  {version?.content?.description || (
                    <Tag>{t('projects.details.no_description')}</Tag>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

WhatDisplay.propTypes = {
  version: PropTypes.shape({
    content: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(PropTypes.string)
    })
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  setDataToModalFiles: PropTypes.func,
  getFilesByItem: PropTypes.func,
  project: PropTypes.shape({
    status: PropTypes.string,
    perimeter: PropTypes.shape({
      attached_files: PropTypes.bool
    })
  })
};

WhatDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  setDataToModalFiles: () => {},
  getFilesByItem: () => {},
  project: null
};

export default WhatDisplay;
