import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A button component for actor activation requests with conditional icons and tooltips.
 *
 * @param {Object} props - React component props.
 * @param {string} props.status - The status of the activation request ('pending' or other).
 * @param {Function} props.sendActivationRequest - A function to handle sending an activation request.
 * @param {Function} props.cancelActivationRequest - A function to handle canceling an activation request.
 * @returns {JSX.Element} The ActorActivationButton component.
 */
const ActorActivationButton = ({
  status,
  sendActivationRequest,
  cancelActivationRequest
}) => {
  const { t } = useTranslation();

  const getTooltipTitle = () => {
    switch (status) {
      case 'pending':
        return t('projects.form.actors_list.actor.activation_request.cancel');
      default:
        return t('projects.form.actors_list.actor.activation_request.send');
    }
  };

  const getIcon = () => {
    switch (status) {
      case 'pending':
        return (
          <CheckCircleOutlined
            style={{ fontSize: '18px' }}
            onClick={cancelActivationRequest}
          />
        );
      default:
        return (
          <UserAddOutlined
            style={{ fontSize: '18px' }}
            onClick={sendActivationRequest}
          />
        );
    }
  };

  return <Tooltip title={getTooltipTitle()}>{getIcon()}</Tooltip>;
};

ActorActivationButton.propTypes = {
  status: PropTypes.string,
  sendActivationRequest: PropTypes.func,
  cancelActivationRequest: PropTypes.func
};

ActorActivationButton.defaultProps = {
  status: '',
  sendActivationRequest: () => {},
  cancelActivationRequest: () => {}
};

export default ActorActivationButton;
