import React from 'react';
import { Row, Col, Tag } from 'antd';
import { getItemVersion } from '../../Projects/Projects/utilsProject/ManageVersion/utils';

const useFederation = (data, translate) => {
  const lastFederationVersion = getItemVersion(data?.plan_com, -1);
  const allFederation = [];
  const allImpact = [];
  if (lastFederationVersion?.content) {
    (lastFederationVersion.content || []).forEach((event) => {
      if (event.federation && event.impact) {
        allFederation.push(event?.federation * event?.impact);
        allImpact.push(event?.impact);
      }
    });
    if (allFederation.length > 1)
      return (
        <Row style={{ marginBottom: 8 }} justify="start">
          <Col span={24}>
            <span
              style={{
                textTransform: 'uppercase',
                letterSpacing: '.10em',
                fontSize: 10,
                color: '#8c8c8c'
              }}
            >
              {translate(`projects.details.membership`)}
            </span>
          </Col>
          <Col span={24}>
            {`${Math.round(
              allFederation.reduce((a, b) => a + b, 0) /
                allImpact.reduce((a, b) => a + b, 0)
            )} %`}
          </Col>
        </Row>
      );
  }

  return (
    <>
      <Col>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {translate(`projects.details.membership`)}
        </span>
        <Tag color="orange" style={{ marginRight: 16 }}>
          Non saisi
        </Tag>
      </Col>
    </>
  );
};

export default useFederation;
