import React from 'react';
import useSearchFilter from '../../../../../../utilsProject/Filters/useSearchFilter';
import useRangeFilter from '../../../../../../utilsProject/Filters/useRangeFilter';
import ItemFilters from '../../../../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing and applying filters to planCom information.
 *
 * @hook
 * @returns {Object} - An object containing functions for rendering filters and applying filters.
 */
const usePlanComFilters = () => {
  const { getSearchFilter, getSearchedValues } = useSearchFilter();
  const { getRangeFilter, getRangeFilteredRange } = useRangeFilter();

  const getPlanComFilters = () => {
    return <ItemFilters filters={[getSearchFilter(), getRangeFilter()]} />;
  };

  const getFilteredPlanComs = (planComs) => {
    if (!planComs) return [];
    return getRangeFilteredRange(
      getSearchedValues(planComs, (planCom) => planCom?.name),
      (planCom) => [planCom?.start_date, planCom?.due_date]
    );
  };

  return { getPlanComFilters, getFilteredPlanComs };
};

export default usePlanComFilters;
