import React from 'react';
import useAuthContext from '../../contexts/AuthContext';
import ProjectsGantt from '../Projects/Projects/projectGantt/ProjectsGantt';
import { routes } from '../../utils/constants/routes';

/**
 * Displays a Gantt chart of projects supervised by the currently authenticated user.
 *
 * @component
 * @returns {JSX.Element} The rendered Gantt chart for supervised projects.
 */
const ShowSupervisedProjects = () => {
  const { user } = useAuthContext();

  return (
    <ProjectsGantt
      purpose="supervisor"
      projectsQuery={`supervisors=${user._id}`}
      projectPath={`${routes.SUPERVISOR}${routes.SUPERVISOR}/show/`}
    />
  );
};

export default ShowSupervisedProjects;
