import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Col, Row, Table, Card } from 'antd';
import MemoForm from './MemoForm';

const MemoComponent = ({ data, getProject, idProject }) => {
  const { t } = useTranslation();
  const [dataToForm, setDataToForm] = useState();

  const columns = [
    {
      title: t('dashboard.column.object'),
      dataIndex: 'object',
      width: 280,
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text) => <a href="#">{text}</a>
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => moment(date).format('DD-MM-YYYY')
    }
  ];
  return (
    <Card
      style={{
        boxShadow: 'none',
        color: 'var(--textColor)',
        fontSize: '1.1em',
        padding: 0,
        margin: '16px 0 24px 0',
        borderRadius: 10,
        minHeight: 200,
        overflow: 'auto'
      }}
      bodyStyle={{ background: 'var(--itemHoverBackground)' }}
    >
      <Row justify="space-between">
        <Col span={18}>
          <MemoForm
            data={dataToForm}
            allData={data}
            id={idProject}
            setDataToForm={setDataToForm}
            getProjects={getProject}
          />
        </Col>
        <Col span={6}>
          <Table
            rowKey="_id"
            onRow={(record) => ({
              onClick: () => {
                setDataToForm(record);
              }
            })}
            scroll={{ y: 500 }}
            size="small"
            columns={columns}
            dataSource={data.sort((a, b) => {
              const c = new Date(a.date);
              const d = new Date(b.date);
              return c - d;
            })}
          />
        </Col>
      </Row>
    </Card>
  );
};

MemoComponent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]),
  getProject: PropTypes.func.isRequired,
  idProject: PropTypes.string
};

MemoComponent.defaultProps = {
  data: [],
  idProject: null
};

export default MemoComponent;
