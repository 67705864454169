import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A component that displays the details of a risk.
 *
 * @component
 * @param {Object} risk - The risk object containing risk details to be displayed.
 * @returns {JSX.Element} A JSX Element representing the RiskBody component.
 */
const RiskBody = ({ risk }) => {
  const { t } = useTranslation();
  const {
    _id,
    files,
    type_of_risk,
    number,
    potential_risk,
    impact_effect,
    decision_date,
    decision,
    measure_description,
    criticality,
    ...rest
  } = risk || {};
  return (
    <>
      <Descriptions column={3} size="small" style={{ padding: 12 }}>
        {Object.entries(rest).map(([key, value]) => {
          return (
            <Descriptions.Item
              key={key}
              label={t(`projects.form.items.risks.${key}`)}
            >
              {value}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
      <Descriptions column={4} size="small" style={{ padding: 12 }}>
        <Descriptions.Item label={t(`projects.form.items.risks.decision`)}>
          {decision}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          label={t(`projects.form.items.risks.measure_description`)}
        >
          {measure_description}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

RiskBody.propTypes = {
  risk: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.string),
    type_of_risk: PropTypes.string,
    number: PropTypes.number,
    potential_risk: PropTypes.string,
    impact_effect: PropTypes.string,
    decision_date: PropTypes.string,
    decision: PropTypes.string,
    measure_description: PropTypes.string,
    criticality: PropTypes.number
  })
};

RiskBody.defaultProps = {
  risk: {}
};

export default RiskBody;
