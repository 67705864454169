import React, { createContext, useContext, useState } from 'react';
import useErrorMessage from '../utils/ErrorMessage';
import useAuthContext from './AuthContext';
import { getSortedProjects } from '../routes/Projects/Projects/utilsProject/projectSorter';

const HomeContext = createContext({});

export const HomeContextProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI, user, getUser } = useAuthContext();
  const [projects, setProjects] = useState();
  const [loading, setLoading] = useState(false);

  const getUserRole = () => {
    if (user.role === 'admins:ADMIN') {
      return 'admin';
    }
    if (user.role === 'commercials:COMMERCIAL') {
      return `created_by=${user._id}&populate=assignee,created_by`;
    }
    if (user.role === 'managers:PROJECT_MANAGER') {
      return `status=V&status=V0&status=V1&status=VX&assignee=${user._id}&populate=assignee,created_by&sort=-updated_at`;
    }
  };

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects?${getUserRole()}`
      });
      setProjects(getSortedProjects(data, (await getUser()).selected_project));
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateResource = async (body, version, titleItem, field, func) => {
    try {
      const newBody = field ? body[field] : { ...body };
      if (!version && newBody._id)
        await dispatchAPI('PATCH', {
          url: `/projects/items/${field === 'event' ? 'event' : titleItem}/${
            newBody._id
          }`,
          body: newBody
        });
      else {
        if (titleItem === 'actors') newBody._id = version.content._id;
        if (newBody._id) {
          await dispatchAPI('PATCH', {
            url: `/projects/items/${titleItem}/${newBody._id}/${version._id}`,
            body: newBody
          });
        } else {
          await dispatchAPI('POST', {
            url: `/projects/items/${titleItem}/${version._id}`,
            body: newBody
          });
        }
      }
      func();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <HomeContext.Provider
      value={{
        updateResource,
        getProjects,
        projects,
        loading,
        setLoading,
        setProjects
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default () => useContext(HomeContext);
