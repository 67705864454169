import React from 'react';
import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { enums } from '../../../utils';

/**
 *
 * Custom hook that generates action type fields.
 * @param {string} setActionType - Function to set action type.
 * @param {string} purpose - Purpose argument indicating the use case.
 * @param {boolean} isMacroTask - Boolean indicating if deliverable is a macro task.
 * @returns {Array} An array of fields.
 */
const useActionTypeFields = (setActionType, purpose, isMacroTask) => {
  const { t } = useTranslation();
  const actionTypeFields = [
    isMacroTask && {
      name: ['deliverables', 'planning', 'default'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'planning', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'planning', 'action_type'],
      rules: [{ required: true }],
      input: (
        <Radio.Group
          disabled={purpose !== 'createMatrice'}
          onChange={(event) => {
            setActionType(event.target.value);
          }}
        >
          {Object.keys(enums.action_types).map((key) => (
            <Radio key={key} value={key}>
              {t(
                `projects.form.deliverables.planning.${enums.action_types[
                  key
                ].toLowerCase()}`
              )}
            </Radio>
          ))}
        </Radio.Group>
      )
    }
  ].filter(Boolean);

  return actionTypeFields;
};

export default useActionTypeFields;
