import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';

/**
 * Custom hook to generate form fields for reassigning project perimeter and project manager.
 *
 * @function
 * @param {Array} perimeters - An array of perimeters for selection.
 * @param {Object} initialValues - The initial values for the form.
 * @param {Object} form - Ant Design form instance for form control.
 * @returns {Array} - An array of form fields for reassignment.
 */
const useFields = (perimeters, initialValues, form) => {
  const { t } = useTranslation();
  const [managers, setManagers] = useState([initialValues.assignee]);
  const [isLoading, setIsLoading] = useState();
  const { dispatchAPI } = useAuthContext();

  const getManagers = async (perimeterId) => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=managers:PROJECT_MANAGER&perimeters=${perimeterId}`
      });
      setManagers(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  };

  const handlePerimeterChange = async (newPerimeterId) => {
    try {
      form.setFieldValue('assignee', null);
      await getManagers(newPerimeterId);
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  useEffect(() => {
    if (initialValues.perimeter) {
      (async () => {
        await getManagers(initialValues.perimeter);
      })();
    }
  }, [initialValues.perimeter]);

  useEffect(() => {}, [managers]);

  useEffect(() => {
    form.setFieldsValue({ assignee: initialValues.assignee?._id });
  }, []);

  const fields = [
    {
      name: ['perimeter'],
      input: (
        <Select
          placeholder={t('perimeters.form.search')}
          onChange={handlePerimeterChange}
        >
          {perimeters?.map(
            (perimeter) =>
              !perimeter.default && (
                <Select.Option key={perimeter._id} value={perimeter._id}>
                  {perimeter.name}
                </Select.Option>
              )
          )}
        </Select>
      )
    },
    {
      name: ['assignee'],
      input: (
        <Select
          disabled={
            !initialValues.perimeter && !form.getFieldValue('perimeter')
          }
          placeholder={t('projects.assignment_manager')}
          loading={isLoading}
        >
          {managers
            ?.filter((manager) => manager)
            .map((projectManager) => (
              <Select.Option
                key={projectManager._id}
                value={projectManager._id}
              >
                {`${projectManager.first_name} ${projectManager.last_name}`}
              </Select.Option>
            ))}
        </Select>
      )
    }
  ];

  return fields;
};

export default useFields;
