import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Table, Tag, Row, Col, Button, Upload, Spin } from 'antd';
import {
  CloseOutlined,
  FileSearchOutlined,
  CloudUploadOutlined
} from '@ant-design/icons';
import useProjectContext from '../../../../contexts/ProjectContext';

const ShowModalFilesByItem = ({ matriceData }) => {
  const {
    visibleToShowFiles,
    setVisibleToShowFiles,
    project,
    t,
    deleteFiles,
    displayFileMedia,
    onPreview,
    fileList,
    setFileList,
    onChangeImg,
    token,
    getProject,
    loadingMedia,
    dataFiles,
    title,
    idItem,
    setMatriceFiles
  } = useProjectContext();

  const columns = [
    {
      title: `${t(`projects.name`)}`,
      dataIndex: 'metadata',
      key: 'name',
      render: ({ originalName }) => originalName
    },
    {
      title: `${t(`projects.date`)}`,
      dataIndex: 'uploadDate',
      key: 'name',
      render: (date) => <Tag>{moment(date).format('DD MM YYYY')}</Tag>
    },
    {
      title: `${t(`projects.see`)}`,
      key: 'voir',
      render: ({ _id, contentType }) => (
        <Button
          type="primary"
          onClick={() => displayFileMedia(`/files/${_id}`, contentType)}
          icon={<FileSearchOutlined />}
        />
      )
    },
    {
      title: `${t(`buttons.delete`)}`,
      key: 'supprimer',
      render: (record) => (
        <Button
          onClick={() =>
            deleteFiles(
              record._id,
              project._id,
              title,
              idItem,
              matriceData?.matrice,
              matriceData?.id
            )
          }
          icon={<CloseOutlined />}
        />
      )
    }
  ];
  return (
    <Modal
      title={`${t(`projects.form.items.${title}.title`)} pour ${project.name}`}
      visible={visibleToShowFiles}
      closable
      destroyOnClose
      onCancel={() => setVisibleToShowFiles(false)}
      footer={false}
    >
      <Spin spinning={loadingMedia}>
        <Row>
          <Col style={{ marginBottom: 10, textAlign: 'center' }} span={24}>
            <Upload
              style={{ width: '100%', margin: 50 }}
              action={`${process.env.REACT_APP_API_URL}/files/project/${
                project._id
              }/${title}/${idItem}${
                matriceData ? `/${matriceData?.matrice}/${matriceData?.id}` : ''
              }`}
              data="text"
              headers={{ Authorization: `Bearer ${token}` }}
              listType="text"
              fileList={fileList}
              defaultFileList={[...fileList]}
              onChange={onChangeImg}
              onPreview={onPreview}
              onRemove={(file) => {
                deleteFiles(
                  file.response,
                  project._id,
                  title,
                  idItem,
                  matriceData?.matrice,
                  matriceData?.id
                );
              }}
            >
              <Button
                type="primary"
                disabled={fileList.length > 5}
                icon={<CloudUploadOutlined />}
              >
                {t('projects.upload_file')}
              </Button>
            </Upload>
          </Col>
          <Col
            style={{ marginBottom: 10, marginRight: 20, textAlign: 'center' }}
            span={24}
          >
            {fileList.length > 0 ? (
              <Button
                onClick={() => {
                  setFileList([]);
                  setMatriceFiles();
                  setVisibleToShowFiles(false);
                  getProject(project._id);
                }}
              >
                {t('projects.upload_done')}
              </Button>
            ) : null}
          </Col>
          <Col span={24}>
            {dataFiles && (
              <Table rowKey="_id" columns={columns} dataSource={dataFiles} />
            )}
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

ShowModalFilesByItem.propTypes = {
  matriceData: PropTypes.oneOfType([PropTypes.object])
};
ShowModalFilesByItem.defaultProps = {
  matriceData: undefined
};
export default ShowModalFilesByItem;
