import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Divider, Layout, Menu, Row, message } from 'antd';
import {
  GlobalOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdown from '../../HeaderDropdown/HeaderDropdown';
import useLanguageContext from '../../../contexts/LanguageContext';
import NotificationDropDown from './notifications/NotificationDropDown';

const { Header } = Layout;

const StyledHeader = styled.div`
  background: var(--bodyBackground);
  padding: 0;
  z-index: 8;
  width: 100%;
  position: fixed;
`;

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-items: center;
`;

const StyledFullNameSpan = styled.span`
  color: var(--textColor);
  font-size: 1.15em;
`;

const StyledRoleP = styled.p`
  font-size: 10px;
  color: var(--textColor);
  margin: 0;
  letter-spacing: 0.1em;
`;

const TriggerCollapse = styled.div`
  color: var(--textColor);
  font-size: 20px;
  padding: 0 24px;
  cursor: pointer;
  margin-left: ${(props) => props.marginleft};
  z-index: 10000;
  :hover {
    color: var(--primaryColor);
  }
`;

const HeaderLayout = ({
  collapseSider,
  collapsed,
  collapseWidth,
  themeLogo
}) => {
  const history = useHistory();
  const { user, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t, i18n } = useTranslation();
  const [marginTrigger, setMarginTrigger] = useState('0px');

  const languages = {
    en_GB: {
      label: 'English',
      icon: '🇬🇧'
    },
    fr_FR: {
      label: 'Français',
      icon: '🇫🇷'
    }
    // es_ES: {
    //   label: 'Español',
    //   icon: '🇪🇸'
    // }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success(t('login.logoutSuccess'));
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const test = () => {
    return (
      <Menu onClick={onLanguageMenuClick}>
        {Object.keys(languages).map((locale) =>
          i18n.language === locale ? (
            <Menu.Item key={locale} style={{ background: '#e0e0e0' }}>
              <span>{languages[locale].icon}</span>
              {` ${languages[locale].label}`}
            </Menu.Item>
          ) : (
            <Menu.Item key={locale}>
              <span>{languages[locale].icon}</span>
              {` ${languages[locale].label}`}
            </Menu.Item>
          )
        )}
      </Menu>
    );
  };

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      {window.innerWidth <= 992 && user && (
        <Menu.Item key="user">
          <Button
            style={{
              width: '100%',
              height: 50
            }}
            type="link"
          >
            <StyledFullNameSpan>
              {`${user.first_name} ${user.last_name}`}
            </StyledFullNameSpan>
            <StyledRoleP>
              {t(`users.tags.${user.role.split(':')[1]}`)}
            </StyledRoleP>
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key="profile" onClick={() => history.push('/profile')}>
        <UserOutlined
          style={{
            fontSize: 16,
            marginRight: 8
          }}
        />
        {t('header.menu.user.profile')}
      </Menu.Item>
      {/* <Menu.Item key="settings" onClick={() => history.push('/settings')}>
        <SettingOutlined
          style={{
            fontSize: 16
          }}
        />{' '}
        {t('header.menu.user.settings')}
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key="logout">
        <LogoutOutlined
          style={{
            fontSize: 16,
            marginRight: 8
          }}
        />
        {t('header.menu.user.logout')}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (collapsed) {
      if (collapseWidth === 0) {
        setMarginTrigger('0px');
      } else {
        setMarginTrigger('80px');
      }
    } else {
      setMarginTrigger('256px');
    }
  }, [collapsed, collapseWidth]);

  return (
    <StyledHeader as={Header}>
      {window.innerWidth < 992 && (
        <TriggerCollapse
          as={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined}
          marginleft={marginTrigger}
          onClick={() => collapseSider(!collapsed)}
        />
      )}
      {themeLogo && (
        <img
          style={{
            width: 100,
            maxHeight: 50,
            position: 'absolute',
            top: 10,
            left: '50%',
            marginLeft: '-50px'
          }}
          src={themeLogo}
          alt="Company logo"
        />
      )}
      <StyledContainer
        style={{
          paddingRight: '23px'
        }}
      >
        <NotificationDropDown />
        <Divider type="vertical" />
        <HeaderDropdown overlayMenu={profileMenu}>
          <Row className="styled-dropdown-container">
            {window.innerWidth <= 992 ? (
              <UserOutlined />
            ) : (
              <>
                <Avatar
                  size="medium"
                  icon={user && user.photo ? '' : <UserOutlined />}
                  src={user && user.photo ? user.photo : ''}
                >
                  {user && `${user.first_name[0]}${user.last_name[0]}`}
                </Avatar>
                <Button
                  type="link"
                  style={{
                    height: '50px',
                    marginLeft: '10px'
                  }}
                >
                  <StyledFullNameSpan>
                    {`${user.first_name} ${user.last_name}`}
                  </StyledFullNameSpan>
                  <StyledRoleP>
                    {t(`users.tags.${user.role.split(':')[1]}`)}
                  </StyledRoleP>
                </Button>
              </>
            )}
          </Row>
        </HeaderDropdown>
        <Divider type="vertical" />
        <HeaderDropdown overlayMenu={test}>
          <Row className="styled-dropdown-container">
            <GlobalOutlined
              style={{
                fontSize: 16
              }}
            />
          </Row>
        </HeaderDropdown>
      </StyledContainer>
    </StyledHeader>
  );
};

HeaderLayout.propTypes = {
  collapseSider: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  collapseWidth: PropTypes.number.isRequired,
  themeLogo: PropTypes.string
};

HeaderLayout.defaultProps = {
  themeLogo: null
};

export default HeaderLayout;
