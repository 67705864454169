import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useColumns from './columns';

/**
 * A component to display a table of repeated events.
 *
 * @component
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.events - An array of objects representing the events to be displayed.
 * @param {string} props.display - A string representing the display type of the table (e.g. past or scheduled).
 * @returns {JSX.Element} - A JSX Element representing the RepeatedEventTable.
 */
const RepeatedEventTable = ({ events, display }) => {
  const { t } = useTranslation();
  const columns = useColumns(display);

  const currentDate = moment();

  const filteredEvetns = events.filter((event) =>
    display === 'past'
      ? moment(event.start_date).isBefore(currentDate)
      : moment(event.start_date).isAfter(currentDate)
  );

  return (
    <Card>
      <Table
        title={() =>
          t(`projects.form.deliverables_plan_com.duplicate.${display}_events`)
        }
        pagination={false}
        columns={columns}
        dataSource={filteredEvetns}
        rowKey="_id"
      />
    </Card>
  );
};

RepeatedEventTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape({})),
  display: PropTypes.string
};

RepeatedEventTable.defaultProps = {
  events: [],
  display: 'past'
};

export default RepeatedEventTable;
