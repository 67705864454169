import moment from 'moment';

/**
 * Converts PlanCom data to chart data for rendering the Gantt chart.
 *
 * @param {Array} planCom - The PlanCom data.
 * @returns {Array} Chart data for Gantt chart
 */
export default (planCom) => {
  let chartData = [];
  if (planCom) {
    planCom.forEach((e) => {
      const { start_date, due_date, duplicates, color } = e;
      if ((start_date, due_date !== null) && duplicates?.length) {
        const result = e.duplicates.map((event) => ({
          ...event,
          description: e.name,
          status: e.name,
          task: `${e._id + e.name}`,
          start_date:
            start_date !== null &&
            moment(event.start_date).format('YYYY-MM-DD'),
          due_date: moment(event.due_date).format('YYYY-MM-DD'),
          init_start: moment(start_date).format('YYYY-MM-DD'),
          init_due: moment(due_date).format('YYYY-MM-DD'),
          color: color || '#000000',
          active: event.active,
          idEvent: e._id
        }));
        chartData = [...chartData, ...result];
      } else {
        const result = [
          {
            description: e.name,
            ...e,
            task: `${e._id + e.name}`,
            start_date:
              start_date !== null && moment(start_date).format('YYYY-MM-DD'),
            due_date: moment(due_date || start_date).format('YYYY-MM-DD'),
            color: color || '#000000',
            idEvent: e._id
          }
        ];
        chartData = [...chartData, ...result];
      }
    });
  } else {
    chartData = undefined;
  }
  return chartData;
};
