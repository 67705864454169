import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Switch, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../contexts/AuthContext';
import useColumns from './useColumns';
import { routes } from '../../utils/constants/routes';

/**
 * Table wih all users.
 *
 * @component
 * @return {JSX.Element} React component for the list of users.
 */
const ListUsers = () => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('ACTIVE');
  const history = useHistory();

  const removeUser = async (id) => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `users/${user._id}`,
        body: { usersArray: [id], purpose: 'remove' }
      });

      message.success(t(`perimeters.group.remove_user.success`));
      setLoading(false);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
      setLoading(false);
    }
  };

  const reintegrateUser = async (id) => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `users/reintegrate/${id}`
      });

      message.success(t(`users.message.success.reintegrate`));
      setLoading(false);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
      setLoading(false);
    }
  };

  const handleChange = (checked) => {
    setStatus(checked ? 'ACTIVE' : 'ARCHIVED');
    history.push({
      pathname: routes.USERS
    });
  };

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'Address street number',
      key: 'address.number'
    },
    {
      label: 'Address street name',
      key: 'address.street'
    },
    {
      label: 'Address additional information',
      key: 'address.additional'
    },
    {
      label: 'Address postal code',
      key: 'address.postal_code'
    },
    {
      label: 'Address city',
      key: 'address.city'
    },
    {
      label: 'Address country',
      key: 'address.country'
    }
  ];

  const columns = useColumns(user, removeUser, status, reintegrateUser);

  const addButton = user?.role.includes('admins:') && (
    <Link to="users/create">
      <Button type="add">
        {`${t('buttons.create')}`}
        &nbsp;
        <PlusOutlined />
      </Button>
    </Link>
  );

  const deletedUserSwitch = user?.role === 'admins:ADMIN' && (
    <Switch
      checkedChildren={t('users.status.ACTIVE')}
      unCheckedChildren={t('users.status.ARCHIVED')}
      defaultChecked={status}
      onChange={handleChange}
    />
  );

  const extraButtons = (
    <Row gutter={16} align="middle">
      <Col>{deletedUserSwitch}</Col>
      <Col>{addButton}</Col>
    </Row>
  );

  return (
    <SimpleResourceLandingLayout
      resourceName="users"
      customRoute={user?.role === 'admins:ADMIN' ? '' : '/perimeters'}
      extraQuery={`status=${status}`}
      extraButtons={extraButtons}
      columns={columns}
      headers={headers}
      resourceModelName="User"
      customActionColumn={
        user?.role === 'admins:PERIMETER_ADMIN' || status === 'ARCHIVED'
      }
      forceRefresh={loading}
    />
  );
};

export default ListUsers;
