import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateFilterSelect } from '../../../../../utils/GenerateFilterItem';

/**
 * Custom hook to create a select filter with options.
 *
 * @hook
 * @param {Array} options - The available options for the filter.
 * @param {string} type - The filter type (used for translation).
 * @param {Function} render - The rendering function to display each option.
 * @returns {Object} An object containing functions to get the select filter component
 *                   and to filter values based on the selected option.
 */
const useSelectFilter = (options, type, render) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();

  const getSelectFilter = () => {
    return {
      content: generateFilterSelect(
        options,
        t(`projects.items.filters.${type}`),
        setSelectedOption,
        render
      ),
      type
    };
  };

  const getFilteredValues = (values, getValueToCompare) => {
    if (!values) return [];
    return values.filter((value) => {
      return getValueToCompare(value) === selectedOption || !selectedOption;
    });
  };

  return { getSelectFilter, getFilteredValues };
};

export default useSelectFilter;
