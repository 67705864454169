import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Collapse, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IndexItem from '../Projects/Projects/utilsProject/IndexItem';
import WhatDisplay from '../Projects/Projects/ItemsDisplay/WhatDisplay';
import WhyDisplay from '../Projects/Projects/ItemsDisplay/WhyDisplay';
import PriorDisplay from '../Projects/Projects/ItemsDisplay/PriorDisplay/PriorDisplay';
import ContextDisplay from '../Projects/Projects/ItemsDisplay/ContextDisplay/ContextDisplay';
import UtilityDisplay from '../Projects/Projects/ItemsDisplay/UtilityDisplay/UtilityDisplay';
import CostDisplay from '../Projects/Projects/ItemsDisplay/CostDisplay/CostDisplay';
import PlanningDisplay from '../Projects/Projects/ItemsDisplay/PlanningDisplay/PlanningDisplay';
import PlanComDisplay from '../Projects/Projects/ItemsDisplay/PlanComDisplay/PlanComDisplay';
import RiskDisplay from '../Projects/Projects/ItemsDisplay/RiskDisplay/RiskDisplay';
import { setVersionItem } from '../../utils/constants/setVersionItem';
import ActorsDisplay from '../Projects/Projects/ItemsDisplay/ActorsDisplay/ActorsDisplay';

/**
 * Component to display a collapsible container with item details.
 *
 * @component
 * @param {string} title - The type of item.
 * @param {Object} data - Version data.
 * @param {Array} actors - Project actors.
 * @param {number} version - The version of the item container.
 * @param {Object} project - The project.
 * @returns {JSX.Element} - The generated item container component.
 */
const ItemContainer = ({ title, data, actors, version, project }) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const ItemCheckList = {
    what: WhatDisplay,
    why: WhyDisplay,
    prior: PriorDisplay,
    context: ContextDisplay,
    utility: UtilityDisplay,
    cost: CostDisplay,
    actors: ActorsDisplay,
    planning: PlanningDisplay,
    plan_com: PlanComDisplay,
    risks: RiskDisplay
  };

  return data !== null ? (
    <>
      <Collapse
        collapsible="header"
        defaultActiveKey={['0']}
        style={{
          backgroundColor: '#FFF',
          borderRadius: '5px'
        }}
        className="item-header-collapse"
      >
        <Panel
          showArrow={false}
          header={
            data ? (
              <>
                <Row justify="center">
                  <DownOutlined />
                  <IndexItem
                    key={data?._id || null}
                    data={data || null}
                    title={title}
                    version={version || null}
                  />
                </Row>
              </>
            ) : (
              <>
                <Descriptions title={t(`projects.form.${title}`).toUpperCase()}>
                  <Descriptions.Item label={t('projects.details.last_version')}>
                    <Tag color="#108ee9">
                      {t('projects.details.no_version')}
                      {data.length}
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
              </>
            )
          }
        >
          {data ? (
            <>
              {Object.entries(ItemCheckList).map(([key, Value]) => {
                return title === key ? (
                  <Value
                    key={data._id}
                    data={data}
                    actors={actors || null}
                    project={project}
                    version={data}
                    title={title}
                  />
                ) : null;
              })}
            </>
          ) : (
            <Descriptions>
              <Descriptions.Item>
                <Tag color="red">{t('projects.details.no_version_data')}</Tag>
              </Descriptions.Item>
            </Descriptions>
          )}
        </Panel>
      </Collapse>
    </>
  ) : (
    <>
      <Collapse collapsible="disabled">
        <Panel
          showArrow={false}
          header={
            <Descriptions
              extra={setVersionItem(version)}
              title={t(`projects.form.${title}`).toUpperCase()}
            >
              <Descriptions.Item>
                <Tag color="magenta">{t('projects.details.no_version')}</Tag>
              </Descriptions.Item>
            </Descriptions>
          }
        />
      </Collapse>
    </>
  );
};
ItemContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  actors: PropTypes.oneOfType([PropTypes.array]),
  version: PropTypes.number
};

ItemContainer.defaultProps = {
  data: null,
  title: null,
  actors: null,
  version: null
};

export default ItemContainer;
