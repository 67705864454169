import React from 'react';
import { Select, InputNumber, Input } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

const useUtilityFields = (id, enums, actors, loading) => {
  const utilityFields = [
    {
      name: ['deliverables', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['deliverables', 'use_for'],
      rules: [{ required: false }],
      input: enums ? (
        <Select loading={loading}>
          {enums.deliverables_for.map((value) => (
            <Option key={value} value={value}>
              {`${value}`}
            </Option>
          ))}
        </Select>
      ) : null
    },
    {
      name: ['deliverables', 'beneficiaries'],
      rules: [{ required: false }],
      input: actors ? (
        <Select loading={loading}>
          {actors[actors.length - 1]
            ? actors[actors.length - 1].actors_list.map((value) => {
                return value ? (
                  <Option key={value._id} value={value._id}>
                    {`${value.first_name} ${value.last_name} : ${value.role}`}
                  </Option>
                ) : null;
              })
            : null}
        </Select>
      ) : null
    },
    {
      name: ['deliverables', 'why'],
      rules: [{ required: false }],
      input: <TextArea row={6} />
    },
    {
      name: ['deliverables', 'coefficient'],
      rules: [{ required: false }],
      input: <InputNumber min={1} max={100} />
    }
  ];

  return { utilityFields };
};

export default useUtilityFields;
