import moment from 'moment';

const Columns = (t) => [
  {
    title: t('files.list.columns.name'),
    key: 'name',
    render: (value) => value.metadata.originalName
  },
  {
    title: t('files.list.columns.content'),
    key: 'content',
    dataIndex: 'contentType'
  },
  {
    title: t('files.list.columns.upload_date'),
    key: 'content',
    render: (value) => moment(value.uploadDate).format('DD-MM-YY')
  }
];

export default Columns;
