import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Component that displays a list of structured descriptions with titles and values.
 * Can be used to present information in an organized manner.
 *
 * @component
 * @param {string} title - The title displayed above the list of descriptions.
 * @param {Array} data - An array of objects representing the descriptions to display.
 * @param {boolean} skipEmpty - Indicates whether descriptions with an empty value ("-" by default) should be skipped.
 * @param {boolean} translate - Indicates whether description labels should be translated using react-i18next.
 * @param {number} column - The number of columns in which the descriptions should be displayed (default is 3).
 * @returns {JSX.Element} React component representing the list of descriptions.
 */
const DescriptionList = ({ title, data, skipEmpty, translate, column }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title} column={column}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty) && (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 700 }}>
                  {item.content || '-'}
                </Typography>
              </Descriptions.Item>
            )
        )}
      </Descriptions>
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  column: PropTypes.number
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  column: 3
};

export default DescriptionList;
