import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import Gantt from '../../../../components/Widgets/Gantt';
import ProjectCard from '../../../../components/ProjectCard/ProjectCard';

const GanttChart = ({
  projects,
  fetchSelectedProject,
  selectedProject,
  setSelectedProject,
  searchValue,
  projectPath
}) => {
  const [filteredProjects, setFilteredProjects] = useState([]);
  const getDataChart = (event) => {
    if (event && event.data && event.data._origin.id) {
      fetchSelectedProject(event.data._origin.id);
    } else {
      setSelectedProject();
    }
  };

  useEffect(() => {
    const newFilteredProjects = projects.filter((defaultMacroTask) =>
      defaultMacroTask.description
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
    setFilteredProjects(newFilteredProjects);
  }, [searchValue, projects]);

  return (
    <div>
      <div>
        <Card
          style={{
            overflowY: 'hidden',
            width: '100%',
            overflowX: 'auto',
            minHeight: 'fit-content'
          }}
        >
          <Row justify="space-between">
            <Col xl={24} sm={24}>
              <Row>
                {selectedProject ? (
                  <ProjectCard
                    project={selectedProject}
                    pathname={
                      projectPath
                        ? `${projectPath}${selectedProject._id}`
                        : null
                    }
                    styles={{
                      zIndex: 5,
                      marginLeft: '40%',
                      marginTop: '5%',
                      width: 500,
                      position: 'absolute'
                    }}
                  />
                ) : null}
              </Row>
              <div
                style={{
                  maxWidth: '100%',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  maxHeight: 750
                }}
              >
                <Gantt data={filteredProjects} onClick={getDataChart} />
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

GanttChart.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSelectedProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.shape({ _id: PropTypes.string.isRequired }),
  setSelectedProject: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  projectPath: PropTypes.string
};

GanttChart.defaultProps = {
  projects: [],
  selectedProject: null,
  searchValue: '',
  projectPath: null
};

export default GanttChart;
