/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Layout, message, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import useHandleResize from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import useAuthContext from '../../contexts/AuthContext';
import Logo from '../../assets/images/l_citizencan_logo.png';
import WhiteLogo from '../../assets/images/l_citizencan_logo_blanc.svg';

const Login = () => {
  const { dispatchAPI } = useAuthContext();
  const { token } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const [googleUrl, setGoogleUrl] = useState('');
  const [code, setCode] = useState('');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };
  const { location } = history;

  const postGoogleCode = async (code_) => {
    try {
      await dispatchAPI('GOOGLE_LOGIN', { code_ });
      message.success(t('login.success'));
    } catch (e) {
      console.log(e);
    }
  };

  if (location.search) {
    const searchString = location.search.split('?')[1];
    const searchParts = searchString.split('&');

    searchParts.forEach((part) => {
      const partMap = part.split('=');

      if (partMap[0] === 'code') {
        const newCode = partMap[1];
        if (code === '') setCode(newCode);
      }
    });
  }

  useEffect(() => {
    if (code !== '') {
      (async () => {
        await postGoogleCode(decodeURIComponent(code));
      })();
    }
  }, [code]);

  const getGoogleUrl = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/auth2/google` });
      setGoogleUrl(data.url);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  useEffect(() => {
    (async () => {
      await getGoogleUrl();
    })();
  }, [googleUrl]);

  return (
    <div>
      <Layout
        style={{ minHeight: '100vh', maxHeight: '100vh', display: 'inherit' }}
      >
        <Row>
          <Col
            xxl={18}
            xl={16}
            lg={14}
            md={12}
            sm={0}
            xs={0}
            style={{
              minHeight: '100vh',
              background: 'var(--secondaryColor)',
              position: 'relative'
            }}
          >
            <img
              alt="logo"
              style={{
                width: width > 992 ? 450 : '80%',
                position: 'relative',
                left: '50%',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)'
              }}
              src={WhiteLogo}
            />
          </Col>
          <Col
            xxl={6}
            xl={8}
            lg={10}
            md={12}
            sm={24}
            xs={24}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100vh',
              maxHeight: '100vh',
              background: '#fff',
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '24px 0'
            }}
          >
            {width < 768 && (
              <img
                alt="logo"
                style={{
                  width: '50%',
                  marginBottom: 80,
                  minWidth: 250,
                  maxWidth: 375
                }}
                src={Logo}
              />
            )}
            <Title
              style={{
                textAlign: 'center',
                fontSize: '2.5em',
                marginBottom: 50,
                color: 'var(--secondaryColor)',
                textTransform: 'uppercase',
                letterSpacing: '.1em',
                width: '80%'
              }}>
              {t(`login.title.${currentForm}`)}
            </Title>
            {forms[currentForm]}
          </Col>
        </Row>
      </Layout>
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default Login;
