import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * A React component for displaying detailed information about an action.
 *
 * @component
 * @param {object} props - The component's properties.
 * @param {object} props.action - The action to display information about.
 * @returns {JSX.Element} The rendered ActionCard component.
 */
const ActionCard = ({ action }) => {
  const { t } = useTranslation();

  const { planning } = action;
  return (
    <Descriptions column={2} size="small">
      {action?.planning?.start_date !== undefined ? (
        <Descriptions.Item
          label={t(`projects.form.items.utility.deliverables.start_date`)}
        >
          {moment(planning?.start_date).format('DD-MM-YYYY') !== undefined
            ? moment(planning?.start_date).format('DD-MM-YYYY')
            : ''}
        </Descriptions.Item>
      ) : null}

      <Descriptions.Item
        label={t('projects.form.items.utility.deliverables.due_date')}
      >
        {moment(planning?.due_date).format('dddd DD-MM-YYYY') !== null
          ? moment(planning?.due_date).format('dddd DD-MM-YYYY')
          : ''}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t('projects.form.items.utility.deliverables.description')}
      >
        {action.description}
      </Descriptions.Item>
      {planning.action_type !== 'MACRO_TASK' && (
        <>
          <Descriptions.Item
            span={2}
            label={t('projects.form.items.utility.deliverables.actor')}
          >
            {planning.assigned_actors
              .map(
                (a) =>
                  `${a?.first_name || ''} ${a?.last_name || ''} ${
                    a.role ? `(${a.role})` : ''
                  }`
              )
              .join(' - ')}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={t('projects.form.items.utility.deliverables.group')}
          >
            {planning.assigned_groups.map((g) => `[${g.name}]`).join(' - ')}
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            label={t(
              'projects.form.items.utility.deliverables.macro_task_linked'
            )}
          >
            {planning?.macro_task?.description}
          </Descriptions.Item>
        </>
      )}
      <Descriptions.Item
        span={2}
        style={{ margin: 50 }}
        label={t('projects.form.items.utility.deliverables.status')}
      >
        {t(`projects.form.deliverables.statuses.${action?.planning?.status}`)}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        style={{ margin: 50 }}
        label={t('projects.form.items.utility.deliverables.comment')}
      >
        {action.comment}
      </Descriptions.Item>
    </Descriptions>
  );
};

ActionCard.propTypes = {
  action: PropTypes.shape({
    planning: PropTypes.shape({
      start_date: PropTypes.string,
      due_date: PropTypes.string,
      status: PropTypes.string,
      assigned_actors: PropTypes.arrayOf(PropTypes.shape({})),
      assigned_groups: PropTypes.arrayOf(PropTypes.shape({})),
      action_type: PropTypes.string,
      macro_task: PropTypes.shape({
        description: PropTypes.string
      })
    }),
    description: PropTypes.string.isRequired,
    comment: PropTypes.string
  }).isRequired
};

export default ActionCard;
