import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import { getDefaultMacroTaskFromVersion } from '../../utilsProject/ManagePlanning/utils';
import ProjectRange from './ProjectRange';
import ManagePlanningTabs from './ManagePlanningTab/ManagePlanningTabs';

/**
 * A component for displaying project planning data, including Gantt charts and tabs.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.version - The version object.
 * @param {string} props.title - The title for the component.
 * @param {function} props.getProject - Function to get the project data.
 * @param {function} props.generateForm - Function to generate the form.
 * @param {boolean} props.role - A flag indicating the user's role.
 * @param {object} props.project - The project object.
 * @returns {JSX.Element} - A JSX element for displaying project planning data.
 */
const PlanningDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataDeliverables, setDataDeliverables] = useState();
  const [ganttConfig, setGanttConfig] = useState();
  const config = {
    onGetResource: {
      setFields: (data) => {
        const { planning, ...rest } = data;
        return {
          deliverables: {
            ...rest,
            planning: {
              ...data.planning,
              assigned_actors: planning.assigned_actors?.map(
                (assigned_actor) => assigned_actor._id
              ),
              assigned_groups: planning.assigned_groups?.map(
                (assigned_group) => assigned_group._id
              )
            }
          }
        };
      }
    }
  };

  const defaultMacroTask = getDefaultMacroTaskFromVersion(version);

  const openFormModal = () => {
    setVisibleModal(true);
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  return (
    <>
      <ProjectRange
        defaultMacroTask={defaultMacroTask}
        version={version}
        generateForm={generateForm}
      />
      <ManagePlanningTabs
        project={project}
        version={version}
        generateForm={generateForm}
        config={config}
        role={role}
        dataDeliverables={dataDeliverables}
        setDataDeliverables={setDataDeliverables}
        setVisibleModal={setVisibleModal}
        setGanttConfig={setGanttConfig}
        getProject={getProject}
      />
      {visibleModal && (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          destroyOnClose
          width={1000}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            ganttConfig ? 'edit' : 'createMatrice',
            version,
            'manage_planning',
            closeFormModal,
            dataDeliverables,
            'deliverables',
            ganttConfig ? config : null
          )}
        </Modal>
      )}

      {role && (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={() => {
            setGanttConfig(false);
            openFormModal();
          }}
          title={t('projects.form.items.planning.deliverables.addPlanning')}
        />
      )}
    </>
  );
};

PlanningDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  project: PropTypes.shape({
    status: PropTypes.string
  })
};

PlanningDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  project: null
};

export default PlanningDisplay;
