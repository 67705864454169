import React from 'react';
import { Row, Col, Tag } from 'antd';

const useUtility = (data, translate) => {
  const utility = data.manage_planning[data.manage_planning.length - 1];
  if (utility !== undefined && utility !== null) {
    const allUtility = [];
    (utility.deliverables || [])
      .filter((deli) => deli.utility !== undefined)
      .forEach((e) => {
        allUtility.push(e.utility.coefficient);
      });
    return (
      <Row style={{ marginBottom: 8 }} justify="start">
        <Col span={24}>
          <span
            style={{
              textTransform: 'uppercase',
              letterSpacing: '.10em',
              fontSize: 10,
              color: '#8c8c8c'
            }}
          >
            {translate(`projects.details.utility`)}
          </span>
        </Col>
        <Col span={24}>
          {`${
            allUtility.length > 0
              ? Math.round(
                  allUtility.reduce((a, b) => a + b, 0) / allUtility.length
                )
              : 0
          } %`}
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Col>
        <span
          style={{
            textTransform: 'uppercase',
            letterSpacing: '.10em',
            fontSize: 10,
            color: '#8c8c8c'
          }}
        >
          {translate(`projects.details.utility`)}
        </span>
      </Col>
      <Col>
        <Tag color="orange" style={{ marginRight: 16 }}>
          Non saisi
        </Tag>
      </Col>
    </>
  );
};

export default useUtility;
