import React from 'react';
import { Select, Input, InputNumber, Popover, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { SwatchesPicker } from 'react-color';
import { BgColorsOutlined } from '@ant-design/icons';
import getActorsAndGroupsSelect from '../../../fieldsItems/Inputs/getActorsAndGroupsSelect';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook for generating communication fields configuration.
 *
 * @hook
 * @param {string} purpose - Purpose of the form ('create' or 'edit').
 * @param {object} actorsItem - AcotrsItem containing actors and groups.
 * @param {string} colorGantt - Gantt color.
 * @param {Function} onChangeColor - Function to handle color change.
 * @param {boolean} isRepeated - Boolean indicating if the event is repeated.
 * @returns {Object} Object containing arrays of communication fields configuration.
 */
const useCommunicationFields = (
  purpose,
  actorsItem,
  colorGantt,
  onChangeColor,
  isRepeated
) => {
  const { t } = useTranslation();

  const planComFields1 = [
    {
      name: ['deliverables_plan_com', 'type'],
      input: <TextArea />
    },
    {
      name: ['deliverables_plan_com', 'target'],
      input: getActorsAndGroupsSelect(actorsItem)
    }
  ];

  const planComFields2 = [
    {
      name: ['deliverables_plan_com', 'goal_description'],
      input: <TextArea row={2} />
    },
    {
      name: ['deliverables_plan_com', 'goal_action'],
      input: <TextArea row={2} />
    }
  ];

  const planComFields3 = [
    {
      name: ['deliverables_plan_com', 'in_charge_of'],
      input: getActorsAndGroupsSelect(actorsItem)
    },
    {
      name: ['deliverables_plan_com', 'transmitter'],
      input: getActorsAndGroupsSelect(actorsItem)
    }
  ];

  const planComFields4 = [
    {
      name: ['deliverables_plan_com', 'channel'],
      input: <Input />
    },
    {
      name: ['deliverables_plan_com', 'impact'],
      input: (
        <Select>
          <Option value={1}>{t('projects.form.minor')}</Option>
          <Option value={2}>{t('projects.form.medium')}</Option>
          <Option value={3}>{t('projects.form.major')}</Option>
        </Select>
      )
    },
    purpose === 'edit' &&
      !isRepeated && {
        name: ['deliverables_plan_com', 'federation'],
        input: <InputNumber min={0} max={100} />
      },
    {
      name: ['deliverables_plan_com', 'color'],
      colon: false,
      priorityLabel: true,
      label: (
        <>
          <Button type="link" style={{ color: '#000' }}>
            Couleur
          </Button>
          <Popover
            content={<SwatchesPicker onChange={onChangeColor} />}
            trigger="hover"
          >
            <BgColorsOutlined
              style={{
                fontSize: 25,
                color: colorGantt
              }}
            />
          </Popover>
        </>
      )
    }
  ].filter(Boolean);

  return {
    planComFields1,
    planComFields2,
    planComFields3,
    planComFields4
  };
};

export default useCommunicationFields;
