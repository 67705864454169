import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Descriptions, Tag, Collapse, List, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import IndexItem from './IndexItem';
import WhatDisplay from '../ItemsDisplay/WhatDisplay';
import WhyDisplay from '../ItemsDisplay/WhyDisplay';
import PriorDisplay from '../ItemsDisplay/PriorDisplay/PriorDisplay';
import ContextDisplay from '../ItemsDisplay/ContextDisplay/ContextDisplay';
import UtilityDisplay from '../ItemsDisplay/UtilityDisplay/UtilityDisplay';
import CostDisplay from '../ItemsDisplay/CostDisplay/CostDisplay';
import ActorsDisplay from '../ItemsDisplay/ActorsDisplay/ActorsDisplay';
import PlanningDisplay from '../ItemsDisplay/PlanningDisplay/PlanningDisplay';
import PlanComDisplay from '../ItemsDisplay/PlanComDisplay/PlanComDisplay';
import ButtonDeleteAll from './ButtonDeleteAll';
import RiskDisplay from '../ItemsDisplay/RiskDisplay/RiskDisplay';
import useProjectContext from '../../../../contexts/ProjectContext';
import { EditIcon } from '../../../../utils/constants/customIcon';
import { getPaginationDrop } from '../../../../utils/constants/setVersionItem';
import { getItemVersion } from './ManageVersion/utils';

/**
 * A container component for creating items.
 *
 * @component
 * @param {string} title - The title/type of the item.
 * @param {Object} data - Project data.
 * @param {string} projectId - The ID of the project.
 * @param {Function} getProject - A function to fetch the project data.
 * @param {Function} openModal - A function to open a modal.
 * @param {boolean} visibleRole - Control element visibility.
 * @param {Function} generateForm - A function to generate a form.
 * @param {Object} managePlanning - Planning management information.
 * @param {Array} actors - An array of project actors.
 * @returns {JSX.Element} - The rendered CreateItemContainer component.
 */
const CreateItemContainer = ({
  title,
  data,
  projectId,
  getProject,
  openModal,
  visibleRole,
  generateForm,
  managePlanning,
  actors
}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const [index, setIndex] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const noItem = '';
  const ItemCheckList = {
    what: WhatDisplay,
    why: WhyDisplay,
    prior: PriorDisplay,
    context: ContextDisplay,
    utility: UtilityDisplay,
    cost: CostDisplay,
    actors: ActorsDisplay,
    planning: PlanningDisplay,
    plan_com: PlanComDisplay,
    risks: RiskDisplay
  };
  const getVersion = (targetVersion) => {
    return data.find((dataVersion) => dataVersion?.index === targetVersion - 1);
  };
  const { project, setDataToModalFiles, getFilesByItem } = useProjectContext();
  const config = {
    onGetResource: {
      setFields: (dataConfig) => ({
        ...dataConfig,
        files: [],
        _id: dataConfig._id,
        event: {
          ...dataConfig.event,
          date: moment(dataConfig.event.date)
        }
      })
    }
  };

  const itemRenderShow = (current, type, originalElement) => {
    if (type === 'page') {
      return getPaginationDrop(current, currentPage);
    }
    return originalElement;
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setIndex(itemRenderShow(currentPage));
  };
  useEffect(() => {
    if (data && data.length) {
      setCurrentPage(data.length);
    }
  }, [data.length]);

  const selectedVersion = getItemVersion(data, currentPage - 1);

  return (
    data !== undefined && (
      <>
        <Collapse
          collapsible="header"
          defaultActiveKey={['0']}
          style={{
            backgroundColor: '#FFF',
            borderRadius: '5px'
          }}
          className="item-header-collapse"
        >
          <Panel
            showArrow={false}
            header={
              data[data.length - 1] ? (
                <Col onClick={(e) => e.stopPropagation()}>
                  <Row justify="center">
                    <span
                      style={{ fontSize: 14, color: 'var(--disabledColor)' }}
                    >
                      {`${t('projects.form.details_items')} ${t(
                        `projects.form.${title}`
                      ).toUpperCase()}`}
                    </span>
                  </Row>
                  <Row>
                    <IndexItem
                      key={selectedVersion?._id || null}
                      data={selectedVersion}
                      title={title}
                      version={data.length ? currentPage : null}
                      extra={
                        visibleRole && (
                          <>
                            <Button
                              disabled={project?.status === 'close'}
                              onClick={() =>
                                openModal(
                                  data[currentPage - 1],
                                  managePlanning || title,
                                  index,
                                  'event',
                                  'event',
                                  config,
                                  data[currentPage - 1]
                                )
                              }
                              size="small"
                              type="link"
                              style={{
                                color: 'var(--secondaryColor)',
                                top: -2
                              }}
                            >
                              <EditIcon />
                            </Button>
                            {selectedVersion &&
                              title !== 'actors' &&
                              currentPage > 3 && (
                                <ButtonDeleteAll
                                  purpose="version"
                                  title={managePlanning || title}
                                  projectId={projectId}
                                  titleId={selectedVersion?._id}
                                  handleCancel={getProject}
                                  version={selectedVersion}
                                />
                              )}
                          </>
                        )
                      }
                    />
                  </Row>
                </Col>
              ) : (
                <>
                  <Descriptions
                    title={t(`projects.form.${title}`).toUpperCase()}
                    extra={
                      visibleRole && (
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() =>
                            openModal(
                              noItem,
                              managePlanning || title,
                              index,
                              'event',
                              'event',
                              config
                            )
                          }
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: -2
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )
                    }
                  >
                    <Descriptions.Item
                      label={t('projects.details.last_version')}
                    >
                      <Tag color="#108ee9">
                        {t('projects.details.no_version')}
                      </Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )
            }
          >
            <List
              pagination={{
                pageSize: 1,
                current: currentPage,
                total: data ? data.length : 1,
                itemRender: itemRenderShow,
                onChange: (page) => handlePageChange(page),
                showTotal: (total) => (
                  <span count={total || 0}>
                    {`${total} ${t('wallet.versions')}`}
                  </span>
                )
              }}
            >
              <List.Item>
                {data && getVersion(currentPage) ? (
                  <>
                    {Object.entries(ItemCheckList).map(([key, Value]) => {
                      return title === key ? (
                        <Value
                          changeVersion={openModal}
                          key={getVersion(currentPage)._id}
                          version={getVersion(currentPage)}
                          id={projectId}
                          title={key}
                          getProject={getProject}
                          generateForm={generateForm}
                          actors={actors || null}
                          role={visibleRole}
                          project={project}
                          setDataToModalFiles={setDataToModalFiles}
                          getFilesByItem={getFilesByItem}
                        />
                      ) : null;
                    })}
                  </>
                ) : (
                  <Descriptions>
                    <Descriptions.Item>
                      <Tag color="red">
                        {t('projects.details.no_version_data')}
                      </Tag>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </List.Item>
            </List>
          </Panel>
        </Collapse>
      </>
    )
  );
};
CreateItemContainer.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]).isRequired,
  projectId: PropTypes.string,
  openModal: PropTypes.func,
  getProject: PropTypes.func.isRequired,
  visibleRole: PropTypes.bool.isRequired,
  indexPanel: PropTypes.string,
  generateForm: PropTypes.func,
  managePlanning: PropTypes.string,
  actors: PropTypes.oneOfType([PropTypes.array])
};

CreateItemContainer.defaultProps = {
  title: null,
  projectId: null,
  openModal: null,
  indexPanel: null,
  generateForm: null,
  managePlanning: null,
  actors: null
};

export default CreateItemContainer;
