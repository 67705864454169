/* eslint-disable no-param-reassign */
import { Workbook } from 'exceljs';
import moment from 'moment';
import { message } from 'antd';
import headerByitems from './headerByItems';
import { actorsToString, frequencyToString, groupsToString } from './utils';
import { getDisplayedActorRole } from '../../../Projects/Projects/ItemsDisplay/ActorsDisplay/utils';

const evolutionProject = (t, project, filter) => {
  const getCellToMerge = {
    what: 'B',
    why: 'B',
    prior: 'E',
    actors: 'G',
    groups: 'G',
    context: 'E',
    cost: 'F',
    risks: 'H',
    planning: 'L',
    utility: 'G',
    plan_com: 'N'
  };

  const getContent = (version, key) => {
    if (key === 'actors') return version.content.actors;
    if (key === 'groups') return version.content.groups;
    return version.content;
  };

  const getKey = (key) => {
    if (['actors', 'groups'].includes(key)) return 'actors';
    if (['planning', 'utility'].includes(key)) return 'manage_planning';
    return key;
  };

  const typeOfEvolution = (arrayOfItem) => {
    if (filter.type_evolution === 'V0toV1') {
      return arrayOfItem.slice(0, 2);
    }
    return arrayOfItem;
  };
  const checkDataAndReturnSorting = (data, type) => {
    switch (type) {
      case 'prior':
        return [
          data.type,
          data.denomination,
          moment(data.creation_date).format('DD-MM-YYYY'),
          data.description
        ];
      case 'actors':
        return [
          data.first_name,
          data.last_name,
          data.user ? t('global.yes') : t('global.no'),
          data.code,
          getDisplayedActorRole(data, project?.assignee._id, t),
          data.coefficient
        ];
      case 'groups':
        if (data.default) return false;
        return [
          data.name,
          data.actors.length,
          data.code,
          data.role,
          actorsToString(data.actors),
          data.coefficient
        ];
      case 'context':
        return [data.denomination, data.type, data.impact, data.description];
      case 'cost':
        return [
          data.denomination,
          data.type,
          `${data.charge} j/h`,
          `${data.cost_value} €`,
          data.description
        ];
      case 'risks':
        return [
          data.number,
          data.potential_risk,
          data.type_of_risk,
          data.impact_effect,
          data.criticality,
          data.decision,
          data.measure_description
        ];
      case 'planning':
        if (data.planning) {
          return [
            data.description,
            t(
              `projects.form.deliverables.planning.${data.planning.action_type.toLowerCase()}`
            ),
            data.global_type,
            data.comment,
            moment(data.planning.start_date).format('DD-MM-YYYY'),
            moment(data.planning.due_date).format('DD-MM-YYYY'),
            actorsToString(data.planning.assigned_actors),
            groupsToString(data.planning.assigned_groups),
            data.planning.workload,
            t(`projects.form.deliverables.statuses.${data.planning.status}`),
            `${data.planning.percentage} %`
          ];
        }
        return false;
      case 'utility':
        if (data.utility) {
          return [
            data.description,
            data.global_type,
            data?.utility?.use_for
              ? t(
                  `projects.form.items.utility.deliverables.beneficiaries.${data.utility.use_for}`
                )
              : '',
            data.utility.beneficiaries,
            data.utility.why,
            data.utility.coefficient
          ];
        }
        return false;
      case 'plan_com':
        return [
          data.name,
          moment(data.start_date).format('DD-MM-YYYY'),
          moment(data.due_date).format('DD-MM-YYYY'),
          frequencyToString(data, t),
          data.type,
          data.channel,
          data.impact,
          data.transmitter,
          data.in_charge_of,
          data.target,
          data.federation,
          data.goal_description,
          data.goal_action
        ];
      default:
        return false;
    }
  };
  const workbook = new Workbook();

  const createWorksheet = (key) => {
    const worksheet = workbook.addWorksheet(`${t(`projects.form.${key}`)}`);
    worksheet.properties.defaultColWidth = 15;
    worksheet.properties.defaultRowHeight = 20;
    const newHeader = headerByitems(true);
    const header = newHeader[key].map(({ label }) => {
      return t(`projects.form.${label}`);
    });
    const headerRow = worksheet.addRow(header);

    headerRow.eachCell((cell) => {
      cell.width = cell._value.model.value?.length + 10;
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '202364' },
        bgColor: { argb: '' }
      };
      cell.font = {
        bold: false,
        color: { argb: 'FFFFFF' },
        size: 11
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    if (key === 'what' || key === 'why') {
      const typeEvolution = typeOfEvolution(project[key]);
      typeEvolution.forEach((element) => {
        const cellToStyle = worksheet.addRow([
          `${moment(element.event.date).format('DD-MM-YYYY')} - ${
            element.event.type
          } - ${element.event.name}`
        ]);
        cellToStyle.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '757171' },
            bgColor: { argb: '' }
          };
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
        worksheet.mergeCells(
          `${worksheet.lastRow._cells[0]._address}:B${
            worksheet.lastRow._cells[0]._address.split('A')[1]
          }`
        );
        if (element.content.description) {
          const cellStyle = worksheet.addRow([
            `V${element.index}`,
            element.content.description
          ]);
          if (element.index < typeEvolution.length - 1) {
            if (
              element.description !==
              typeEvolution[element.index + 1].content.description
            ) {
              cellStyle.eachCell((cell) => {
                if (cell._value.value === element.content.description) {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'D80000' },
                    bgColor: { argb: '' }
                  };
                  cell.font = {
                    bold: false,
                    color: { argb: 'FFFFFF' },
                    size: 12
                  };
                }
              });
            }
          } else if (element.index > 0) {
            if (
              element.content.description !==
              typeEvolution[element.index - 1].content.description
            ) {
              cellStyle.eachCell((cell) => {
                if (cell._value.value === element.content.description) {
                  cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '159001' },
                    bgColor: { argb: '' }
                  };
                  cell.font = {
                    bold: false,
                    color: { argb: 'FFFFFF' },
                    size: 12
                  };
                }
              });
            }
          }
        }
      });
    } else {
      let row;

      const typeEvolution = typeOfEvolution(project[getKey(key)]);
      typeEvolution.forEach((version) => {
        const cellToStyle = worksheet.addRow([
          `${moment(version.event.date).format('DD-MM-YYYY')} - ${
            version.event.type
          } - ${version.event.name}`
        ]);
        cellToStyle.eachCell((cell) => {
          cell.height = 20;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '757171' },
            bgColor: { argb: '' }
          };
          cell.font = {
            bold: true,
            color: { argb: 'FFFFFF' },
            size: 12
          };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
        worksheet.mergeCells(
          `${worksheet.lastRow._cells[0]._address}:${getCellToMerge[key]}${
            worksheet.lastRow._cells[0]._address.split('A')[1]
          }`
        );
        getContent(version, key).forEach((itemList, itemIndex) => {
          row = checkDataAndReturnSorting(itemList, key);
          if (row) {
            const cellStyle = worksheet.addRow([`V${version.index}`, ...row]);
            row.forEach((cell, columnIndex) => {
              if (version.index > 0) {
                const prevElement =
                  typeEvolution[version.index - 1].content[itemIndex];
                let prevVersionRow;
                if (prevElement) {
                  prevVersionRow = checkDataAndReturnSorting(prevElement, key);
                }
                if (
                  cell !==
                  (prevVersionRow
                    ? prevVersionRow[columnIndex]
                    : prevVersionRow)
                ) {
                  cellStyle.eachCell((styleCell) => {
                    if (styleCell._value.value === cell) {
                      styleCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '159001' },
                        bgColor: { argb: '' }
                      };
                      styleCell.font = {
                        bold: false,
                        color: { argb: 'FFFFFF' },
                        size: 12
                      };
                    }
                  });
                }
                if (version.index < typeEvolution.length - 1) {
                  const nextElement = getContent(
                    typeEvolution[version.index + 1],
                    key
                  )[itemIndex];
                  let nextVersionRow;
                  if (nextElement) {
                    nextVersionRow = checkDataAndReturnSorting(
                      nextElement,
                      key
                    );
                  }
                  if (
                    cell !==
                    (nextVersionRow
                      ? nextVersionRow[columnIndex]
                      : nextVersionRow)
                  ) {
                    cellStyle.eachCell((styleCell) => {
                      if (styleCell._value.value === cell) {
                        styleCell.fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'D80000' },
                          bgColor: { argb: '' }
                        };
                        styleCell.font = {
                          bold: false,
                          color: { argb: 'FFFFFF' },
                          size: 12
                        };
                      }
                    });
                  }
                }
              }
            });
          }
        });
      });
    }
  };

  try {
    filter.items.forEach((key) => {
      createWorksheet(key);
      if (key === 'actors') {
        createWorksheet('groups');
      }
    });
  } catch (e) {
    message.error(`${t('errors.message.on_format_xlsx_file')} : ${e}`, 5);
  }

  /// Generate file .xlsx
  workbook.xlsx.writeBuffer().then((_data) => {
    const newBlob = new Blob([_data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${project.name}_${t(`reporting.type.${filter.type}`)}.xlsx`;
    a.click();
  });
};
export default evolutionProject;
