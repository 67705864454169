import React from 'react';
import { Select } from 'antd';

// eslint-disable-next-line import/prefer-default-export
export const generateFilterSelect = (
  list,
  defaultValue,
  setSelectedValue,
  renderLabel
) => {
  return (
    <Select
      defaultValue={defaultValue}
      onChange={(value) => setSelectedValue(value)}
    >
      <Select.Option key={null} value={null}>
        {defaultValue}
      </Select.Option>
      ,
      {list
        ?.filter((el) => el !== null && el !== undefined)
        .map((el) => (
          <Select.Option
            key={typeof el === 'object' ? el._id : el}
            value={typeof el === 'object' ? el._id : el}
          >
            {renderLabel ? renderLabel(el) : el}
          </Select.Option>
        ))}
    </Select>
  );
};
