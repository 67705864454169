import React from 'react';
import { Spin, notification } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingOutlined } from '@ant-design/icons';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ServiceWorkerProvider } from '../contexts/ServiceWorkerContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import { FallbackComponent } from '../components/FallbackModal/FallbackComponent';
import { SocketContextProvider } from '../contexts/SocketContext';
import Router from '../routes/Router';
import '../assets/styles/SCSS/index.scss';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const handleError = async (error, { componentStack }) => {
  if (process.env.NODE_ENV === 'production') {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/gitlab-issues`, {
        method: 'POST',
        body: JSON.stringify({
          error: { title: error?.message, description: error.toString() },
          path: window.location.href,
          stack: componentStack
        }),
        headers: new Headers({ 'Content-type': 'application/json' })
      });
    } catch (e) {
      notification.error({
        message: "Impossible de signaler l'erreur",
        key: 'gitlab_error'
      });
    }
  }
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      <LanguageContextProvider>
        <ServiceWorkerProvider>
          <AuthContextProvider>
            <ThemeContextProvider>
              <SocketContextProvider>
                <Router />
              </SocketContextProvider>
            </ThemeContextProvider>
          </AuthContextProvider>
        </ServiceWorkerProvider>
      </LanguageContextProvider>
    </ErrorBoundary>
  );
};

export default App;
