import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateFilterSelect } from '../../../utils/GenerateFilterItem';

const { Search } = Input;

/**
 * Project filters
 *
 * @component
 * @param {Object} props Props
 * @param {string} props.searchValue Search value
 * @param {Function} props.setSearchValue Set search value
 * @param {Array} props.perimeters List of perimeters
 * @param {Function} props.setSelectedPerimeter Set selected perimeter
 * @param {Array} props.customers List of customers
 * @param {Function} props.setSelectedCustomer Set selected customer
 * @param {Array} props.managers List of managers
 * @param {Function} props.setSelectedManager Set selected manager
 * @param {Function} props.setSelectedStatus Set selected status
 * @returns {JSX.Element} Project filters
 */
const ProjectFilters = ({
  searchValue,
  setSearchValue,
  perimeters,
  setSelectedPerimeter,
  customers,
  setSelectedCustomer,
  managers,
  setSelectedManager,
  setSelectedStatus
}) => {
  const statuses = ['V0', 'V1', 'VX'];
  const { t } = useTranslation();

  return (
    <Row className="custom-header">
      <Col>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => setSearchValue(value)}
        />
      </Col>
      <Col>
        {generateFilterSelect(
          perimeters,
          t('perimeters.filter.perimeter'),
          setSelectedPerimeter,
          (perimeter) => perimeter.name
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          customers,
          t('perimeters.filter.customer'),
          setSelectedCustomer
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          managers,
          t('perimeters.filter.manager'),
          setSelectedManager,
          (manager) => `${manager.first_name} ${manager.last_name}`
        )}
      </Col>
      <Col>
        {generateFilterSelect(
          statuses,
          t('perimeters.filter.version'),
          setSelectedStatus,
          (status) => t(`projects.status.${status}`)
        )}
      </Col>
    </Row>
  );
};

export default ProjectFilters;

ProjectFilters.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  perimeters: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedPerimeter: PropTypes.func,
  customers: PropTypes.arrayOf(PropTypes.string),
  setSelectedCustomer: PropTypes.func,
  managers: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedManager: PropTypes.func,
  setSelectedStatus: PropTypes.func
};

ProjectFilters.defaultProps = {
  searchValue: null,
  setSearchValue: () => {},
  perimeters: [],
  setSelectedPerimeter: () => {},
  customers: [],
  setSelectedCustomer: () => {},
  managers: [],
  setSelectedManager: () => {},
  setSelectedStatus: () => {}
};
