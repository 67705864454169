import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import Gantt from '../../../../../../../../components/Widgets/Gantt';
import useHandleResize from '../../../../../../../../utils/HandleResize';
import planComToChart from './dataToChart';
import PlanComEventCard from './PlanComEventCard';

/**
 * Component for displaying the Gantt chart tab in the PlanCom section.
 *
 * @component
 * @param {Object} project - The project data.
 * @param {Object} version - The version object containing planCom items.
 * @param {Function} setVisibleModal - A function to set the visibility of the modal.
 * @param {boolean} role - Control component visibility according to user's role.
 * @returns {JSX.Element} GanttTabPane component
 */
const GanttTabPane = ({ project, version, setVisibleModal, role }) => {
  const [ganttData, setGanttData] = useState();
  const [dataChart, setDataChart] = useState();
  const { width } = useHandleResize();

  const getDataChart = (event) => {
    if (event && event.data && event.data._origin) {
      setDataChart({
        ...event.data._origin,
        task: event.data._origin.task.substring(24, 40)
      });
    }
  };

  const closeFormGantt = () => {
    setDataChart();
    setVisibleModal(false);
  };

  useEffect(() => {
    if (version) {
      setGanttData(planComToChart(version.content));
    }
  }, [version]);

  return (
    <Card style={{ margin: '20px' }} className="gantt-card">
      <Gantt
        key="federation"
        data={ganttData}
        purpose="federation"
        height={300}
        width={width > 1600 ? 1500 : 800}
        onClick={getDataChart}
        lengthForFederation={version?.content?.length}
      />

      {dataChart && (
        <PlanComEventCard
          dataChart={dataChart}
          setDataChart={setDataChart}
          projectStatus={project?.status}
          versionId={version?._id}
          handleClose={closeFormGantt}
          role={role}
        />
      )}
    </Card>
  );
};

GanttTabPane.propTypes = {
  project: PropTypes.shape({ status: PropTypes.string }).isRequired,
  version: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setVisibleModal: PropTypes.func.isRequired,
  role: PropTypes.bool
};

GanttTabPane.defaultProps = {
  role: false
};

export default GanttTabPane;
