import React, { useEffect, useState } from 'react';
import { Form, Spin, Transfer, message, Input, Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';

const ListTransfer = ({ item, savedItemContent, sourceItemContent }) => {
  const { user, dispatchAPI } = useAuthContext();
  const [targetKeys, setTargetKeys] = useState(savedItemContent ?? []);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [itemContent, setItemContent] = useState();
  const [loading, setLoading] = useState(true);
  const [newWord, setNewWord] = useState('');
  const [wordsList, setWordsList] = useState([]);

  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const formatListContent = (list) =>
    list.map((word, index) => ({
      key: word,
      value: word,
      sub_category: list.sub_category,
      index
    }));

  const getUserItemContent = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/lists?sub_category=${item}${
          user.role === 'admins:PERIMETER_ADMIN'
            ? `&created_by=${user._id}`
            : ''
        }`
      });
      if (sourceItemContent) {
        const formattedPerimeterItemContent = formatListContent(
          sourceItemContent
        ).filter(
          (perimeterItemContent) =>
            !data.some(
              (userItemContent) =>
                userItemContent.value === perimeterItemContent.value
            )
        );
        setItemContent([...data, ...formattedPerimeterItemContent]);
      } else {
        setItemContent(data);
      }
      setLoading(false);
    } catch (e) {
      if (e.message) message.error(e.message);
      setLoading(false);
    }
  };

  const addNewWord = async () => {
    try {
      if (newWord && !itemContent.some((el) => el.key === newWord)) {
        const newItem = { key: newWord, value: newWord };
        setWordsList([...wordsList, newWord]);
        await dispatchAPI('PATCH', {
          url: `/lists`,
          body: {
            sub_category: item,
            label: newWord,
            category: 'USER'
          }
        });
        setItemContent([...itemContent, newItem]);
        setNewWord('');
      }
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  useEffect(() => {
    try {
      (async () => {
        await getUserItemContent();
      })();
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <Row gutter={10}>
        <Col flex="auto">
          <Input
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            placeholder="Ajouter un mot..."
            style={{ marginBottom: 10, width: 350 }}
          />
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />} onClick={addNewWord}>
            Ajouter
          </Button>
        </Col>
      </Row>
      <Form.Item name={item}>
        <Transfer
          dataSource={itemContent}
          showSearch
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          render={(i) => i.key}
          oneWay
          style={{
            marginBottom: 16
          }}
        />
      </Form.Item>
    </Spin>
  );
};

ListTransfer.propTypes = {
  item: PropTypes.string.isRequired,
  savedItemContent: PropTypes.arrayOf(PropTypes.string),
  sourceItemContent: PropTypes.arrayOf(PropTypes.string)
};

ListTransfer.defaultProps = {
  savedItemContent: [],
  sourceItemContent: null
};

export default ListTransfer;
