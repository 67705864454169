import { useTranslation } from 'react-i18next';
import { message } from 'antd';

const useErrorMessage = () => {
  const { t } = useTranslation();
  return {
    message: (status, duration) => {
      message.error(t(`errors.message.${status}`), duration);
    },
    setFieldError: (form, err) => {
      const error = Object.entries(err)[0];
      if (error[0] && error[1] && error[1].kind)
        form.setFields([
          { name: error[0], errors: [t(`errors.form.${[error[1].kind]}`)] }
        ]);
    },
    success: (status, duration) => {
      message.success(t(`${status}`), duration);
    }
  };
};

export default useErrorMessage;
