import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Row,
  Button,
  Input,
  DatePicker,
  Card,
  Typography,
  Col,
  Space,
  Modal
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';

const { Title } = Typography;

const MemoForm = ({ data, getProjects, id, setDataToForm, allData }) => {
  const [form] = Form.useForm();
  const { message: errorMessage } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/projects/${id}`,
        body: { memos: body }
      });
      setDataToForm();
      getProjects();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const onFinish = (values) => {
    const { object, date, memo } = values;
    if ((!object, !date, !memo)) {
      Modal.warning({
        title: t('dashboard.error.memo_failed'),
        content: (
          <div>
            <p>{t('dashboard.error.memo_failed_message')}</p>
            <p style={{ color: 'red' }}>
              {!object && t('dashboard.error.object')}
            </p>
            <p style={{ color: 'red' }}>{!date && t('dashboard.error.date')}</p>
            <p style={{ color: 'red' }}>{!memo && t('dashboard.error.memo')}</p>
          </div>
        )
      });
    } else {
      let result;
      if (values._id) {
        result = allData.filter((ele) => ele._id !== values._id);
        result.push(values);
      } else {
        result = allData;
        result.push(values);
      }
      updateResource(result);
      form.resetFields();
    }
  };

  const config = {
    getResource: {
      setValues: (dataToSet) => ({
        _id: dataToSet._id,
        memo: dataToSet.memo,
        date: moment(dataToSet.date),
        object: dataToSet.object
      })
    }
  };

  const deleteMemo = (idMemo) => {
    const result = allData.filter((ele) => ele._id !== idMemo);
    updateResource(result);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(config.getResource.setValues(data));
    } else form.resetFields();
  }, [data]);

  return (
    <Form name="dynamic_form_item" onFinish={onFinish} form={form}>
      <Card>
        <Row justify="space-between">
          <Col span={10} style={{ marginBottom: 10 }}>
            <Col
              span={24}
              style={{
                borderBottom: '1px solid',
                padding: 10,
                display: 'flex'
              }}
            >
              <Title level={3} style={{ marginRight: 100 }}>
                Memo
              </Title>
              {data && (
                <Space>
                  <Button
                    onClick={() => setDataToForm()}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Ajouter un CR
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={() => deleteMemo(data._id)}
                    type="danger"
                    icon={<DeleteOutlined />}
                  />
                </Space>
              )}
            </Col>
          </Col>
          <Col span={6} style={{ marginBottom: 10 }}>
            <Col span={24}>
              <Form.Item name={['_id']} hidden noStyle>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name={['object']}
                validateTrigger={['onChange', 'onBlur']}
                noStyle
              >
                <Input placeholder="Object..." style={{ height: 50 }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={['date']}
                validateTrigger={['onChange', 'onBlur']}
                noStyle
              >
                <DatePicker
                  format="DD-MM-YYYY"
                  bordered={false}
                  placeholder="Date..."
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>

        <Form.Item
          name={['memo']}
          validateTrigger={['onChange', 'onBlur']}
          noStyle
        >
          <TextArea
            placeholder="memo..."
            autoSize
            row={10}
            style={{
              whiteSpace: 'pre-line',
              // width: '85%',
              height: 100
            }}
          />
        </Form.Item>

        <Row justify="center" style={{ marginTop: 20 }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {`${t('buttons.save')} `}
            </Button>
          </Form.Item>
        </Row>
      </Card>
    </Form>
  );
};

MemoForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  allData: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string.isRequired,
  getProjects: PropTypes.func.isRequired,
  setDataToForm: PropTypes.func.isRequired
};

MemoForm.defaultProps = {
  allData: [],
  data: null
};

export default MemoForm;
