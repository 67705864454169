import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';

const useReassignPerimeterField = () => {
  const { dispatchAPI } = useAuthContext();
  const [perimetersAdmins, setPerimetersAdmins] = useState();

  const getPerimetersAdmins = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?role=admins:PERIMETER_ADMIN`
      });
      setPerimetersAdmins(data);
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  useEffect(() => {
    try {
      (async () => {
        await getPerimetersAdmins();
      })();
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  }, []);

  const reassignPerimeterField = {
    name: ['assignee'],
    label: 'assignee',
    rules: [{ required: true }],
    input: (
      <Select>
        {perimetersAdmins?.map((admin) => (
          <Select.Option key={admin._id} value={admin._id}>
            {`${admin.first_name} ${admin.last_name}`}
          </Select.Option>
        ))}
      </Select>
    )
  };

  return { reassignPerimeterField };
};

export default useReassignPerimeterField;
