import React from 'react';
import { Button, Collapse, Descriptions, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../../../utils/constants/customIcon';
import ButtonDeleteAll from '../../../../../utilsProject/ButtonDeleteAll';
import Header from './Header';

const { Panel } = Collapse;

/**
 * Renders the Utility tab pane containing utility deliverables.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.project - The project data.
 * @param {Object} props.version - The version data.
 * @param {function} props.generateForm - The function to generate forms.
 * @param {function} props.closeForm - The function to close a form.
 * @param {Object} props.config - The configuration object.
 * @param {boolean} props.visible - The visibility status.
 * @param {boolean} props.role - Controls components visibility according to user role.
 * @param {function} props.openForm - The function to open a form.
 * @param {Object} props.dataDeliverables - The deliverables data.
 * @returns {JSX.Element|null} - The rendered JSX element or null if version content is empty.
 */
const UtilityTabPane = ({
  project,
  version,
  generateForm,
  closeForm,
  config,
  visible,
  role,
  openForm,
  dataDeliverables
}) => {
  const { t } = useTranslation();
  return version?.content
    ? version?.content
        .filter((deli) => deli.planning && deli.utility)
        .map((deli) => {
          const { _id, utility } = deli;

          return visible === _id && dataDeliverables ? (
            generateForm(
              'edit',
              version,
              'manage_planning',
              closeForm,
              dataDeliverables,
              'deliverables',
              config
            )
          ) : (
            <React.Fragment key={_id}>
              <Collapse key={_id}>
                <Panel
                  style={{ marginBottom: 12 }}
                  extra={
                    role && (
                      <Row style={{ flexWrap: 'nowrap' }} justify="center">
                        <Button
                          disabled={project?.status === 'close'}
                          onClick={() => openForm(_id, deli)}
                          size="small"
                          type="link"
                          style={{
                            color: 'var(--secondaryColor)',
                            top: 2
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <ButtonDeleteAll
                          purpose="matrice"
                          projectId={project._id}
                          title="utility"
                          titleId={version._id}
                          data={version}
                          matriceName="deliverables"
                          matriceId={_id}
                          handleCancel={closeForm}
                          version={version}
                        />
                      </Row>
                    )
                  }
                  header={<Header deliverable={deli} />}
                >
                  <Descriptions column={2} size="small" style={{ padding: 12 }}>
                    <Descriptions.Item
                      label={t(`projects.form.deliverables.beneficiaries`)}
                    >
                      {utility?.beneficiaries}
                    </Descriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </React.Fragment>
          );
        })
    : null;
};

UtilityTabPane.propTypes = {};

export default UtilityTabPane;
