import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import ContextCard from './ContextCard';
import useContextFilters from './ContextFilters/useContextFilters';

/**
 * A component to display and manage context information.
 *
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.version - The version object containing version details.
 * @param {string} props.title - The type of the item.
 * @param {Function} props.getProject - A function to get project details.
 * @param {Function} props.generateForm - A function to generate a form.
 * @param {boolean} props.role - Controls components visibility according to user role.
 * @param {Object} props.setDataToModalFiles - A function to set data to modal files.
 * @param {Function} props.getFilesByItem - A function to get files by item.
 * @param {Object} props.project - The project.
 * @returns {JSX.Element} A JSX Element representing the ContextDisplay component.
 */
const ContextDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  setDataToModalFiles,
  getFilesByItem,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataFactor, setDataFactor] = useState();
  const { getContextFilters, getFilteredContexts } = useContextFilters(
    project?.context_list
  );

  const config = {
    onGetResource: {
      setFields: (data) => ({
        factors: { ...data }
      })
    }
  };
  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, factor) => {
    setVisible(idRender);
    setDataFactor({ ...factor });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  useEffect(() => {
    if (version?.content?.files) getFilesByItem(version.content.files);
  }, [project]);

  return (
    <>
      {getContextFilters()}
      {getFilteredContexts(version?.content).map((context) => {
        const { _id } = context;
        return (
          <React.Fragment key={_id}>
            {visible === _id && dataFactor ? (
              generateForm(
                'edit',
                version,
                title,
                closeForm,
                dataFactor,
                'factors',
                config
              )
            ) : (
              <ContextCard
                context={context}
                project={project}
                version={version}
                role={role}
                title={title}
                setDataToModalFiles={setDataToModalFiles}
                openForm={openForm}
                closeForm={closeForm}
              />
            )}
          </React.Fragment>
        );
      })}
      {visibleModal ? (
        <Modal
          title={t(`projects.form.items.${title}.title`)}
          closable
          width={1000}
          visible={visibleModal}
          footer={false}
          onCancel={closeFormModal}
        >
          {generateForm(
            'createMatrice',
            version,
            title,
            closeFormModal,
            null,
            'factors'
          )}
        </Modal>
      ) : null}
      {role ? (
        <AddItemButton
          disabled={project?.status === 'close'}
          onClick={openFormModal}
          title={t('projects.form.items.context.factors.addFactor')}
        />
      ) : null}
    </>
  );
};

ContextDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({ map: PropTypes.func }))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  setDataToModalFiles: PropTypes.func,
  getFilesByItem: PropTypes.func,
  project: PropTypes.shape({
    status: PropTypes.string,
    context_list: PropTypes.arrayOf(PropTypes.shape({}))
  })
};

ContextDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  setDataToModalFiles: () => {},
  getFilesByItem: () => {},
  project: null
};

export default ContextDisplay;
