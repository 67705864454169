import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Collapse, Form, Input, message, Modal, Select } from 'antd';
import {
  CaretRightOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import usePwdPattern from '../../utils/pwdPattern';
import useEmailPattern from '../../utils/emailPattern';

const { Panel } = Collapse;
const { Option } = Select;
const RegisterForm = ({ switchForm }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const registerUser = async values => {
    setLoading(true);
    try {
      await dispatchAPI('REGISTER', values);
      message.success(t('login.registrationSuccess'));
      switchForm('login');
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
    }
    setLoading(false);
  };

  const handleSubmit = async values => {
    await registerUser({ ...values, email: values.username.toLowerCase() });
  };

  return (
    <>
      <Form
        onFinish={handleSubmit}
        style={{ width: '80%', maxWidth: '400px', maxHeight: '80vh' }}
        className="register-form"
      >
        <Form.Item
          name="first_name"
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <Input
            prefix={
              <UserOutlined
                style={{ color: 'var(--disabledColor)', marginRight: 5 }}
              />
            }
            placeholder={t('login.first_name')}
          />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[{ required: true, message: t('errors.form.REQUIRED') }]}
        >
          <Input
            prefix={
              <UserOutlined
                style={{ color: 'var(--disabledColor)', marginRight: 5 }}
              />
            }
            placeholder={t('login.last_name')}
          />
        </Form.Item>
        <Form.Item
          name="username"
          rules={[
            { required: true, message: t('login.usernameMissing') },
            ...useEmailPattern()]}
        >
          <Input
            prefix={
              <MailOutlined
                style={{ color: 'var(--disabledColor)', marginRight: 5 }}
              />
            }
            placeholder={t('login.username')}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('login.pwdMissing') },
            ...usePwdPattern()
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={
              <LockOutlined
                style={{ color: 'var(--disabledColor)', marginRight: 5 }}
              />
            }
            placeholder={t('login.password')}
          />
        </Form.Item>
        <Form.Item
          dependencies={['password']}
          hasFeedback
          name="confirm"
          rules={[
            {
              required: true,
              message: t('login.pwdMissing')
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('login.pwdNotMatching'));
              }
            })
          ]}
        >
          <Input.Password
            prefix={
              <LockOutlined
                style={{ color: 'var(--disabledColor)', marginRight: 5 }}
              />
            }
            placeholder={t('login.confirmPassword')}
          />
        </Form.Item>
        <Collapse
          bordered
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          accordion
        >
          <Panel
            header={t('login.additional-information')}
            key="1"
            className="site-collapse-custom-panel"
          >
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  noStyle
                  name={['phone_number', 'country_code']}
                  initialValue="+33"
                >
                  <Select style={{ width: '25%' }}>
                    <Option value="+33">+33</Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle name={['phone_number', 'number']}>
                  <Input
                    style={{ width: '75%' }}
                    prefix={
                      <PhoneOutlined
                        style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                      />
                    }
                    placeholder={t('login.phone-number')}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
            <Form.Item name={['address', 'number']}>
              <Input
                type="number"
                style={{width: '100%'}}
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                  />
                }
                placeholder={t('login.street-number')}
              />
            </Form.Item>
            <Form.Item name={['address', 'street']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                  />
                }
                placeholder={t('login.street-name')}
              />
            </Form.Item>
            <Form.Item name={['address', 'additional']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                  />
                }
                placeholder={t('login.address-extra')}
              />
            </Form.Item>
            <Form.Item name={['address', 'postal_code']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                  />
                }
                placeholder={t('login.postal-code')}
              />
            </Form.Item>
            <Form.Item name={['address', 'city']}>
              <Input
                prefix={
                  <HomeOutlined
                    style={{ color: 'var(--disabledColor)', marginRight: 5 }}
                  />
                }
                placeholder={t('login.city')}
              />
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item
          style={{ textAlign: 'left' }}
          name="CGU"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(t('login.CGUMissing'))
            }
          ]}
        >
          <Checkbox>
            {`${t('login.CGU1')} `}
            <Button
              type="link"
              style={{ padding: 0, height: 'auto' }}
              onClick={() => setModalVisible(!modalVisible)}
            >
              {`${t('login.CGU2')}`}
            </Button>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: '100%' }}
            loading={isLoading}
          >
            {t('login.registration')}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="button"
            style={{ width: '100%' }}
            onClick={() => switchForm('login')}
          >
            {t('login.connection')}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Conditions générales d'utilisation"
        visible={modalVisible}
        bodyStyle={{ height: '500px', overflow: 'scroll' }}
        onCancel={() => setModalVisible(!modalVisible)}
        footer={[
          <Button
            key="Ok"
            onClick={() => setModalVisible(!modalVisible)}
            type="primary"
          >
            Compris
          </Button>
        ]}
      >
        <p>Some many text</p>
      </Modal>
    </>
  );
};

RegisterForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default RegisterForm;
