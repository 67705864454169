import React, { useState } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

/**
 * Create a search filter using Ant Design Input component.
 *
 * @hook
 * @returns {SearchFilterHook} An object with functions to get the search filter
 *                             component and to filter values based on the search input.
 */
const useSearchFilter = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState();

  const getSearchFilter = () => {
    return {
      content: (
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => {
            setSearchValue(value);
          }}
        />
      ),
      type: 'search'
    };
  };

  const getSearchedValues = (values, getValueToCompare) => {
    if (!values) return [];
    return values.filter((value) => {
      return (
        getValueToCompare(value)
          ?.toLowerCase()
          .includes(searchValue?.toLowerCase()) || !searchValue
      );
    });
  };

  return { getSearchFilter, getSearchedValues };
};

export default useSearchFilter;
