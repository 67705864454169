import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Table } from 'antd';

const EventsTableForPlanning = ({ dataSource }) => {
  const { t } = useTranslation();
  const [visibleComment, setVisibleComment] = useState(false);
  const column = [
    {
      title: 'Modifié le',
      dataIndex: 'created_at',
      render: (date) => (
        <>
          <p>{moment(date).format('DD-MM-YYYY')}</p>
        </>
      )
    },
    {
      title: t('projects.form.deliverables.events.old_start_date'),
      dataIndex: 'start_date',
      render: (start, record) => (
        <div>
          <p style={{ color: 'grey', textAlign: 'center' }}>
            {record.old_start_date
              ? moment(record.old_start_date).format('DD-M-YYYY')
              : '-'}
          </p>
          <p
            style={{
              color:
                record.old_start_date !== start ? 'var(--primaryColor)' : null,
              textAlign: 'center'
            }}
          >
            {start ? moment(start).format('DD-M-YYYY') : '-'}
          </p>
        </div>
      )
    },
    {
      title: t('projects.form.deliverables.events.old_due_date'),
      dataIndex: 'due_date',
      render: (due, record, rowIndex) => (
        <>
          <div>
            <p style={{ color: 'grey' }}>
              {moment(record.old_due_date).format('DD-M-YYYY')}
            </p>
            <p
              style={{
                color:
                  record.old_due_date !== due ? 'var(--primaryColor)' : null
              }}
            >
              {moment(due).format('DD-M-YYYY')}
            </p>
          </div>
          {visibleComment === rowIndex && (
            <div
              style={{
                position: 'absolute',
                width: 400,
                zIndex: 1,
                left: -300,
                top: -20,
                border: '0.1px solid',
                backgroundColor: 'var(--bodyBackground)',
                borderRadius: 5,
                padding: 20,
                overflowX: 'scroll'
              }}
            >
              {record.comment}
            </div>
          )}
        </>
      )
    }
  ];
  return (
    <Table
      // style={{ overflowY: 'scroll', maxHeight: '100%' }}
      rowKey="_id"
      dataSource={dataSource}
      columns={column}
      pagination={false}
      onRow={(record, rowIndex) => {
        return {
          onMouseEnter: () => setVisibleComment(rowIndex),
          onMouseLeave: () => setVisibleComment(false)
        };
      }}
    />
  );
};

EventsTableForPlanning.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.array]).isRequired
};

export default EventsTableForPlanning;
