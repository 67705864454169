import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Table,
  message
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import { formItemLayout } from '../../utils/constants/formLayout';
import CreateEditModal from '../../components/CreateUpdateContainer/Modals/CreateEditModal';
import { items } from '../../utils/constants/configurableItemList';
import { getColumns, getExpandedColumns } from './Columns';

const ListCard = ({ category, perimeter }) => {
  const { t } = useTranslation();
  const purposes = { CREATE: 'create', EDIT: 'edit' };
  const [listData, setListData] = useState();
  const [visible, setVisible] = useState(false);
  const [purpose, setPurpose] = useState(false);
  const [wordToEdit, setWordToEdit] = useState();
  const [form] = Form.useForm();

  const { user, dispatchAPI } = useAuthContext();

  const editPermission =
    !perimeter &&
    ((category === 'MC' && user.role === 'admins:ADMIN') ||
      category === 'USER' ||
      (category === 'PERIMETER' && user.role.includes('admins:')));

  const getLists = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/lists?category=${category}`
      });
      if (perimeter) {
        setListData(
          items.flatMap((item) =>
            perimeter[`${item.key}_list`].map((word, index) => ({
              key: word,
              value: word,
              sub_category: item.key,
              index
            }))
          )
        );
      } else setListData(data ?? []);
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  const getData = (key) => {
    return listData.filter((data) => data.sub_category === key);
  };

  const handleCancel = () => {
    setVisible(false);
    setWordToEdit();
  };

  const handleSubmit = async (values) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/lists`,
        body: { ...values, category, wordToEdit }
      });
      await getLists();
      form.resetFields();
      setVisible(false);
      setWordToEdit();
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  const deleteWord = async (word) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/lists`,
        body: { ...word, category }
      });
      await getLists();
      form.resetFields();
      setVisible(false);
      setWordToEdit();
    } catch (e) {
      if (e.message) message.error(e.message);
    }
  };

  const columns = getColumns(t);
  const expandedColumns = getExpandedColumns(
    editPermission,
    setWordToEdit,
    setPurpose,
    form,
    setVisible,
    deleteWord,
    purposes,
    t
  );

  useEffect(() => {
    (async () => {
      await getLists();
    })();
  }, []);

  return (
    <Card
      title={t(`configurations.${perimeter ? 'title' : `titles.${category}`}`)}
    >
      <CreateEditModal
        handleCancel={handleCancel}
        handleSubmit={() => form.submit()}
        visible={visible}
        title={t(`configurations.form.title.${purpose}`)}
        form={form}
      >
        <Form {...formItemLayout} onFinish={handleSubmit} form={form}>
          <Form.Item
            label={t('configurations.form.item')}
            name="sub_category"
            rules={[{ required: true }]}
          >
            <Select>
              {items.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {`${t(`configurations.items.${item.key}`)} - ${t(
                    `configurations.items.types.${item.type}`
                  )}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('configurations.form.label')}
            name="label"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </CreateEditModal>
      <Divider />
      <Row style={{ justifyContent: 'end' }}>
        {editPermission ? (
          <Button
            type="primary"
            onClick={() => {
              setPurpose(purposes.CREATE);
              setVisible(true);
            }}
            style={{ marginBottom: 10 }}
          >
            {t(`buttons.create`)}
            <PlusOutlined />
          </Button>
        ) : null}
      </Row>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={expandedColumns}
              dataSource={getData(record.key)}
              style={{ borderBottom: 'none' }}
              pagination={false}
              showHeader={false}
            />
          )
        }}
        dataSource={items}
        pagination={false}
      />
    </Card>
  );
};

ListCard.propTypes = {
  category: PropTypes.oneOf(['MC', 'PERIMETER', 'USER']).isRequired,
  perimeter: PropTypes.shape({})
};

ListCard.defaultProps = {
  perimeter: null
};

export default ListCard;
