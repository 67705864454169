/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chart, Axis, Tooltip, Geom, Coord, Shape } from 'bizcharts';
import {
  CloseOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  CameraOutlined
} from '@ant-design/icons';
import { Row, Col, Slider, Button, DatePicker, Spin, message } from 'antd';
import html2canvas from 'html2canvas';
import Logo from '../../assets/images/blue-drop-upsidedown.png';

const { RangePicker } = DatePicker;

/**
 * A Gantt chart component for displaying tasks and their schedules.
 *
 * @component
 * @param {Object[]} data - An array of task data objects.
 * @param {Function} onClick - A function to handle click events on the chart.
 * @param {string} purpose - The purpose of the Gantt chart.
 * @param {number} lengthForFederation - Length value for federation (optional).
 * @param {number} defaultLengthSize - Default length size (optional).
 * @param {Date[]} selectedDates - An array of selected dates (optional).
 * @returns {JSX.Element} - The Gantt chart component.
 */
const Gantt = ({
  data,
  onClick,
  purpose,
  lengthForFederation,
  defaultLengthSize,
  selectedDates
}) => {
  const [dataChart, setDataChart] = useState();
  const { t } = useTranslation();
  const [scaleChart, setScaleChart] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [widthChart, setWidthChart] = useState({
    width: 2500,
    height: 200
  });
  const [scaleTranspose, setScaleTranspose] = useState(1);
  const [dates, setDates] = useState();

  const setScale = () => {
    setIsLoading(true);
    setScaleChart({
      range: {
        alias: 'Temps',
        tickCount: 20,
        type: 'time',
        formatter: (timeValue) => moment(timeValue).format('DD-MM-YYYY')
      }
    });
    setIsLoading(false);
  };
  const values = [
    'En cours',
    'Finalisé',
    'Planifié',
    'A planifier',
    'Suspendu',
    'Annulé',
    'En attente de validation'
  ];

  const colors = ['#000'];

  const getData = () => {
    const macroTasks = [];
    const microTasks = [];

    data.forEach((task) => {
      if (task.action_type === 'MACRO_TASK') {
        macroTasks.push(task);
      } else {
        microTasks.push(task);
      }
    });

    const tasks = macroTasks.concat(microTasks);

    const sortDataChart = tasks.sort(
      (a, b) => new Date(a?.start_date) - new Date(b?.start_date)
    );

    if (sortDataChart !== undefined) {
      sortDataChart.forEach((obj) => {
        obj.range = [obj.start_date, obj.due_date];
        obj.status = values[values.indexOf(obj.status)];
      });
    }
    return sortDataChart;
  };

  useEffect(() => {
    if (data) {
      setScale();
      setWidthChart({
        ...widthChart,
        height: lengthForFederation
          ? 200 + lengthForFederation * 30
          : 200 + data.length * 30
      });
      setDataChart(getData());
    }
  }, [data]);

  useEffect(() => {
    Shape.registerShape('point', 'image', {
      draw(cfg, container) {
        cfg.points = this.parsePoints(cfg.points);
        const avatarHeight = cfg.size;
        const avatarWidth = cfg.size - 5;
        const avatarX = cfg.points[0].x;
        const avatarY = cfg.points[0].y - avatarHeight / 2;

        return container.addShape('image', {
          attrs: {
            x: avatarX - avatarWidth + 10,
            y: avatarY - avatarHeight + 20,
            width: avatarWidth,
            height: avatarHeight,
            img: cfg.shape[1]
          }
        });
      }
    });
  }, [dataChart]);

  const handleChangeRange = () => {
    if (dates?.length && purpose !== 'gantt_gap') {
      const result = [];
      const sortedData = getData();
      for (let i = 0; i < sortedData?.length; i += 1) {
        if (
          (moment(sortedData[i].start_date).diff(moment(dates[0]), 'days') >=
            0 &&
            moment(sortedData[i].start_date).diff(moment(dates[1]), 'days') <=
              0) ||
          (moment(sortedData[i].due_date).diff(moment(dates[0]), 'days') >= 0 &&
            moment(sortedData[i].due_date).diff(moment(dates[1]), 'days') <=
              0) ||
          (moment(sortedData[i].start_date) <= moment(dates[0]) &&
            moment(sortedData[i].due_date) >= moment(dates[1]))
        ) {
          result.push(sortedData[i]);
        }
      }
      setDataChart(result);
      setScale();
    } else {
      setDataChart(getData());
    }
  };

  useEffect(() => {
    handleChangeRange();
  }, [dates]);

  useEffect(() => {
    setDates(selectedDates);
  }, [selectedDates]);

  const label = {
    formatter(text) {
      return text.substring(24);
    }
  };

  const onHandleChangeSize = (value) => {
    setWidthChart({
      ...widthChart,
      width: value
    });
    setScale();
  };

  const getScreenShotOfChart = async () => {
    try {
      const result = await html2canvas(
        document.querySelector(`#${purpose}`)
      ).then((canvas) => {
        return canvas.toDataURL();
      });
      const a = document.createElement('a');
      a.href = result;
      a.download = `gantt.png`;
      a.click();
    } catch (error) {
      message.error('Problème de capture, veuillez recharger la page');
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Row
          justify="space-around"
          style={{
            marginBottom: 20,
            marginTop: 20
          }}
        >
          <Col span={6}>
            <Button
              type="link"
              style={{ color: 'var(--primaryColor)' }}
              onClick={() => setScaleTranspose(1)}
            >
              {t('projects.show.gantt.chronological')}
            </Button>
            <Button
              type="link"
              style={{ color: 'var(--primaryColor)' }}
              onClick={() => setScaleTranspose(-1)}
            >
              {t('projects.show.gantt.antechronological')}
            </Button>
          </Col>
          <Col style={{ textAlign: 'center' }} span={8}>
            <RangePicker
              syle={{ width: 250 }}
              defaultValue={dates}
              value={dates}
              onChange={(value) => setDates(value)}
            />
            <p style={{ fontSize: 15, color: 'var(--disabledColor)' }}>
              {t('projects.show.gantt.filter_description')}
            </p>
          </Col>
          <Col span={4}>
            <Row>
              <ZoomOutOutlined
                style={{ fontSize: 20, color: 'var(--disabledColor)' }}
              />
              <Slider
                style={{ width: 200 }}
                min={1000}
                max={4000}
                step={10}
                defaultValue={widthChart.width}
                onChange={onHandleChangeSize}
              />
              <ZoomInOutlined
                style={{ fontSize: 20, color: 'var(--disabledColor)' }}
              />
            </Row>
          </Col>
          {purpose !== 'dashboard' && (
            <Col style={{ textAlign: 'center' }} span={4}>
              <CameraOutlined
                style={{ fontSize: 25, color: 'var(--primaryColor)' }}
                onClick={() => getScreenShotOfChart()}
              />
            </Col>
          )}
        </Row>
        <div
          id={purpose}
          style={{
            overflowY: 'auto',
            maxHeight: 600
          }}
        >
          {dataChart && scaleChart ? (
            <Chart
              onClick={onClick || null}
              theme="light"
              padding="auto"
              width={defaultLengthSize || widthChart.width}
              height={widthChart.height}
              data={dataChart || []}
              autoFit
              scale={scaleChart}
            >
              <Axis name="task" label={label} />
              <Tooltip shared />
              <Axis name="range" />
              <Coord transpose scale={[scaleTranspose, -1]} />
              <Geom
                size={[
                  'action_type',
                  (action_type) => (action_type === 'MACRO_TASK' ? 30 : 20)
                ]}
                tooltip={[
                  'status*due_date*description*start_date*init_start*init_due',
                  (
                    status,
                    due_date,
                    description,
                    start_date,
                    init_start,
                    init_due
                  ) => {
                    if (purpose === 'federation') {
                      return {
                        name: status,
                        title: description,
                        value: `${
                          init_start !== init_due
                            ? `Date de début: ${moment(init_start).format(
                                'dddd DD-MM-YYYY'
                              )} - `
                            : ''
                        } Date de Fin: ${moment(init_due || due_date).format(
                          'dddd DD-MM-YYYY'
                        )}`
                      };
                    }
                    return {
                      name: status,
                      title: description,
                      value: `${
                        start_date !== due_date
                          ? `Date de début: ${moment(start_date).format(
                              'dddd DD-MM-YYYY'
                            )} - `
                          : ''
                      } Date de Fin: ${moment(due_date).format(
                        'dddd DD-MM-YYYY'
                      )}`
                    };
                  }
                ]}
                shape={[
                  'start_date*due_date',
                  (start_date, due_date) => {
                    if (
                      moment(start_date).format('DD-MM-YYYY') ===
                      moment(due_date).format('DD-MM-YYYY')
                    )
                      return '';
                    return 'rect';
                  }
                ]}
                type="intervalDodge"
                position="task*range"
                color={['color', (value) => value || colors]}
              />
              <Geom
                type="point"
                position="task*range"
                size={25}
                shape={[
                  'start_date*due_date',
                  (start_date, due_date) => {
                    if (
                      moment(start_date).format('DD-MM-YYYY') ===
                      moment(due_date).format('DD-MM-YYYY')
                    ) {
                      return ['image', Logo];
                    }
                    return null;
                  }
                ]}
              />
            </Chart>
          ) : (
            <span>
              <CloseOutlined style={{ color: '#fff' }} />
            </span>
          )}
        </div>
      </Spin>
    </>
  );
};

Gantt.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  onClick: PropTypes.func,
  purpose: PropTypes.string,
  lengthForFederation: PropTypes.number,
  defaultLengthSize: PropTypes.number,
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};

Gantt.defaultProps = {
  data: [],
  onClick: null,
  purpose: null,
  lengthForFederation: undefined,
  defaultLengthSize: null,
  selectedDates: []
};

export default Gantt;
