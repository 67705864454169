import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';
import { ScanContextProvider } from './Scan/ScanContext';
import { TemplatesContextProvider } from './Templates/TemplatesContext';
import DocumentsManager from './GED/DocumentsManager';
import CreateFile from './GED/CreateFile';

const DocumentsRouter = () => {
  return (
    <TemplatesContextProvider>
      <ScanContextProvider>
        <Switch>
          <Route
            exact
            path={`${routes.DOCUMENTS}`}
            component={DocumentsManager}
          />
          <Route
            exact
            path={`${routes.DOCUMENTS}/create`}
            component={CreateFile}
          />
        </Switch>
      </ScanContextProvider>
    </TemplatesContextProvider>
  );
};

export default DocumentsRouter;
