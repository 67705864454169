import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { routes } from '../../utils/constants/routes';

import ShowHistory from './ShowHistory';
import { ProjectContextProvider } from '../../contexts/ProjectContext';

const HistoryRouter = () => {
  const { path } = useRouteMatch();
  return (
    <ProjectContextProvider>
      <Switch>
        <Route path={`${path}`} component={ShowHistory} />
        <Route
          path={`${path}${routes.PROJECTS}/show/:id`}
          render={() => <Redirect to={`${path}${routes.PROJECTS}/show/:id`} />}
        />
      </Switch>
    </ProjectContextProvider>
  );
};
export default HistoryRouter;
