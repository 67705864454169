import React from 'react';

import useSelectFilter from '../../../utilsProject/Filters/useSelectFilter';
import useSearchFilter from '../../../utilsProject/Filters/useSearchFilter';
import ItemFilters from '../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing and using filters for actors.
 *
 * @hook
 * @param {Array} roles - An array of available roles.
 * @returns {Object} - An object containing functions and data for actors filtering.
 */
const useActorsFilters = (roles) => {
  const { getSearchFilter, getSearchedValues } = useSearchFilter();
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    roles,
    'roles'
  );

  const getActorsItemFilters = () => {
    return <ItemFilters filters={[getSearchFilter(), getSelectFilter()]} />;
  };

  const getFilteredActorsItems = (actorsItem) => {
    if (!actorsItem) return [];
    return {
      actors: getFilteredValues(
        getSearchedValues(
          actorsItem.actors,
          (actor) => `${actor?.first_name} ${actor?.last_name} ${actor?.code}`
        ),
        (actor) => actor?.role
      ),
      groups: getFilteredValues(
        getSearchedValues(
          actorsItem.groups,
          (group) => `${group?.code} ${group?.code}`
        ),
        (group) => group?.role
      )
    };
  };

  return { getActorsItemFilters, getFilteredActorsItems };
};

export default useActorsFilters;
