import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Input, Spin, Table, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import AddUserModal from './AddUserModal';
import useColumns from './useColumns';

const { Search } = Input;

/**
 * Table that will show the list of users in the perimeter group of a perimeter admin
 *
 * @component
 * @param {object} perimeterAdmin Perimeter admin object
 * @param {function} setRefresh Function to refresh the table
 * @param {boolean} refresh Boolean to refresh the table
 * @returns {JSX} React component
 */
const PerimeterGroup = ({ perimeterAdmin, setRefresh, refresh }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [users, setUsers] = useState([]);
  const [usersToAddOrRemove, setUsersToAddOrRemove] = useState([]);
  const [purpose, setPurpose] = useState();

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?role!=admins:ADMIN'
      });
      setUsers(data ?? null);
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  const updatePerimeterAdmin = async () => {
    try {
      setLoading(true);
      await dispatchAPI('PATCH', {
        url: `users/${perimeterAdmin?._id}`,
        body: { usersArray: usersToAddOrRemove, purpose }
      });
      setLoading(false);
      setRefresh(!refresh);
      message.success(t(`users.perimeter_group.updated`));
    } catch (e) {
      setLoading(false);
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setUsersToAddOrRemove([]);
  };

  const handleSelectChange = (selectedUsers) => {
    setUsersToAddOrRemove(selectedUsers);
  };

  const columns = useColumns(perimeterAdmin, setPurpose, setUsersToAddOrRemove);

  useEffect(() => {
    try {
      if (purpose) {
        (async () => {
          await updatePerimeterAdmin();
          setVisible(false);
          setPurpose();
        })();
      }
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
      setLoading(false);
    }
  }, [purpose]);

  useEffect(() => {
    try {
      (async () => {
        await getUsers();
      })();
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
    }
  }, []);

  const displayedUsers = perimeterAdmin?.users?.filter((user) =>
    user.keywords?.some((keyword) => keyword.includes(searchValue || ''))
  );

  return users ? (
    <Spin spinning={loading}>
      <Card
        title={t(`perimeters.form.perimeter_group.title`, {
          perimeterAdmin
        })}
        className="perimeter-users-card"
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setVisible(true);
            }}
          >
            {t(`buttons.add`)}
          </Button>
        }
        style={{ marginTop: '16px' }}
      >
        <AddUserModal
          visible={visible}
          setVisible={setVisible}
          handleCancel={handleCancel}
          setPurpose={setPurpose}
          handleSelectChange={handleSelectChange}
          users={users.filter(
            (user) => !perimeterAdmin?.users.some((u) => user._id === u._id)
          )}
          perimeterAdmin={perimeterAdmin}
        />
        <Divider />
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => setSearchValue(value)}
          style={{ marginBottom: '16px' }}
        />
        <Table
          pagination={false}
          columns={columns}
          dataSource={displayedUsers}
          rowKey="_id"
        />
      </Card>
    </Spin>
  ) : (
    <Spin />
  );
};

PerimeterGroup.propTypes = {
  perimeterAdmin: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  setRefresh: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired
};

PerimeterGroup.defaultProps = {};

export default PerimeterGroup;
