import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Row, Col, Tooltip } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { CreateIcon } from '../../../../utils/constants/customIcon';
import CreateProjectFormModal from '../CreateProjectFormModal/CreateProjectFormModal';

/**
 * The ButtonCreateProject component displays a button to create a new project.
 *
 * @component
 * @returns {JSX.Element} - The ButtonCreateProject component.
 */
const ButtonCreateProject = () => {
  const { dispatchAPI } = useAuthContext();
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { message, success } = useErrorMessage();

  const createResource = async (body) => {
    try {
      const response = await dispatchAPI('POST', {
        url: 'projects',
        body
      });
      success(t('projects.form.title.created'));
      history.push(`/projects/projects/show/${response.data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleSubmit = async (values) => {
    setVisible(false);
    await createResource(values);
  };

  return (
    <Row justify="space-around" gutter={[48, 48]}>
      <Col>
        <Row align="middle">
          <Tooltip title={t(`breadcrumbs.create`)}>
            <Button
              type="link"
              className="light"
              style={{
                padding: '4px 0',
                top: -1
              }}
              onClick={showModal}
            >
              <CreateIcon />
            </Button>
          </Tooltip>
        </Row>

        <CreateProjectFormModal
          handleSubmit={handleSubmit}
          visible={visible}
          setVisible={setVisible}
          purpose="create"
          loading={false}
        />
      </Col>
    </Row>
  );
};

export default ButtonCreateProject;
