import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import Filters from './Filters';
import GanttChart from './GanttChart';
import { formatProjectData } from './utils';

/**
 * Displays a Gantt chart of projects based on the specified purpose and filters.
 *
 * @component
 * @param {string} purpose - The purpose of displaying the Gantt chart ('actor' or 'supervisor').
 * @param {string} projectsQuery - The query string to filter the projects.
 * @param {string} projectPath - The path to individual project details.
 * @returns {JSX.Element} The rendered Gantt chart component with filters and project data.
 */
const ProjectsGantt = ({ purpose, projectsQuery, projectPath }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const [selectedPerimeter, setSelectedPerimeter] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedManager, setSelectedManager] = useState();
  const [perimeters, setPerimeters] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [formattedProjects, setFormattedProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState();

  const fetchProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects${
          purpose === 'actor' ? '/actorView' : ''
        }?status!=closed${projectsQuery ? `&${projectsQuery}` : ''}${
          selectedPerimeter ? `&perimeter=${selectedPerimeter}` : ''
        }${selectedCustomer ? `&customer=${selectedCustomer}` : ''}${
          selectedManager ? `&assignee=${selectedManager}` : ''
        }&populate=assignee,perimeter`
      });
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      if (e.response) message(e.response.status);
      return null;
    }
  };

  const getProjects = async () => {
    try {
      const projects = await fetchProjects();
      const {
        newPerimeters,
        newCustomers,
        newManagers,
        newFormattedProjects
      } = formatProjectData(projects);
      setPerimeters(newPerimeters);
      setCustomers(newCustomers);
      setManagers(newManagers);
      setFormattedProjects(newFormattedProjects);
    } catch (error) {
      setLoading(false);
      if (error.response) message(error.response.status);
    }
  };

  const fetchSelectedProject = async (projectId) => {
    try {
      setLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${projectId}?populate=assignee,perimeter`
      });
      setSelectedProject(data ?? null);
    } catch (e) {
      setLoading(false);
      if (e.response) message(e.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProjects();
    })();
  }, [selectedPerimeter, selectedCustomer, selectedManager]);

  return formattedProjects ? (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`${purpose}.title`)}
      </div>
      <Spin spinning={loading} tip={t('global.loading')}>
        <Filters
          setSearchValue={setSearchValue}
          setSelectedPerimeter={setSelectedPerimeter}
          setSelectedCustomer={setSelectedCustomer}
          setSelectedManager={setSelectedManager}
          searchValue={searchValue}
          perimeters={perimeters}
          customers={customers}
          managers={managers}
        />
        <GanttChart
          projects={formattedProjects}
          fetchSelectedProject={fetchSelectedProject}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          searchValue={searchValue}
          projectPath={projectPath}
        />
      </Spin>
    </>
  ) : (
    <Spin />
  );
};

ProjectsGantt.propTypes = {
  purpose: PropTypes.string,
  projectsQuery: PropTypes.string,
  projectPath: PropTypes.string
};

ProjectsGantt.defaultProps = {
  purpose: 'actor',
  projectsQuery: '',
  projectPath: ''
};

export default ProjectsGantt;
