import React from 'react';
import { Select, Input, InputNumber, DatePicker } from 'antd';
import useProjectContext from '../../../../contexts/ProjectContext';
import useGlossarySelect from './Inputs/useGlossarySelect';

const { TextArea } = Input;
const { Option } = Select;

const useRisksFields = (id, enums, loading) => {
  const { project } = useProjectContext();

  const typeSelect = useGlossarySelect(project?.risks_list, loading, 'type');

  const impact = ['Très limité', 'Limité', 'Modéré', 'Elevé', 'Très élevé'];
  const probability = ['Très faible', 'Faible', 'Moyen', 'Fort', 'Très fort'];
  const detection = [
    'Fortes probalités de détection',
    "Probalités d'être détecté",
    'Détectable',
    'Presque indétectable',
    'Indétectable'
  ];
  const decision = [
    'Couverture préventive du risque',
    'Couverture curative du risque',
    'Pas de couverture du risque grave'
  ];
  const risksFields = [
    {
      name: ['risk_list', '_id'],
      hidden: true,
      noStyle: true
    },
    {
      name: ['risk_list', 'number'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['risk_list', 'potential_risk'],
      rules: [{ required: true }],
      input: <TextArea />
    },
    {
      name: ['risk_list', 'type_of_risk'],
      rules: [{ required: true }],
      input: typeSelect
    },
    {
      name: ['risk_list', 'impact_effect'],
      rules: [{ required: true }],
      input: <TextArea />
    },
    {
      name: ['risk_list', 'probability'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {probability.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'detection'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {detection.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'impact'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {impact.map((e, index) => (
            <Option key={e} value={index + 1}>
              {`${index + 1} : ${e}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['risk_list', 'criticality'],
      rules: [{ required: true }],
      input: <InputNumber disabled />
    },
    {
      name: ['risk_list', 'decision'],
      rules: [{ required: true }],
      input: (
        <Select loading={loading}>
          {decision.map((value) => {
            return value ? (
              <Option key={value} value={value}>
                {value}
              </Option>
            ) : null;
          })}
        </Select>
      )
    },
    {
      name: ['risk_list', 'decision_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD-MM-YYYY"
          bordered={false}
          placeholder="Date..."
        />
      )
    },
    {
      name: ['risk_list', 'measure_description'],
      rules: [{ required: false }],
      input: <TextArea />
    }
  ];

  return { risksFields };
};

export default useRisksFields;
