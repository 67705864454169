import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Badge, Row, message } from 'antd';
import HeaderDropdown from '../../../HeaderDropdown/HeaderDropdown';
import NotificationMenu from './NotificationMenu';
import useAuthContext from '../../../../contexts/AuthContext';
import { useSocketContext } from '../../../../contexts/SocketContext';

/**
 * NotificationDropDown is a React component that provides a dropdown menu for notifications.
 * It displays a bell icon with a badge indicating the number of unread notifications.
 *
 * @component
 * @returns {ReactNode} - A ReactNode representing the NotificationDropDown component.
 */
const NotificationDropDown = () => {
  const { dispatchAPI } = useAuthContext();
  const { socket } = useSocketContext();
  const [notifications, setNotifications] = useState([]);

  const getNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/notifications' });
      if (data) setNotifications(data);
    } catch (error) {
      if (error.message) message.error(error.message);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('notification', getNotifications);
    }
  }, [socket]);

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <HeaderDropdown
      overlayMenu={() => (
        <NotificationMenu
          notifications={notifications}
          getNotifications={getNotifications}
        />
      )}
      onOpenChange={getNotifications}
    >
      <Row className="styled-dropdown-container">
        <Badge
          count={notifications.filter((n) => !n.isRead).length}
          offset={[10, 0]}
        >
          <BellOutlined
            style={{
              fontSize: 20
            }}
          />
        </Badge>
      </Row>
    </HeaderDropdown>
  );
};

export default NotificationDropDown;
