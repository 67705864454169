import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DatePicker, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import FederationIdicator from '../../../../FederationIdicator';
import EditButton from '../../../../../../../../../../components/EditButton/EditButton';
import useProjectContext from '../../../../../../../../../../contexts/ProjectContext';
import FederationInput from '../../../../../../../fieldsItems/Inputs/FederationInput';

/**
 * A hook that returns columns configuration for a table of repeated events.
 *
 * @hook
 * @param {string} display - A string representing the display type of the table (e.g. past or scheduled).
 * @returns {Array} - An array of column configuration objects.
 */
const useColumns = (display) => {
  const { t } = useTranslation();
  const { patchDuplicateEvent } = useProjectContext();
  const [eventToEdit, setEventToEdit] = useState();

  const handleSubmit = async () => {
    try {
      await patchDuplicateEvent(eventToEdit, eventToEdit._id);
      setEventToEdit();
    } catch (error) {
      if (error.response) message.error(error.response);
    }
  };

  const columns = [
    {
      title: t('projects.form.deliverables_plan_com.start_date'),
      dataIndex: 'start_date',
      render: (date, record) =>
        record._id === eventToEdit?._id ? (
          <DatePicker
            allowClear={false}
            defaultValue={moment(date)}
            onChange={(value) =>
              setEventToEdit({ ...eventToEdit, start_date: value })
            }
          />
        ) : (
          moment(date).format('DD/MM/YYYY')
        )
    },
    {
      title: t('projects.form.deliverables_plan_com.federation'),
      dataIndex: 'federation',
      render: (federation, record) =>
        record._id === eventToEdit?._id ? (
          <FederationInput
            defaultValue={eventToEdit.federation}
            onChange={(value) =>
              setEventToEdit({ ...eventToEdit, federation: value })
            }
          />
        ) : (
          <FederationIdicator federationValue={federation} />
        )
    },
    display === 'past' && {
      render: (record) =>
        record._id === eventToEdit?._id ? (
          <>
            <CheckOutlined
              onClick={handleSubmit}
              style={{ marginRight: '16px' }}
            />
            <CloseOutlined onClick={() => setEventToEdit()} />
          </>
        ) : (
          <EditButton onClick={() => setEventToEdit(record)} />
        )
    }
  ].filter(Boolean);

  return columns;
};

export default useColumns;
