import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Select, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';

/**
 * Modal to add users to a perimeter.
 *
 * @component
 * @param {Object} props - Props object.
 * @param {Boolean} props.visible - Whether the modal is visible or not.
 * @param {Function} props.setVisible - Function to set the visibility of the modal.
 * @param {Function} props.setPurpose - Function to set the purpose of the modal.
 * @param {Function} props.setUsersToAddOrRemove - Function to set the users to add or remove (array of IDs).
 * @param {Array} props.users - The list of users.
 * @param {String} props.perimeterName - The name of the perimeter.
 * @returns {JSX} React component
 */
const AddUsersModal = ({
  visible,
  setVisible,
  setPurpose,
  setUsersToAddOrRemove,
  users,
  perimeterName
}) => {
  const { user, dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [perimeterAdmin, setPerimeterAdmin] = useState();

  const getPerimeterAdmin = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${user._id}`
      });
      setPerimeterAdmin(data ?? null);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setUsersToAddOrRemove([]);
  };

  const handleSelectChange = (selectedUsers) => {
    setUsersToAddOrRemove(selectedUsers);
  };

  useEffect(() => {
    (async () => {
      await getPerimeterAdmin();
    })();
  }, []);

  return (
    <Modal
      closable
      destroyOnClose
      centered
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      title={`${t('perimeters.form.users.title')} ${perimeterName}`}
      footer={[
        <Button
          key="cancel"
          style={{ margin: '0 6px' }}
          type="link"
          danger
          onClick={handleCancel}
        >
          <span>{`${t('buttons.cancel')} `}</span>
          <CloseOutlined />
        </Button>,
        <Button
          key="submit"
          type="add"
          htmlType="submit"
          onClick={() => setPurpose('add')}
        >
          <span>{`${t('buttons.save')} `}</span>
          <CheckOutlined />
        </Button>
      ]}
    >
      <span>{`${t('perimeters.form.email_search')} : `}</span>
      <Select
        mode="multiple"
        placeholder={t('perimeters.form.email_placeholder')}
        onChange={handleSelectChange}
        style={{ width: '300px', marginLeft: '16px' }}
        optionFilterProp="children"
      >
        {users
          ?.filter((u) =>
            perimeterAdmin?.users?.some((userId) => userId === u._id)
          )
          .map((u) => (
            <Select.Option key={u._id} value={u._id}>
              {u.email}
            </Select.Option>
          ))}
      </Select>
    </Modal>
  );
};

export default AddUsersModal;

AddUsersModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setPurpose: PropTypes.func,
  setUsersToAddOrRemove: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      email: PropTypes.string
    })
  ),
  perimeterName: PropTypes.string
};

AddUsersModal.defaultProps = {
  visible: false,
  setVisible: () => {},
  setPurpose: () => {},
  setUsersToAddOrRemove: () => {},
  users: [],
  perimeterName: ''
};
