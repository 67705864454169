import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import usePerimeterContext from '../../../contexts/PerimeterContext';
import CreateUpdatePerimeterForm from './Form/CreateUpdatePerimeterForm';

const CreateOrUpdatePerimeter = ({ purpose }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { getPerimeter, perimeter, getPerimeters } = usePerimeterContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      if (purpose === 'edit') {
        (async () => {
          await getPerimeter(id);

          setLoading(false);
        })();
      } else {
        form.setFieldsValue({
          attached_files: true,
          import_perimeter: {
            import: false,
            perimeter: '',
            configurations: ['users', 'glossary']
          }
        });
        setLoading(false);
      }

      (async () => {
        await getPerimeters();
      })();
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  }, []);

  useEffect(() => {
    if (perimeter) {
      if (purpose === 'edit') form.setFieldsValue(perimeter);
      form.setFieldsValue({
        assignee: perimeter.assignee?._id
      });
    }
  }, [perimeter]);

  return (
    <>
      <PageHeaderCustom title={t(`perimeters.form.title.${purpose}`)} />
      <Spin spinning={loading}>
        <ContentCustom>
          <CreateUpdatePerimeterForm
            form={form}
            purpose={purpose}
            perimeter={perimeter}
          />
        </ContentCustom>
      </Spin>
    </>
  );
};

export default CreateOrUpdatePerimeter;

CreateOrUpdatePerimeter.propTypes = {
  purpose: PropTypes.string.isRequired
};
