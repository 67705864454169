import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import useAuthContext from '../../../contexts/AuthContext';
import Datatable from '../../../components/DataTable/Datatable';
import usePerimeterColumns from './usePerimeterColumns';

/**
 *  List of perimeters without perimeter admin
 *
 * @returns {JSX.Element} Perimeters
 */
const Perimeters = () => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const history = useHistory();

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  const columns = usePerimeterColumns();

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  return (
    <>
      <div style={{ fontSize: '21px', marginBottom: 10 }}>
        {t(`perimeters.home.${user?.role.split(':')[1]}`)}
      </div>
      <Row className="custom-header">
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Datatable
            resourceName="perimeters"
            populate="users,assignee"
            resourceModelName="Perimeter"
            extraQuery="assignee=null&default=false"
            columns={columns}
            customActionColumn
            disableDoubleClick
          />
        </Col>
      </Row>
    </>
  );
};

export default Perimeters;
