import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Component to display header information for a utility item.
 *
 * @component
 * @param {Object} deliverable - The deliverable object containing utility information.
 * @param {string} deliverable.description - The description of the deliverable.
 * @param {Object} deliverable.utility - The utility information of the deliverable.
 * @param {string} deliverable.utility.use_for - The beneficiaries type for the deliverable.
 * @param {number} deliverable.utility.coefficient - The coefficient of the deliverable.
 * @param {string} deliverable.utility.why - The reason for the deliverable.
 * @returns {JSX.Element} - The rendered Header component.
 */
const Header = ({ deliverable }) => {
  const { t } = useTranslation();
  const { description } = deliverable;
  const { use_for, coefficient, why } = deliverable?.utility;

  return (
    <Descriptions column={5} size="small">
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.description`)}
      >
        {description}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.use_for`)}
      >
        {t(`projects.form.items.utility.deliverables.beneficiaries.${use_for}`)}
      </Descriptions.Item>
      <Descriptions.Item
        label={t(`projects.form.items.utility.deliverables.coefficient`)}
      >
        {coefficient}
      </Descriptions.Item>
      <Descriptions.Item
        span={2}
        label={t(`projects.form.items.utility.deliverables.why`)}
      >
        {why}
      </Descriptions.Item>
    </Descriptions>
  );
};

Header.propTypes = {
  deliverable: PropTypes.shape({
    description: PropTypes.string,
    utility: PropTypes.shape({})
  }).isRequired
};

export default Header;
