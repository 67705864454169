import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import useColumns from './useColumns';
import { routes } from '../../utils/constants/routes';

/**
 * Table wih all perimeter admins.
 *
 * @component
 * @return {JSX.Element} React component for the list of perimeters admin.
 * @example
 */
const ListUsers = () => {
  const { t } = useTranslation();

  const iconSize = 18;

  const actionColumn = [
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.PERIMETER_ADMINS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
        </div>
      ),
      fixed: 'rigth'
    }
  ];

  const columns = useColumns(actionColumn);

  return (
    <SimpleResourceLandingLayout
      extraQuery="role=admins:PERIMETER_ADMIN"
      columns={columns}
      customActionColumn
      resourceName="users"
      resourceModelName="User"
      tradKey={t(`basiclayout.menu.perimeter_admins`)}
      withUploadButton={false}
    />
  );
};

export default ListUsers;
