import React from 'react';
import { Select } from 'antd';

/**
 * Functional component to render a Select input with actors' options.
 *
 * @component
 * @param {Array} actorsItem - Array of actors.
 * @param {boolean} loading - Indicates if data is loading.
 * @param {boolean} disabled - Indicates if the input should be disabled.
 * @param {Function} onChange - Function to handle the change event.
 * @returns {JSX.Element} Select with actors list.
 */
export default (actorsItem, loading, disabled, onChange) => {
  return (
    <Select
      showSearch
      loading={loading}
      disabled={disabled}
      onChange={onChange}
    >
      {actorsItem?.actors?.map((actor) => {
        return actor ? (
          <Select.Option
            key={actor._id}
            value={`${actor?.first_name || ''} ${actor?.last_name || ''}${
              actor?.role ? ` - ${actor.role}` : ''
            }`}
          />
        ) : null;
      })}
      {actorsItem?.groups.map((group) => {
        return !group?.default ? (
          <Select.Option
            style={{ width: 300 }}
            key={group._id}
            value={`${`[${group.name}]` || ''}${
              group?.role ? ` - ${group.role}` : ''
            }`}
          />
        ) : null;
      })}
    </Select>
  );
};
