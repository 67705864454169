export const items = [
  { type: 'what', model_name: 'WhatItem', version_model: 'ObjectVersion' },
  { type: 'why', model_name: 'WhyItem', version_model: 'ObjectVersion' },
  { type: 'prior', model_name: 'PriorItem', version_model: 'Version' },
  { type: 'context', model_name: 'ContextItem', version_model: 'Version' },
  { type: 'actors', model_name: 'ActorsItem', version_model: 'ObjectVersion' },
  { type: 'cost', model_name: 'CostItem', version_model: 'Version' },
  { type: 'risks', model_name: 'RisksItem', version_model: 'Version' },
  { type: 'planning', model_name: 'PlanningItem', version_model: 'Version' },
  { type: 'utility', model_name: 'UtilityItem', version_model: 'Version' },
  {
    type: 'manage_planning',
    model_name: 'ManagePlanningItem',
    version_model: 'Version'
  },
  { type: 'plan_com', model_name: 'PlanComItem', version_model: 'Version' }
];

/**
 * Finds an item by its type.
 *
 * @param {string} type - The type of the item to search for.
 * @returns {Object|undefined} The item corresponding to the type, or undefined if not found.
 */
export const getItemByType = (type) => items.find((item) => item.type === type);

/**
 * Gets the version model name associated with an item type.
 *
 * @param {string} type - The type of the item to get the version model for.
 * @returns {string|undefined} The corresponding version model name, or undefined if not found.
 */
export const getVersionModelByType = (type) =>
  items.find((item) => item.type === type)?.version_model;
