import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const CreateEditModal = ({
  children,
  visible,
  handleCancel,
  handleSubmit,
  title,
  noFooter
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      closable
      destroyOnClose
      centered
      visible={visible}
      onCancel={handleCancel}
      title={title}
      footer={
        !noFooter && [
          <Button
            key="cancel"
            style={{ margin: '0 6px' }}
            type="link"
            danger
            onClick={handleCancel}
          >
            <span>{`${t('buttons.cancel')} `}</span>
            <CloseOutlined />
          </Button>,
          <Button
            key="submit"
            type="add"
            htmlType="submit"
            onClick={handleSubmit}
          >
            <span>{`${t('buttons.save')} `}</span>
            <CheckOutlined />
          </Button>
        ]
      }
    >
      {children}
    </Modal>
  );
};

CreateEditModal.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  noFooter: PropTypes.bool
};

CreateEditModal.defaultProps = {
  children: null,
  visible: true,
  handleCancel: null,
  handleSubmit: null,
  title: '',
  noFooter: false
};

export default CreateEditModal;
