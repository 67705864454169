import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row, Card, Skeleton, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import Gantt from '../../components/Widgets/Gantt';
import { getItemVersion } from '../Projects/Projects/utilsProject/ManageVersion/utils';
import { checkActionActor } from '../Projects/Projects/utilsProject/ManagePlanning/utils';
import useAuthContext from '../../contexts/AuthContext';

/**
 * Component for displaying project indicators on a dashboard.
 *
 * @component
 * @param {Object} data - The project data.
 * @param {Function} getDataChartEvent - Callback function to get chart data for events.
 * @param {string} purpose - The purpose of the component.
 * @returns {JSX.Element} - A Gantt chart.
 */
const IndicatorsDashboard = ({ data, getDataChartEvent, purpose }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [dataGantt, setDataGantt] = useState();

  const lastManangePlanningVersion = getItemVersion(data?.manage_planning, -1);
  const lastPlanComVersion = getItemVersion(data?.plan_com, -1);

  const getDataChart = () => {
    try {
      if (lastPlanComVersion?.content) {
        const previousPlanCom = [];
        lastPlanComVersion.content.forEach((p) => {
          if (p.start_date) {
            const days = moment(Date.now()).diff(moment(p.start_date), 'days');
            previousPlanCom.push({
              date: p?.start_date,
              daysDiff: days,
              actor: p?.in_charge_of
            });
          }
        });
      }

      if (lastManangePlanningVersion?.content) {
        const chartData = [];
        lastManangePlanningVersion.content
          .filter(
            (action) =>
              purpose !== 'actor' || checkActionActor(action, user._id)
          )
          .map((e) => {
            if (e?.planning?.gantt_mark) {
              const {
                start_date,
                due_date,
                status,
                color,
                action_type
              } = e.planning;
              if ((start_date, due_date !== null)) {
                chartData.push({
                  id: e?._id,
                  task: `${e?._id + e?.description}`,
                  start_date: start_date
                    ? moment(start_date).format('YYYY-MM-DD')
                    : moment(due_date).format('YYYY-MM-DD'),
                  due_date: moment(due_date).format('YYYY-MM-DD'),
                  status,
                  description: e?.description,
                  comment: e?.comment,
                  color: color || '#000',
                  action_type
                });
              }
            }
            setDataGantt(chartData);
            return true;
          });
      }
      return true;
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  useEffect(() => {
    getDataChart();
  }, [data]);

  return data ? (
    <>
      <Row justify="space-between" gutter={16}>
        <Col xxl={24} sm={24} xs={24}>
          {lastManangePlanningVersion.content ? (
            <Card
              title={t('projects.gantt')}
              style={{ width: '100%', height: 800, margin: '16px 0 24px' }}
            >
              <Divider />
              <div
                style={{
                  maxWidth: '100%',
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  maxHeight: 750
                }}
              >
                <Gantt
                  onClick={getDataChartEvent}
                  data={dataGantt}
                  purpose="dashboard"
                />
              </div>
            </Card>
          ) : (
            <Card style={{ width: 600 }} title={t('projects.gantt')}>
              <Divider />
              {null}
            </Card>
          )}
        </Col>
      </Row>
    </>
  ) : (
    <Skeleton />
  );
};

IndicatorsDashboard.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  getDataChartEvent: PropTypes.func,
  purpose: PropTypes.string
};

IndicatorsDashboard.defaultProps = {
  data: null,
  getDataChartEvent: null,
  purpose: ''
};

export default IndicatorsDashboard;
