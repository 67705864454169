import { useTranslation } from 'react-i18next';

/**
 * A custom hook for generating a list of common details for display.
 *
 * @hook
 * @param {object} commonData - The common data to generate the list for.
 * @returns {Array} An array of common details with labels and content.
 */
const useCommonList = (commonData) => {
  const { t } = useTranslation();
  if (!commonData) return [];

  const commonList = [
    {
      label: t('projects.form.deliverables.description'),
      content: commonData.description
    },
    {
      label: t('projects.form.deliverables.global_type'),
      content: commonData.global_type
    },
    {
      label: t('projects.form.deliverables.comment'),
      content: commonData.comment
    }
  ];

  return commonList;
};

export default useCommonList;
