import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Descriptions,
  Divider,
  InputNumber,
  Row,
  message
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../../../../../../utils/constants/customIcon';
import useProjectContext from '../../../../../../../../contexts/ProjectContext';
import FederationIdicator from '../../FederationIdicator';
import UpdateDuplicate from '../../../../../utilsProject/UpdateDuplicate';

/**
 * Component for displaying detailed information about a PlanCom event card.
 *
 * @component
 * @param {Object} dataChart - The formatted data for the PlanCom event card.
 * @param {Function} setDataChart - A function to set data for the event card.
 * @param {string} projectStatus - The status of the project.
 * @param {string} versionId - The ID of the version.
 * @param {Function} handleClose - A function to handle the close action.
 * @param {boolean} role - Control components visibility according to user's role.
 * @returns {JSX.Element} PlanComEventCard component
 */
const PlanComEventCard = ({
  dataChart,
  setDataChart,
  projectStatus,
  versionId,
  handleClose,
  role
}) => {
  const { t } = useTranslation();
  const { patchDuplicateEvent } = useProjectContext();
  const [visibleDuplication, setVisibleDuplication] = useState(false);
  const [editFederationValue, setEditFederationValue] = useState(false);
  const [federationValue, setFederationValue] = useState(dataChart.federation);

  const handleFederationChange = (value) => {
    setFederationValue(value);
  };

  const handleSubmit = async () => {
    try {
      if (editFederationValue)
        await patchDuplicateEvent(
          { federation: federationValue },
          dataChart._id
        );

      setEditFederationValue(!editFederationValue);
    } catch (error) {
      if (error.response) message.error(error.response);
    }
  };

  useEffect(() => {
    setFederationValue(dataChart.federation);
  }, [dataChart]);

  return (
    <Card
      style={{
        width: 500,
        height: 250,
        position: 'absolute',
        right: 0,
        top: 0
      }}
      extra={
        <Row justify="space-between">
          <Button
            onClick={handleClose}
            style={{
              color: 'var(--errorColor)',
              background: 'transparent',
              border: 'none',
              marginLeft: 5,
              boxShadow: 'none'
            }}
          >
            <CloseOutlined />
          </Button>
        </Row>
      }
      key={dataChart._id}
      title={dataChart.task}
    >
      <Divider />
      {visibleDuplication && (
        <>
          <UpdateDuplicate
            setData={setDataChart}
            visible={visibleDuplication}
            setVisible={setVisibleDuplication}
            data={dataChart}
            versionId={versionId}
          />
          <Divider />
        </>
      )}
      <Descriptions column={2} size="small">
        {dataChart?.start_date !== null ? (
          <Descriptions.Item
            span={2}
            label={t(`projects.form.items.utility.deliverables.start_date`)}
          >
            <Row justify="space-between">
              <span>
                {moment(dataChart.start_date).format('dddd DD-MM-YYYY') !== null
                  ? `${moment(dataChart.start_date).format('dddd DD-MM-YYYY')}`
                  : ''}
              </span>
              {role && (
                <Button
                  disabled={projectStatus === 'close'}
                  size="small"
                  type="link"
                  style={{
                    color: 'var(--secondaryColor)',
                    top: -2,
                    marginLeft: 50
                  }}
                  onClick={() => setVisibleDuplication(!visibleDuplication)}
                >
                  <EditIcon />
                </Button>
              )}
            </Row>
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item
          span={2}
          label={t('projects.form.items.utility.deliverables.description')}
        >
          {dataChart.description}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          label={t(
            'projects.form.items.plan_com.deliverables_plan_com.in_charge_of'
          )}
        >
          {dataChart.in_charge_of}
        </Descriptions.Item>
        <Descriptions.Item
          label={t(
            'projects.form.items.plan_com.deliverables_plan_com.federation'
          )}
        >
          <Row>
            {editFederationValue ? (
              <InputNumber
                style={{ width: '50px' }}
                value={federationValue}
                onChange={handleFederationChange}
              />
            ) : (
              <FederationIdicator noStyle federationValue={federationValue} />
            )}
            {role && (
              <Button
                disabled={projectStatus === 'close'}
                size="small"
                type="link"
                style={{
                  color: 'var(--secondaryColor)',
                  top: -2,
                  marginLeft: 50
                }}
                onClick={handleSubmit}
              >
                {editFederationValue ? <CheckOutlined /> : <EditIcon />}
              </Button>
            )}
          </Row>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

PlanComEventCard.propTypes = {
  dataChart: PropTypes.shape({
    _id: PropTypes.string,
    start_date: PropTypes.string,
    task: PropTypes.string,
    in_charge_of: PropTypes.string,
    description: PropTypes.string,
    federation: PropTypes.number
  }).isRequired,
  setDataChart: PropTypes.func.isRequired,
  projectStatus: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  role: PropTypes.bool
};

PlanComEventCard.defaultProps = {
  role: false
};

export default PlanComEventCard;
