import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Modal, Row, Col } from 'antd';
import usePriorFilters from './PriorFilters/usePriorFilters';
import AddItemButton from '../../../../../components/AddItemButton/AddIemButton';
import PriorCard from './PriorCard';

/**
 * Component to display prior information for a version.
 *
 * @component
 * @param {Object} version - The version object containing prior items.
 * @param {string} title - The item type.
 * @param {Function} getProject - The function to get the project information.
 * @param {Function} generateForm - The function to generate a form.
 * @param {boolean} role - A boolean controling component visibility according to user role.
 * @param {Function} setDataToModalFiles - Function to set data to modal files.
 * @param {Function} getFilesByItem - Function to get files by item.
 * @param {Object} project - The project.
 * @returns {JSX.Element} - The rendered PriorDisplay component.
 */
const PriorDisplay = ({
  version,
  title,
  getProject,
  generateForm,
  role,
  setDataToModalFiles,
  getFilesByItem,
  project
}) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visible, setVisible] = useState();
  const [dataStudie, setDataStudie] = useState();
  const { getPriorFilters, getfilteredPriors } = usePriorFilters(
    project?.prior_list
  );

  const config = {
    onGetResource: {
      setFields: (data) => ({
        studies: data
      })
    }
  };

  const openFormModal = () => {
    setVisibleModal(true);
  };
  const openForm = (idRender, studie) => {
    const { creation_date, ...rest } = studie;
    setVisible(idRender);
    setDataStudie({ ...rest, creation_date: moment(creation_date) });
  };

  const closeForm = () => {
    setVisible(false);
    getProject();
  };

  const closeFormModal = () => {
    setVisibleModal(false);
    getProject();
  };

  useEffect(() => {
    if (version?.content?.files) getFilesByItem(version.content.files);
  }, [project]);

  return (
    <>
      {getPriorFilters()}
      <Row>
        <Col span={24}>
          {getfilteredPriors(version?.content).map((prior) => {
            const { _id } = prior;
            return (
              <React.Fragment key={_id}>
                {visible === _id && dataStudie ? (
                  generateForm(
                    'edit',
                    version,
                    title,
                    closeForm,
                    dataStudie,
                    'studies',
                    config
                  )
                ) : (
                  <PriorCard
                    prior={prior}
                    project={project}
                    version={version}
                    title={title}
                    role={role}
                    setDataToModalFiles={setDataToModalFiles}
                    openForm={openForm}
                    closeForm={closeForm}
                  />
                )}
              </React.Fragment>
            );
          })}
          {visibleModal && (
            <Modal
              title={t(`projects.form.items.${title}.title`)}
              closable
              width={800}
              visible={visibleModal}
              footer={false}
              onCancel={closeFormModal}
            >
              {generateForm(
                'createMatrice',
                version,
                title,
                closeFormModal,
                null,
                'studies'
              )}
            </Modal>
          )}
          {role && (
            <AddItemButton
              disabled={project?.status === 'close'}
              onClick={openFormModal}
              title={t('projects.form.items.prior.studies.addStudie')}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

PriorDisplay.propTypes = {
  version: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  title: PropTypes.string,
  getProject: PropTypes.func,
  generateForm: PropTypes.func,
  role: PropTypes.bool,
  setDataToModalFiles: PropTypes.func,
  getFilesByItem: PropTypes.func,
  project: PropTypes.shape({
    status: PropTypes.string,
    perimeter: PropTypes.shape({
      attached_files: PropTypes.bool
    }),
    prior_list: PropTypes.arrayOf(PropTypes.string)
  })
};

PriorDisplay.defaultProps = {
  version: null,
  title: null,
  generateForm: null,
  getProject: null,
  role: false,
  setDataToModalFiles: () => {},
  getFilesByItem: () => {},
  project: null
};

export default PriorDisplay;
