import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Input,
  Popconfirm,
  Spin,
  Tag,
  message
} from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Datatable from '../../../components/DataTable/Datatable';
import { routes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import AddUsersModal from './AddUsersModal';
import { userRoles } from '../../../utils/constants/tagColors';

const { Search } = Input;

const UsersList = ({
  users,
  perimeter,
  updatePerimeter,
  refresh,
  setUsersToAddOrRemove,
  purpose,
  setPurpose
}) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const iconSize = 18;
  const { pathname, location } = useLocation();
  const params = new URLSearchParams(location?.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const history = useHistory();

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  const columns = [
    {
      title: `${t(`datatable.column.last_name`)}`,
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name)
    },
    {
      title: `${t(`datatable.column.first_name`)}`,
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: `${t(`datatable.column.email`)}`,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('_').length > 1
            ? `${r.split('_')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      key: 'action',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.USERS}/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {user?.role === 'admins:PERIMETER_ADMIN' && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                key={record.key}
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={() => {
                  setPurpose('remove');
                  setUsersToAddOrRemove([record.key]);
                }}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{ color: 'var(--errorColor)', fontSize: iconSize }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  useEffect(() => {
    try {
      if (purpose) {
        (async () => {
          await updatePerimeter();
        })();
        setVisible(false);
        setPurpose();
      }
    } catch (e) {
      message.error(
        e.response ? t(`errors.message.${e.response.status}`) : e.message
      );
      setLoading(false);
    }
  }, [purpose]);

  return users ? (
    <Spin spinning={loading}>
      <Card
        title={t(`perimeters.users.title`)}
        className="perimeter-users-card"
        extra={
          user?.role === 'admins:PERIMETER_ADMIN' && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setVisible(true);
              }}
            >
              {t(`buttons.add`)}
            </Button>
          )
        }
      >
        <AddUsersModal
          visible={visible}
          setVisible={setVisible}
          setPurpose={setPurpose}
          setUsersToAddOrRemove={setUsersToAddOrRemove}
          users={users}
          perimeterName={perimeter?.name}
        />
        <Divider />
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          defaultValue={searchValue}
          onSearch={(value) => searchResource(value)}
        />
        <Datatable
          style={{ marginTop: 16 }}
          resourceName="Users"
          columns={columns}
          customActionColumn
          extraQuery={`perimeters=${perimeter._id}`}
          populate="users"
          forceRefresh={refresh}
          scroll={500}
          disableDoubleClick={user.role !== 'admins:ADMIN'}
          path={routes.USERS}
        />
      </Card>
    </Spin>
  ) : (
    <Spin />
  );
};

UsersList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired
    })
  ).isRequired,
  perimeter: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    assignee: PropTypes.shape({ _id: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  updatePerimeter: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setUsersToAddOrRemove: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  setPurpose: PropTypes.func.isRequired
};

UsersList.defaultProps = {
  purpose: null
};

export default UsersList;
