import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col, Modal, Spin, message } from 'antd';
import usePlanningList from './usePlanningList';
import DescriptionList from '../../../../../../components/DescriptionList/DescriptionList';
import useUtilityList from './useUtilityList';
import useCommonList from './useCommonList';

/**
 * A modal component for displaying detailed information about project manage planning.
 *
 * @component
 * @param {Object} dataItems - Data items containing planning and utility details.
 * @param {boolean} visible - Indicates whether the modal is visible or not.
 * @param {function} setVisible - Function to control the visibility of the modal.
 * @param {function} updateResource - Function to update the resource.
 * @returns {JSX.Element} React component for displaying detailed action information in a modal.
 */
const ShowActionModal = ({
  dataItems,
  visible,
  setVisible,
  updateResource
}) => {
  const { t } = useTranslation();
  const commonList = useCommonList(dataItems);
  const { planning, utility } = dataItems || {};
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (percentage) => {
    setIsLoading(true);
    try {
      await updateResource({
        ...dataItems,
        planning: { _id: planning?._id, percentage }
      });
      message.success(
        t('projects.form.deliverables.planning.update_percentage')
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.message) message.error(error.message);
    }
  };

  const listItemsManage = {
    planning: usePlanningList(planning, (percentage) =>
      handleSubmit(percentage)
    ),
    utility: useUtilityList(utility)
  };

  return (
    <Modal
      visible={visible}
      title={t('projects.form.deliverables.show_title')}
      onCancel={() => {
        setVisible(false);
      }}
      footer={null}
    >
      <Spin spinning={isLoading}>
        {dataItems ? (
          <Col span={24} style={{ padding: 0 }}>
            <Row
              style={{
                boxShadow: 0,
                borderRadius: 10,
                backgroundColor: 'var(--clientColor',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                height: 50,
                marginBottom: 10
              }}
            >
              <span style={{ color: '#fff' }}>
                {t('projects.form.common_fields')}
              </span>
            </Row>
            <DescriptionList data={commonList} column={2} />
            <Row gutter={16}>
              {Object.entries(listItemsManage).map(([key, value]) => {
                const spanValue = Math.floor(24 / dataItems.length);
                return (
                  dataItems[key] && (
                    <Col
                      key={key}
                      span={spanValue}
                      style={{ marginBottom: 20 }}
                    >
                      <Row
                        style={{
                          boxShadow: 0,
                          borderRadius: 10,
                          backgroundColor: 'var(--clientColor',
                          display: 'flex',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          height: 50,
                          marginBottom: 10,
                          marginTop: 20
                        }}
                      >
                        <span style={{ color: '#fff' }}>
                          {t(`projects.form.${key}`)}
                        </span>
                      </Row>
                      <DescriptionList data={value} column={2} />
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
        ) : null}
      </Spin>
    </Modal>
  );
};

ShowActionModal.propTypes = {
  dataItems: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    })
  }),
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  updateResource: PropTypes.func
};
ShowActionModal.defaultProps = {
  dataItems: null,
  config: null,
  visible: false,
  updateResource: () => {}
};

export default ShowActionModal;
