import React from 'react';
import useSearchFilter from '../../../../../../utilsProject/Filters/useSearchFilter';
import useSelectFilter from '../../../../../../utilsProject/Filters/useSelectFilter';
import useRangeFilter from '../../../../../../utilsProject/Filters/useRangeFilter';
import ItemFilters from '../../../../../../utilsProject/Filters/ItemFilters';

/**
 * Custom hook for managing and applying filters to risk information.
 *
 * @hook
 * @param {string[]} types - An array of available types for filtering.
 * @returns {Object} - An object containing functions for rendering filters and applying filters.
 */
const useRiskFilters = (types) => {
  const { getSearchFilter, getSearchedValues } = useSearchFilter();
  const { getSelectFilter, getFilteredValues } = useSelectFilter(
    types,
    'types'
  );
  const { getRangeFilter, getRangeFilteredValues } = useRangeFilter();

  const getRiskFilters = () => {
    return (
      <ItemFilters
        filters={[getSearchFilter(), getSelectFilter(), getRangeFilter()]}
      />
    );
  };

  const getFilteredRisks = (risks) => {
    if (!risks) return [];
    return getRangeFilteredValues(
      getFilteredValues(
        getSearchedValues(risks, (risk) => risk?.potential_risk),
        (risk) => risk?.type_of_risk
      ),
      (risk) => risk?.decision_date
    );
  };

  return { getRiskFilters, getFilteredRisks };
};

export default useRiskFilters;
