import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { useTranslation } from 'react-i18next';
import EventsTabPane from './TabPanes/EventsTabPane/EventsTabPane';
import GanttTabPane from './TabPanes/GanttTabPane/GanttTabPane';

/**
 * Component that displays tabs for different views within the PlanCom display.
 *
 * @component
 * @param {Object} project - The project data.
 * @param {Object} version - The version object containing planCom items.
 * @param {Function} generateForm - A function to generate the form for creating or updating PlanCom items.
 * @param {Object} config - Configuration object for the component.
 * @param {boolean} role - Control component visibility according to user's role.
 * @param {Object} dataDeliverables - Data for deliverables.
 * @param {Function} setDataDeliverables - A function to set data for deliverables.
 * @param {Function} setVisibleModal - A function to set the visibility of a modal.
 * @param {Function} getProject - A function to fetch project data.
 * @param {string} title - The item type.
 * @returns {JSX.Element} PlanComTabs component
 */
const PlanComTabs = ({
  project,
  version,
  generateForm,
  config,
  role,
  dataDeliverables,
  setDataDeliverables,
  setVisibleModal,
  getProject,
  title
}) => {
  const { t } = useTranslation();

  const tabPanes = {
    events: (
      <EventsTabPane
        project={project}
        version={version}
        generateForm={generateForm}
        title={title}
        config={config}
        dataDeliverables={dataDeliverables}
        setDataDeliverables={setDataDeliverables}
        getProject={getProject}
        role={role}
      />
    ),
    gantt: (
      <GanttTabPane
        project={project}
        version={version}
        setVisibleModal={setVisibleModal}
        role={role}
      />
    )
  };

  return (
    <Tabs
      animated={{ inkBar: true }}
      id="blue-tab-planning"
      defaultActiveKey="Gantt"
    >
      {Object.entries(tabPanes).map(([key, value]) => (
        <TabPane tab={t(`projects.form.items.plan_com.${key}`)} key={key}>
          {value}
        </TabPane>
      ))}
    </Tabs>
  );
};

PlanComTabs.propTypes = {
  project: PropTypes.shape({}).isRequired,
  version: PropTypes.shape({}).isRequired,
  generateForm: PropTypes.func.isRequired,
  config: PropTypes.shape({}).isRequired,
  role: PropTypes.bool,
  dataDeliverables: PropTypes.shape({}),
  setDataDeliverables: PropTypes.func.isRequired,
  setVisibleModal: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

PlanComTabs.defaultProps = {
  role: false,
  dataDeliverables: null
};

export default PlanComTabs;
