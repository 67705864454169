import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form, DatePicker, Divider, Radio, Tooltip } from 'antd';
import setIconStatus from '../../../utils/constants/setIconStatus';
import { listVersion } from '../../../utils/constants/constantes';
import RadioForChooseTypeOfFile from '../widgetsReporting/RadioForChooseTypeOfFile';

const { RangePicker } = DatePicker;
const PlanningSelected = ({
  t,
  keyTab,
  version,
  current_status,
  actors_list,
  extensionFile,
  setExtensionFile,
  setselectedDates,
  setselectedActor
}) => {
  const checkIfStatusIsAvailable = (v) => {
    if (v === 'V-init') {
      return false;
    }
    if (v === 'V0') {
      if (
        current_status === 'V0' ||
        current_status === 'V1' ||
        current_status === 'VX'
      )
        return false;
      return true;
    }
    if (v === 'V1') {
      if (current_status === 'V1' || current_status === 'VX') return false;
      return true;
    }
    if (v === 'VX') {
      if (current_status === 'VX') return false;
      return true;
    }
    return true;
  };
  return (
    <div
      style={{
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}
    >
      <RadioForChooseTypeOfFile
        keyTab={keyTab}
        extensionFile={extensionFile}
        setExtensionFile={setExtensionFile}
      />
      <Divider />
      <Form.Item name={['version']} initialValue={version}>
        <Radio.Group
          keyTab={keyTab}
          buttonStyle="solid"
          style={{
            margin: '10px 0px 10px 0px',
            width: '100%'
          }}
        >
          {listVersion.map((e) => (
            <Tooltip
              key={e}
              placement="topLeft"
              title={`${t('reporting.items_tooltip')} ${e === 'VX' ? 'V+' : e}`}
            >
              <Radio disabled={checkIfStatusIsAvailable(e)} value={e}>
                {setIconStatus(null, e, false, 19)}
              </Radio>
            </Tooltip>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name={['dates']}>
        <RangePicker
          onChange={(dates) =>
            dates ? setselectedDates(dates) : setselectedDates([])
          }
        />
      </Form.Item>
      <Form.Item label={t('reporting.select_actor')} name={['actor']}>
        <Select
          allowClear
          onChange={(actor) =>
            actor ? setselectedActor(actor) : setselectedActor(null)
          }
        >
          {actors_list.map((actor) => (
            <Select.Option key={actor._id} value={actor._id}>
              {`${actor.group || ''} ${actor.first_name ||
                ''} ${actor.last_name || ''}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

PlanningSelected.propTypes = {
  t: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  current_status: PropTypes.string,
  keyTab: PropTypes.string,
  actors_list: PropTypes.oneOfType([PropTypes.array]),
  extensionFile: PropTypes.string.isRequired,
  setExtensionFile: PropTypes.func.isRequired,
  setselectedDates: PropTypes.func.isRequired,
  setselectedActor: PropTypes.func
};
PlanningSelected.defaultProps = {
  current_status: null,
  actors_list: [],
  keyTab: null,
  setselectedActor: () => {}
};

export default PlanningSelected;
