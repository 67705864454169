import React from 'react';
import PropTypes from 'prop-types';
import { CloudServerOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';

/**
 * Component for an import document button with a badge.
 *
 * @component
 * @param {number} count - The number to display on the badge.
 * @param {boolean} disabled - Indicates whether the button is disabled.
 * @param {Function} onClick - The function to be called when the button is clicked.
 * @returns {JSX.Element} Component for an import document button with a badge.
 */
const ImportDocumentButton = ({ count, disabled, onClick }) => {
  return (
    <Badge count={count}>
      <Button
        disabled={disabled}
        onClick={onClick}
        type="primary"
        size="small"
        style={{ bottom: -5 }}
        icon={<CloudServerOutlined size={20} />}
      />
    </Badge>
  );
};

ImportDocumentButton.propTypes = {
  count: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

ImportDocumentButton.defaultProps = {
  count: 0,
  disabled: true,
  onClick: null
};

export default ImportDocumentButton;
