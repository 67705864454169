import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

/**
 * HeaderDropdown is a reusable React component that wraps content with a dropdown behavior.
 *
 * @component
 * @param {object|function} overlayMenu - The content of the dropdown menu, either an object or a function.
 * @param {ReactNode} children - The content that triggers the dropdown when clicked.
 * @param {function} onOpenChange - A function called when the dropdown is opened or closed.
 * @returns {ReactNode} - A ReactNode representing the HeaderDropdown component.
 */
const HeaderDropdown = ({ children, overlayMenu, onOpenChange }) => {
  return (
    <Dropdown overlay={overlayMenu} onOpenChange={onOpenChange}>
      {children}
    </Dropdown>
  );
};

HeaderDropdown.propTypes = {
  overlayMenu: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  children: PropTypes.node,
  onOpenChange: PropTypes.func
};

HeaderDropdown.defaultProps = {
  onOpenChange: () => {},
  children: null
};

export default HeaderDropdown;
